<template>
  <div class="atmo-contest-list-item">
    <router-link
      :to="{
        name: 'contests.show',
        params: { contestId: contest.id },
      }"
      class="atmo-contest-list-item__image"
      :style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${contest.image.url})` }"
    />
    <div class="atmo-contest-list-item__content-wrap">
      <div class="content-wrap__title-description-wrap">
        <div class="title-description-wrap__title-edit-popover">
          <router-link
            :to="{
              name: 'contests.show',
              params: { contestId: contest.id },
            }"
            class="title-edit-popover__title"
          >
            {{ contest.name }}
          </router-link>
          <atmo-popover v-if="$store.state.currentUserId === contest.user_id" placement="bottom">
            <template #trigger>
              <img class="contest-trigger" src="@/assets/images/icons/kebab_menu.png" alt="actions" />
            </template>
            <template #content>
              <router-link :to="{ name: 'contests.edit', params: { contestId: contest.id } }" class="atmo-popover__dropdown-action">
                <div class="atmo-popover__dropdown-action-image-container">
                  <img class="atmo-popover__dropdown-action-image" src="@/assets/images/icons/edit.png" alt="Edit contest" />
                </div>
                Edit contest
              </router-link>
              <a class="atmo-popover__dropdown-action" @click="deleteContest(contest)">
                <div class="atmo-popover__dropdown-action-image-container">
                  <img class="atmo-popover__dropdown-action-image" src="@/assets/images/icons/delete.png" alt="Delete contest" />
                </div>
                Delete contest
              </a>
            </template>
          </atmo-popover>
        </div>
        <div class="title-description-wrap__description">
          {{ contest.description }}
        </div>
      </div>
      <div class="content-wrap__author-date-wrap">
        <div class="author-date-wrap__author-wrap">
          <img
            class="atmo-avatar atmo-avatar--extra-small"
            :style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${getFeaturedImage(contest.user.images, 'full')})` }"
          />
          <div class="">
            {{ artist.name }}
          </div>
        </div>
        <div class="author-date-wrap__date-wrap">{{ timeLeft }} Left</div>
      </div>
    </div>
  </div>
</template>

<script>
import { distanceInWordsStrict } from "date-fns";
//import User from '@/models/user';
import devMockData from "@/devUtils/mockData.js";
import { getFeaturedImage } from "@/helpers/utilityFunctions";
import AtmoPopover from "@/components/atmo-popover";

export default {
  components: {
    AtmoPopover,
  },
  props: {
    contest: {
      type: Object,
      required: true,
    },
  },
  created() {},
  data: function () {
    return {
      devMockData: devMockData,
    };
  },
  methods: {
    deleteContest(contest) {
      this.$emit("delete-contest", contest);
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
  },
  computed: {
    artist() {
      return this.contest.user;
    },
    timeLeft() {
      return distanceInWordsStrict(new Date(), new Date(this.contest.end_date));
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-contest-list-item {
  display: flex;
  align-items: center;
  // min-height: 12rem;
  color: white;
  padding: 0.5rem 0rem 0.5rem 0rem;

  &__image {
    width: 17rem;
    min-height: 10rem;
    border-radius: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $atmo-gray--light;
    background-size: cover;
  }

  &__content-wrap {
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 0px 5px 5px 0px;
    min-height: 8rem;
    width: 100%;
  }

  .contest-trigger {
    height: 1rem;
  }

  .content-wrap__title-description-wrap {
    margin: 0rem 1rem 1rem 1rem;
    padding: 1rem 0rem 1rem 0rem;
    border-bottom: 1px solid $atmo-gray--medium;
  }

  .title-description-wrap__title-edit-popover {
    display: flex;
    justify-content: space-between;
  }

  .title-edit-popover__title {
    color: $atmo-blue--medium;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .content-wrap__author-date-wrap {
    padding: 0rem 1rem 1rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .author-date-wrap__author-wrap {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-weight: 500;
  }

  .author-date-wrap__date-wrap {
    font-weight: 500;
  }
}
</style>
