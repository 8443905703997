<template>
  <AtmoModal
    id="directory-filter"
    ref="directoryFilterRef"
    title="Filter"
    customClass="atmo-directory-filter"
    v-model="$store.state.directoryProfiles.isFilterProfilesModalOpen"
    @opened="populateDropdowns"
    :closeFunction="() => $store.dispatch('directoryProfiles/toggleFilterProfilesModal')"
  >
    <header class="atmo-modal__header">
      <ModalHeader heading="Filter" marginBottom="2rem" />
    </header>

    <form class="atmo-form atmo-directory-filter__form" @submit.prevent="submitForm">
      <fieldset class="atmo-directory-filter__radio-group">
        <!-- Location Filter -->
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'location' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="location" v-model="formSelectedFilterType" />
          </div>

          <div class="field-wrap">
            <label for="country">Country</label>
            <select v-model="formSelectedCountry" @change="onCountryChange" @focus="setFilterType('location')" class="dropdown-group">
              <option value="" disabled>Select Country</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">
                {{ country.name }}
              </option>
            </select>
          </div>

          <div class="field-wrap">
            <label for="state">State</label>
            <select v-model="formSelectedState" :disabled="!stateOptions.length" @change="onStateChange" @focus="setFilterType('location')" class="dropdown-group">
              <option value="" disabled>{{ stateOptions.length ? "Select State" : "No States Available" }}</option>
              <option v-for="state in stateOptions" :key="state.id" :value="state.id">
                {{ state.name }}
              </option>
            </select>
          </div>

          <div class="field-wrap">
            <label for="city">City</label>
            <select v-model="formSelectedCity" :disabled="!cityOptions.length" @focus="setFilterType('location')" class="dropdown-group">
              <option value="" disabled>Select City</option>
              <option v-for="city in cityOptions" :key="city.id" :value="city.id">
                {{ city.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="atmo-form__row">
          <div class="atmo-form__radio-container" />
          <hr class="atmo-form__hr" />
        </div>

        <!-- Profession Filter -->
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'profession' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="profession" v-model="formSelectedFilterType" />
          </div>
          <div class="field-wrap">
            <label for="profession">Profession</label>
            <select v-model="formSelectedProfession" @focus="setFilterType('profession')" class="dropdown-group">
              <option value="" disabled>Select Profession</option>
              <option v-for="profession in professions" :key="profession.id" :value="profession.id">
                {{ profession.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="atmo-form__row">
          <div class="atmo-form__radio-container" />
          <hr class="atmo-form__hr" />
        </div>

        <!-- Price Filter -->
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'price' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="price" v-model="formSelectedFilterType" />
          </div>
          <AtmoSlider v-model="formSelectedPrice" :min="minPrice" :max="maxPrice" :step="10" @selectFilter="setFilterType('price')" />
        </div>
      </fieldset>

      <button type="submit" :disabled="!formSelectedFilterValue" class="atmo-button atmo-button--primary atmo-button--stretch">Search Now</button>
    </form>
  </AtmoModal>
</template>

<script>
import ModalHeader from "@/components/modal-header";
import AtmoSlider from "@/components/atmo-slider";
import { mapState, mapActions } from "vuex";

const MIN_PRICE = 0;
const MAX_PRICE = 5000;

export default {
  components: {
    ModalHeader,
    AtmoSlider,
  },
  props: {
    professions: { type: Array, required: true },
    selectedFilterType: { type: String, required: true },
    selectedCountry: { type: Object, default: null },
    selectedState: { type: Object, default: null },
    selectedCity: { type: Object, default: null },
    selectedProfession: { type: Object, default: null },
    selectedMinPrice: { type: Number, default: null },
    selectedMaxPrice: { type: Number, default: null },
    onSubmit: { type: Function, required: true },
  },
  data() {
    return {
      minPrice: MIN_PRICE,
      maxPrice: MAX_PRICE,
      formSelectedPrice: [MIN_PRICE, MAX_PRICE],
      formSelectedFilterType: "location",
      formSelectedCountry: this.selectedCountry?.id ?? null,
      formSelectedState: this.selectedState?.id ?? null,
      formSelectedCity: this.selectedCity?.id ?? null,
      formSelectedProfession: this.selectedProfession?.id ?? null,
    };
  },
  computed: {
    ...mapState("address", ["countries", "states", "cities"]),
    formSelectedFilterValue() {
      switch (this.formSelectedFilterType) {
        case "location":
          return this.formSelectedState;
        case "profession":
          return this.formSelectedProfession;
        case "price":
          return [this.formSelectedMinPrice, this.formSelectedMaxPrice];
        default:
          return null;
      }
    },
    stateOptions() {
      return this.states.filter((state) => state.country_id === this.formSelectedCountry);
    },
    cityOptions() {
      return this.cities.filter((city) => city.state_id === this.formSelectedState);
    },
  },
  watch: {
    selectedCountry(newVal) {
      this.formSelectedCountry = newVal?.id ?? null;
    },
    selectedState(newVal) {
      this.formSelectedState = newVal?.id ?? null;
    },
    selectedCity(newVal) {
      this.formSelectedCity = newVal?.id ?? null;
    },
    selectedProfession(newVal) {
      this.formSelectedProfession = newVal?.id ?? null;
    },
  },
  methods: {
    ...mapActions("address", ["fetchCountries", "fetchStates", "fetchCities"]),
    async populateDropdowns() {
      await this.fetchCountries();
      if (this.formSelectedCountry) {
        await this.fetchStates(this.formSelectedCountry);
      }
      if (this.formSelectedState) {
        await this.fetchCities(this.formSelectedState);
      }
    },
    setFilterType(newFilterType) {
      this.formSelectedFilterType = newFilterType;
    },
    onCountryChange() {
      this.formSelectedState = null;
      this.formSelectedCity = null;
      if (this.formSelectedCountry) {
        this.fetchStates(this.formSelectedCountry);
      }
    },
    onStateChange() {
      this.formSelectedCity = null;
      if (this.formSelectedState) {
        this.fetchCities(this.formSelectedState);
      }
    },
    submitForm() {
      this.onSubmit({
        filterType: this.formSelectedFilterType,
        country_id: this.formSelectedCountry,
        state_id: this.formSelectedState,
        city_id: this.formSelectedCity,
        directory_profession_id: this.formSelectedProfession,
        minPrice: this.formSelectedPrice[0],
        maxPrice: this.formSelectedPrice[1],
      });
      this.$store.dispatch("directoryProfiles/toggleFilterProfilesModal");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-directory-filter {
  .atmo-modal__header {
    display: flex;
    justify-content: center;
  }

  &__form {
    margin: 0 20px 25px;
  }

  &__radio-group {
    margin-bottom: 50px;
  }

  .multiselect {
    flex: 1;
  }

  .field-wrap {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }

  .dropdown-group {
    background: $atmo-purple--medium-dark;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    font-weight: 500rem;
    cursor: pointer;
  }

  .atmo-form__radio-container {
    display: none;
  }

  .atmo-form__row--blurred .asd__wrapper {
    display: none;
  }
}
</style>
