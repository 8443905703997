<template>
  <div class="songs-grid">
    <div v-for="(item, index) in artists" :key="index" class="shared-item-card">
      <router-link v-if="item?.id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: item?.id } }">
        <div
          class="image-wrap"
          :style="{
            'background-image': `linear-gradient(-270deg, rgba(68, 174, 220, 0.5), rgba(217, 82, 167, 0.5)), url(${getFeaturedImage(item?.user?.images, 'full')})`,
          }"
        ></div>
        <p class="item-title">{{ item?.name }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import { getFeaturedImage } from "@/helpers/utilityFunctions";
export default {
  props: {
    artists: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getFeaturedImage(images, type) {
      return getFeaturedImage(images, type);
    },
  },
};
</script>
