<template>
  <div class="atmo-products-edit-variants">
    <div class="modal" v-if="showModal">
      <div class="atmo-modal-content">
        <img class="hide-modal" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Image Selection" @click="closeModalAndSetVariantImage" />
        <header class="atmo-modal__header">
          <div class="atmo-page-header-container">
            <div class="atmo-page-header-container__header-icon-wrap">
              <h2 class="atmo-page-header">Select Image</h2>
            </div>
          </div>
        </header>
        <div class="images-container">
          <div v-for="existingImage in editProductData.existingProductImages" :key="existingImage.id" class="image-wrapper" @click="selectImage(existingImage, 'existing')">
            <img :src="existingImage.image.url" alt="Product Image" :class="{ selected: isImageSelected('existing', existingImage) }" class="modal-image" />
          </div>
          <div v-for="(image, index) in editProductData.newProductImages" :key="index" class="image-wrapper" @click="selectImage(image, 'new')">
            <img :src="image.imagePreview" alt="Product Image" :class="{ selected: isImageSelected('new', image) }" class="modal-image" />
          </div>
        </div>
        <button type="submit" class="main-wrap__submit-button" @click="closeModalAndSetVariantImage">Done</button>
      </div>
    </div>
    <div class="atmo-products-edit-variants__container">
      <div class="back-container" @click="goBack()">
        <div class="back-container__icon-container">
          <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
        </div>
        <div class="back-container__text-container">Back to previous</div>
      </div>
      <div class="container__container-wrap">
        <atmo-loading v-if="isLoading" />
        <div v-else>
          <div class="tabs">
            <button v-for="(group, index) in productVariantGroups" :key="index" @click="selectColor(index)" :class="{ active: selectedColorIndex === index }">
              {{ group.color.name }}
            </button>
          </div>
          <form @submit.prevent="updateProductAndVariants" class="container-wrap__form">
            <div v-if="selectedColor" class="variant-wrap">
              <div class="field-wrap select-image">
                <label for="newVariantImage">{{ selectedColor.name }} Image</label>
                <div @click="openModal()">
                  <div v-if="getSelectedImageUrl()">
                    <img :src="getSelectedImageUrl()" alt="Selected Image" class="selected-image-preview" />
                  </div>
                  <div v-else class="select-image-box">
                    <img src="@/assets/images/icons/profile/upload_new.png" />
                  </div>
                </div>
              </div>
              <perfect-scrollbar class="variant-details-container">
                <div class="form__product-details-wrap" v-for="(variant, index) in productVariantGroups[selectedColorIndex].variants" :key="index">
                  <div class="size-name">
                    {{ variant.product_size.name }}
                  </div>
                  <div class="product-details-wrap__product-details">
                    <div class="field-wrap">
                      <label for="newVariantPrice">Price ($USD)</label>
                      <input class="text-field" id="newVariantPrice" v-model="variant.price" type="number" :placeholder="variant.price" required />
                    </div>
                    <div class="field-wrap">
                      <label for="newVariantQuantity">Quantity</label>
                      <input class="text-field" id="newVariantQuantity" v-model="variant.quantity" type="number" placeholder="200" required />
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
            <div class="main-wrap__footer">
              <button type="submit" class="main-wrap__submit-button" v-if="formCompleted">Finish</button>
              <button type="submit" class="main-wrap__submit-button-inactive" v-else>Finish</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoLoading from "@/components/atmo-loading";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { editProductAndVariants } from "@/helpers/services/products/editProduct";
import { callProductsShow } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoLoading,
  },

  data: function () {
    return {
      isLoading: true,
      selectedColorIndex: 0,
      showModal: false,
      productVariantGroups: [],
      selectedColor: null,
    };
  },

  computed: {
    ...mapState("products", ["editProductData"]),
    ...mapGetters("products", []),
    formCompleted() {
      return this.formIsCompleted();
    },
  },
  async created() {
    console.log("THIS EDIT IMAGES", this.editProductData);
    this.$store.commit("setHudHeader", "Edit Product Variants");
    await this.loadExistingVariants();
  },
  methods: {
    ...mapActions("products", ["fetchCategories", "fetchSizeSystems", "fetchMeasurementSystems", "fetchMeasurementUnits", "fetchColors"]),
    ...mapMutations("products", ["setEditProductData"]),
    getSelectedImageUrl() {
      const selectedImage = this.productVariantGroups[this.selectedColorIndex]?.selectedImage;
      const variantImages = this.productVariantGroups[this.selectedColorIndex]?.variants[0]?.product_variant_images;

      // Check if there is an existing image URL in the first variant's first product_variant_image (all variants have same image)
      const initialImageUrl = variantImages ? this.productVariantGroups[this.selectedColorIndex]?.variants[0]?.product_variant_images[0]?.image?.image?.url : null;

      // If initialImageUrl exists, return it
      if (initialImageUrl) {
        return initialImageUrl;
      }

      // Otherwise, display selected image
      if (selectedImage && this.productVariantGroups[this.selectedColorIndex].imageSelected) {
        if (selectedImage.type === "new") {
          return selectedImage.imagePreview;
        } else if (selectedImage.type === "existing") {
          return selectedImage.image.url;
        }
      }

      return null;
    },

    isImageSelected(type, image) {
      const selectedImage = this.productVariantGroups[this.selectedColorIndex]?.selectedImage;
      if (type === "existing") {
        return selectedImage && selectedImage.id === image.id;
      } else if (type === "new") {
        return selectedImage && selectedImage.imageName === image.imageName;
      }
      return false;
    },
    goBack() {
      window.confirm(`Are you sure you want to go back? All product variant data will be lost.`);
      this.$router.push({ name: "product_images.edit", params: { productId: this.$route.params.productId } });
    },
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    selectImage(image, type) {
      const group = this.productVariantGroups[this.selectedColorIndex];
      group.selectedImage = { type, ...image };
      group.imageSelected = true; // Add this flag to indicate image selection

      // Update all variants within the selected group
      group.variants.forEach((variant) => {
        variant.selectedImage = group.selectedImage;
      });
    },
    closeModalAndSetVariantImage() {
      this.closeModal();
      const currentGroup = this.productVariantGroups[this.selectedColorIndex];
      currentGroup.variantImage = currentGroup.selectedImage;

      // Update all variants within the current group
      currentGroup.variants.forEach((variant) => {
        variant.product_variant_images = [{ image: currentGroup.variantImage }];
      });
    },
    async loadExistingVariants() {
      try {
        const product = await callProductsShow(this.$route.params.productId);
        const variants = product.product_variants;
        this.productVariantGroups = this.formatVariantsForEditing(variants);
        this.addSelectedColorsToGroups();
        this.selectedColor = this.productVariantGroups[0].color;
        this.isLoading = false;
      } catch (error) {
        console.error("Failed to load product variants:", error);
        this.isLoading = false;
      }
    },
    formatVariantsForEditing(variants) {
      const groupedVariants = {};

      variants.forEach((variant) => {
        const color = variant.product_color;
        if (!groupedVariants[color.id]) {
          groupedVariants[color.id] = {
            color: color,
            variants: [],
            variantImage: variant.image,
            selectedImage: variant.image,
            imageSelected: false,
          };
        }
        groupedVariants[color.id].variants.push(variant);
      });

      return Object.values(groupedVariants);
    },
    addSelectedColorsToGroups() {
      this.productVariantGroups.forEach((group) => {
        group.imageSelected = false;
        group.selectedImage = null;
      });

      const { selectedColors, editProductSizeSystem, editProductPrice } = this.editProductData;

      selectedColors.forEach((color) => {
        const variants = editProductSizeSystem.product_sizes.map((size) => ({
          product_id: null,
          product_size: size,
          product_color: color,
          quantity: null,
          price: editProductPrice,
        }));

        this.productVariantGroups.push({
          color: color,
          variants: variants,
          variantImage: null,
          selectedImage: null,
        });
      });
    },
    formIsCompleted() {
      const {
        editProductTitle,
        editProductDescription,
        editProductCategory,
        editProductSizeSystem,
        editProductMeasurementSystem,
        editProductDimensionUnit,
        editProductWeightUnit,
        editProductWeight,
        editProductLength,
        editProductWidth,
        editProductHeight,
        selectedColors,
      } = this.editProductData;

      if (
        !editProductTitle ||
        !editProductDescription ||
        !editProductCategory ||
        !editProductSizeSystem ||
        !editProductMeasurementSystem ||
        !editProductDimensionUnit ||
        !editProductWeightUnit ||
        !editProductWeight ||
        !editProductLength ||
        !editProductWidth ||
        !editProductHeight ||
        !selectedColors
      ) {
        return false;
      }

      for (const group of this.productVariantGroups) {
        for (const variant of group.variants) {
          if (!variant.price || !variant.quantity) {
            return false;
          }
        }
      }

      return true;
    },
    async updateProductAndVariants() {
      this.submitAttempted = true;

      if (this.formIsCompleted()) {
        const productData = {
          currentUserId: this.$store.state.currentUserId,
          editProductData: this.editProductData,
          productVariantGroups: this.productVariantGroups,
        };

        try {
          await editProductAndVariants(productData);
          this.setEditProductData({});
          this.$toast("Product Saved Successfully", { type: "success" });
          this.$router.push({ name: "products.show", params: { productId: this.$route.params.productId } });
        } catch (error) {
          console.error("Error updating product and variants:", error);
        }
      }
    },
    selectColor(index) {
      this.selectedColorIndex = index;
      this.selectedColor = this.productVariantGroups[index].color;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-products-edit-variants {
  display: flex;
  flex-direction: column;
  // max-width: 50rem;

  .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.3);
  }

  .atmo-modal__header {
    display: flex;
    justify-content: center;
  }

  .atmo-modal-content {
    position: fixed;
    height: 40vh;
    width: 40vw;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    padding: 20px;
    z-index: 100;
  }

  .hide-modal {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    opacity: 0.5;
    cursor: pointer;
  }

  .images-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .image-wrapper {
    width: 100px;
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .modal-image {
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    border-radius: 3px;
  }

  .modal-image.selected {
    border: 1px solid white;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    width: 100%;
    // border: 1px solid white;
  }

  .back-container__icon-container {
    margin-right: 0.5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .tabs {
    display: flex;
    gap: 0.2rem;
  }

  .tabs button {
    border: 1px solid white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem;
    color: white;
    cursor: pointer;
    background: transparent;
  }

  .tabs button.active {
    background: rgba(255, 255, 255, 0.2);
  }

  .tabs button:hover {
    background: rgba(255, 255, 255, 0.2);
  }

  .container-wrap__form {
    margin-top: 20px;
  }

  .variant-wrap {
    display: flex;
    gap: 1rem;
  }

  .ps {
    max-height: 20rem;
  }

  textarea,
  textarea::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    color: white;
  }

  input::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.4) !important;
  }

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .field-wrap {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .text-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.4);
    }

    .dropdown-group {
      background: $atmo-purple--medium-dark;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 5px;
      padding: 0.5rem;
      font-weight: 500rem;
      cursor: pointer;
    }

    .dropdown-field {
    }

    .radio-group {
      display: block !important;
      padding: 0.6rem 0rem 0.6rem 0rem;
    }

    .radio-field-wrap {
      margin-right: 1rem;
      display: flex;
      justify-content: start !important;
      align-items: center;
      margin-bottom: 0.1rem;
    }

    .radio-field {
      margin-right: 0.5rem;
      cursor: pointer;
    }

    .radio-field-label {
      margin: 2px 0px 0px 0px;
    }
  }

  .file-type-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $atmo-purple--light;
    cursor: pointer;
    text-transform: unset !important;
    font-weight: 400 !important;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  }

  .field-label {
    font-weight: 500;
    margin-bottom: 0.8rem;
  }

  .atmo-products-edit-variants__container {
    margin: auto;
    max-width: 1000px;
    width: 100%;
  }

  .select-image {
    cursor: pointer;
  }

  .select-image-box {
    height: 7rem;
    width: 7rem;
    border-radius: 5px;
    border: 2px dashed rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 2rem;
      width: auto;
    }
  }

  .variant-details-container {
    width: 100%;
  }

  .selected-image-preview {
    height: 7rem;
    width: 7rem;
    object-fit: cover;
    border-radius: 5px;
  }

  .form__product-details-wrap {
    margin-bottom: 1rem;
  }

  .size-name {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-decoration: underline;
    text-transform: uppercase;
  }

  .product-details-wrap__product-details {
    width: 100%;

    .flex-columns {
      display: flex;
      gap: 1rem;

      .text-field {
        min-width: unset;
      }
    }
  }

  .available-colors-container {
    label {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
  }

  .shipping-info-heading {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: 500;
  }

  .main-wrap__submit-button {
    margin-left: auto;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  .main-wrap__submit-button-inactive {
    margin-left: auto;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.3) 0%, rgba(68, 174, 220, 0.3) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}
</style>
