import Axios from 'axios';

export function callBusinessDashboardsIndex(businessProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/business_profiles/${businessProfileId}/business_dashboards`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callBusinessDashboardFanInsights(businessProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/business_profiles/${businessProfileId}/business_dashboards/fan_insights`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callBusinessDashboardRevenueInsights(businessProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/business_profiles/${businessProfileId}/business_dashboards/revenue_insights`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}