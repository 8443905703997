import createChannel from "@/cable";

let callback;
let liveStreamChatChannel = null;

function connectToLiveStreamChatChannel(roomId){
  liveStreamChatChannel = createChannel(`LiveStreamChatChannel`, roomId, {
    received({ message }) {
      if (callback) callback.call(null, message);
    }
  });
}

function sendMessage(messageBody, roomId, userId) {
  console.log("SENDING MESSAGE IN LIVE STREAM CHANNEL")
  if (!liveStreamChatChannel) return;
  
  liveStreamChatChannel.perform("send_message", {
    content: messageBody,
    user_id: userId,
    commentable_id: roomId,
    commentable_type: "LiveStream",
  });
}

function setCallback(fn) {
  console.log("CALLBACK CALLED")
  callback = fn;
}

export { connectToLiveStreamChatChannel, sendMessage, setCallback };
