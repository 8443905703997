<template>
  <div class="atmo-form-prize">
    <div class="atmo-form-prize__header" @click="onHeaderClick">
      <span>{{ formSelectedName ? formSelectedName : "New Prize" }}</span>
      <span>{{ expanded ? "-" : "+" }}</span>
    </div>
    <div v-if="expanded">
      <select id="prizeType" v-model="formSelectedPrizeType" class="dropdown-field" @change="onPrizeChange">
        <option value="" disabled>Select Prize Type</option>
        <option v-for="type in prizeTypes" :key="type.name" :value="type.name">
          {{ type.name }}
        </option>
      </select>

      <div v-if="formSelectedPrizeType === 'Merch'" class="atmo-form-prize__body atmo-form-prize__body--merch">
        <input
          :id="`prize-name-${index}`"
          v-model="formSelectedName"
          type="text"
          class="text-field name"
          :aria-invalid="submitAttempted && !formSelectedName"
          placeholder="Type prize name here"
          @input="onPrizeChange"
        />
        <atmo-upload-card
          :id="`prize-image-${index}`"
          size="small"
          icon-size="small"
          :image-src="formSelectedImage"
          :is-dropzone="true"
          :has-label="true"
          :on-files-selected="onImageSelected"
          alt="Upload Image"
          componentStyle="width:5rem; height:2rem;"
        >
          <p class="upload-image-label">Upload Image</p>
        </atmo-upload-card>
        <textarea
          class="text-field"
          :id="`prize-description-${index}`"
          v-model="formSelectedDescription"
          rows="3"
          placeholder="Type a description here"
          :aria-invalid="submitAttempted && !formSelectedDescription"
          @input="onPrizeChange"
        ></textarea>
      </div>

      <div v-else-if="formSelectedPrizeType === 'Credits'" class="atmo-form-prize__body atmo-form-prize__body--credits">
        <input
          :id="`prize-name-${index}`"
          v-model="formSelectedName"
          type="text"
          class="text-field name"
          :aria-invalid="submitAttempted && !formSelectedName"
          placeholder="Type prize name here"
          @input="onPrizeChange"
        />
        <input
          :id="`prize-credits-${index}`"
          v-model="formSelectedCredits"
          type="number"
          min="0"
          class="atmo-form-prize__credits text-field"
          :aria-invalid="submitAttempted && !formSelectedCredits"
          placeholder="Number of credits"
          @input="onPrizeChange"
        />
      </div>

      <div v-else class="atmo-form-prize__body atmo-form-prize__body--other">
        <input
          :id="`prize-name-${index}`"
          v-model="formSelectedName"
          type="text"
          class="text-field name"
          :aria-invalid="submitAttempted && !formSelectedName"
          placeholder="Type prize name here"
          @input="onPrizeChange"
        />
        <textarea
          class="text-field"
          :id="`prize-description-${index}`"
          v-model="formSelectedDescription"
          rows="3"
          placeholder="Type a description here"
          :aria-invalid="submitAttempted && !formSelectedDescription"
          @input="onPrizeChange"
        ></textarea>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoUploadCard from "@/components/atmo-upload-card";

export default {
  components: {
    AtmoUploadCard,
  },

  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    prize: {
      type: Object,
      required: true,
    },
    prizeTypes: {
      type: Array,
      default: () => [],
    },
    selectedPrizeType: {
      type: Object,
      required: true,
    },
    submitAttempted: {
      type: Boolean,
      required: true,
    },
    expanded: {
      type: Boolean,
      required: true,
    },
    onHeaderClick: {
      type: Function,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      formSelectedPrizeType: "",
      formSelectedImage: null,
      formSelectedImageFile: null,
      formSelectedDescription: null,
      formSelectedCredits: null,
      formSelectedName: null,
    };
  },

  computed: {
    rank() {
      return { 0: "First", 1: "Second", 2: "Third" }[this.index];
    },
  },

  created() {
    this.setInitialFormProps();
  },

  methods: {
    setInitialFormProps() {
      console.log("Prize data:", this.prize); // Debugging log

      if (this.editMode) {
        this.formSelectedPrizeType = this.prize.contest_prize_type?.name || "";

        // Ensure we are correctly fetching the image URL
        this.formSelectedImage = this.prize.merch_image?.url || this.prize.other_image?.url || null;
        this.formSelectedImageFile = this.formSelectedImage;

        this.formSelectedDescription = this.prize.other_description || this.prize.merch_description;
        this.formSelectedCredits = this.prize.credit_amount;
        this.formSelectedName = this.prize.name;
      } else {
        this.formSelectedPrizeType = this.selectedPrizeType?.name || "";
      }
    },
    onImageSelected(files) {
      if (files && files.length > 0) {
        const file = files[0];

        // Set the new file for submission
        this.formSelectedImageFile = file;

        // Show preview immediately
        const reader = new FileReader();
        reader.onload = (event) => {
          this.formSelectedImage = event.target.result; // Update preview image
        };
        reader.readAsDataURL(file);

        // Trigger the prize update
        this.onPrizeChange();
      }
    },
    onPrizeChange() {
      const selectedPrizeType = this.prizeTypes.find((type) => type.name === this.formSelectedPrizeType);

      const updatedPrize = {
        ...this.prize,
        contest_prize_type: selectedPrizeType ? { id: selectedPrizeType.id, name: selectedPrizeType.name } : null,
        name: this.formSelectedName,
        description: this.formSelectedDescription,
        credits: this.formSelectedCredits,
        image: this.formSelectedImageFile,
      };

      this.onChange(updatedPrize);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-form-prize {
  &__header {
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    padding: 15px;
    background-color: white;
    color: $atmo-purple--dark;
    border-radius: 5px;
    margin: 5px 0;
    cursor: pointer;
  }

  .text-field,
  .text-field::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    color: white;
  }

  .dropdown-field {
    background: $atmo-purple--medium-dark;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    font-weight: 500;
    width: 100%;
  }

  &__body {
    margin: 3px 0;

    &--merch,
    &--credits,
    &--other {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }

  .upload-image-label {
    margin-top: 0.7rem;
    text-align: left;
  }
}
</style>
