import Axios from 'axios';

export function callSongProfilesShow(songProfileId, queryParams = {}) {
    return Axios.get(`/api/v1/song_profiles/${songProfileId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongProfilesCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/song_profiles`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongProfilesUpdate(songProfileId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/song_profiles/${songProfileId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongProfilesBuildSongProfile(songId, queryParams = {}) {
    return Axios.post(`/api/v1/song_profiles/build_song_profile/${songId}`, {}, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
