<template>
  <VueFinalModal
    :id="id"
    ref="modalRef"
    v-bind="$attrs"
    :class="['atmo-modal', `atmo-modal--${size}`, customClass]"
    content-class="atmo-modal atmo-modal-content"
    overlay-class="atmo-modal-overlay"
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    :esc-to-close="true"
  >
    <button v-if="closeFunction" class="atmo-modal-close" @click="closeFunction">✖</button>
    <slot />
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";

export default {
  components: {
    VueFinalModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "fullscreen",
      validator: (value) => ["small", "medium", "large", "fullscreen"].includes(value),
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
    customClass: {
      type: String,
      default: "",
    },
    closeFunction: {
      type: Function,
      default: null,
    },
  },
  emits: ["update:modelValue"],
};
</script>

<style>
.atmo-modal-close {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}
</style>
