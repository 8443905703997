<template>
  <div class="video-edit-page">
    <atmo-back-link></atmo-back-link>
    <div v-if="video" class="video-edit-container">
      <!-- Video on the left -->
      <video v-if="video.video" :src="video.video.url" controls class="video-preview"></video>

      <!-- Fields on the right -->
      <div class="form-container">
        <input class="text-field" type="text" v-model="video.name" placeholder="Video Name" />
        <textarea class="text-area" v-model="video.lyrics" placeholder="Lyrics"></textarea>

        <div class="action-buttons">
          <button class="save-video" @click="updateVideo">Save Changes</button>
          <button class="delete-video" @click="deleteVideo">Delete Video</button>
        </div>
      </div>
    </div>

    <div v-else class="loading-message">
      <p>Loading video details...</p>
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callVideosShow, callVideosUpdate, callVideosDelete } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoBackLink,
  },
  data() {
    return {
      video: null,
    };
  },
  computed: {
    videoId() {
      return this.$route.params.videoId;
    },
  },
  created() {
    this.fetchVideoDetails();
    this.$store.commit("setHudHeader", "Edit Video");
  },
  methods: {
    async fetchVideoDetails() {
      try {
        const response = await callVideosShow(this.videoId);
        this.video = response;
      } catch (error) {
        console.error("Error fetching video:", error);
        this.$toast("Failed to load video details", { type: "error" });
      }
    },
    async updateVideo() {
      if (!this.video) return;

      const response = await callVideosUpdate(this.videoId, {
        name: this.video.name,
        lyrics: this.video.lyrics,
      });

      if (response && response.message === "Video updated successfully") {
        this.$toast("Video updated successfully", { type: "success" });
        this.$router.push({ name: "videos.index", params: { userId: this.$store.state.currentUserId } });
      } else {
        console.log("Unexpected API response");
      }
    },
    async deleteVideo() {
      if (!confirm("Are you sure you want to delete this video?")) return;

      try {
        await callVideosDelete(this.videoId);
        this.$toast("Video deleted successfully", { type: "success" });
        this.$router.push({ name: "videos.index", params: { userId: this.$store.state.currentUserId } });
      } catch (error) {
        console.error("Error deleting video:", error);
        this.$toast("Failed to delete video", { type: "error" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.video-edit-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;

  .video-preview {
    width: 50%;
    height: 20rem;
    object-fit: cover;
  }

  .text-area {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem;
    color: white;
    margin-top: 10px;
    min-height: 10rem;
  }

  textarea,
  textarea::placeholder {
    color: $atmo-white--medium-faded;
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
  }

  .text-field {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem;
    color: white;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .text-field,
  .text-area {
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
  }

  .save-video,
  .delete-video {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
  }

  .save-video {
    background: linear-gradient(-270deg, rgba(217, 82, 167, 0.8), rgba(68, 174, 220, 0.8));
    color: white;
  }

  .delete-video {
    background: $atmo-invalid;
    color: white;
  }
}
</style>
