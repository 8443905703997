<template>
  <perfect-scrollbar class="atmo-product-details__details">
    <div class="atmo-product-details__images">
      <div class="atmo-product-details__image-container atmo-product-details__main-image">
        <img v-if="product" :src="selectedImage" />
      </div>
      <div v-if="product" class="atmo-product-details__thumbnails">
        <div v-for="productImage in product.images" :key="productImage.id" class="atmo-product-details__image-container atmo-product-details__thumbnail" @click="selectImage(productImage)">
          <img class="atmo-product-details__thumbnail-img" :src="productImage.image.url" />
        </div>
      </div>
    </div>
    <div v-if="product" class="atmo-product-details__content">
      <div class="atmo-product-details__header">
        <div class="atmo-product-details__info">
          <div class="atmo-product-details__title">
            {{ product.title }}
          </div>
          <div class="atmo-product-details__category">
            {{ product.product_category.name }}
          </div>
        </div>
        <div class="atmo-product-details__price">
          {{ returnCurrencyFormat(this.selectedVariant.price) }}
        </div>
      </div>
      <section class="atmo-product-details__description">
        {{ product.description }}
      </section>
      <div class="atmo-product-details__control">
        <span class="atmo-product-details__label"> color: </span>
        <div class="atmo-product-details__colors">
          <div
            v-for="(color, index) in colorOptions"
            :key="index"
            :class="{
              'atmo-product-details__color': true,
              'atmo-product-details__color--active': selectedColorIndex === index,
            }"
            :style="'background-color: ' + color"
            @click="selectColor(color, index)"
          />
        </div>
      </div>
      <div class="atmo-product-details__control">
        <span class="atmo-product-details__label"> Size: </span>
        <div class="atmo-product-details__colors">
          <select name="sizes" id="sizes" v-model="selectedSize" @change="selectSize(selectedSize)">
            <option v-for="(size, index) in selectedSizeOptions" :key="index" :value="size">{{ size }}</option>
          </select>
        </div>
      </div>
      <div class="atmo-product-details__control">
        <span class="atmo-product-details__label"> Quantity: </span>
        <div class="atmo-product-details__quantity-controls">
          <div class="atmo-product-details__quantity-control" title="decrement quantity" @click="decrementQuantity()">&ndash;</div>
          <div class="atmo-product-details__quantity-display">
            {{ quantity }}
          </div>
          <div class="atmo-product-details__quantity-control" title="increment quantity" @click="incrementQuantity()">+</div>
        </div>
      </div>
      <div class="atmo-product-details__quantity-alert" v-if="selectedVariant.quantity <= 10">Hurry! Only {{ selectedVariant.quantity }} left in stock</div>
      <div class="atmo-product-details__footer">
        <button v-show="!isInCart" class="atmo-button atmo-button--primary" @click="addVariantToCart()">
          <img src="@/assets/images/icons/store/add_to_cart.png" class="atmo-button__icon" />
          <span>Add To Cart</span>
        </button>
        <button v-show="isInCart" class="atmo-button atmo-button--secondary atmo-button--active atmo-button--no-click">
          <img src="@/assets/images/icons/store/added.png" class="atmo-button__icon atmo-button__icon--small" />
          <span>Added</span>
        </button>
      </div>
      <div v-if="$store.state.currentUserId == product.user_id" class="atmo-product-details__product-actions">
        <div>
          <span>
            <router-link :to="{ name: 'products.edit', params: { productId: product.id } }">
              <button class="product-actions__edit-product">Edit</button>
            </router-link>
          </span>
        </div>
        <div>
          <div class="product-actions__delete-product" @click="deleteProduct(product.id)">
            <img src="@/assets/images/icons/delete-red.png" />
          </div>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { callProductsShow, callProductsDelete } from "@/helpers/axiosCalls";
import { extractUniqueColorNames, getSizesByColor, formatPriceAsCurrency } from "@/helpers/utilityFunctions";
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      product: null,
      productImages: [],
      quantity: 1,
      selectedColor: null,
      selectedColorIndex: null,
      selectedSize: null,
      selectedImage: null,
      selectedVariant: null,
      selectedSizeOptions: null,
    };
  },

  computed: {
    ...mapState("products", ["cart", "cartCost"]),
    colorOptions() {
      return this.extractUniqueColorNames(this.product.product_variants);
    },
    isInCart() {
      return this.cart.includes(this.product);
    },
  },
  created() {
    callProductsShow(this.$route.params.productId)
      .then((response) => {
        this.product = response;
        this.selectedVariant = response.product_variants[0];
        this.setInitialSize();
        this.setInitialColor();
        this.setInitialImage();
        this.setSizeOptions(this.selectedColor);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  methods: {
    ...mapMutations("products", ["addToCart", "updateCartCost"]),
    deleteProduct() {
      if (!window.confirm(`Are you sure you want to delete this product`)) {
        return false;
      }

      this.$toast("Deleting product...");
      callProductsDelete(this.$route.params.productId)
        .then(() => {
          this.$toast("Product Deleted Successfully", { type: "success" });
          //DOING THIS BECAUSE PRODUCT IS STILL SHOWING UP IN STORE (HAVE TO REFRESH)
          this.$router.push({ name: "artist_profiles.show", params: { artistProfileId: this.$store.state.currentUserProfileId } });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSizesByColor(color, product) {
      return getSizesByColor(color, product);
    },
    setSizeOptions(color) {
      this.selectedSizeOptions = this.getSizesByColor(color, this.product);
    },
    setInitialImage() {
      this.selectedImage = this.product.images[0].image.url;
    },
    selectImage(productImage) {
      this.selectedImage = productImage.image.url;
    },
    selectVariantImage(productImage) {
      this.selectedImage = productImage.image.url;
    },
    setInitialSize() {
      this.selectedSize = this.selectedVariant.product_size.name;
    },
    setInitialColor() {
      this.selectedColor = this.selectedVariant.product_color.name;
      this.selectedColorIndex = 0;
    },
    selectSize(size) {
      this.selectedSize = size;
      this.quantity = 1;
      this.selectedVariant = this.getVariantBySizeAndColor();
    },
    selectColor(color, index) {
      this.selectedColor = color;
      this.selectedColorIndex = index;
      this.quantity = 1;
      this.setSizeOptions(color);
      this.selectedSize = this.selectedSizeOptions[0];
      this.selectedVariant = this.getVariantBySizeAndColor();
      this.selectedImage = this.selectedVariant.product_variant_images[0].image.image.url;
    },
    extractUniqueColorNames(data) {
      return extractUniqueColorNames(data);
    },
    returnCurrencyFormat(number) {
      return formatPriceAsCurrency(number);
    },
    getVariantBySizeAndColor() {
      return this.product.product_variants.find((variant) => variant.product_size.name === this.selectedSize && variant.product_color.name === this.selectedColor);
    },
    addVariantToCart() {
      const variant = this.getVariantBySizeAndColor();
      if (variant && this.quantity <= variant.quantity) {
        for (var i = 0; i < this.quantity; i++) {
          this.addToCart(this.selectedVariant);
        }
      }

      this.updateCartCost();
      this.quantity = 1;
    },
    decrementQuantity() {
      this.quantity--;
    },
    incrementQuantity() {
      this.quantity++;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-product-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;

  &__details {
    display: flex;
    margin-left: 20px;
    // flex-wrap: wrap-reverse;
    max-height: 70vh;
  }

  $images-container-width: 225px;

  &__images {
    display: flex;
    flex-direction: column;
    max-width: $images-container-width;
  }

  &__image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(white, 0.1);
    // box-shadow: 1px 0 0 0 rgba($atmo-purple--dark, 0.15), 0 1px 0 0 rgba($atmo-purple--dark, 0.54);
  }

  &__main-image {
    height: 275px;
    max-width: $images-container-width;
    border-radius: 5px;
    position: relative;
    padding: 3rem;

    img {
      height: auto;
      max-width: 210px;
      object-fit: cover;
    }
  }

  &__thumbnails {
    display: flex;
    margin: 6px -4px;
    flex-wrap: wrap;
  }

  &__thumbnail {
    height: 60px;
    width: 50px;
    margin: 4px;
    border-radius: 2px;
    cursor: pointer;

    &--active {
      background: $atmo-purple--medium-dark;
      border: 2px solid white;
      box-shadow: 1px 0 0 0 rgba($atmo-purple--dark, 0.15), 0 1px 0 0 $atmo-purple--medium-dark;
    }
  }

  &__thumbnail-img {
    height: 35px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    // width: 200px;
    max-width: 50vw;
    flex-grow: 1;
    margin-left: 22px;
  }

  &__header {
    display: flex;
    margin-bottom: 20px;
    gap: 2rem;
  }

  &__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  &__category {
    color: $atmo-pink--medium;
    font-size: 1.1em;
    font-weight: 500;
  }

  &__price {
    color: $atmo-blue--medium;
    font-size: 2em;
    font-weight: 600;
  }

  &__description {
    font-weight: 300;
    margin-bottom: 20px;
  }

  &__control {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }

  &__label {
    color: white;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 10px;
  }

  &__quantity-controls {
    display: flex;
    height: 22px;
  }

  &__quantity-control {
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    font-weight: 300;
    background-color: $atmo-purple--dark;
    color: white;

    &:hover {
      background-color: $atmo-purple--extra-dark;
    }
  }

  &__quantity-alert {
    margin-top: 1.5rem;
  }

  &__quantity-display {
    background: white;
    color: $atmo-purple--dark;
    font-weight: 300;
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__colors {
    display: flex;
    align-items: center;
  }

  &__color {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin: 0 2px;
    cursor: pointer;

    &--active {
      height: 16px;
      width: 16px;
      background-image: url(@/assets/images/icons/store/added.png);
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
    }
  }

  &__footer {
    margin-top: 2rem;
    // align-self: flex-end;
    // display: flex;
    // align-items: flex-end;
    // margin-top: auto;
  }

  &__product-actions {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;

    .product-actions__edit-product {
      width: 100%;
      min-width: 5rem;
      min-height: 2rem;
      line-height: 40px;
      background-color: transparent;
      color: white;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      border: 1px solid white;
      border-radius: 5px;
    }

    .product-actions__delete-product {
      background: white;
      border: 1px solid white;
      border-radius: 5px;
      margin-left: 0.1rem;
      min-height: 2.6rem;
      min-width: 2.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        height: 1.2rem;
      }
    }
  }
}
</style>
