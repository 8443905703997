<template>
  <div class="artist-list">
    <section class="artist-list__body">
      <div class="artist-list__main-content">
        <div>
          <template v-if="isEmpty">
            <atmo-loading v-if="isLoading" />
            <template v-else>
              <div class="empty-artists-container">
                <img class="atmo-empty-state__icon" src="@/assets/images/icons/no-artists.png" />
                <span class="atmo-empty-state__label">No associated artists found.</span>
              </div>
            </template>
          </template>
          <perfect-scrollbar class="artists-grid">
            <div v-for="artist in filteredArtists" :key="artist.id">
              <router-link class="artist-card-wrap" :to="getProfileLink(artist)">
                <div class="artist-card">
                  <div class="artist-card__background-image" :style="{ 'background-image': 'url(' + getFeaturedImage(artist.user.images, 'full') + ')' }"></div>
                  <div class="artist-card__footer">
                    <div class="footer__user-name">{{ artist.name }}</div>
                  </div>
                </div>
              </router-link>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AtmoLoading from "@/components/atmo-loading";
import { callLabelProfilesArtists } from "@/helpers/axiosCalls";
import { getFeaturedImage } from "@/helpers/utilityFunctions";

export default {
  components: { AtmoLoading },
  data() {
    return {
      artists: [],
      isLoading: false,
      query: "",
    };
  },
  computed: {
    filteredArtists() {
      return this.artists.filter((artist) => artist.name.toLowerCase().includes(this.query.toLowerCase()));
    },
    isEmpty() {
      return !this.isLoading && this.filteredArtists.length === 0;
    },
  },
  methods: {
    getFeaturedImage,
    getProfileLink(artist) {
      return {
        name: "artist_profiles.show",
        params: { artistProfileId: artist.id },
      };
    },
    async fetchArtists() {
      this.isLoading = true;
      try {
        const data = await callLabelProfilesArtists(this.$route.params.labelProfileId);
        this.artists = data;
      } catch (error) {
        console.error("Error fetching artists:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.$store.commit("setHudHeader", `${this.$store?.state?.profiles?.profile?.name} Artists`);
    this.fetchArtists();
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";
@import "~@/assets/stylesheets/mixins";

.artist-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
  }

  .empty-artists-container {
    @include flex-center;
    flex-direction: column;
  }

  .atmo-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .atmo-empty-state__label {
    text-align: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .back-container__icon-container {
    margin-right: 0.5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__artist-search.atmo-input.atmo-input--search {
    height: 32px;
    min-width: 150px;
  }

  &__pending-link {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__pending-button {
    background-color: $atmo-purple--medium-dark !important;
    padding: 5px 7px;
    margin: 0 10px;
  }

  &__pending-link:hover {
    color: $atmo-purple--light;

    .artist-list__pending-button {
      background-color: $atmo-purple--dark;
    }
  }

  &__main-content {
    margin: 0rem 1rem 0rem 1rem;
  }

  .atmo-music-items__empty {
    flex-direction: column;
  }

  .atmo-music-items .atmo-card__image-container {
    padding: 0px;
  }

  .atmo-card__background-image {
    height: 6.6rem;
  }

  .artists-grid {
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .artist-card-wrap {
    display: block;
    height: 100%;
  }

  .artist-card {
    height: 100%;
    cursor: pointer;
  }

  .artist-card__background-image {
    height: 6rem;
    background-color: rgba(190, 167, 221, 0.4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 5px 5px 0 0;
    margin: auto;
  }

  .artist-card__footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-270deg, rgba($atmo-blue--medium, 0.5) 0%, rgba($atmo-pink--medium, 0.5) 100%);
    font-weight: 300;
    padding: 8px;
    border-radius: 0 0 5px 5px;
  }

  .footer__user-name {
    font-weight: 500;
    color: white;
  }
}
</style>
