import { Howl, Howler } from "howler";

class HowlerManager {
  constructor() {
    this.songHowls = new Map();
    // Map to store the soundId for each song (keyed by song.id)
    this.songSoundIds = new Map();
  }

  getSongDuration(song) {
    const howl = this.getHowl(song);
    return howl ? howl.duration() : 0;
  }

  createHowl(song) {
    // console.log("createHowl called for song:", song);
    if (!this.songHowls.has(song.id)) {
      const howl = new Howl({
        src: [song.url],
        preload: true,
        // onload: () => {
        //   console.log(`Howl for song ${song.id} loaded.`);
        // },
        onend: () => this.handleSongEnd(song),
      });
      this.songHowls.set(song.id, howl);
    } else {
      // console.log("Howl already exists for song:", song.id);
    }
    return this.songHowls.get(song.id);
  }  
  
  handleSongEnd(song) {
    const store = require('@/store').default;
    const { repeatMode, autoplayEnabled } = store.state.player;
    const queueMode = store.state.atmoQueues.queueMode;
  
    if (queueMode !== 'queue' && queueMode !== 'radio') return;
  
    if (repeatMode === 'one') {
      const howl = this.getHowl(song);
      if (howl) {
        howl.seek(0);
        howl.play();
        store.commit("player/setIsPlaying", true);
      }
    } else if (autoplayEnabled && (repeatMode === 'all' || repeatMode === 'none')) {
      store.dispatch('player/playNextTrack');
    } else if (!autoplayEnabled) {
      store.commit('player/resetPlayer');
    }
    // Clear the stored soundId since the song has ended
    this.songSoundIds.delete(song.id);
  }

  getHowl(song) {
    const howl = this.songHowls.get(song.id);
    if (!howl) {
      console.error(`No Howl instance found for song: ${song.id}`);
    }
    return howl;
  }

  playHowl(song) {
    const howl = this.getHowl(song) || this.createHowl(song);
    if (howl) {
      let soundId = this.songSoundIds.get(song.id);
      if (soundId !== undefined) {
        // Only play if the sound is not already playing.
        if (!howl.playing(soundId)) {
          howl.play(soundId);
        }
      } else {
        // Play for the first time; store the returned soundId.
        soundId = howl.play();
        this.songSoundIds.set(song.id, soundId);
      }
    }
    return howl;
  }  

  stopHowl(song) {
    const howl = this.getHowl(song);
    if (howl) {
      const soundId = this.songSoundIds.get(song.id);
      if (soundId !== undefined) {
        howl.stop(soundId);
      } else {
        howl.stop();
      }
      // Remove the stored soundId when stopping.
      this.songSoundIds.delete(song.id);
    }
  }

  pauseHowl(song) {
    const howl = this.getHowl(song);
    if (howl) {
      const soundId = this.songSoundIds.get(song.id);
      if (soundId !== undefined) {
        howl.pause(soundId);
      } else {
        howl.pause();
      }
    }
  }

  seekHowl(song, seekPosition) {
    const howl = this.getHowl(song);
    if (howl) {
      const soundId = this.songSoundIds.get(song.id);
      if (soundId !== undefined) {
        howl.seek(seekPosition, soundId);
      } else {
        howl.seek(seekPosition);
      }
    }
  }

  /**
   * Returns the current playback time (in seconds) of the song.
   * Uses Howl.seek() with the stored soundId if available.
   */
  getCurrentTime(song) {
    const howl = this.getHowl(song);
    if (howl) {
      const soundId = this.songSoundIds.get(song.id);
      if (soundId !== undefined) {
        // console.log("Howl playing:", howl.playing(soundId), "Current seek:", howl.seek(soundId));
        return howl.seek(soundId);
      }
      // console.log("Howl playing:", howl.playing(), "Current seek:", howl.seek());
      return howl.seek();
    }
    return 0;
  }  

  getVolume() {
    return Howler.volume();
  }

  updateVolume(volume) {
    Howler.volume(volume);
  }

  toggleMute(isMuted) {
    Howler.mute(isMuted);
  }
}

export default new HowlerManager();
