<template>
  <div class="fan-list">
    <!-- <h2 class="atmo-page-header fan-list__header">Fans</h2> -->
    <section class="fan-list__body">
      <div v-if="isEmpty" class="atmo-empty-state">
        <img class="atmo-empty-state__icon" src="@/assets/images/icons/no-friends.png" />
        <span class="atmo-empty-state__label">{{ emptyMessage }}</span>
      </div>
      <perfect-scrollbar class="friends-grid" v-else>
        <div v-for="(user, index) in fans" :key="index" class="friend-card-wrap">
          <router-link :to="getProfileLink(user)" v-if="user && user.images">
            <div class="friend-card">
              <div class="friend-card__background-image" :style="{ 'background-image': 'url(' + getFeaturedImage(user.images, 'full') + ')' }"></div>
              <div class="friend-card__footer">
                <div class="footer__user-name">{{ user.name || "No name" }}</div>
              </div>
            </div>
          </router-link>
        </div>
      </perfect-scrollbar>
    </section>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getFeaturedImage } from "@/helpers/utilityFunctions";
// import AtmoLoading from '@/components/atmo-loading';
import PaginationMixin from "@/mixins/pagination";

export default {
  // components: { AtmoLoading },
  mixins: [PaginationMixin],
  data() {
    return {
      query: null,
      fans: [],
      isLoading: true,
    };
  },
  computed: {
    isEmpty() {
      return this.fans.length === 0;
    },
    emptyMessage() {
      return "No fans found.";
    },
  },
  async created() {
    await this.fetchFans();
    this.$store.commit("setHudHeader", "Fans");
  },
  methods: {
    ...mapActions("userRelationships", ["fetchFollowedRelationships"]),
    async fetchFans() {
      this.isLoading = true;
      try {
        const response = await this.fetchFollowedRelationships(this.$route.params.userId);
        this.fans = response.map((rel) => rel.follower);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    getProfileLink(user) {
      if (!user || !user.profile_type) {
        return "#";
      }

      if (user.profile_type === "UserProfile") {
        return { name: "user_profiles.show", params: { userProfileId: user.profile_id } };
      } else if (user.profile_type === "ArtistProfile") {
        return { name: "artist_profiles.show", params: { artistProfileId: user.profile_id } };
      } else if (user.profile_type === "LabelProfile") {
        return { name: "label_profiles.show", params: { labelProfileId: user.profile_id } };
      } else if (this.profileType == "BusinessProfile") {
        return { name: "business_profiles.show", params: { businessProfileId: user.profileId } };
      } else if (this.profileType == "PublisherProfile") {
        return { name: "publisher_profiles.show", params: { publisherProfileId: user.profileId } };
      }
      return "#";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.fan-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
  }

  .atmo-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .atmo-empty-state__label {
    text-align: center;
  }

  .friends-grid {
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .friend-card-wrap {
    display: block;
    height: 100%;
  }

  .friend-card {
    height: 100%;
    cursor: pointer;
  }

  .friend-card__background-image {
    height: 6rem;
    background-color: rgba(190, 167, 221, 0.4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 5px 5px 0 0;
    margin: auto;
  }

  .friend-card__footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-270deg, rgba($atmo-blue--medium, 0.5) 0%, rgba($atmo-pink--medium, 0.5) 100%);
    font-weight: 300;
    padding: 8px;
    border-radius: 0 0 5px 5px;
  }

  .footer__user-name {
    font-weight: 500;
    color: white;
  }
}
</style>
