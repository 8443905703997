<template>
  <div v-if="isLoading" class="suggested-playlist-show suggested-playlist-show--loading">
    <atmo-loading size="large" />
  </div>
  <div v-else class="suggested-playlist-show">
    <div class="suggested-playlist-show__header-settings">
      <!-- <h2 class="atmo-page-header">
        {{ playlist.name }}
      </h2> -->
      <!-- <router-link class="playlist-settings" v-if="playlist.user_id == $store.state.currentUserId"
                :to="{ name: 'playlists.settings', params: { playlistId: playlist.id } }">
                <img src="@/assets/images/icons/chat/settings_icon.png" class="settings-icon" />
            </router-link> -->
    </div>
    <atmo-back-link class="suggested-playlist-show__breadcrumbs"></atmo-back-link>
    <div class="suggested-playlist-show__body">
      <div class="suggested-playlist-show__playlist-info">
        <div class="suggested-playlist-show__playlist-info">
          <div class="playlist-card atmo-playlist-card">
            <div class="playlist-card__image-container">
              <img
                class="image-container__image"
                :src="getFeaturedImage(playlist.song_references[Math.floor(Math.random() * playlist.song_references.length)].song.primary_artist.user.images, 'full')"
              />
              <div class="image-container__overlay"></div>
              <div class="playlist-card__upper-left"></div>
              <!-- <div class="playlist-card__upper-right">
                                <div class="atmo-playlist-card__album-year"><span>2020</span></div>
                            </div> -->
            </div>
            <div class="playlist-card__body"></div>
            <div class="playlist-card__footer">
              <div class="atmo-playlist-card__playlist-footer">
                <div class="atmo-playlist-card__playlist-info">
                  <div class="atmo-playlist-card__playlist-name">
                    <span>{{ playlist?.name }}</span>
                  </div>
                  <!-- <div class="atmo-playlist-card__album-genre"><span>Jazz</span></div> -->
                </div>
                <div class="atmo-playlist-card__playlist-length"><img :src="require('@/assets/images/icons/clock.png')" class="atmo-playlist-card__playlist-length-icon" /> 123 min</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="suggested-playlist-show__songs">
        <div v-if="playlist == null">Loading…</div>
        <perfect-scrollbar>
          <table class="atmo-table atmo-songs-table">
            <tbody>
              <tr
                v-for="(songReference, index) in playlist.song_references"
                :key="index"
                :class="index % 2 !== 0 ? 'atmo-table__tr' : 'atmo-table__tr--light'"
                @click="$store.dispatch('player/prependAndPlaySong', song)"
              >
                <td class="atmo-table__td atmo-table__td--action-arrow">
                  <song-actions-popover :song="songReference.song" :actions="songActions" />
                </td>
                <td data-cy="table-row-song-name" class="atmo-table__td atmo-table__td--stretch">
                  {{ songReference?.song?.name }}
                </td>
                <!-- TODO: real song length and genre -->
                <td class="atmo-table__td">
                  {{ getSongDuration(songReference?.song) }}
                </td>
                <td class="atmo-table__td">
                  {{ songReference?.song?.song_profile?.primary_genre?.name }}
                </td>
                <td v-if="showCommentsLinks" class="atmo-table__td atmo-table__td--tight">
                  <router-link :to="{ name: 'songs.show', params: { songId: songReference?.song?.id } }">
                    <img class="atmo-songs-table__comment-icon" src="@/assets/images/icons/comment.png" alt="comments" />
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import AtmoLoading from "@/components/atmo-loading";
import SongActionsPopover from "@/components/song-actions-popover";
import { getFeaturedImage, getSongDuration } from "@/helpers/utilityFunctions";
import AtmoBackLink from "@/components/atmo-back-link";
import { callPlaylistsShow } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoLoading,
    SongActionsPopover,
    AtmoBackLink,
  },

  data() {
    return {
      isLoading: true,
      album: null,
      albumSongs: null,
      playlist: null,
      isLink: false,
      showCommentsLinks: true,
      songActions: [
        "share-song-as-post",
        "share-song-as-message",
        "add-to-queue",
        "add-to-library",
        "add-to-playlist",
        "create-radio-station",
        "display-artist",
        "display-song",
        "display-album",
        "copy-song-link",
        "send-song-sms",
      ],
    };
  },

  computed: {
    artistProfileId() {
      return Number(this.$route.params.artistProfileId);
    },
    albumId() {
      return Number(this.$route.params.albumId);
    },
    canEditAlbum() {
      const { currentUserProfileType, currentUserProfileId } = this.$store.state;
      return currentUserProfileType === "ArtistProfile" && currentUserProfileId === this.artistProfileId;
    },
  },

  created() {
    // this.getAlbum();
    this.getPlaylist();
  },

  methods: {
    getSongDuration(song) {
      return getSongDuration(song);
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    getPlaylist() {
      callPlaylistsShow(this.$route.params.playlistId)
        .then((response) => {
          this.playlist = response;
          this.$store.commit("setHudHeader", this.playlist?.name);
          this.getAlbum();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAlbum() {
      this.isLoading = true;
      if (this.playlist?.song_references[0]?.song) {
        Axios.get(`/api/v1/artist_profiles/${this.playlist?.song_references[0]?.song?.primary_artist?.id}/albums/${this.playlist?.song_references[0]?.song?.album_id}`)
          .then((response) => {
            this.album = response.data;
          })
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.suggested-playlist-show {
  display: flex;
  flex-direction: column;
  align-items: center;

  .suggested-playlist-show__header-settings {
    display: flex;
    align-items: center;
    margin-bottom: 3rem;

    .atmo-page-header {
      margin: 0;
    }

    .settings-icon {
      height: 1.5rem;
      margin-top: 1rem;
      margin-left: 0.5rem;
    }
  }

  &__breadcrumbs {
    margin: 20px auto 0 30px;
  }

  &__body {
    display: flex;
    margin-top: 20px;
    // width: 95%;
    // max-width: 900px;
    width: 100%;
  }

  &__playlist-info {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  &__edit-button {
    margin-top: 15px;
  }

  &__songs {
    flex: 1;
  }

  .playlist-card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-left: 2rem;
    // overflow: hidden;
    // box-shadow: 1px 1px 1px 0px rgba(black, 0.2);

    .playlist-card__image-container {
      position: relative;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      border-radius: 5px 5px 0px 0px;
    }

    .image-container__image {
      width: 100%;
      position: relative;
      border-radius: 5px 5px 0px 0px;
    }

    .image-container__overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      font-weight: bold;
      text-align: right;
      font-size: 30px;
      border-radius: 5px 5px 0px 0px;
      background-image: linear-gradient(-270deg, rgba($atmo-blue--medium, 0.5) 0%, rgba($atmo-pink--medium, 0.5) 100%);
    }

    &--clickable {
      cursor: pointer;
    }

    &--with-background-gradient {
      background-image: linear-gradient(-270deg, rgba($atmo-blue--medium, 0.5) 0%, rgba($atmo-pink--medium, 0.5) 100%);
    }

    &__background-image-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    &__background-image {
      position: absolute;
      z-index: -1;
      opacity: 0.7;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($atmo-purple--light, 0.4);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 5px 5px 0 0;
      height: 100%;
      margin: auto;
    }

    &:hover .playlist-card__link-icon-container {
      visibility: visible;
      background-color: rgba($atmo-purple--dark, 0.7);
    }

    &:hover .playlist-card__link-icon {
      opacity: 1;
    }

    &__upper-left {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__upper-right {
      position: absolute;
      top: 0;
      right: 0;
    }

    &__lower-left {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &__lower-right {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__image-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($atmo-purple--light, 0.4);
      border-radius: 5px 5px 0 0;
      position: relative;
    }

    &__link-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($atmo-purple--dark, 0);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background-color 0.2s ease-in;
      visibility: hidden;
      border-radius: inherit;
    }

    .atmo-playlist-card__playlist-footer {
      display: flex;
      flex-direction: column;
      background-image: linear-gradient(-269deg, rgba(68, 174, 220, 0.39) 1%, rgba(217, 82, 167, 0.39) 100%);
      border-radius: 5px 5px 5px 5px;
    }

    .atmo-playlist-card__playlist-info {
      display: flex;
      flex-direction: column;
      padding: 15px;
    }

    .atmo-playlist-card__playlist-length {
      color: white;
      text-transform: uppercase;
      font-size: 0.9em;
      padding: 11px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .atmo-playlist-card__playlist-length-icon {
      height: 18px;
      margin-right: 2px;
    }

    .atmo-playlist-card__playlist-name {
      color: white;
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 5px;
      text-align: center;
    }
  }

  .suggested-playlist-show__songs {
    margin-right: 2rem;
  }
}
</style>
