<template>
  <div class="atmo-contests">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">
        {{ pageTitle }}
      </h2>
    </div> -->
    <section class="atmo-contests__body">
      <div class="atmo-contests__controls">
        <router-link v-if="isMyContests" :to="{ name: 'contests.new' }" class="atmo-contests__add-new-contest" data-cy="new-contest" @click="addNewContest">
          <button class="atmo-icon-button">
            <img src="@/assets/images/icons/add.png" alt="create playlist" class="atmo-contests__add-new-contest-icon" />
          </button>
          Add New Contest
        </router-link>
        <form v-else class="atmo-form">
          <input type="text" placeholder="Search Contests" class="atmo-contests__search atmo-input atmo-input--search" :value="query" @input="onSearch" />
        </form>
      </div>
      <perfect-scrollbar class="atmo-contests__main-content">
        <!-- <contest-card
            v-for="contest in Pagination_itemsForCurrentPage"
            :key="contest.id"
            :contest="contest"
            class="atmo-contests__card"
          /> -->
        <contest-list-item v-for="contest in Pagination_itemsForCurrentPage" :key="contest.id" :contest="contest" @delete-contest="deleteContest" />
        <template v-if="isEmpty">
          <div v-if="isLoading" class="atmo-contests__empty">
            <atmo-loading />
          </div>
          <div v-else-if="filteredContests?.length === 0" class="atmo-contests__empty">
            <img class="atmo-empty-state__icon" :src="`${allContests?.length === 0 ? getImgUrl('no_contests') : getImgUrl('no-results')}`" />
            <span v-if="allContests?.length === 0" class="atmo-empty-state__label"> You haven't added any contests yet </span>
            <span v-else class="atmo-empty-state__label"> There are no contests that match your search </span>
          </div>
        </template>
      </perfect-scrollbar>
    </section>
  </div>
</template>

<script>
import { callContestsIndex, callContestsDelete } from "@/helpers/axiosCalls";
import AtmoLoading from "@/components/atmo-loading";
// import AtmoMusicItems from '@/components/atmo-music-items';
// import ContestCard from './contest-card';
import ContestListItem from "./contest-list-item";
import PaginationMixin from "@/mixins/pagination";
import { utilsGetImgUrl } from "@/helpers/utilityFunctions";

export default {
  title: (context) => {
    return ` - ${context.pageTitle}`;
  },

  components: {
    AtmoLoading,
    ContestListItem,
    // AtmoMusicItems,
    // ContestCard
  },

  mixins: [PaginationMixin],

  data: function () {
    return {
      isLoading: true,
      allContests: [],
      pageSize: 6,
      query: null,
    };
  },

  computed: {
    isMyContests() {
      return Number(this.$store.state.currentUserId) === Number(this.$route.params.userId);
    },
    pageTitle() {
      return `${this.isMyContests ? "My" : "Atmo"} Contests`;
    },
    filteredContests() {
      return (this.allContests || []).filter((contest) => {
        const matchesContestName = contest.name.toLowerCase().includes((this.query || "").toLowerCase());
        const matchesArtistName = contest.user.name.toLowerCase().includes((this.query || "").toLowerCase());
        return matchesContestName || matchesArtistName;
      });
    },
    fullCollection() {
      return this.filteredContests;
    },
    isEmpty() {
      return this.isLoading || this.filteredContests.length === 0;
    },
  },

  created() {
    this.getContests();
    this.$store.commit("setHudHeader", `${this.isMyContests ? "My" : "Atmo"} Contests`);
  },
  methods: {
    deleteContest(contest) {
      if (!window.confirm(`Are you sure you want to contest "${contest.name}"?`)) {
        return false;
      }

      callContestsDelete(contest.id).then(() => {
        this.getContests();
      });
    },
    getImgUrl(image) {
      return utilsGetImgUrl(image);
    },
    async getContests() {
      this.isLoading = true;
      try {
        const params = {};
        if (this.isMyContests) {
          params.user_id = this.$store.state.currentUserId;
        }
        const response = await callContestsIndex(params);
        this.allContests = response;
      } catch (error) {
        console.error(error);
        this.$toast("Error Fetching Contests", { type: "error" });
      }
      this.isLoading = false;
    },
    onSearch(event) {
      this.query = event.currentTarget.value;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-contests {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__add-new-contest {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.1em;
    cursor: pointer;

    &:hover {
      font-weight: 500;
    }

    .atmo-icon-button {
      margin-right: 8px;

      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  &__add-new-contest-icon {
    height: 25px;
  }

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    // justify-content: center;
  }

  &__controls {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__main-content {
    min-height: 60vh;
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    // gap: 1rem;
  }
}
</style>
