<template>
  <div class="ops-new-page">
    <div class="ops-new-page__wrap">
      <!-- <div>
        <div align-self="center">
          <h2 class="atmo-page-header mb-5 text-center">
            New Campaign
          </h2>
        </div>
      </div> -->
      <div>
        <div cols="3" class="ops-new-page__navigate-back">
          <router-link :to="{ name: 'users.ops_pages', params: { userId: this.$store.state.currentUserId } }">
            <img src="@/assets/images/icons/back.png" class="ops-new-page__go-back-image" />
            <p>Back to campaigns</p>
          </router-link>
        </div>
      </div>
      <div class="atmo-ops-pages-campaign-form">
        <div class="campaign-form-wrap">
          <!-- campaigns image and video -->
          <div class="picture-video-container">
            <div>
              <div class="label">
                <label>Campaign Picture</label>
              </div>
            </div>
            <div>
              <atmo-upload-card
                id="opsPageImage"
                :image-src="formSelectedImage"
                :is-dropzone="true"
                :on-files-selected="onImageSelected"
                alt="Upload Image"
                componentStyle="width:15rem; height:14rem; border-radius: 2px; border: 2px dashed rgba(255, 255, 255, .4);"
              >
                Campaign Image
              </atmo-upload-card>
            </div>

            <div>
              <div class="upload-video-label">
                <label class="video-label">Campaign video</label>
                <!-- <img src="@/assets/images/icons/info.png"> -->
              </div>
              <!-- <div class="campaign-video-container">
                <img src="@/assets/images/icons/ops/watch_video.png" alt="Upload video" />
              </div> -->
              <atmo-upload-card
                id="opsPageVideo"
                :image-src="formSelectedVideo"
                :is-dropzone="true"
                :on-files-selected="onVideoSelected"
                alt="Upload Video"
                iconSize="small"
                componentStyle="width:15rem; height:6rem; border-radius: 2px; border: 2px dashed rgba(255, 255, 255, .4);"
              >
                Campaign Image
              </atmo-upload-card>
            </div>
          </div>
          <!-- goal, title and description -->
          <div class="goal-title-description-container">
            <form class="description-form">
              <!-- campaign goal -->
              <div>
                <div class="label">
                  <label> Campaign goal ($US) </label>
                </div>
              </div>
              <div>
                <div>
                  <input v-model="goal" class="form-control" type="text" placeholder="$100000" name="campaign-goal" />
                </div>
              </div>
              <!-- campaign title -->
              <div>
                <div class="label mt-3">
                  <label>Campaign title</label>
                </div>
              </div>
              <div>
                <div>
                  <input v-model="title" class="form-control" type="text" placeholder="Design our next t-shirt and win free tickets" name="campaign-title" />
                </div>
              </div>
              <div>
                <div class="label mt-3">
                  <label>Campaign Duration</label>
                  <div class="datepicker-trigger">
                    <div class="datepicker-wrap">
                      <DatePicker v-model="formSelectedStartDate" format="yyyy-MM-dd" placeholder="Select Start Date" />
                    </div>
                    <div class="datepicker-wrap">
                      <DatePicker v-model="formSelectedEndDate" format="yyyy-MM-dd" placeholder="Select End Date" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- campaign description -->
              <div>
                <div class="label mt-3">
                  <label>Description</label>
                </div>
              </div>
              <div>
                <div>
                  <textarea v-model="description" class="form-control" type="text" placeholder="Enter your description" name="campaign-description"></textarea>
                </div>
              </div>
            </form>
          </div>
          <!-- donation options -->
          <div class="donation-container">
            <form autocomplete="off" @submit.prevent="addDonationOption">
              <div>
                <div class="label">
                  <label>Donation amounts</label>
                </div>
              </div>
              <div class="new-donation-field-container">
                <div class="amount-container">
                  <input v-model="donateAmount" class="form-control text-center" type="text" placeholder="$" data-cy="donation-amount" />
                </div>
                <div class="description-container">
                  <input v-model="donateLabel" class="form-control" type="text" placeholder="Enter prize description" data-cy="donation-description" />
                </div>
                <div class="add-amount-container">
                  <button class="donate-action-create" data-cy="create-donation" type="submit">
                    <img src="@/assets/images/icons/plus--purple.png" />
                  </button>
                </div>
              </div>
              <perfect-scrollbar>
                <div class="new-donation-wrap" v-for="(donateOption, index) in donateOptions" :key="donateOption.amount">
                  <div class="donation-amount">
                    <div class="amount-wrap" v-if="!editMode[index]">
                      {{ returnCurrencyFormat(donateOption.amount) }}
                    </div>
                    <input class="form-control" v-else v-model="donateOptions[index].editedAmount" @keydown.enter.prevent="saveEdit(index)" @blur="saveEdit(index)" />
                  </div>
                  <div class="donation-description">
                    <div v-if="!editMode[index]">
                      {{ donateOption.label }}
                    </div>
                    <input class="form-control" v-else v-model="donateOptions[index].editedLabel" @keydown.enter.prevent="saveEdit(index)" @blur="saveEdit(index)" />
                  </div>
                  <div class="donation-actions">
                    <div class="donate-action-edit" @click="toggleEditMode(index)">
                      <img src="@/assets/images/icons/edit--pencil.png" />
                    </div>
                    <div class="donate-action-delete" data-cy="delete-donation" @click="removeDonationOption(index)">
                      <img src="@/assets/images/icons/delete.png" />
                    </div>
                  </div>
                </div>
              </perfect-scrollbar>
            </form>
          </div>
        </div>
        <div class="ops-new-page__edit-campaign-container">
          <div>
            <button class="submit-button" data-cy="edit-campaign" @click="createOpsPage()">
              <span> Save changes </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import format from "date-fns/format";
import AtmoUploadCard from "@/components/atmo-upload-card";
// import AtmoOpsCampaignForm from "@/components/atmo-ops-campaign-form.vue";
import { callOpsPagesCreate, callOpsPageDonationsCreate, callImagesCreate, callVideosCreate, callPreviewImageCreate } from "@/helpers/axiosCalls";
import DatePicker from "vue3-datepicker";

export default {
  components: {
    AtmoUploadCard,
    DatePicker,
    // AtmoOpsCampaignForm
  },
  data: function () {
    return {
      campaignToEdit: {},
      goal: null,
      title: null,
      description: null,
      donateAmount: null,
      donateLabel: null,
      donateOptions: [],
      editMode: [], // Array to keep track of edit mode for each item
      editedLabel: "", // Store the edited label temporarily
      dateFormat: "MMM D, YYYY",
      formSelectedStartDate: null,
      formSelectedEndDate: null,
      submitAttempted: false,
      formSelectedImage: null,
      formSelectedImageFile: null,
      formSelectedVideo: null,
      formSelectedVideoFile: null,
    };
  },
  computed: {
    // ...mapGetters({
    //   campaign: "ops/currentPage"
    // }),
    formSelectedDates() {
      return this.formatDates(this.formSelectedStartDate, this.formSelectedEndDate);
    },
  },
  created() {
    // this.setPage(this.$route.params.opsPageId);
    // this.campaignToEdit = {
    //   goal: this.campaign.goal,
    //   title: this.campaign.campaign_name,
    //   description: this.campaign.campaign_description,
    //   donateOptions: this.campaign.ops_page_donations.map(donation => {
    //     return { label: donation.description, amount: donation.amount }
    //   })
    // }
    this.$store.commit("setHudHeader", "New Campaign");
  },
  methods: {
    // ...mapActions("ops", ["setPage"]),
    onImageSelected(files) {
      this.formSelectedImageFile = files[0];
    },
    onVideoSelected(files) {
      //TODO: EDIT THIS IMAGE SHOWN
      this.formSelectedVideo = require("@/assets/images/default_pic.jpg");
      this.formSelectedVideoFile = files[0];
    },
    formatDates(startDate, endDate) {
      let formattedDates = "";
      if (startDate) {
        formattedDates = format(startDate, this.dateFormat);
      }
      if (endDate) {
        formattedDates += " - " + format(endDate, this.dateFormat);
      }

      return formattedDates;
    },
    returnCurrencyFormat(number) {
      // Create number formatter (Javascript - Internationalization API)
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
    addDonationOption() {
      this.donateOptions = this.donateOptions.concat({
        amount: this.donateAmount,
        label: this.donateLabel,
      });

      this.donateAmount = null;
      this.donateLabel = null;
    },
    toggleEditMode(index) {
      // Toggle edit mode for the clicked item
      this.$set(this.editMode, index, !this.editMode[index]);
      // If entering edit mode, set the edited amount and label to the current values
      if (this.editMode[index]) {
        this.$set(this.donateOptions[index], "editedAmount", this.donateOptions[index].amount);
        this.$set(this.donateOptions[index], "editedLabel", this.donateOptions[index].label);
      }
    },
    saveEdit(index) {
      // Save the edited amount and label and exit edit mode
      this.$set(this.donateOptions[index], "amount", parseFloat(this.donateOptions[index].editedAmount));
      this.$set(this.donateOptions[index], "label", this.donateOptions[index].editedLabel);
      this.$set(this.editMode, index, false);
      // Optionally, you can perform additional actions here like updating your backend
    },
    removeDonationOption(index) {
      this.donateOptions.splice(index, 1);
    },
    async createOpsPage() {
      this.$toast("Saving campaign...");

      const formData = new FormData();
      formData.append("campaign_name", this.title);
      formData.append("campaign_description", this.description);
      formData.append("goal", this.goal);
      formData.append("user_id", this.$store.state.currentUserId);
      formData.append("start_date", this.formSelectedStartDate);
      formData.append("end_date", this.formSelectedEndDate);

      // Append donations correctly
      formData.append(
        "donations",
        JSON.stringify(
          this.donateOptions.map((donation) => ({
            amount: donation.amount,
            description: donation.label, // Ensure this matches the backend column
          }))
        )
      );

      // Append image file (don't wrap it in JSON)
      if (this.formSelectedImageFile) {
        formData.append("image", this.formSelectedImageFile);
        formData.append("is_featured_image", "true"); // Strings in FormData
      }

      // Append video file (don't wrap it in JSON)
      if (this.formSelectedVideoFile) {
        formData.append("video", this.formSelectedVideoFile);
        formData.append("is_featured_video", "true");
      }

      try {
        const response = await callOpsPagesCreate(formData);
        this.$toast("Campaign saved successfully", { type: "success" });
        this.$router.push({ name: "ops_pages.show", params: { opsPageId: response.id } });
      } catch (error) {
        console.error(error);
        this.$toast("Failed to save campaign.", { type: "error" });
      }
    },
  },
};
</script>

<style lang="scss">
.ops-new-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__navigate-back {
    margin-bottom: 1rem;

    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }

    p {
      display: inline;
      font-weight: bold;
      padding-left: 6px;
    }
  }

  &__edit-campaign-container {
    padding: 0.5rem 2.2rem;
    text-transform: none;
    margin-top: 2rem;
    float: right;
  }

  .atmo-ops-pages-campaign-form {
    .campaign-form-wrap {
      display: grid;
      grid-template-columns: auto 2fr 1fr;
      gap: 2rem;
    }

    .ps {
      max-height: 19.5rem;
    }

    label {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .form-control {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
      width: 100%;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.4);
    }

    textarea {
      min-height: 7.4rem;
    }

    textarea,
    textarea::placeholder {
      font-family: "Roboto", sans-serif;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.4);
    }

    .picture-video-container {
      border-radius: 5px;
    }

    .campaign-image-container {
      text-align: center;
      border: 2px dashed rgba(255, 255, 255, 0.4);
      border-radius: 2px;
      min-height: 15rem;
      min-width: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        height: 5rem;
      }
    }

    .upload-video-label {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 1rem 0rem 1rem 0rem;

      .video-label {
        margin-bottom: 0rem;
      }

      img {
        height: 1rem;
      }
    }

    .goal-title-description-container {
      border-radius: 5px;
    }

    .description-form {
      display: flex;
      flex-direction: column;

      textarea {
        width: 100%;
      }
    }

    input {
      margin-bottom: 1rem;
    }

    .datepicker-trigger {
      input {
        background: transparent;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        color: white;
      }
    }

    .donation-container {
      border-radius: 5px;
    }

    .new-donation-field-container {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 1rem;
      gap: 1rem;
    }

    .amount-container {
      max-width: 5rem;
    }

    .description-container {
    }

    .add-amount-container {
      width: auto;
    }

    .donate-action-create {
      background-color: white;
      border-radius: 5px;
      border: none;
      height: 2.3rem;
      width: 2.3rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 1rem;
      }
    }

    .new-donation-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.5rem;
    }

    .donation-actions {
      max-width: 5rem;
      display: flex;
      gap: 0.5rem;
    }

    .donation-amount {
      font-size: 1.2rem;
      font-weight: bold;
      /* background-color: $atmo-purple--medium-dark--faded; */
      min-width: 6rem;
      display: flex;
      height: 3rem;
      align-items: flex-start;
      margin-right: 1rem;

      .amount-wrap {
        background-color: rgba(96, 72, 117, 0.3);
        border-radius: 5px;
        padding: 0.5rem;
        min-width: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .donate-action-edit {
      /* background-color: $atmo-purple--medium-dark; */
      background-color: #604875;
      border-radius: 100%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 0.5rem;

      img {
        height: 0.6rem;
      }
    }

    .donate-action-delete {
      /* background-color: $atmo-invalid; */
      background-color: #dc3545;
      margin-left: 6px;
      border-radius: 100%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        height: 0.8rem;
      }
    }

    .submit-button {
      color: white;
      background-color: transparent;
      background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
      border-radius: 5px;
      font-weight: 500;
      font-size: 1rem;
      border: none;
      padding: 0.6rem 1rem 0.6rem 1rem;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
</style>
