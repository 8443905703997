<template>
  <div class="business-subscriptions" data-cy="business-subscriptions">
    <div class="business-subscriptions-container">
      <!-- Free Plan -->
      <div class="business-subscriptions__plan">
        <div class="plan__title-container">
          <p class="plan__header">Business Core</p>
        </div>
        <div class="plan__content-container">
          <div class="content__row">Create Branded Business Profile</div>
          <div class="content__row --dark">Chat Capability</div>
          <div class="content__row">Online Store</div>
          <div class="content__row --dark">Logo, Bio, Website, Social Links</div>
          <div class="content__row">Business Category Tag</div>
          <div class="content__row --dark">Public Business Page</div>
          <div class="content__row">Directory Placement</div>
          <div class="content__row --dark">Follow Artists or Labels</div>
          <div class="content__row">Message Artists/Labels (limited)</div>
          <div class="content__row --dark">Post Announcements or Events</div>
        </div>
      </div>

      <!-- Pro Plan -->
      <div class="business-subscriptions__plan">
        <div class="plan__title-container premium">
          <p class="plan__header">Business Amplify</p>
          <p class="plan__subheader">$49.99–99.99/mo</p>
        </div>
        <perfect-scrollbar>
          <div class="plan__content-container">
            <div class="content__row">Everything in Business Core</div>
            <div class="content__row --dark">Ad Campaign Builder</div>
            <div class="content__row">Target by Genre, Region, Follower Count</div>
            <div class="content__row --dark">Insights Dashboard</div>
            <div class="content__row">Track Followers, Clicks, Engagement</div>
            <div class="content__row --dark">Artist/Label Match Tool</div>
            <div class="content__row">Fan Region Heatmaps</div>
            <div class="content__row --dark">Sponsorship/Collab Proposals</div>
            <div class="content__row">Custom Landing Pages</div>
          </div>
        </perfect-scrollbar>
        <div class="plan__button-container">
          <router-link class="button" :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: $store.state.currentUser.profile_id } }">Select Pro</router-link>
        </div>
      </div>

      <!-- Enterprise Plan -->
      <div class="business-subscriptions__plan">
        <div class="plan__title-container premium">
          <p class="plan__header">Business Quantum</p>
          <p class="plan__subheader">$149.99/mo, $1299/yr</p>
        </div>
        <perfect-scrollbar>
          <div class="plan__content-container">
            <div class="content__row">Everything in Business Amplify</div>
            <div class="content__row --dark">Event Hosting Toolkit</div>
            <div class="content__row">Brand Ambassador Tools</div>
            <div class="content__row --dark">API Access for Campaign Data</div>
            <div class="content__row">Custom Analytics Reports</div>
            <div class="content__row --dark">Featured Directory Placement</div>
            <div class="content__row">In-App Product Drops</div>
            <div class="content__row --dark">Co-Branded Merchandise</div>
            <div class="content__row">Fan-Facing Giveaways via Artist/Label Pages</div>
          </div>
        </perfect-scrollbar>
        <div class="plan__button-container">
          <router-link class="button" :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: $store.state.currentUser.profile_id } }">Select HQ</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit("setHudHeader", "Business Plans");
  },
};
</script>

<style lang="scss">
.business-subscriptions {
  margin: 0 75px;

  .business-subscriptions-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .business-subscriptions__plan {
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.09);
    background: rgba(255, 255, 255, 0.05);
  }

  .plan__title-container {
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    &.premium {
      background: #604875;
      color: white;
    }
  }

  .plan__header {
    font-size: 2rem;
    font-weight: 700;
  }

  .plan__subheader {
    font-size: 1.2rem;
    opacity: 0.8;
  }

  .plan__content-container {
    background: rgba(96, 72, 117, 0.15);
  }

  .content__row {
    padding: 0.8rem;
    font-weight: 500;
    text-align: center;
  }

  .--dark {
    opacity: 0.7;
    background: #604875;
  }

  .plan__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: rgba(96, 72, 117, 0.15);
  }

  .button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8), rgba(68, 174, 220, 0.8));
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
  }
}
</style>

<!-- That’s a fantastic revenue stream idea — building business profiles turns your platform into more than a music hub… it becomes a real creator economy marketplace. Businesses can sponsor artists, collaborate with labels, run campaigns, host events, and more — all while getting tools and analytics.

Let’s break this down into a clear feature set by category, including value tiers you could monetize.

Business Profiles: What They Are
A dedicated profile type for brands, sponsors, venues, merch companies, promoters, or music-aligned startups that want to:

Advertise or sponsor content

Connect with artists/labels

Run promotions or contests

Analyze audience reach and performance

Core Features (Free or Entry Tier)
Create Branded Business Profile

Logo, bio, website, social links

Tag business category (venue, merch brand, tech company, etc.)

Public Business Page with discoverable presence

Directory Placement (searchable by artists/labels/fans)

Follow Artists or Labels (get updates, insights)

Message Artists/Labels (limited)

Post Announcements or Events

Pro Features (Paid Tier: $49.99–99.99/mo)
For Businesses That Want to Promote and Analyze
Ad Campaign Builder

Promote products, contests, or collabs to fans or artists

Choose targets: genre, region, follower count, etc.

Insights Dashboard

View campaign reach, engagement, and clicks

Track followers, profile visits, and artist/label interactions

Artist/Label Match Tool

Discover artists by fanbase size, genre, engagement rate

Fan Region Heatmaps

See where interested audiences are engaging with your brand

Offer Sponsorships or Collabs

Propose campaigns or sponsorship deals directly to artists/labels

Custom Landing Pages

Build microsites for product launches or collabs

Enterprise Features (Add-On or High Tier)
Event Hosting Toolkit

Run virtual or IRL events, track RSVP & attendance

Brand Ambassador Tools

Recruit artists/fans as reps, track reach/sales

API Access for Campaign Data

Integrate with internal systems

Custom Analytics Reports

Segment by demographics, artist partnerships, etc.

Featured Placement in Business Directory

In-App Product Drops

Launch physical/virtual products directly to fans

Interaction Opportunities Across the Ecosystem
Let businesses:

Sponsor releases, contests, or tours

Get featured on artist/label pages as partners

Create discoverable offers or promo codes

Launch co-branded merchandise with artists

Run fan-facing giveaways via artist/label pages

Monetization Ideas:
Monthly subscriptions

Upsells for featured placement or ad credits

Revenue-sharing on branded product sales

Analytics or API access tiers
 -->
