<template>
  <AtmoModal id="emoji-picker-modal" ref="emojiPickerModalRef" v-model="isOpen" title="Choose an Emoji" customClass="emoji-picker-modal" size="small" :closeFunction="() => closeModal()">
    <header class="emoji-picker-modal__header atmo-modal__header">
      <ModalHeader heading="Select Emoji" marginBottom="2rem" />
    </header>
    <div class="emoji-picker-modal__body">
      <EmojiPicker @select="onSelectEmoji" class="custom-emoji-picker" />
    </div>
  </AtmoModal>
</template>

<script>
import { defineComponent } from "vue";
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";
import ModalHeader from "@/components/modal-header";

export default defineComponent({
  components: {
    EmojiPicker,
    ModalHeader,
  },
  props: {
    modelValue: Boolean, // For v-model
  },
  emits: ["update:modelValue", "select"],
  computed: {
    isOpen: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    onSelectEmoji(emoji) {
      this.$emit("select", emoji.i); // Emit the selected emoji
      this.closeModal();
    },
    closeModal() {
      this.isOpen = false;
    },
  },
});
</script>

<style lang="scss">
.emoji-picker-modal {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0); /* Fully transparent background */
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .atmo-modal__header {
    display: flex;
    justify-content: center;
  }
}

.emoji-picker-modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  opacity: 0.6;
}

.emoji-picker-modal__header {
  text-align: center;
  margin-bottom: 10px;
}

.emoji-picker-modal__body {
  background: none;
}

.v3-emoji-picker {
  --v3-picker-bg: transparent;
  --v3-picker-fg: white;
  --v3-picker-border: transparent;
  --v3-picker-input-bg: transparent;
  --v3-picker-input-border: transparent;
  --v3-picker-input-focus-border: white;
  --v3-group-image-filter: brightness(0) invert(1); /* Makes group icons white */
  --v3-picker-emoji-hover: rgba(255, 255, 255, 0.2);
  background: var(--v3-picker-bg);
  color: var(--v3-picker-fg);
  box-shadow: none;
  border-radius: none;
  width: unset;
}

.v3-body-inner {
  scroll-behavior: smooth;
  scrollbar-color: rgba(255, 255, 255, 0.6) transparent;
  scrollbar-width: thin;
}
</style>
