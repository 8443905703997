<template>
  <atmo-popover class="song-actions-popover" :placement="placement" :arrow="true">
    <template #trigger>
      <img src="@/assets/images/icons/share--blue.png" alt="Song Actions" class="song-actions-popover__trigger" />
    </template>
    <template #content>
      <div v-for="(action, index) in actions" :key="`${uid}-${action}`">
        <hr v-if="index > 0" class="atmo-popover__hr" />
        <a class="atmo-popover__dropdown-action atmo-popover__dropdown-action--extra-padding" @click="dispatchStoreActionAndNotify(action)">
          {{ actionTypes[action].label }}
        </a>
      </div>
    </template>
  </atmo-popover>
</template>

<script>
import AtmoPopover from "./atmo-popover";
import { v4 } from "uuid";

export default {
  components: { AtmoPopover },
  props: {
    placement: {
      type: String,
      default: "right",
    },
    song: {
      type: Object,
    },
    playlist: {
      type: Object,
    },
    station: {
      type: Object,
    },
    album: {
      type: Object,
    },
    actions: {
      type: Array,
      default: () => ["add-to-queue"],
    },
  },

  data() {
    return {
      uid: v4(),
      actionTypes: {
        "share-song-as-post": {
          label: "Share Song as Post",
          storeMethodName: "shareSongAsPost",
          successMessage: "Song Shared",
          errorMessage: "Error sharing song",
        },
        "share-song-as-message": {
          label: "Share Song as Message",
          storeMethodName: "toggleFriendPickerModal",
          suppressNotifications: true,
          extraArgs: { type: "message" },
        },
        "add-to-queue": {
          label: "Add to Queue",
          storeMethodName: "atmoQueues/addToQueueFromPopover",
          successMessage: "Song Added to Queue",
          errorMessage: "Error adding song to queue",
        },
        "add-playlist-to-queue": {
          label: "Add Playlist to Queue",
          storeMethodName: "atmoQueues/addPlaylistToQueue",
          successMessage: "Playlist Added to Queue",
          errorMessage: "Error adding playlist to queue",
        },
        "add-to-library": {
          label: "Add to Library",
          storeMethodName: "libraries/addToLibraryFromPopover",
          successMessage: "Song Added to Library",
          errorMessage: "Error adding song to library",
        },
        "add-album-to-library": {
          label: "Add Album to Library",
          storeMethodName: "libraries/addAlbumToLibraryFromPopover",
          successMessage: "Album Added to Library",
          errorMessage: "Error adding album to library",
        },
        "remove-from-library": {
          label: "Remove from Library",
          storeMethodName: "libraries/removeFromLibraryFromPopover",
          successMessage: "Song Removed from Library",
          errorMessage: "Error removing song from library",
        },
        "add-to-playlist": {
          label: "Add to Playlist",
          storeMethodName: "togglePlaylistPickerModal",
          suppressNotifications: true,
        },
        "create-playlist-from-station": {
          label: "Create Playlist From Station",
          storeMethodName: "playlists/createPlaylistFromStation",
          successMessage: "Creating Playlist",
          errorMessage: "Error creating playlist",
        },
        "create-radio-station": {
          label: "Create Station For Song",
          storeMethodName: "atmoRadioStations/createUserRadioStationFromPopover",
          successMessage: "Station created",
          errorMessage: "Error creating station",
        },
        "display-artist": {
          label: "Display Artist Page",
          storeMethodName: "displayArtist",
          successMessage: "Displaying Artist Page",
          errorMessage: "Error displaying page",
        },
        "display-song": {
          label: "Display Song Page",
          storeMethodName: "displaySong",
          successMessage: "Displaying Song Page",
          errorMessage: "Error displaying page",
        },
        "display-album": {
          label: "Display Album Page",
          storeMethodName: "displayAlbum",
          successMessage: "Displaying Album Page",
          errorMessage: "Error displaying page",
        },
        "copy-song-link": {
          label: "Copy Song Link",
          storeMethodName: "copySongLink",
          successMessage: "Song Link Copied",
          errorMessage: "Error copying link",
        },
        "send-song-sms": {
          label: "Send Song Via SMS",
          storeMethodName: "toggleFriendPickerModal",
          suppressNotifications: true,
          extraArgs: { type: "sms" },
        },
      },
    };
  },

  methods: {
    dispatchStoreActionAndNotify(action) {
      const actionType = this.actionTypes[action];

      if (!actionType) {
        console.error(`Action '${action}' not found`);
        return;
      }

      // Construct the payload dynamically
      let payload = this.song ? { song: this.song } : this.playlist ? { playlist: this.playlist } : this.station ? { station: this.station } : this.album ? { album: this.album } : {};

      // Merge extraArgs into payload (if any)
      if (actionType.extraArgs && typeof actionType.extraArgs === "object") {
        payload = { ...payload, ...actionType.extraArgs };
      }

      if (this.$store._mutations[actionType.storeMethodName]) {
        this.$store.commit(actionType.storeMethodName, payload);
        if (!actionType.suppressNotifications) {
          this.$toast(actionType.successMessage, { type: "success" });
        }
      } else if (this.$store._actions[actionType.storeMethodName]) {
        this.$store
          .dispatch(actionType.storeMethodName, payload)
          .then(() => {
            if (!actionType.suppressNotifications) {
              this.$toast(actionType.successMessage, { type: "success" });
            }
          })
          .catch(() => {
            if (!actionType.suppressNotifications) {
              this.$toast(actionType.errorMessage, { type: "error" });
            }
          });
      } else {
        console.error(`Action or mutation '${actionType.storeMethodName}' not found`);
      }
    },
  },
};
</script>

<style lang="scss">
.song-actions-popover {
  &__trigger {
    width: 15px;
    transition: width 0.2s ease-in;
    cursor: pointer;

    &:hover {
      width: 18px;
    }
  }
}
</style>
