<template>
  <perfect-scrollbar class="user-profiles__photos-grid">
    <div v-for="(photo, index) in photoArray" :key="index" class="photos-grid__photo-card">
      <div
        class="photo-card__image-wrap"
        v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + `${photo?.image?.url}` + ')' }"
      >
        <div class="image-wrap__controls-container">
          <!-- Open Modal -->
          <div class="controls-container__view-label" @click.stop="openUserMediaModal({ type: 'Image', caption: '', url: photo.image.url, id: photo.id })">
            <img :src="require('@/assets/images/icons/accomplishments/comment.png')" />
          </div>
          <!-- Edit Photo -->
          <router-link v-if="$store.state.currentUserId === $route.params.userId" class="controls-container__edit-label" :to="{ name: 'photos.edit', params: { photoId: photo.id } }" @click.stop>
            <img :src="require('@/assets/images/icons/edit.png')" />
          </router-link>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import PaginationMixin from "@/mixins/pagination";
import devMockData from "@/devUtils/mockData.js";

export default {
  mixins: [PaginationMixin],
  props: { photoArray: { type: Array, required: true } },

  methods: {
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", {
        type: userMediaObj.type,
        caption: userMediaObj.caption,
        id: userMediaObj.id,
        url: userMediaObj.url,
      });
      this.$store.commit("videoPlayer/setUserMediaModalObj", userMediaObj);
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
  },
};
</script>

<style lang="scss">
.user-profiles__photos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  grid-gap: 1rem;
  margin-top: 10px;
  margin-bottom: 1rem;
  width: 100%;
}

.photos-grid__photo-card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10rem;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  cursor: pointer;
  position: relative;
}

.photo-card__image-wrap {
  min-height: 8rem;
  flex: 1;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 800;
  color: #fff;
  position: relative;
}

.image-wrap__controls-container {
  position: absolute;
  top: 0.4rem;
  left: 0.4rem;
  z-index: 1;
}

.controls-container__view-label {
  font-size: 0.8rem;
  font-weight: 400;
  background-color: rgba(96, 72, 117, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 0.2rem;

  img {
    margin: 3px 0px 0px 0px;
    height: 0.9rem;
  }
}

.controls-container__edit-label {
  font-size: 0.8rem;
  font-weight: 400;
  background-color: rgba(96, 72, 117, 0.7);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    margin-left: 2px;
    height: 0.8rem;
  }
}
</style>
