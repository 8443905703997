<template>
  <div class="label-dashboard fan-metrics" data-cy="label-fan-insights">
    <atmo-back-link></atmo-back-link>
    <div class="label-dashboard__grid-container">
      <div class="label-dashboard__summary-container">
        <div class="label-dashboard__section-header">Total Fan Count</div>
        <div class="label-dashboard__card">
          <p class="label-dashboard__metric-highlight stat-count">
            {{ totalFans !== null ? totalFans.toLocaleString() : "—" }}
          </p>
          <p class="text-sm">{{ monthlyChange != null ? `${monthlyChange > 0 ? "+" : ""}${monthlyChange}% this month` : "—" }}</p>
        </div>

        <div class="label-dashboard__section-header">Top Fan Regions</div>
        <div class="label-dashboard__card">
          <ul class="label-dashboard__list">
            <li v-for="region in topRegions" :key="region.region">{{ region.region }} — {{ region.count?.toLocaleString?.() || 0 }} fans</li>
          </ul>
        </div>

        <div class="demo-container__gender-age-location-container">
          <div class="gender-age-location-container__gender-demo-container">
            <div class="gender-demo-container__gender-demos-wrap">
              <div class="gender-demos-wrap__icon-wrap">
                <img class="icon-wrap__icon" src="@/assets/images/icons/artist_dashboard/male.png" />
              </div>
              <div class="gender-demos-wrap__text-wrap">
                <div class="text-wrap__gender-text">Male</div>
                <div class="text-wrap__gender-percentage">{{ fanData.gender_data?.male_percentage || 0 }}%</div>
              </div>
            </div>
            <div class="gender-demo-container__gender-demos-wrap">
              <div class="gender-demos-wrap__icon-wrap">
                <img class="icon-wrap__icon" src="@/assets/images/icons/artist_dashboard/female.png" />
              </div>
              <div class="gender-demos-wrap__text-wrap">
                <div class="text-wrap__gender-text">Female</div>
                <div class="text-wrap__gender-percentage">{{ fanData.gender_data?.female_percentage ?? "0" }}%</div>
              </div>
            </div>
          </div>

          <div class="gender-age-location-container__age-location-demo-container">
            <div class="age-location-demo-container__age-location-wrap">
              <div class="age-location-wrap__image-wrap">
                <img class="image-wrap__image" src="@/assets/images/icons/artist_dashboard/birthday.png" />
              </div>
              <div class="age-location-wrap__text-wrap">
                <div class="text-wrap__top-line">
                  {{ fanData.fan_average_age || "Unknown" }}
                </div>
                <div class="text-wrap__bottom-line">Average Age (Yrs)</div>
              </div>
            </div>
            <div class="age-location-demo-container__age-location-wrap">
              <div class="age-location-wrap__image-wrap">
                <img class="image-wrap__image" src="@/assets/images/icons/artist_dashboard/pin.png" />
              </div>
              <div class="age-location-wrap__text-wrap">
                <div class="text-wrap__top-line">
                  <template v-if="topLocations?.length">
                    <span v-for="(location, index) in topLocations" :key="index">
                      <template v-if="index === topLocations.length - 1">& {{ location }}</template>
                      <template v-else>{{ location }}, </template>
                    </span>
                  </template>
                  <span v-else>No data available</span>
                </div>
                <div class="text-wrap__bottom-line">Top Locations</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="label-dashboard__section-header fan-growth">Fan Growth Over Time</div>
        <div class="label-dashboard__section-split">
          <div class="label-dashboard__section-left">
            <div class="label-dashboard__card" style="margin-bottom: 2rem">
              <ul class="label-dashboard__list">
                <li v-for="insight in humanizedInsights" :key="insight">{{ insight }}</li>
              </ul>
            </div>
          </div>
          <div class="label-dashboard__section-right">
            <div class="label-dashboard__card">
              <apexchart width="100%" height="200" type="line" :options="fanGrowthChartOptions" :series="fanGrowthSeries" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callBusinessDashboardFanInsights } from "@/helpers/axiosCalls";

export default {
  components: { AtmoBackLink },
  created() {
    this.$store.commit("setHudHeader", "Fan Insights");
    this.fetchData();
  },
  data() {
    return {
      businessProfileId: this.$route.params.businessProfileId,
      totalFans: null,
      topRegions: [],
      fanData: {
        gender_data: {},
        fan_age_counts: [],
      },
      monthlyChange: null,
      fanGrowthSeries: [],
      humanizedInsights: [],
      topLocations: [],
      fanGrowthChartOptions: {
        chart: { type: "line", height: 200, toolbar: { show: false } },
        stroke: { curve: "smooth", width: 4 },
        xaxis: {
          categories: [],
          labels: { show: false },
        },
        yaxis: { labels: { style: { colors: "#ddd" } } },
        tooltip: { theme: "dark" },
        colors: ["#44AEDC"],
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        const data = await callBusinessDashboardFanInsights(this.businessProfileId);
        this.totalFans = data.total_followers;
        this.topRegions = data.top_regions || [];

        const genderData = data.gender_data || {};
        const totalGender = Object.values(genderData).reduce((sum, val) => sum + val, 0);

        this.fanData.gender_data = {
          male_percentage: totalGender ? Math.round(((genderData.male || 0) / totalGender) * 100) : 0,
          female_percentage: totalGender ? Math.round(((genderData.female || 0) / totalGender) * 100) : 0,
        };

        this.fanData.fan_age_counts = data.age_data || [];
        this.fanData.fan_average_age = data.average_age ?? "Unknown";

        this.topLocations = (data.top_regions || []).map((r) => r.region).slice(0, 3);

        this.humanizedInsights = data.insights || [];

        // If growth_chart or monthly_change get added later, handle here
        this.fanGrowthSeries = [
          {
            name: "Fans",
            data: data.fan_growth_chart.map((point) => point.fans),
          },
        ];

        this.fanGrowthChartOptions.xaxis.categories = data.fan_growth_chart.map((point) => point.day);

        this.monthlyChange = null;
      } catch (err) {
        console.error("Fan Insights API error:", err);
      }
    },
    formatSourceLabel(key) {
      const labels = {
        atmo_discovery: "Atmo Organic Discovery",
        social_shares: "Social Shares",
        external_links: "External Links",
        collaborations: "Collaborations",
      };
      return labels[key] || key;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.fan-metrics {
  .fan-growth {
    margin-bottom: 1.5rem;
  }

  .demo-container__gender-age-location-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .gender-age-location-container__gender-demo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .gender-demo-container__gender-demos-wrap {
    display: flex;
    min-width: 10rem;
  }

  .gender-demo-container__gender-demos-wrap + .gender-demo-container__gender-demos-wrap {
    margin-top: 2rem;
  }

  .gender-demos-wrap__icon-wrap {
    margin-right: 1rem;

    .icon-wrap__icon {
      height: 3.2rem;
    }
  }

  .gender-demos-wrap__text-wrap {
    .text-wrap__gender-text {
      font-weight: 600;
      margin-bottom: 0.2rem;
      color: $atmo-white--medium-faded;
    }

    .text-wrap__gender-percentage {
      font-size: 2.2rem;
      font-weight: 600;
    }
  }

  .gender-age-location-container__age-location-demo-container {
    padding: 1rem;
  }

  .age-location-demo-container__age-location-wrap {
    background: rgba(161, 125, 187, 0.6);
    margin-bottom: 1rem;
    padding: 0.7rem;
    border-radius: 10px;
    display: flex;

    .age-location-wrap__image-wrap {
      margin-right: 1rem;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 100%;
      height: 2.5rem;
      width: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-wrap__image {
      height: 2.5rem;
    }

    .text-wrap__top-line {
      font-size: 1.1rem;
      font-weight: 600;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    .text-wrap__bottom-line {
      text-transform: uppercase;
      color: $atmo-gray--light--faded;
    }
  }
}
</style>
