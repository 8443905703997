<template>
  <AtmoModal
    id="live-listening-index"
    ref="liveListeningIndexRef"
    v-model="$store.state.liveListeningRooms.liveListeningIndexOpen"
    title="LiveListeningIndex"
    customClass="live-listening"
    @opened="filterRooms('accepted')"
    size="large"
  >
    <img class="live-listening__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Live Listening" @click="hideModal()" />
    <header class="live-listening__header atmo-modal__header">
      <ModalHeader heading="Live Listening Center" marginBottom="0rem" />
    </header>
    <div class="live-listening-subheader">Please select the desired session below:</div>

    <div class="live-listening__your-room-container" @click="openLiveListeningUser($store.state.liveListeningRooms.currentUserLiveListeningChatRoom.id)">
      <div class="your-room-container__room-wrap">
        <div class="room-wrap__image-wrap">
          <img
            class="image-wrap__image"
            v-if="$store.state.liveListeningRooms.currentUserLiveListeningChatRoom?.creator"
            :src="getFeaturedImage($store.state.liveListeningRooms.currentUserLiveListeningChatRoom.creator.images, 'full')"
            alt="User Image"
          />
        </div>
        <div class="room-wrap__name-wrap">{{ $store.state.liveListeningRooms.currentUserLiveListeningChatRoom?.creator?.name || "Unknown User" }} (Your Session)</div>
      </div>
    </div>
    <div class="live-listening__friends-room-container">
      <div class="friends-room-container__header">Select one of your friends' sessions:</div>
      <div class="friends-room-container__search-wrap">
        <input class="search-field-rounded" type="text" placeholder="Search for a friend" v-model="searchQuery" @input="filterList" />
        <button :class="['search-wrap__room-select', { active: activeRoomSelectionButton === 'accepted' }]" @click="filterRooms('accepted')">Accepted</button>
        <button :class="['search-wrap__room-select', { active: activeRoomSelectionButton === 'requested' }]" @click="filterRooms('requested')">Requested</button>
        <button :class="['search-wrap__room-select', { active: activeRoomSelectionButton === 'unrequested' }]" @click="filterRooms('unrequested')">Unrequested</button>
      </div>
      <perfect-scrollbar class="friends-room-container__rooms-wrap">
        <div class="friends-room-container__empty-room-wrap" v-if="filteredRoomList.length === 0">No results</div>
        <div class="friends-room-container__room-wrap" v-for="(room, index) in filteredRoomList" :key="index">
          <div class="room-wrap__image-wrap">
            <img class="image-wrap__image" v-if="room.creator" :src="getFeaturedImage(room.creator.images, 'full')" alt="Friend Image" />
          </div>
          <div class="room-wrap__name-wrap">{{ room.creator?.name || "Unknown User" }}</div>
          <div class="room-wrap__manage-wrap" v-if="!room.current_chat_room_user_request">
            <div @click="requestToJoinRoom(room)">Request to join</div>
          </div>
          <div class="room-wrap__manage-wrap" v-else>
            <div @click="openLiveListeningUser(room.id)" v-if="room.current_chat_room_user_request.status === 'accepted'">Join room</div>
            <div @click="leaveRoom(room.id, room.current_chat_room_user.id, room.current_chat_room_user_request.id)" v-if="room.current_chat_room_user_request.status === 'accepted'">Leave room</div>
            <div @click="cancelRequestToJoinRoom(room)" v-if="room.current_chat_room_user_request.status === 'requested'">Cancel request</div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </AtmoModal>
</template>

<script>
import { connectToLiveListeningChatChannel } from "@/cable-channels/liveListeningChat";
import { connectToLiveListeningSongsChannel } from "@/cable-channels/liveListeningSongs";
import devMockData from "@/devUtils/mockData.js";
import {
  callChatRoomUserRequestsCreate,
  callChatRoomUserRequestsDelete,
  callChatRoomUsersDelete,
  callNotificationsCreate,
  callNotificationsDelete,
  callNotificationsDeleteWithParams,
} from "@/helpers/axiosCalls";
import { sendMessage, setCallback, connectToNotificationsChannel } from "@/cable-channels/notifications";
import { getFeaturedImage } from "@/helpers/utilityFunctions";
import ModalHeader from "@/components/modal-header";

export default {
  components: {
    ModalHeader,
  },
  data() {
    return {
      artistImageArray: devMockData.artistImageArray,
      friendNameArray: devMockData.friendNameArray,
      searchQuery: "",
      friendLiveListeningChatRooms: [],
      activeRoomSelectionButton: null,
    };
  },
  computed: {
    filteredRoomList() {
      return this.friendLiveListeningChatRooms.filter((room) => room.creator?.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    updateUserLiveListeningRooms() {
      this.$store.dispatch("liveListeningRooms/getFriendLiveListeningChatRooms", this.$store.state.currentUserId);
      this.friendLiveListeningChatRooms = this.$store.state.liveListeningRooms.friendLiveListeningChatRooms;
    },
    requestToJoinRoom(room) {
      callChatRoomUserRequestsCreate({
        user_id: this.$store.state.currentUserId,
        chat_room_id: room.id,
        status: "requested",
      })
        .then((response) => {
          callNotificationsCreate(room.creator.id, {
            sender_id: this.$store.state.currentUserId,
            receiver_id: room.creator.id,
            notification_type_name: "Live Listening Request",
            status: "unread",
            notifiable_type: "ChatRoomUserRequest",
            notifiable_id: response.id,
          })
            .then((response) => {
              console.log(response);
              this.updateUserLiveListeningRooms();
              connectToNotificationsChannel(room.creator.id);
              sendMessage(this.$store.state.currentUserId, room.creator.id, 3, "unread", "ChatRoomUserRequest", response.id);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    leaveRoom(chatRoomId, chatRoomUserId, requestId) {
      callChatRoomUsersDelete(chatRoomId, chatRoomUserId)
        .then(() => {
          callChatRoomUserRequestsDelete(requestId)
            .then((response) => {
              console.log(response);
              this.updateUserLiveListeningRooms();
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    cancelRequestToJoinRoom(room) {
      callChatRoomUserRequestsDelete(room.current_chat_room_user_request.id)
        .then((response) => {
          console.log(response);
          callNotificationsDeleteWithParams({
            notifiable_id: room.current_chat_room_user_request.id,
            notifiable_type: "ChatRoomUserRequest",
          })
            .then((response) => {
              console.log(response);
              this.updateUserLiveListeningRooms();
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hideModal() {
      this.$store.commit("liveListeningRooms/closeLiveListeningIndex");
      this.searchQuery = "";
    },
    handleButtonState(type) {
      if (this.activeRoomSelectionButton === type) {
        this.activeRoomSelectionButton = null; // Toggle off if the same button is clicked again
      } else {
        this.activeRoomSelectionButton = type; // Set the active button to the clicked button
      }
    },
    filterRooms(type) {
      this.handleButtonState(type);
      const rooms = this.$store.state.liveListeningRooms.friendLiveListeningChatRooms;

      if (type === "accepted") {
        this.friendLiveListeningChatRooms = rooms.filter((room) => room.current_chat_room_user_request && room.current_chat_room_user_request.status === "accepted");
      } else if (type === "requested") {
        this.friendLiveListeningChatRooms = rooms.filter((room) => room.current_chat_room_user_request && room.current_chat_room_user_request.status === "requested");
      } else if (type === "unrequested") {
        this.friendLiveListeningChatRooms = rooms.filter((room) => room.current_chat_room_user_request === null);
      }
    },
    openLiveListeningUser(roomId) {
      this.$store.commit("liveListeningRooms/closeLiveListeningIndex");
      this.$store.commit("liveListeningRooms/openLiveListeningUser");
      this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoom", roomId);
      this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomMessages", roomId);
      this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomUsers", roomId);
      this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomSongs", roomId);
      connectToLiveListeningChatChannel(roomId);
      connectToLiveListeningSongsChannel(roomId);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.live-listening {
  .ps {
    max-height: 27rem;
  }

  .live-listening__hide-modal {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    opacity: 0.5;
    cursor: pointer;
  }

  .live-listening__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  .live-listening-subheader {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .live-listening__your-room-container {
    margin-bottom: 1rem;

    &:hover {
      background-color: rgba(190, 167, 221, 0.3);
      backdrop-filter: blur(5px);
    }
  }

  .your-room-container__room-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    margin-bottom: 1rem;
    cursor: pointer;

    .image-wrap__image {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      margin-right: 0.5rem;
    }

    .room-wrap__name-wrap {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .live-listening__friends-room-container {
  }

  .friends-room-container__header {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .friends-room-container__search-wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .search-wrap__room-select {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    padding: 0.5rem;
    display: inline-block;
    text-transform: uppercase;
    background: none;
    color: white;

    &:hover {
      background-color: rgba(190, 167, 221, 0.3);
      backdrop-filter: blur(5px);
    }

    &.active {
      background-color: rgba(190, 167, 221, 0.3);
      backdrop-filter: blur(5px);
    }
  }

  .search-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .friends-room-container__empty-room-wrap {
    min-height: 27rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .friends-room-container__rooms-wrap {
    min-height: 27rem;
    font-size: 1.2rem;
  }

  .friends-room-container__room-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    margin-bottom: 1rem;
    cursor: pointer;

    .image-wrap__image {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      margin-right: 0.5rem;
    }

    .room-wrap__name-wrap {
      font-size: 0.9rem;
      font-weight: 400;
    }

    .room-wrap__manage-wrap {
      display: none;
    }

    &:hover {
      background-color: rgba(190, 167, 221, 0.3);
      backdrop-filter: blur(5px);

      .room-wrap__manage-wrap {
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 1rem;
        gap: 1rem;
        text-transform: uppercase;
        font-weight: 400;
      }
    }
  }
}
</style>
