<template>
  <div class="photo-edit-page">
    <atmo-back-link></atmo-back-link>
    <div v-if="photo" class="photo-edit-container">
      <!-- Image on the left -->
      <img v-if="photo.image" :src="photo.image.url" alt="Photo Preview" class="photo-preview" />

      <!-- Fields on the right -->
      <div class="form-container">
        <input class="text-field" type="text" v-model="photo.name" placeholder="Photo Name" />

        <div class="action-buttons">
          <button class="save-photo" @click="updatePhoto">Save Changes</button>
          <button class="delete-photo" @click="deletePhoto">Delete Photo</button>
        </div>
      </div>
    </div>

    <div v-else class="loading-message">
      <p>Loading photo details...</p>
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callImagesShow, callImagesUpdate, callImagesDelete } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoBackLink,
  },
  data() {
    return {
      photo: null,
    };
  },
  computed: {
    photoId() {
      return this.$route.params.photoId;
    },
  },
  created() {
    this.fetchPhotoDetails();
    this.$store.commit("setHudHeader", "Edit Photo");
  },
  methods: {
    async fetchPhotoDetails() {
      try {
        const response = await callImagesShow(this.photoId);
        this.photo = response;
      } catch (error) {
        console.error("Error fetching photo:", error);
        this.$toast("Failed to load photo details", { type: "error" });
      }
    },
    async updatePhoto() {
      if (!this.photo) return;

      callImagesUpdate(this.photoId, {
        name: this.photo.name,
      })
        .then(() => {
          this.$toast("Photo updated successfully", { type: "success" });
          this.$router.push({ name: "photos.index", params: { userId: this.$store.state.currentUserId } });
        })
        .catch(() => {
          console.log("Unexpected API response");
        });
    },
    async deletePhoto() {
      if (!confirm("Are you sure you want to delete this photo?")) return;

      try {
        await callImagesDelete(this.photoId);
        this.$toast("Photo deleted successfully", { type: "success" });
        this.$router.push({ name: "photos.index", params: { userId: this.$store.state.currentUserId } });
      } catch (error) {
        console.error("Error deleting photo:", error);
        this.$toast("Failed to delete photo", { type: "error" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.photo-edit-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start; // Aligns items to the top
  justify-content: center;
  gap: 20px;
  max-width: 900px;
  margin: 0 auto;

  .photo-preview {
    width: 50%;
    height: 20rem;
    object-fit: cover;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }

  .text-field {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem;
    color: white;
    margin-bottom: 1rem;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
  }

  .save-photo,
  .delete-photo {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    text-transform: uppercase;
  }

  .save-photo {
    background: linear-gradient(-270deg, rgba(217, 82, 167, 0.8), rgba(68, 174, 220, 0.8));
    color: white;
  }

  .delete-photo {
    background: $atmo-invalid;
    color: white;
  }
}
</style>
