<template>
  <div class="user-profile-photos-container">
    <!-- <div class="header-container">
            <h2 class="atmo-page-header">Photos</h2>
        </div> -->
    <atmo-back-link></atmo-back-link>
    <div class="upload-link-container" v-if="userId == $store.state.currentUserId">
      <router-link :to="{ name: 'photos.new', params: { userId: userId } }" class="upload-button"> Upload New Photo </router-link>
    </div>
    <atmo-profiles-photos-grid :photoArray="userPhotos" />
  </div>
</template>

<script>
import AtmoProfilesPhotosGrid from "@/components/profiles/atmo-profiles-photos-grid.vue";
import AtmoBackLink from "@/components/atmo-back-link";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AtmoProfilesPhotosGrid,
    AtmoBackLink,
  },
  computed: {
    ...mapGetters("profiles", {
      userPhotos: "getUserPhotos",
    }),
    userId() {
      return this.$route.params.userId;
    },
  },
  created() {
    this.fetchUserPhotos(this.userId);
    this.$store.commit("setHudHeader", "Photos");
  },
  methods: {
    ...mapActions("profiles", ["fetchUserPhotos"]),
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.user-profile-photos-container {
  margin: 0 75px;

  .upload-link-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .upload-button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-right: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }
  .upload-button:hover {
    background-color: #0056b3;
  }
}
</style>
