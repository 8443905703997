<template>
  <div class="label-dashboard" data-cy="label-streaming-insights">
    <atmo-back-link></atmo-back-link>
    <div class="label-dashboard__grid-container">
      <div class="label-dashboard__summary-container">
        <div class="label-dashboard__section-header">Total Plays</div>
        <div class="label-dashboard__card">
          <p class="label-dashboard__metric-highlight stat-count">{{ totalPlays !== null ? totalPlays.toLocaleString() : "—" }}</p>
          <p class="text-sm">{{ monthlyChange != null ? `${monthlyChange > 0 ? "+" : ""}${monthlyChange}% this month` : "—" }}</p>
        </div>

        <div class="label-dashboard__section-header">Top Performing Songs</div>
        <div class="label-dashboard__card">
          <ul class="label-dashboard__list">
            <li v-for="song in topSongs" :key="song.id">
              <span>
                <router-link v-if="song" :to="{ name: 'songs.show', params: { songId: song.id } }">{{ song.title }}</router-link> by
                <router-link v-if="song.artist_id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: song.artist_id } }">{{ song.artist }}</router-link> —
                {{ song.plays?.toLocaleString?.() || 0 }} plays
              </span>
            </li>
          </ul>
        </div>

        <div class="label-dashboard__section-header">Streams by Artist</div>
        <div class="label-dashboard__card">
          <ul class="label-dashboard__list">
            <li v-for="artist in streamsByArtist" :key="artist.name">
              <span>
                <router-link v-if="artist.id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: artist.id } }">
                  {{ artist.name }}
                </router-link>
                – {{ artist.plays.toLocaleString() }} plays
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div>
        <div class="label-dashboard__section-header streaming-trends">Streaming Trends (Last 30 Days)</div>
        <div class="label-dashboard__section-split">
          <div class="label-dashboard__section-left">
            <div class="label-dashboard__card" style="margin-bottom: 2rem">
              <ul class="label-dashboard__list">
                <li v-for="insight in humanizedInsights" :key="insight">{{ insight }}</li>
              </ul>
            </div>
          </div>
          <div class="label-dashboard__section-right">
            <div class="label-dashboard__card">
              <apexchart width="100%" height="200" type="line" :options="streamingChartOptions" :series="streamingChartSeries" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callLabelDashboardsStreamingInsights } from "@/helpers/axiosCalls";

export default {
  components: { AtmoBackLink },
  created() {
    this.$store.commit("setHudHeader", "Streaming Insights");
    this.fetchData();
  },
  data() {
    return {
      labelProfileId: this.$route.params.labelProfileId,
      totalPlays: null,
      topSongs: [],
      streamsByArtist: [],
      monthlyChange: null,
      humanizedInsights: [],
      streamingChartSeries: [],
      streamingChartOptions: {
        chart: { type: "line", height: 200, toolbar: { show: false } },
        stroke: { curve: "smooth", width: 4 },
        xaxis: {
          categories: Array.from({ length: 15 }, (_, i) => `Day ${i + 1}`),
          labels: { show: false },
        },
        yaxis: { labels: { style: { colors: "#ddd" } } },
        tooltip: { theme: "dark" },
        colors: ["#44AEDC"],
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        const data = await callLabelDashboardsStreamingInsights(this.labelProfileId);
        this.totalPlays = data.total_plays;
        this.topSongs = data.top_songs;
        this.streamsByArtist = data.streams_by_artist;
        this.monthlyChange = data.monthly_change;
        this.humanizedInsights = data.humanized_insights;

        // Format chart series
        this.streamingChartSeries = [
          {
            name: "Plays",
            data: data.trends_chart.map((point) => point.plays),
          },
        ];

        this.streamingChartOptions.xaxis.categories = data.trends_chart.map((point) => point.day);
      } catch (err) {
        console.error("Streaming Insights API error:", err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.streaming-trends {
  margin-bottom: 1.5rem;
}
</style>
