import Axios from 'axios';

export function callLabelProfilesShow(labelProfileId, queryParams = {}) {
    return Axios.get(`/api/v1/label_profiles/${labelProfileId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLabelProfilesUpdate(labelProfileId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/label_profiles/${labelProfileId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLabelProfilesArtists(labelProfileId, queryParams = {}) {
    return Axios.get(`/api/v1/label_profiles/${labelProfileId}/artists`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
