<template>
  <div class="artist-subscriptions" data-cy="label-subscriptions">
    <div class="artist-subscriptions-container">
      <!-- Free Plan -->
      <div class="artist-subscriptions-container__free-plan">
        <div class="free-plan__title-container">
          <p class="title-container__free-header">Label Core</p>
        </div>
        <perfect-scrollbar>
          <div class="free-plan__content-container">
            <div class="content-container__row --dark"><div class="row__text-container">Create Label Profile</div></div>
            <div class="content-container__row"><div class="row__text-container">Add Unlimited Artists</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Chat Capability</div></div>
            <div class="content-container__row"><div class="row__text-container">Online Store</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Direct to Fan Messaging</div></div>
            <div class="content-container__row"><div class="row__text-container">Live Listening Sessions</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Atmosphere Directory</div></div>
            <div class="content-container__row"><div class="row__text-container">Public Roster Page</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Public Posting</div></div>
            <div class="content-container__row"><div class="row__text-container">Contests & Giveaways</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Basic Streaming Overview (total plays/followers)</div></div>
          </div>
        </perfect-scrollbar>
      </div>

      <!-- Pro Plan -->
      <div class="artist-subscriptions-container__paid-plan">
        <div class="paid-plan__title-container">
          <div class="title-container__image-container">
            <img class="image-container__vip_label" src="@/assets/images/subscriptions_images/vip_label.png" />
          </div>
          <div class="title-container__header-container">
            <p class="title-container__paid-header">Label Amplify</p>
            <p class="title-container__paid-subheader">$49.99/mo, $480/yr</p>
          </div>
        </div>
        <perfect-scrollbar>
          <div class="paid-plan__content-container">
            <div class="content-container__row --dark"><div class="row__text-container">Everything in Label Core</div></div>
            <div class="content-container__row"><div class="row__text-container">Roster-Wide Streaming & Fan Analytics</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Top Songs & Artists Overview</div></div>
            <div class="content-container__row"><div class="row__text-container">Top Regions (Cities/Countries)</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Fan Demographics (Age/Gender)</div></div>
            <div class="content-container__row"><div class="row__text-container">Fan Demographics by Artist</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Revenue by Artist Breakdown</div></div>
            <div class="content-container__row"><div class="row__text-container">Revenue Dashboard by Artist</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Promote Releases in Directory & Discovery</div></div>
            <div class="content-container__row"><div class="row__text-container">Early Access to Sync & Playlist Submissions</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Export Monthly Performance Reports</div></div>
            <div class="content-container__row"><div class="row__text-container">Playlist Tracker</div></div>
          </div>
        </perfect-scrollbar>
        <div class="paid-plan__button-container">
          <router-link class="button-container__button" :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: $store.state.currentUser.profile_id } }">Select Now</router-link>
        </div>
      </div>

      <!-- Enterprise Plan -->
      <div class="artist-subscriptions-container__paid-plan">
        <div class="paid-plan__title-container">
          <div class="title-container__image-container">
            <img class="image-container__vip_label" src="@/assets/images/subscriptions_images/p_label.png" />
          </div>
          <div class="title-container__header-container">
            <p class="title-container__paid-header">Label Quantum</p>
            <p class="title-container__paid-subheader">$149.99/mo, $1299/yr</p>
          </div>
        </div>
        <perfect-scrollbar>
          <div class="paid-plan__content-container">
            <div class="content-container__row --dark"><div class="row__text-container">Everything in Label Amplify</div></div>
            <div class="content-container__row"><div class="row__text-container">Ops Page Campaigns</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Daily Data Refresh (instead of weekly)</div></div>
            <div class="content-container__row"><div class="row__text-container">Compare Artist Performance (side-by-side)</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Fan Funnel Insights (listen → follow → buy)</div></div>
            <div class="content-container__row"><div class="row__text-container">Revenue Source Breakdown (streaming, merch, tips, etc.)</div></div>
            <div class="content-container__row --dark">
              <div class="row__text-container">A&R Discovery Tool - Discover rising talent based on platform metrics (most added, fastest growing, etc.)</div>
            </div>
            <div class="content-container__row">
              <div class="row__text-container">Sync Licensing Marketplace Access - Pitch songs to media opportunities — or respond to briefs from music supervisors.</div>
            </div>
            <div class="content-container__row --dark"><div class="row__text-container">Smart Revenue Forecasting - Predict next quarter’s income based on trends, release schedules, etc.</div></div>
            <div class="content-container__row"><div class="row__text-container">Custom Label Branding & Theme Options</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Email Campaign Builder (segment fans by artist or region)</div></div>
            <div class="content-container__row"><div class="row__text-container">Add Team Members with Role-Based Access</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Exportable CSV/PDF Reports for Finance & Marketing</div></div>
            <div class="content-container__row"><div class="row__text-container">Promo Pack Generator - Auto-generate press kits or marketing materials using artist/label dat</div></div>
            <div class="content-container__row --dark"><div class="row__text-container">Dedicated Account Support</div></div>
            <div class="content-container__row"><div class="row__text-container">Beta Access to New Label Tools</div></div>
            <div class="content-container__row --dark">
              <div class="row__text-container">Virtual Label Events Dashboard - Run listening parties or industry meetups with built-in guest analytics.</div>
            </div>
            <div class="content-container__row"><div class="row__text-container">Analytics API Access - For labels who want to integrate your data into their own dashboards.</div></div>
          </div>
        </perfect-scrollbar>
        <div class="paid-plan__button-container">
          <router-link class="button-container__button" :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: $store.state.currentUser.profile_id } }">Select Now</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { callLabelProfilesShow } from "@/helpers/axiosCalls";

export default {
  data() {
    return {
      labelProfile: {},
    };
  },
  created() {
    callLabelProfilesShow(this.$store.state.currentUser.profile_id).then((response) => {
      this.labelProfile = response;
    });

    this.$store.commit("setHudHeader", "Upgrade Your Label Plan");
  },
};
</script>

<!--
🟣 Label Lounge (Free)
Create a Label Profile

Add Unlimited Artists

Public Roster Page

Basic Streaming Overview (total plays/followers)

Submit to Open Opportunities

🔵 Label Suite ($49.99/mo, $480/yr)
For labels that want performance visibility and fan data.

Everything in Label Lounge

Roster-Wide Streaming & Fan Analytics

Top Songs & Artists Overview

Top Regions (Cities/Countries)

Fan Demographics (Age/Gender)

Revenue Dashboard by Artist

Promote Releases in Directory & Discovery

Early Access to Sync & Playlist Submissions

Export Monthly Performance Reports

🔶 Label HQ ($149.99/mo, $1299/yr)
For labels who want deeper control, brand power, and tools for scaling.

Everything in Label Suite

Daily Data Refresh (instead of weekly)

Compare Artist Performance (side-by-side)

Fan Funnel Insights (listen → follow → buy)

Revenue Source Breakdown (streaming, merch, tips, etc.)

Custom Label Branding & Theme Options

Email Campaign Builder (segment fans by artist or region)

Add Team Members with Role-Based Access

Exportable CSV/PDF Reports for Finance & Marketing

Dedicated Account Support

Beta Access to New Label Tools

Analytics API Access

These changes position higher tiers as data-driven, operational, and marketing-focused — which is what serious labels care about.

Would you like me to update the LabelSubscriptions.vue template to match this new breakdown now?



Core Tier (Label Suite) – Operational + Data Tools
These tools help labels manage and monitor artist activity:

Roster Dashboard
Central hub showing total plays, fans, and activity across all signed artists.

Top Songs Across Label
View and filter best-performing tracks across your roster.

Top Regions Map
Visual map of where your label is growing most (city/country view).

Fan Demographics by Artist
Age/gender breakdown across the roster or per artist.

Revenue by Artist Breakdown
High-level insight into where your label’s revenue is coming from.

Monthly PDF Reports
Automatically generated performance reports for investors or internal review.

Playlist Tracker
Track which songs are being added to user-generated or platform playlists.

Pro Tier (Label HQ) – Marketing + Strategic Tools
These tools add deeper control, marketing reach, and insights:

Artist Comparison Tool
Compare growth, engagement, and sales across two or more artists.

Fan Funnel Insights
Track user behavior like: discover → play → follow → buy/engage.

Email Campaign Builder
Send email updates to fans of specific artists, regions, or genres.

Content Scheduler
Schedule posts, releases, or messages across multiple artists.

Label Branding Tools
Customize profile theme, color palette, or release landing pages.

Promo Pack Generator
Auto-generate press kits or marketing materials using artist/label data.

Team Access Manager
Add staff with permissions (e.g., A&R, marketing, accounting).

Elite / Add-On Tools (Optional Upsells or Enterprise Features)
For enterprise-level labels or extra revenue streams:

A&R Discovery Tool
Discover rising talent based on platform metrics (most added, fastest growing, etc.)

Sync Licensing Marketplace Access
Pitch songs to media opportunities — or respond to briefs from music supervisors.

Smart Revenue Forecasting
Predict next quarter’s income based on trends, release schedules, etc.

Custom API Access
For labels who want to integrate your data into their own dashboards.

White-Label Artist Pages
Co-branded microsites for big releases or press campaigns.

Virtual Label Events Dashboard
Run listening parties or industry meetups with built-in guest analytics.




Label Subscription Plan Tiers (Finalized)
🟣 Label Lounge (Free Tier)
Goal: Get labels in the door and onboarded

Create Label Profile

Add Unlimited Artists

View Public Roster Page

Basic Roster-Level Stats (fans, total plays)

Submit to Open Opportunities

🔵 Label Suite (Pro Tier – $49.99/mo, $480/yr)
Goal: Equip labels with analytics and lightweight marketing tools
Includes all Label Lounge features, plus:

Roster Dashboard (total fans, streams, trends)

Top Songs Across Label

Top Regions Map

Fan Demographics by Artist (age, gender)

Revenue Breakdown by Artist

Monthly Exportable PDF Reports

Playlist Tracker

Promo Pack Generator

Early Access to Editorial & Sync Submissions

🔶 Label HQ (Premium Tier – $149.99/mo, $1299/yr)
Goal: Provide full data access, brand control, and operational power
Includes all Label Suite features, plus:

Daily Analytics Refresh

Compare Artist Performance Tool

Fan Funnel Tracking (Discover → Play → Follow → Buy)

Revenue Source Insights (streams, merch, tips, etc.)

Email Campaign Builder

Team Access Manager (assign roles: A&R, marketing, etc.)

Label Branding Toolkit (custom themes, branded pages)

Content Scheduler (Posts/Releases)

CSV + API Access

Dedicated Label Support Rep

Beta Access to New Label Tools

🧩 Add-Ons / Future Monetizable Features
(Optional for HQ members or Enterprise add-ons)

A&R Discovery Tool (discover rising artists via data)

Sync Licensing Marketplace Access

Smart Revenue Forecasting Tool

White-Label Microsite Builder

Virtual Label Event Dashboard




Te Public Roster Page is a profile-style landing page for each label that showcases all the artists they manage or represent. Think of it like a digital press kit or branded hub that fans, media, venues, or other industry professionals can view.

Core Purpose:
To make the label discoverable, promote its artists, and establish credibility.

What It Might Include:
1. Label Overview
Label name, logo, bio/mission statement

Social links, website

Location or region focus (optional)

2. Featured Artists
Grid or carousel of current roster

Each artist card shows:

Name, profile image

Genre tags

Link to artist profile

Top song or stat preview (e.g. “1.2M streams”)

3. Recent Releases
New music from label artists (grouped chronologically)

Play buttons or links to release pages

4. Spotlight Section (optional for paid tiers)
Label-curated playlists

Notable achievements: tours, awards, press, syncs

Example Use Cases:
A fan browsing artists and landing on the label’s page

A music blog linking to a label’s page for context in an article

A label sharing their roster for sync/licensing pitches

An artist browsing the platform looking to get signed

You can build it public-first (like a landing page) and add extra private-only features (e.g. internal analytics, editing) for label admins behind the scenes.
 -->
