<template>
  <div class="atmo-ops">
    <div class="atmo-contests">
      <!-- <div class="header-container">
        <h2 class="atmo-page-header">
          {{ pageTitle }}
        </h2>
      </div> -->
      <section class="atmo-contests__body">
        <div class="atmo-contests__controls">
          <router-link v-if="isMyCampaigns" :to="{ name: 'ops_pages.new' }" class="atmo-contests__add-new-contest" data-cy="new-contest" @click="addNewCampaign">
            <button class="atmo-icon-button">
              <img src="@/assets/images/icons/add.png" alt="create playlist" class="atmo-contests__add-new-contest-icon" />
            </button>
            Add New Campaign
          </router-link>
          <!-- <form v-else class="atmo-form">
            <input type="text" placeholder="Search Contests" class="atmo-contests__search atmo-input atmo-input--search"
              :value="query" @input="onSearch">
          </form> -->
        </div>
        <perfect-scrollbar class="atmo-contests__main-content">
          <ops-page-list-item v-for="page in pages" :key="page.id" :page="page" @delete-campaign="deleteCampaign" />
        </perfect-scrollbar>
      </section>
    </div>
  </div>
</template>

<script>
// import AtmoCard from "@/components/atmo-card";
import { mapState, mapActions } from "vuex";
// import AtmoPopover from "@/components/atmo-popover";
// import AtmoOpsPageFooter from "@/components/atmo-ops-card-donate-bar.vue";
import devMockData from "@/devUtils/mockData.js";
import OpsPageListItem from "@/routes/ops_pages/ops-page-list-item";
// import AtmoOpsPages from "@/components/atmo-ops-pages-list";

export default {
  components: {
    OpsPageListItem,
    // AtmoOpsPages,
    // AtmoCard,
    // AtmoPopover,
    // AtmoOpsPageFooter
  },
  props: {
    // artistId: {
    //   type: String,
    //   required: true
    // },
    // searchTerm: {
    //   type: String,
    //   required: true
    // }
  },
  data() {
    return {
      devMockData: devMockData,
      searchTerm: "",
    };
  },
  created() {
    this.loadOpsPages();
    // this.loadArtist(this.$route.params.artistProfileId);
    this.$store.commit("setHudHeader", this.pageTitle);
  },
  computed: {
    ...mapState("ops", ["pages"]),
    isMyCampaigns() {
      if (this.$route.params.userId) {
        return Number(this.$store.state.currentUserId) === Number(this.$route.params.userId);
      }

      return false;
    },
    pageTitle() {
      return `${this.isMyCampaigns ? "My" : "Atmo"} Campaigns`;
    },
    artistId() {
      return this.$route.params.artistProfileId;
    },
    // filteredContests() {
    //   return (this.allContests || []).filter((contest) => {
    //     const matchesContestName = contest.name.toLowerCase().includes(
    //       (this.query || '').toLowerCase()
    //     );
    //     const matchesArtistName = contest.user.name.toLowerCase().includes(
    //       (this.query || '').toLowerCase()
    //     );
    //     return matchesContestName || matchesArtistName;
    //   });
    // },
    // fullCollection() {
    //   return this.filteredContests;
    // },
    searchedPages() {
      let term = this.searchTerm.toLowerCase();
      return this.pages.filter((page) => {
        return term === "" || page.campaign_name.toLowerCase().includes(term) || page.campaign_description.toLowerCase().includes(term);
      });
    },
    isEmpty() {
      return this.pages.length === 0;
    },
    emptyMessage() {
      if (this.pages.length === 0) {
        return "No campaigns found at this time.";
      } else {
        return "Campaigns found.";
      }
    },
  },
  methods: {
    ...mapActions("ops", ["editCampaign", "deleteCampaign", "loadOpsPages", "loadArtist"]),
    addNewCampaign() {},
    deleteCampaign() {},
    goToPage: function (id) {
      this.$router.push(`/artist_profiles/${this.artistId}/ops_pages/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.atmo-ops {
  margin: 0 75px;
}

.ops-action__create {
  display: flex;
  align-items: center;

  img {
    padding-right: 18px;
  }
}

.atmo-ops__body {
  .ops-container {
    white-space: nowrap;
    min-height: 400px;

    .atmo-empty-state__label {
      display: flex;
      justify-content: center;
    }
  }

  .ops-item {
    overflow: hidden;
    display: inline-block;
    padding: 7px;
    background-color: $atmo-purple--medium-dark;
    margin-right: 24px;
    width: 250px;
    height: 400px;

    &:hover {
      background-color: $atmo-purple--dark;
    }
  }

  //   &__footer {
  //     padding: 12px;
  //     margin-top: 20px;
  //   }

  //   &__card-image {
  //     width: 235px;
  //     height: 180px;
  //     object-fit: cover;
  //   }

  //   &__body {
  //     h2 {
  //       text-align: center;
  //       margin-top: 12px;
  //     }

  //     p {
  //       margin-top: 12px;
  //       line-height: 1.5;
  //       font-size: 1.3em;
  //       justify-content: center;
  //       white-space: normal;
  //       text-align: center;
  //       max-height: 80px;
  //       min-height: 80px;
  //       overflow: hidden;
  //     }
  //   }

  //   &__card-actions {
  //     margin-right: 5px;
  //     margin-top: 5px;
  //     border-radius: 50%;
  //     width: 30px;
  //     height: 30px;
  //     background: $atmo-purple--medium-dark;
  //     text-align: center;
  //     line-height: 30px;

  //     img {
  //       height: 6px;
  //     }
  //   }
  // }

  // .ops-popover__content {
  //   min-width: 124px;
  // }
}
</style>
