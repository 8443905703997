<template>
  <AtmoModal id="atmo-ad-modal" ref="atmoAdModal" v-model="$store.state.atmoAdModalOpen" title="Atmo Ad Modal" @opened="getPlatformAds">
    <header class="atmo-ad-modal__header">
      <div class="header__hide-ad-wrap" @click="toggleAtmoAdModalOpen">
        <img class="hide-ad-wrap__hide-ad" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Ad" />
      </div>
    </header>
    <div class="atmo-ad-modal__ad-container" v-if="randomPlatformAd">
      <div
        class="ad-container__image-wrap"
        v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${randomPlatformAd.image.url})` }"
      ></div>
      <div class="ad-container__text-wrap">
        <div class="text-wrap__title-wrap">{{ randomPlatformAd.title }}</div>
        <div class="text-wrap__body-button-wrap">
          <div class="body-button-wrap__body-wrap">{{ randomPlatformAd.copy }}</div>
          <div class="body-button-wrap__button-wrap">
            <a class="button-wrap__button" href="randomPlatformAd.link" target="_blank">
              {{ randomPlatformAd.link_label }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import Axios from "axios";
import devMockData from "@/devUtils/mockData.js";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      isOpen: true,
      highResImageArray: devMockData.highResImageArray,
      adHeadlineArray: devMockData.adHeadlineArray,
      adDescriptionArray: devMockData.adDescriptionArray,
      platformAds: null,
      randomPlatformAd: null,
    };
  },
  methods: {
    ...mapMutations(["toggleAtmoAdModalOpen"]),
    getPlatformAds() {
      Axios.get(`/api/v1/users/1/platform_ads`, {})
        .then((response) => {
          this.platformAds = response.data;
          this.randomPlatformAd = this.platformAds[Math.floor(Math.random() * this.platformAds.length)];
          // console.log("RANDOM AD: ", this.randomPlatformAd);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    hideModal() {
      this.$store.commit("hideModal");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

#atmo-ad-modal {
  .modal .modal-lg.modal-dialog {
    max-width: 1000px;
  }

  .atmo-modal-content {
    backdrop-filter: blur(5px);
    border-radius: 5px;
    border: none;
  }

  .atmo-modal-body {
    position: relative;
  }

  .atmo-ad-modal__header {
    position: absolute;
    right: 0rem;
    height: 1rem;
    z-index: 1;
  }

  .header__hide-ad-wrap {
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hide-ad-wrap__hide-ad {
    height: 1rem;
    width: auto;
    cursor: pointer;
  }

  .atmo-ad-modal__ad-container {
    .ad-container__image-wrap {
      height: 25rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      position: relative;
    }

    .ad-container__text-wrap {
      padding: 1rem;
    }

    .text-wrap__title-wrap {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    .text-wrap__body-button-wrap {
      display: flex;
      display: flex;
      align-items: center;
    }

    .body-button-wrap__body-wrap {
      display: flex;
      align-items: center;
      margin-right: 1rem;
    }

    .body-button-wrap__button-wrap {
      justify-content: center;
      align-items: flex-end;
      margin-left: auto;
    }

    .button-wrap__button {
      display: inline-block;
      border: 1px solid white;
      border-radius: 5px;
      text-transform: uppercase;
      padding: 0.5rem 1rem 0.5rem 1rem;
      color: white;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }
}
</style>
