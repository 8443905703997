<template>
  <div>
    <div class="atmo-library__select-view">
      <div class="select-view__option" @click="backToLibrary()">Albums</div>
      <div class="select-view__option" @click="onSongsClick()">Songs</div>
      <div class="select-view__option" @click="onArtistsClick()">Artists</div>
    </div>
    <ProgressLoading :isLoading="isLoading" :loadingMessages="['Fetching data...', 'Finalizing...']" :progress="progress" :simulateProgress="true" />
    <div v-if="!isLoading" class="atmo-library__body">
      <!-- Default View -->
      <section v-if="currentView === 'default'" class="atmo-library__main-column">
        <perfect-scrollbar class="main-column__scroll-container">
          <atmo-album-songs v-for="album in filteredItems" :key="album.id" :album="album" @on-album-click="onAlbumClick" :component-location="componentLocation" />
        </perfect-scrollbar>
      </section>

      <!-- Songs View -->
      <section v-if="currentView == 'songs'" class="atmo-library__main-column">
        <main-section-header :back-to-library="backToLibrary"></main-section-header>
        <div v-if="!songs || songs.length === 0">No Songs Available...</div>
        <atmo-library-songs v-else :songs="filteredItems" :component-location="componentLocation" :scrollEnabled="true" />
      </section>

      <!-- Album View -->
      <section v-if="currentView == 'album'" class="atmo-library__main-column">
        <main-section-header :back-to-library="backToLibrary">
          {{ currentActiveObject.name }}
        </main-section-header>
        <div v-if="songs == null">Loading…</div>
        <atmo-library-songs v-else :songs="filteredItems" :component-location="componentLocation" />
      </section>

      <!-- Artists View -->
      <section v-if="currentView == 'artists'" class="atmo-library__main-column">
        <perfect-scrollbar class="main-column__scroll-container">
          <atmo-artist-songs v-for="artist in filteredItems" :key="artist.artist_id" :artist="artist" @on-artist-click="onArtistClick" :component-location="componentLocation" />
        </perfect-scrollbar>
      </section>

      <!-- Playlist View -->
      <section v-if="currentView == 'playlist'" class="atmo-library__main-column">
        <main-section-header :back-to-library="backToLibrary">
          {{ currentActiveObject.name }}
          <div slot="actions">
            <button class="atmo-icon-button" @click="deleteCurrentPlaylist">
              <img class="atmo-library__main-section-header-action-icon" src="@/assets/images/icons/delete.png" alt="delete playlist" title="delete playlist" />
            </button>
          </div>
        </main-section-header>
        <playlist-collaborators :playlist="currentActiveObject" @update-playlists="handleUpdatePlaylists" />
        <div v-if="songs == null">Loading…</div>
        <atmo-library-songs v-else :songs="filteredItems" :component-location="componentLocation" />
      </section>

      <aside class="atmo-library__sidebar">
        <input type="text" :placeholder="searchPlaceholder" class="atmo-input atmo-input--search" v-model="searchAlbumSongQuery" />
        <atmo-playlists :playlists="playlists" @on-playlist-click="onPlaylistClick" />
        <atmo-lyrics />
      </aside>
    </div>
  </div>
</template>

<script>
import AtmoAlbumSongs from "@/modals/atmo-library/atmo-album-songs";
import AtmoLibrarySongs from "@/components/atmo-library/library-songs";
import AtmoArtistSongs from "@/modals/atmo-library/atmo-artist-songs.vue";
import AtmoPlaylists from "@/modals/atmo-library/atmo-playlists";
import AtmoLyrics from "@/modals/atmo-library/atmo-lyrics";
import MainSectionHeader from "@/modals/atmo-library/main-section-header";
import PlaylistCollaborators from "@/modals/atmo-library/playlist-collaborators";
import ProgressLoading from "@/components/progress-loading.vue";
import { callAtmoLibrariesSongsByAlbum, callAtmoLibrariesSongsByArtist, callAtmoLibrariesSongs, callSongReferencesIndex, callPlaylistsIndex } from "@/helpers/axiosCalls";
import Axios from "axios";

export default {
  props: {
    componentLocation: {
      type: String,
      default: null,
    },
    albums: {
      type: Array,
      default: () => [],
    },
    playlists: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    AtmoAlbumSongs,
    AtmoPlaylists,
    AtmoLyrics,
    MainSectionHeader,
    PlaylistCollaborators,
    AtmoLibrarySongs,
    AtmoArtistSongs,
    ProgressLoading,
  },
  data() {
    return {
      currentView: "default",
      currentActiveObject: null,
      songs: [],
      artists: [],
      isOpen: true,
      searchAlbumSongQuery: "",
      allSongs: [],
      isLoading: false,
      progress: 0,
    };
  },
  computed: {
    searchPlaceholder() {
      switch (this.currentView) {
        case "default":
          return "Search for an album...";
        case "songs":
          return "Search for a song...";
        case "artists":
          return "Search for an artist...";
        case "album":
          return "Search for a song in this album...";
        case "playlist":
          return "Search for a song in this playlist...";
        default:
          return "Search...";
      }
    },
    filteredItems() {
      if (this.currentView === "default") {
        return this.albums.filter((album) => album.name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase()));
      } else if (this.currentView === "songs") {
        return this.songs.filter((song) => song.name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase())); // ✅ Now correctly filtering songs
      } else if (this.currentView === "playlist") {
        return this.songs.filter((song) => song.name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase()));
      } else if (this.currentView === "album") {
        return this.songs.filter((song) => song.name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase()));
      } else if (this.currentView === "artists") {
        return this.artists.filter((artist) => artist.artist_name.toLowerCase().includes(this.searchAlbumSongQuery.toLowerCase()));
      } else {
        return [];
      }
    },
  },
  methods: {
    async getPlaylists() {
      if (!this.$store.state.currentUserId) return [];
      try {
        return await callPlaylistsIndex(this.$store.state.currentUserId);
      } catch (error) {
        console.error("Error fetching playlists:", error);
        return [];
      }
    },
    handleUpdatePlaylists() {
      this.getPlaylists();
    },
    hideModal() {
      this.$store.commit("hideModal");
    },
    onSongsClick() {
      this.isLoading = true;
      this.progress = 0;

      // Reset previous state
      this.songs = [];
      this.currentActiveObject = null;

      callAtmoLibrariesSongs(this.$store.state.currentUserId)
        .then((response) => {
          this.progress = 50;
          this.songs = response;
          this.searchAlbumSongQuery = "";
          this.currentView = "songs";
          this.progress = 100;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.isLoading = false;
            this.progress = 0;
          }, 800);
        });
    },
    onAlbumClick(album) {
      this.searchAlbumSongQuery = "";

      // Directly use album.songs instead of making another API request
      this.songs = [...album.songs]; // Ensure Vue reactivity
      this.currentActiveObject = album;
      this.currentView = "album";
    },
    onPlaylistClick(playlist) {
      this.searchAlbumSongQuery = "";
      this.currentActiveObject = playlist;
      this.currentView = "playlist";
      this.songs = []; // Reset previous songs
      this.isLoading = true;
      this.progress = 0;

      callSongReferencesIndex({ songable_id: playlist.id, songable_type: "Playlist" })
        .then((response) => {
          this.songs = response.map((songReference) => songReference.song);
          this.progress = 80;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.progress = 100;
          setTimeout(() => {
            this.isLoading = false;
            this.progress = 0;
          }, 800);
        });
    },
    onArtistsClick() {
      this.isLoading = true;
      this.progress = 0;

      // Reset songs when switching to artists view
      this.songs = [];
      this.currentActiveObject = null;

      this.searchAlbumSongQuery = "";
      this.songs = null;
      this.currentView = "artists";

      callAtmoLibrariesSongsByArtist(this.$store.state.currentUserId)
        .then((response) => {
          this.progress = 80;
          this.artists = response; // Store the sorted songs
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.progress = 100;
          setTimeout(() => {
            this.isLoading = false;
            this.progress = 0;
          }, 800);
        });
    },
    onArtistClick(artist) {
      this.currentActiveObject = artist;
      this.searchAlbumSongQuery = "";

      // Ensure Vue reactivity by creating a new array
      this.songs = [...artist.songs];

      this.currentView = "songs"; // Show songs of the selected artist
    },
    backToLibrary() {
      this.currentView = "default";
      this.searchAlbumSongQuery = "";
    },
    deleteCurrentPlaylist() {
      const currentPlaylist = this.currentActiveObject;
      if (this.deletePlaylist(currentPlaylist)) {
        this.backToLibrary();
      }
    },
    deletePlaylist(playlist) {
      // TODO: use correct user profile id
      if (!window.confirm(`Are you sure you want to delete playlist "${playlist.name}"?`)) {
        return false;
      }
      if (this.$store.state.currentUserId) {
        Axios.delete(`/api/v1/users/${this.$store.state.currentUserId}/playlists/${playlist.id}`)
          .then(() => {
            this.getPlaylists();
          })
          .catch((err) => {
            console.error(err);
          });
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

#atmo-library {
  .atmo-modal-content {
    border-radius: 5px;
    border: none;
    overflow-y: hidden;
    // width: 100vw;
    // height: 97vh;
  }
}

.atmo-library {
  .atmo-library__select-view {
    display: flex;
    gap: 1rem;
    font-size: 0.8rem;
    margin-left: 1.2rem;
    margin-bottom: 1rem;
  }

  .select-view__option {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    display: inline-block;
    text-transform: uppercase;
    background: none;
    color: white;
  }

  .atmo-modal__header {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    // margin-top: 2rem;
  }

  .atmo-modal__header-side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;

    img {
      height: 1.5rem;
    }
  }

  .atmo-page-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__body {
    display: flex;
    overflow-y: hidden;
  }

  &__main-column {
    display: flex;
    flex-direction: column;
    flex: 1;

    .ps {
      min-height: 70vh;
    }
  }

  .main-column__scroll-container {
    min-height: 75vh;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0 20px 10px 30px;
  }

  &__sidebar-section {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  &__section-header {
    display: flex;
    justify-content: space-between;
    font-size: 1.2em;
    font-weight: 500;
    color: $atmo-blue--medium;
    text-transform: uppercase;

    &--main {
      justify-content: center;
      font-size: 1.3rem;
      font-weight: 600;
    }
  }

  &__section-header-icon {
    height: 15px;
  }

  &__main-section-header {
    display: flex;
    align-items: center;
    margin: 0rem 0rem 2rem 0.3rem;
    padding: 0 10px;
  }

  &__main-section-header-actions {
    display: flex;
    justify-content: flex-end;
    height: 20px;
  }

  &__main-section-header-action-icon {
    height: 15px;
    display: none;
  }

  &__main-section-header:hover .atmo-library__main-section-header-action-icon {
    display: inline;
  }

  &__back-to-library {
    display: flex;
    align-items: flex-end;
    font-size: 1.1em;
    font-weight: 300;
    cursor: pointer;

    img {
      height: 12px;
      margin-right: 3px;
    }
  }

  .atmo-input + button {
    margin-left: 3px;
  }
}
</style>
