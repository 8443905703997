<template>
  <div class="publisher-catalog">
    <section class="publisher-catalog__body">
      <div class="publisher-catalog__main-content">
        <div>
          <template v-if="isEmpty">
            <atmo-loading v-if="isLoading" />
            <template v-else>
              <div class="empty-catalog-container">
                <img class="atmo-empty-state__icon" src="@/assets/images/icons/no-results.png" />
                <span class="atmo-empty-state__label">No catalog items found.</span>
              </div>
            </template>
          </template>
          <perfect-scrollbar class="catalog-grid">
            <div v-for="song in filteredCatalog" :key="song.id">
              <router-link class="catalog-card-wrap" :to="{ name: 'songs.show', params: { songId: song.id } }">
                <div class="catalog-card">
                  <div class="catalog-card__background-image" :style="{ 'background-image': 'url(' + (getFeaturedImage(song?.images || [], 'full') || defaultImage) + ')' }"></div>
                  <div class="catalog-card__footer">
                    <div class="footer__song-title">{{ song.title }}</div>
                    <div class="footer__song-writer">by {{ song.writer_name }}</div>
                  </div>
                </div>
              </router-link>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AtmoLoading from "@/components/atmo-loading";
import { callPublisherProfilesCatalog } from "@/helpers/axiosCalls";
import { getFeaturedImage } from "@/helpers/utilityFunctions";

export default {
  components: { AtmoLoading },
  data() {
    return {
      catalog: [],
      isLoading: false,
      query: "",
      defaultImage: "https://via.placeholder.com/300x300?text=No+Image",
    };
  },
  computed: {
    filteredCatalog() {
      return this.catalog.filter((item) => item.title.toLowerCase().includes(this.query.toLowerCase()));
    },
    isEmpty() {
      return !this.isLoading && this.filteredCatalog.length === 0;
    },
  },
  methods: {
    getFeaturedImage,
    async fetchCatalog() {
      this.isLoading = true;
      try {
        const data = await callPublisherProfilesCatalog(this.$route.params.publisherProfileId);
        this.catalog = data;
      } catch (error) {
        console.error("Error fetching publisher catalog:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    this.$store.commit("setHudHeader", "Publisher Catalog");
    this.fetchCatalog();
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";
@import "~@/assets/stylesheets/mixins";

.publisher-catalog {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__body {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
  }

  .empty-catalog-container {
    @include flex-center;
    flex-direction: column;
  }

  .atmo-empty-state__label {
    text-align: center;
  }

  &__main-content {
    margin: 0rem 1rem;
  }

  .catalog-grid {
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .catalog-card-wrap {
    display: block;
    height: 100%;
  }

  .catalog-card {
    height: 100%;
    cursor: pointer;
  }

  .catalog-card__background-image {
    height: 6rem;
    background-color: rgba(130, 200, 255, 0.3);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px 5px 0 0;
  }

  .catalog-card__footer {
    display: flex;
    flex-direction: column;
    background: linear-gradient(270deg, rgba($atmo-blue--medium, 0.5), rgba($atmo-purple--light, 0.5));
    font-weight: 300;
    padding: 8px;
    border-radius: 0 0 5px 5px;
    color: white;
  }

  .footer__song-title {
    font-weight: 600;
  }

  .footer__song-writer {
    font-size: 0.875rem;
    opacity: 0.85;
  }
}
</style>
