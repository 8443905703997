<template>
  <div class="directory-profile-page" v-if="profile">
    <router-link class="back-container" :to="{ name: 'directory_profiles.index' }">
      <div class="back-container__icon-container">
        <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
      </div>
      <div class="back-container__text-container">Back To Directory</div>
    </router-link>

    <div class="directory-profile-grid">
      <!-- Profile Image (with Fallback) -->
      <div
        class="directory-profile-grid__image-container"
        :style="{
          'background-image': profile.user?.profile_image?.image?.url
            ? `linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%), url(${profile.user.profile_image.image.url})`
            : 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)',
        }"
      ></div>

      <!-- Profile Details -->
      <div class="directory-profile-grid__details-container">
        <div class="details-container__links-wrap">
          <h3 class="pt-2 atmo-subtitle">{{ profile?.name || "Unknown Name" }}</h3>
        </div>

        <div class="details-container__profile-info-container">
          <div class="profile-info-container__profile-detail-wrap">
            <div class="label-wrap">Profession:</div>
            <div class="text-wrap">{{ profile?.directory_profession?.name || "Not Provided" }}</div>
          </div>
          <div class="profile-info-container__profile-detail-wrap">
            <div class="label-wrap">Rate:</div>
            <div class="text-wrap">{{ profile.hourly_rate ? `$${profile.hourly_rate}/hr` : "N/A" }}</div>
          </div>
          <div class="profile-info-container__profile-detail-wrap">
            <div class="label-wrap">Location:</div>
            <div class="text-wrap">
              {{ [profile.city?.name, profile.state?.name, profile.country?.name].filter(Boolean).join(", ") || "Unknown Location" }}
            </div>
          </div>
          <div class="profile-info-container__profile-detail-wrap">
            <div class="label-wrap">Contact:</div>
            <div class="text-wrap message-user" @click="messageUser(profile)">Message User</div>
          </div>
          <div class="profile-info-container__profile-detail-wrap">
            <div class="label-wrap">Website:</div>
            <div class="text-wrap cell-website">
              <a v-if="profile.website" :href="profile.website" target="_blank">{{ profile.website }}</a>
              <span v-else>N/A</span>
            </div>
          </div>
        </div>
      </div>

      <!-- Ads Section -->
      <div class="directory-profile-grid__ads-container">
        <atmo-directory-ad v-for="index in 4" :key="index" />
      </div>
    </div>
  </div>

  <!-- Show Loading or Error if Profile is Missing -->
  <div v-else class="directory-profile-page__error">
    <p>Profile not found or loading failed.</p>
  </div>
</template>

<script>
import AtmoDirectoryAd from "@/components/atmo-directory/atmo-directory-ad";
import { callDirectoryProfilesShow, callChatRoomsIndex, callChatRoomsCreate } from "@/helpers/axiosCalls";
import { connectToVideoCallsChannel, sendSignalingMessage, setCallback as setVideoCallback } from "@/cable-channels/videoCalls";

export default {
  components: {
    AtmoDirectoryAd,
  },
  data() {
    return {
      profile: null,
    };
  },
  async created() {
    await this.fetchDirectoryProfile();
    this.$store.commit("setHudHeader", this.profile?.name || "Directory Profile");
  },
  methods: {
    async fetchDirectoryProfile() {
      const userId = this.$route.params.userId;
      try {
        const response = await callDirectoryProfilesShow(userId);
        this.profile = response;
      } catch (error) {
        console.error("Error fetching directory profile:", error);
      }
    },
    async messageUser(profile) {
      try {
        let chatRoom = await callChatRoomsIndex({
          type: "DirectoryProfile",
          user_id: this.$store.state.currentUserId,
          friend_id: profile.user_id,
        });

        console.log("CHAT ROOM FOR DIRECTORY PROFILE", chatRoom);
        //IF WE DON'T FIND IT, create it, open modal, and select room
        //IF WE DO FIND IT, open modal and select room
        //POLYMORPHISM?

        if (Object.keys(chatRoom).length === 0) {
          let newChatRoom = await callChatRoomsCreate({
            name: `${this.$store.state.currentUser.name} & ${profile.user.name}`,
            room_type: "directory_profile",
            roomable_type: "DirectoryProfile",
            creator_id: this.$store.state.currentUserId,
            user_ids: [this.$store.state.currentUserId, profile.user_id],
          });

          this.$store.commit("chatRooms/setCurrentChatRoom", newChatRoom);
        } else {
          this.$store.commit("chatRooms/setCurrentChatRoom", chatRoom);
        }
      } catch (error) {
        this.$toast("Error messaging user", { type: "error" });
        console.error("Error in messageUser:", error);
      }

      this.$store.commit("chatRooms/toggleMaxChatOpen");

      if (this.$store.state.chatRooms.currentChatRoom) {
        connectToVideoCallsChannel(this.$store.state.chatRooms.currentChatRoom.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.directory-profile-page {
  margin: 0 75px;
}

.back-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  max-width: 10rem;
  margin-bottom: 1rem;
}

.back-container__icon-container {
  margin-right: 0.5rem;
}

.icon-container__back-icon {
  height: 1rem;
}

.directory-profile-grid {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 2rem;
}

.directory-profile-grid__image-container {
  width: 100%;
  max-height: 30rem;
  min-width: 20rem;
  background-size: cover;
  position: relative;
  padding: 1rem;
}

.details-container__links-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.details-container__profile-info-container {
  .label-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 0.5rem;
  }

  .text-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message-user {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .profile-info-container__profile-detail-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: 500;
  }
}

.directory-profile-grid__ads-container {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .directory-ad-container__directory-ad-wrap + .directory-ad-container__directory-ad-wrap {
    margin-top: 1rem;
  }
}

.directory-profile-page__error {
  text-align: center;
  font-size: 1.2rem;
  color: red;
  margin-top: 2rem;
}
</style>
