<template>
  <router-link
    v-if="ad"
    class="directory-ad-container__directory-ad-wrap"
    :to="ad.ad_type === 'seeking' ? { name: 'advertisements.show', params: { adId: ad?.id } } : { name: 'directory_profiles.show', params: { userId: ad?.user_id } }"
    @click="sendClickData()"
  >
    <div class="directory-ad-wrap__image-wrap">
      <img :src="ad?.image?.url" alt="ad" class="image-wrap__image" />
    </div>
    <div class="directory-ad-wrap__copy-wrap">
      <p>{{ ad?.copy || "Check out this amazing artist!" }}</p>
    </div>
  </router-link>

  <div v-else class="directory-ad-container__fallback">
    <p>No ads available.</p>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      ad: null,
    };
  },
  computed: {
    ...mapState("directoryAds", ["directoryAds"]),
    ...mapGetters("directoryAds", ["getUniqueAd"]),
  },
  async created() {
    await this.fetchDirectoryAds(this.$store.state.currentUserId);
    this.assignAd();
  },
  methods: {
    ...mapActions("directoryAds", ["fetchDirectoryAds", "logAdImpression", "logAdClick"]),
    ...mapMutations("directoryAds", ["addDisplayedAd"]),

    assignAd() {
      this.ad = this.getUniqueAd;
      if (this.ad) {
        this.addDisplayedAd(this.ad.id);
        this.logAdImpression({ userId: this.$store.state.currentUserId, ad: this.ad });
      }
    },

    sendClickData() {
      if (!this.ad) return;
      this.logAdClick({ userId: this.$store.state.currentUserId, ad: this.ad });
    },
  },
};
</script>

<style lang="scss" scoped>
.directory-ad-container__directory-ad-wrap {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: white;
}

.directory-ad-wrap__image-wrap img {
  height: 2.8rem;
  border-radius: 5px;
}

.directory-ad-wrap__copy-wrap {
  font-weight: 500;
  max-width: 10rem;
}

.directory-ad-container__fallback {
  text-align: center;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 1rem;
}
</style>
