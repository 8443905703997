<template>
  <div class="photo-upload-page">
    <atmo-back-link></atmo-back-link>

    <div class="photo-upload-container">
      <!-- Custom file input -->
      <label for="file-upload" class="custom-upload-button"> Choose File </label>
      <input id="file-upload" type="file" accept="image/*" @change="handleFileUpload" />

      <span v-if="selectedFile" class="file-name">{{ selectedFile.name }}</span>

      <input class="text-field" type="text" v-model="name" placeholder="Photo Name" />
      <button class="save-photo" @click="uploadPhoto" :disabled="!selectedFile">Upload Photo</button>
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callImagesCreate } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoBackLink,
  },
  data() {
    return {
      selectedFile: null,
      name: "",
    };
  },
  created() {
    this.$store.commit("setHudHeader", "Upload Photo");
  },
  computed: {
    userId() {
      return this.$route.params.userId;
    },
  },
  methods: {
    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadPhoto() {
      if (!this.selectedFile) return;

      const formData = new FormData();
      formData.append("image", this.selectedFile);
      formData.append("imageable_type", "User");
      formData.append("imageable_id", this.userId);
      formData.append("user_id", this.userId);
      formData.append("name", this.name);

      try {
        const newPhoto = await callImagesCreate(formData);

        if (newPhoto) {
          this.$toast("Photo uploaded successfully", { type: "success" });
          this.$router.push({ name: "photos.index", params: { userId: this.userId } });
        }
      } catch (error) {
        console.error("Error uploading photo:", error);
        this.$toast("Failed to upload photo", { type: "error" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.photo-upload-page {
  margin: 0 75px;
  text-align: center;

  .photo-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .custom-upload-button {
    display: inline-block;
    background: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    color: white;
    font-size: 1rem;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
  }

  .custom-upload-button:hover {
    background: linear-gradient(-270deg, rgba(217, 82, 167, 1) 0%, rgba(68, 174, 220, 1) 100%);
  }

  input[type="file"] {
    display: none; /* Hide the default file input */
  }

  textarea::placeholder {
    color: $atmo-white--medium-faded;
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
  }

  .file-name {
    margin-top: 10px;
    font-size: 0.9rem;
    color: $atmo-white--medium-faded;
  }

  .text-field,
  .text-area {
    width: 100%; // Make it take the full width
    max-width: 600px; // Adjust max-width as needed
    box-sizing: border-box; // Ensure padding doesn't affect the width
  }

  .text-field {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem;
    color: white;
    margin-top: 10px;
  }

  .text-field::placeholder {
    color: $atmo-white--medium-faded;
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
  }

  .save-photo {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    border-radius: 5px;
    padding: 0.5rem 2rem;
    font-weight: 500;
    border: 1px solid white;
    cursor: pointer;
    margin-top: 10px;
  }

  .save-photo:disabled {
    background-color: transparent;
    border: 1px solid gray;
    cursor: not-allowed;
  }

  .save-photo:hover:not(:disabled) {
    background: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
  }
}
</style>
