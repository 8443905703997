<template>
  <div class="atmo-products-edit-images">
    <!-- <h2 class="atmo-page-header">
            Edit Product Images
        </h2> -->
    <div class="atmo-products-edit-images__container">
      <div class="back-container" @click="goBack()">
        <div class="back-container__icon-container">
          <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
        </div>
        <div class="back-container__text-container">Back to previous</div>
      </div>

      <div class="container__container-wrap">
        <atmo-loading v-if="isLoading" />
        <form v-else @submit.prevent="doNothing" class="container-wrap__form">
          <div class="form__product-details-wrap">
            <div class="product-details-wrap__image-upload">
              <div class="field-wrap">
                <label for="newProductImages">Images</label>
                <span class="reset-images-wrap" @click="resetImages()">
                  <img src="@/assets/images/icons/refresh.png" />
                </span>
                <input type="file" id="newProductImages" multiple @change="onImagesSelected" style="display: none" />
                <div v-if="newProductImages.length === 0 && existingProductImages.length === 0" class="empty-images-container">No Images Selected</div>
                <perfect-scrollbar v-else class="images-container">
                  <div v-for="(existingImage, index) in existingProductImages" :key="`existing-${index}`" style="position: relative">
                    <atmo-upload-card :image-src="existingImage.image.url" :is-dropzone="false" alt="Existing Image" componentStyle="width:8rem; height:8rem; position:relative;"> </atmo-upload-card>
                    <!-- <button @click="removeImage(index, 'existingProductImages')"
                                            class="remove-image-button">X</button> -->
                  </div>
                  <div v-for="(newImage, index) in newProductImages" :key="`new-${index}`" style="position: relative">
                    <atmo-upload-card :image-src="newImage.imagePreview" :is-dropzone="false" alt="Uploaded Image" componentStyle="width:8rem; height:8rem; position:relative;"> </atmo-upload-card>
                    <button @click="removeImage(index, 'newProductImages')" class="remove-image-button">X</button>
                  </div>
                </perfect-scrollbar>
              </div>
              <button @click="triggerFileInput" class="add-images-button">Add Images</button>
            </div>
          </div>
          <div class="main-wrap__footer">
            <button type="submit" class="main-wrap__submit-button" @click="goToCreateVariants()" v-if="formCompleted">Next</button>
            <button type="submit" class="main-wrap__submit-button-inactive" v-else>Next</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoLoading from "@/components/atmo-loading";
import AtmoUploadCard from "@/components/atmo-upload-card";
import { mapMutations, mapState } from "vuex";
import { objIsEmpty } from "@/helpers/utilityFunctions";
import { callProductsShow } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoLoading,
    AtmoUploadCard,
  },

  data: function () {
    return {
      isLoading: false,
      newProductImages: [],
      existingProductImages: [],
    };
  },

  computed: {
    ...mapState("products", ["editProductData"]),
    artistProfileId() {
      return this.$store.state.currentUserProfileId;
    },
    formCompleted() {
      return this.formIsCompleted();
    },
  },
  async created() {
    await this.checkForExistingProductData();
    this.$store.commit("setHudHeader", "Edit Product Images");
  },
  methods: {
    ...mapMutations("products", ["setEditProductData"]),
    doNothing() {
      console.log("DOING NOTHING");
    },
    async resetImages() {
      this.existingProductImages = await this.loadExistingProductImages();
      this.newProductImages = [];
    },
    formIsCompleted() {
      return this.newProductImages.length > 0 || this.existingProductImages.length > 0;
    },
    goBack() {
      this.$router.push({ name: "products.edit", params: { productId: this.$route.params.productId } });
    },
    objIsEmpty(obj) {
      return objIsEmpty(obj);
    },
    triggerFileInput() {
      document.getElementById("newProductImages").click();
    },
    onImagesSelected(event) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          this.newProductImages.push({
            imageFile: file,
            imagePreview: e.target.result,
            imageName: `${this.editProductData.editProductTitle}-Image-${Date.now()}`,
          });
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage(index, type) {
      this[type].splice(index, 1);
    },
    async checkForExistingProductData() {
      const { existingProductImages, newProductImages } = this.editProductData;

      //TODO: Cleanup logic
      if (!existingProductImages) {
        this.existingProductImages = await this.loadExistingProductImages();
      }

      if (!this.objIsEmpty(this.editProductData)) {
        existingProductImages && existingProductImages.length > 0 ? (this.existingProductImages = existingProductImages) : await this.loadExistingProductImages();
        newProductImages && newProductImages.length > 0 ? (this.newProductImages = newProductImages) : [];
      } else {
        this.existingProductImages = await this.loadExistingProductImages();
      }
    },
    async storeProductInformation() {
      this.setEditProductData({
        ...this.editProductData,
        newProductImages: this.newProductImages,
        existingProductImages: this.existingProductImages,
      });

      console.log("this.editProductData", this.editProductData);
    },
    goToCreateVariants() {
      this.storeProductInformation();
      this.$router.push({ name: "product_variants.edit", params: { productId: this.$route.params.productId } });
    },
    async loadExistingProductImages() {
      this.isLoading = true;
      try {
        const response = await callProductsShow(this.$route.params.productId);
        return response.images;
      } catch (error) {
        console.error("Error loading existing product images:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-products-edit-images {
  display: flex;
  flex-direction: column;

  .ps {
    height: 20rem;
  }

  textarea,
  textarea::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    color: white;
  }

  input::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.4) !important;
  }

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .field-wrap {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .text-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
    }

    ::placeholder {
      color: rgba(255, 255, 255, 0.4);
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.4);
    }

    ::-ms-input-placeholder {
      color: rgba(255, 255, 255, 0.4);
    }

    .dropdown-group {
      background: $atmo-purple--medium-dark;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 5px;
      padding: 0.5rem;
      font-weight: 500rem;
      cursor: pointer;
    }

    .dropdown-field {
    }

    .radio-group {
      display: block !important;
      padding: 0.6rem 0rem 0.6rem 0rem;
    }

    .radio-field-wrap {
      margin-right: 1rem;
      display: flex;
      justify-content: start !important;
      align-items: center;
      margin-bottom: 0.1rem;
    }

    .radio-field {
      margin-right: 0.5rem;
      cursor: pointer;
    }

    .radio-field-label {
      margin: 2px 0px 0px 0px;
    }
  }

  .file-type-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $atmo-purple--light;
    cursor: pointer;
    text-transform: unset !important;
    font-weight: 400 !important;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  }

  .field-label {
    font-weight: 500;
    margin-bottom: 0.8rem;
  }

  .atmo-products-edit-images__container {
    margin: auto;
    max-width: 1000px;
  }

  .container-wrap__form {
    min-width: 1000px;
  }

  .form__product-details-wrap {
    display: flex;
    gap: 1rem;
  }

  .empty-images-container {
    height: 20rem;
    padding-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: bold;
  }

  .product-details-wrap__image-upload {
    width: 100%;
  }

  .reset-images-wrap {
    cursor: pointer;

    img {
      height: 1.2rem;
      width: 1.2rem;
    }
  }

  .images-container {
    display: flex;
    gap: 0.4rem;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-top: 1rem;
  }

  .remove-image-button {
    position: absolute;
    top: -5px;
    right: -5px;
    background: red;
    border-radius: 100%;
    border: none;
    color: white;
    z-index: 100000;
    cursor: pointer;
    height: 1rem;
    width: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    font-size: 0.7rem;
    font-weight: bold;
  }

  .add-images-button {
    // margin-top: 1rem;
    // font-size: 1rem;
    // text-transform: uppercase;
    // color: white;
    // background-color: transparent;
    // background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    // border-radius: 5px;
    // padding: .5rem 2rem .5rem 2rem;
    // font-weight: 500;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // border: none;
    // cursor: pointer;

    background: white;
    cursor: pointer;
    color: #40365d;
    border-radius: 30px;
    font-size: 1em;
    font-weight: 300;
    padding: 8px 15px;
    border: 1px solid transparent;
    background-origin: border-box;
    text-transform: uppercase;
    outline: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product-details-wrap__product-details {
    width: 100%;

    .flex-columns {
      display: flex;
      gap: 1rem;

      .text-field {
        min-width: unset;
      }
    }
  }

  .main-wrap__submit-button {
    margin-left: auto;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  .main-wrap__submit-button-inactive {
    margin-left: auto;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.3) 0%, rgba(68, 174, 220, 0.3) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
}
</style>
