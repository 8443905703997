<template>
  <AtmoModal id="add-songs-modal" ref="addSongsModalRef" v-model="$store.state.addSongsModalOpen" title="Search Results" customClass="add-songs-modal">
    <img class="add-songs-modal__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Add Songs Modal" @click="$store.commit('toggleAddSongsModal')" />
    <header class="add-songs-modal__header atmo-modal__header">
      <div class="atmo-page-header-container">
        <h2 class="atmo-page-header">Add Songs</h2>
      </div>
    </header>
    <div class="add-songs-modal__body">
      <div class="body__add-songs-container">
        <atmo-library-body component-location="addSongsModal"></atmo-library-body>
        <button class="add-songs-button" @click="addSongsToPost()">Add To Post</button>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import AtmoLibraryBody from "@/components/atmo-library/atmo-library-body";

export default {
  components: {
    AtmoLibraryBody,
  },
  data() {
    return {
      isOpen: true,
    };
  },
  methods: {
    addSongsToPost() {
      this.$store.commit("toggleAddSongsModal");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

#add-songs-modal {
  .atmo-modal-content {
    border-radius: 5px;
    border: none;
    width: 100vw;
    height: 97vh;
  }

  .modal-lg.modal-dialog {
    width: 98vw;
    margin: 1rem;
    max-width: unset;
  }

  .ps {
    // min-height: 70vh;
  }

  .add-songs-modal__hide-modal {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    opacity: 0.5;
    cursor: pointer;
  }

  .add-songs-modal__header {
    margin-top: 2rem;
    width: 100%;
  }

  .atmo-page-header-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .atmo-library__body {
    height: 60vh;
  }

  .atmo-library__main-column {
    height: 60vh;
  }

  .main-column__scroll-container {
    max-height: 60vh;
  }

  .atmo-library__select-view {
    display: flex;
    gap: 1rem;
    font-size: 0.8rem;
    margin-left: 1.2rem;
    margin-bottom: 1rem;
  }

  .select-view__option {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    display: inline-block;
    text-transform: uppercase;
    background: none;
    color: white;
  }

  .add-songs-button {
    position: absolute;
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    border: none;
    padding: 0.9rem 1.7rem 0.9rem 1.7rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(68, 174, 220, 0.8) 0%, rgba(217, 82, 167, 0.8) 100%);
    border-radius: 30px;
    font-size: 1rem;
    font-weight: 600;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
</style>
