<template>
  <AtmoModal
    id="friend-picker-modal"
    ref="emojiPickerModalRef"
    v-model="$store.state.friendPickerModalOpen"
    title="Select a Friend"
    customClass="friend-picker-modal"
    size="small"
    :closeFunction="() => $store.commit('toggleFriendPickerModal', null)"
  >
    <header class="friend-picker-modal__header atmo-modal__header">
      <ModalHeader heading="Select Friend" marginBottom="2rem" />
    </header>
    <div class="friend-picker-modal__body">
      <div v-if="friends.length">
        <ul>
          <li v-for="friendship in friends" :key="friendship.id" @click="selectFriend(friendship.friend)" class="friend-item">
            {{ friendship.friend.name }}
          </li>
        </ul>
      </div>
      <div v-else>No friends found.</div>
    </div>
  </AtmoModal>
</template>

<script>
import { defineComponent, computed } from "vue";
import { mapActions, mapState } from "vuex";
import ModalHeader from "@/components/modal-header";

export default defineComponent({
  components: {
    ModalHeader,
  },
  computed: {
    ...mapState("userFriendships", ["currentUserFriendships"]),
    friends() {
      return this.currentUserFriendships || []; // Get friends from Vuex store
    },
  },
  methods: {
    async selectFriend(friend) {
      if (this.$store.state.friendPickerModalType == "message") {
        await this.$store.dispatch("shareSongAsMessage", friend);
        this.$store.commit("toggleFriendPickerModal", null);
      }

      if (this.$store.state.friendPickerModalType == "sms") {
        await this.$store.dispatch("shareSongAsSms", friend);
        this.$store.commit("toggleFriendPickerModal", null);
      }
    },
  },
});
</script>

<style lang="scss">
.friend-picker-modal {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .atmo-modal__header {
    display: flex;
    justify-content: center;
  }
}

.friend-item {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
}

.friend-item:hover {
  background: rgba(255, 255, 255, 0.1);
}
</style>
