<template>
  <div class="publisher-dashboard" data-cy="publisher-dashboard">
    <atmo-back-link></atmo-back-link>
    <perfect-scrollbar class="publisher-dashboard__grid-container">
      <!-- Left Column -->
      <div class="publisher-dashboard__summary-container">
        <div class="publisher-dashboard__section-header">Summary</div>
        <div class="publisher-dashboard__metrics-grid">
          <div class="publisher-dashboard__metric-box">
            <p class="metric-box__title">Total Listeners</p>
            <p class="metric-box__value">{{ summary?.total_listeners?.toLocaleString?.() || "—" }}</p>
            <p class="text-sm">
              {{ monthlyPercentChanges?.listeners != null ? `${monthlyPercentChanges.listeners > 0 ? "+" : ""}${monthlyPercentChanges.listeners}% this month` : "—" }}
            </p>
          </div>

          <div class="publisher-dashboard__metric-box">
            <p class="metric-box__title">Total Plays</p>
            <p class="metric-box__value">{{ summary?.total_plays?.toLocaleString?.() || "—" }}</p>
            <p class="text-sm">
              {{ monthlyPercentChanges?.plays != null ? `${monthlyPercentChanges.plays > 0 ? "+" : ""}${monthlyPercentChanges.plays}% this month` : "—" }}
            </p>
          </div>

          <div class="publisher-dashboard__metric-box">
            <p class="metric-box__title">Top Writer</p>
            <p class="metric-box__value">{{ summary?.top_writer || "—" }}</p>
            <p class="text-sm">{{ summary?.top_writer_plays ? `${summary.top_writer_plays} plays` : "—" }}</p>
          </div>

          <div class="publisher-dashboard__metric-box">
            <p class="metric-box__title">Estimated Royalties</p>
            <p class="metric-box__value">${{ summary?.estimated_royalties?.toLocaleString?.() || "—" }}</p>
            <p class="text-sm">
              {{ monthlyPercentChanges?.royalties != null ? `${monthlyPercentChanges.royalties > 0 ? "+" : ""}${monthlyPercentChanges.royalties}% this month` : "—" }}
            </p>
          </div>
        </div>

        <!-- Fan Growth -->
        <div class="publisher-dashboard__section-header">Listener Growth</div>
        <div class="publisher-dashboard__card">
          <div class="publisher-dashboard__card-title">Last 30 Days</div>
          <apexchart width="100%" height="200" type="line" :options="fanGrowthChartOptions" :series="fanGrowthSeries" />
        </div>
      </div>

      <!-- Right Column -->
      <div class="publisher-dashboard__performance-container">
        <div class="publisher-dashboard__section-header">Writer Performance</div>
        <div class="publisher-dashboard__card-scrollable">
          <perfect-scrollbar class="writers-container">
            <div class="publisher-dashboard__row publisher-dashboard__row--header">
              <div class="row__col">Writer</div>
              <div class="row__col">Listeners</div>
              <div class="row__col">Plays</div>
              <div class="row__col">Royalties</div>
            </div>
            <template v-if="writers.length">
              <div v-for="(writer, index) in writers" :key="writer.id" :class="index % 2 === 0 ? 'publisher-dashboard__row --dark' : 'publisher-dashboard__row --light'">
                <div class="row__col">{{ writer.name }}</div>
                <div class="row__col">{{ writer.listeners }} listeners</div>
                <div class="row__col">{{ writer.plays }} plays</div>
                <div class="row__col">${{ writer.royalties.toLocaleString() }}</div>
              </div>
            </template>
            <p v-else>No writer data yet.</p>
          </perfect-scrollbar>
        </div>

        <div class="publisher-dashboard__section-header">Breakout Songs</div>
        <div class="publisher-dashboard__card">
          <ul v-if="breakoutSongs.length" class="publisher-dashboard__list">
            <li v-for="song in breakoutSongs" :key="song.id">
              <span>{{ song.title }} by {{ song.writer }} — {{ song.plays?.toLocaleString?.() || 0 }} plays this week</span>
            </li>
          </ul>
          <p v-else class="text-sm">No breakout songs yet.</p>
        </div>

        <div class="publisher-dashboard__section-header">Platform Impact</div>
        <div class="publisher-dashboard__card">
          <ul class="publisher-dashboard__list">
            <li>
              Total Listeners via Platform: <strong>{{ atmoImpact?.listeners_acquired?.toLocaleString?.() || "—" }}</strong>
            </li>
            <li>
              Streams Driven by Discovery: <strong>{{ atmoImpact?.discovery_streams?.toLocaleString?.() || "—" }}</strong>
            </li>
            <li>
              Estimated Royalties from Platform: <strong>${{ atmoImpact?.royalties_from_platform?.toLocaleString?.() || "—" }}</strong>
            </li>
          </ul>
        </div>

        <!-- Nav Links -->
        <div class="publisher-dashboard__section-header">Explore More</div>
        <div class="publisher-dashboard__links">
          <router-link :to="{ name: 'publisher_dashboards.fans', params: { publisherProfileId } }">View Fan Insights</router-link>
          <router-link :to="{ name: 'publisher_dashboards.plays', params: { publisherProfileId } }">View Streaming Trends</router-link>
          <router-link :to="{ name: 'publisher_dashboards.revenue', params: { publisherProfileId } }">View Revenue Breakdown</router-link>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callPublisherDashboardsIndex } from "@/helpers/axiosCalls";

export default {
  components: { AtmoBackLink },
  created() {
    this.$store.commit("setHudHeader", "Publisher Dashboard");
    this.fetchPublisherDashboard();
  },
  data() {
    return {
      publisherProfileId: this.$route.params.publisherProfileId,
      writers: [],
      breakoutSongs: [],
      summary: {},
      atmoImpact: {},
      monthlyPercentChanges: {},
      fanGrowthSeries: [{ name: "Listeners", data: [] }],
      fanGrowthChartOptions: {
        chart: { type: "line", height: 200, toolbar: { show: false } },
        stroke: { curve: "smooth", width: 4 },
        xaxis: { categories: [], labels: { show: false } },
        yaxis: { labels: { style: { colors: "#ddd" } } },
        tooltip: { theme: "dark" },
        colors: ["#44AEDC"],
      },
    };
  },
  methods: {
    async fetchPublisherDashboard() {
      try {
        const res = await callPublisherDashboardsIndex(this.publisherProfileId);
        this.writers = res.writer_performance;
        this.breakoutSongs = res.breakout_songs;
        this.fanGrowthSeries[0].data = res.growth_chart.map((p) => p.listeners);
        this.fanGrowthChartOptions.xaxis.categories = res.growth_chart.map((p) => p.day);
        this.summary = res.summary;
        this.atmoImpact = res.atmo_impact;
        this.monthlyPercentChanges = res.monthly_percent_changes;
      } catch (err) {
        console.error("Failed to fetch publisher dashboard:", err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.publisher-dashboard {
  margin: 0 75px;
  height: 74vh;
  display: flex;
  flex-direction: column;

  .publisher-dashboard__grid-container {
    flex: 1;
    overflow: hidden;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    //HOW TO GET PS SCROLL TO FILL UP ALL SPACE
    height: 100%;
    max-height: 100%;
  }

  .publisher-dashboard__section-header {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
  }

  .publisher-dashboard__summary-container,
  .publisher-dashboard__performance-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .publisher-dashboard__metrics-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .publisher-dashboard__metric-box {
    background: rgba(161, 125, 187, 0.6);
    border-radius: 10px;
    padding: 1rem;

    .metric-box__title {
      text-transform: uppercase;
      font-size: 0.8rem;
      color: $atmo-gray--light--faded;
      margin-bottom: 0.5rem;
    }

    .metric-box__value {
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }

  .publisher-dashboard__card {
    background: rgba(161, 125, 187, 0.6);
    padding: 1rem;
    border-radius: 10px;

    .publisher-dashboard__card-title {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    .publisher-dashboard__chart-placeholder {
      height: 12rem;
      background-color: #4f3d63;
      border-radius: 10px;
    }
  }

  .publisher-dashboard__row--header {
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  .artists-container {
    height: 10rem;
  }

  .publisher-dashboard__row {
    display: flex;
    font-weight: 500;
    padding: 0.8rem 1rem;
    border-radius: 25px 0 0 25px;

    .row__col {
      flex: 1;
    }

    &.--light {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    }
  }

  .publisher-dashboard__list {
    padding-left: 1rem;
    list-style: disc;
  }

  .publisher-dashboard__links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: white;
      text-decoration: underline;
      font-size: 0.875rem;

      &:hover {
        color: $atmo-purple--light;
      }
    }
  }

  .stat-count {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}
</style>
