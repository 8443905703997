<template>
  <AtmoModal
    id="live-listening-user"
    ref="liveListeningUserRef"
    v-model="$store.state.liveListeningRooms.liveListeningUserOpen"
    title="LiveListeningUser"
    customClass="user-queue"
    @opened="initializeChat"
  >
    <EmojiPickerModal v-model="isEmojiPickerOpen" @select="onSelectEmoji" />
    <img class="live-listening__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Live Listening" @click="backToLiveListeningIndex()" />
    <header class="live-listening__header atmo-modal__header">
      <div class="modal-header-container">
        <div class="modal-header-container__heading-wrap">
          <h2 class="atmo-page-header">Your Queue</h2>
          <div class="header-icon-wrap__icon-wrap" v-if="currentUserIsAdmin">
            <span title="Queue Actions">
              <atmo-popover placement="bottom">
                <template #trigger>
                  <img src="@/assets/images/icons/menu.png" alt="Queue Actions" class="atmo-popover__actions-trigger" />
                </template>
                <template #content>
                  <!-- TODO: Get these actions to work -->
                  <a class="atmo-popover__dropdown-action atmo-popover__dropdown-action--extra-padding" @click="saveQueueAsPlaylist()"> Save as Playlist </a>
                  <hr class="atmo-popover__hr" />
                  <a class="atmo-popover__dropdown-action atmo-popover__dropdown-action--extra-padding" @click="clearQueue()"> Clear Queue Songs </a>
                  <a class="atmo-popover__dropdown-action atmo-popover__dropdown-action--extra-padding" @click="clearQueue()"> Clear Queue Chat </a>
                </template>
              </atmo-popover>
            </span>
          </div>
        </div>
      </div>

      <div class="disengage-queue-container">
        <div class="disengage-queue-container__text">Engage Live Listening</div>
        <div class="disengage-queue-container__toggle">
          <atmo-toggle :on="$store.getters['liveListeningRooms/isLiveListeningEngaged']" :on-change="toggleLiveListeningEngagement" size="large" />
        </div>
      </div>
    </header>
    <div :class="addSongsClicked ? 'add-songs-grid' : 'queue-grid'">
      <div class="queue-grid__friends-container" v-if="!addSongsClicked">
        <!-- Manage Users Section (unchanged) -->
        <div class="container__heading-wrap">Manage Users</div>
        <div>
          <div class="friends-container__search-wrap">
            <input class="search-field-rounded" type="text" placeholder="Search for a friend" v-model="searchQuery" @input="filterList" />
          </div>
          <div class="friends-container__admins-wrap">
            <div class="admins-wrap__header">Friends</div>
            <div class="users-wrap">
              <perfect-scrollbar class="friends-scroll-wrap">
                <div class="users-wrap__user-wrap" v-for="friendship in filteredFriendList" :key="friendship.id">
                  <div class="user-wrap__image-wrap">
                    <img class="image-wrap__image" :src="getFeaturedImage(friendship.friend.images, 'full')" />
                  </div>
                  <div class="user-wrap__name-wrap">{{ friendship.friend.name }}</div>
                  <div
                    class="user-wrap__make-admin-wrap"
                    @click="
                      addChatRoomUser($store.state.liveListeningRooms.liveListeningChatRoom.id, {
                        user_id: friendship.friend.id,
                        chat_room_id: $store.state.liveListeningRooms.liveListeningChatRoom.id,
                        is_admin: false,
                      })
                    "
                  >
                    Add member
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
          <div class="friends-container__members-wrap">
            <div class="members-wrap__header">Session Members</div>
            <div class="users-wrap">
              <perfect-scrollbar>
                <div class="users-wrap__user-wrap" v-for="(roomUser, index) in liveListeningUsersWithoutRoomCreator" :key="index">
                  <div class="user-wrap__image-wrap"><img class="image-wrap__image" :src="getFeaturedImage(roomUser.user.images, 'full')" /></div>
                  <div class="user-wrap__name-wrap">{{ roomUser.user.name }}</div>
                  <div
                    class="user-wrap__make-admin-wrap"
                    v-if="roomUser.is_admin"
                    @click="
                      updateChatRoomUser($store.state.liveListeningRooms.liveListeningChatRoom.id, roomUser.id, {
                        user_id: roomUser.user_id,
                        chat_room_id: $store.state.liveListeningRooms.liveListeningChatRoom.id,
                        is_admin: false,
                      })
                    "
                  >
                    Undo Admin
                  </div>
                  <div
                    class="user-wrap__make-admin-wrap"
                    v-else
                    @click="
                      updateChatRoomUser($store.state.liveListeningRooms.liveListeningChatRoom.id, roomUser.id, {
                        user_id: roomUser.user_id,
                        chat_room_id: $store.state.liveListeningRooms.liveListeningChatRoom.id,
                        is_admin: true,
                      })
                    "
                  >
                    Make Admin
                  </div>
                  <div class="user-wrap__delete-wrap" @click="deleteChatRoomUser($store.state.liveListeningRooms.liveListeningChatRoom.id, roomUser.id)">
                    <img class="delete-wrap__icon" src="@/assets/images/icons/close_popup.png" />
                  </div>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
      <div class="queue-grid__songs-container">
        <div class="container__heading-wrap" v-if="!addSongsClicked">
          <div class="heading-wrap__text-wrap">Songs in Queue</div>
          <div class="heading-wrap__icon-wrap" @click="toggleAddSongs()">
            <img class="icon-wrap__icon" src="@/assets/images/icons/add.png" />
          </div>
          <live-listening-player location="live-listening" v-if="$store.getters['liveListeningRooms/isLiveListeningEngaged']" />
        </div>

        <div class="container__heading-wrap add-songs-clicked" v-else @click="toggleAddSongs()">
          <div class="heading-wrap__icon-wrap">
            <img class="icon-wrap__icon" src="@/assets/images/icons/back.png" />
          </div>
          <div class="heading-wrap__text-wrap">Back To Session</div>
        </div>
        <div class="songs-container__add-songs-container" v-if="addSongsClicked">
          <atmo-library-body component-location="liveListeningAdd"></atmo-library-body>
          <button class="add-to-session" @click="addSongsToSession()">Add To Session</button>
        </div>
        <atmo-library-songs v-else :songs="sessionSongs" component-location="liveListeningQueue" />
      </div>

      <div class="queue-grid__chat-container" v-if="!addSongsClicked">
        <div class="container__heading-wrap">Chat</div>
        <div class="chat-container__chat-wrap">
          <div class="chat-wrap__queue-chat">
            <div v-if="$store.state.liveListeningRooms.liveListeningChatRoomMessages.length === 0" class="messages-container empty-messages">Empty Chat History :(</div>
            <perfect-scrollbar v-else class="messages-container">
              <div v-for="message in $store.state.liveListeningRooms.liveListeningChatRoomMessages" :key="message.id" :class="messageClassFor(message)">
                <div class="queue-chat__message-content">
                  <p class="queue-chat__message-text" v-html="formatMessage(message.body)"></p>
                  <div v-if="message.song" class="queue-chat__message-song">
                    <img class="queue-chat__message-song-icon" src="@/assets/images/icons/play.png" />
                    <a class="queue-chat__message-song-name">
                      {{ message.song.name }}
                    </a>
                  </div>
                  <div v-if="message.chat_message_image" class="queue-chat__message-image-wrap" @click="openChatImageModal(message.chat_message_image.image.url)">
                    <img class="queue-chat__message-image" :src="message.chat_message_image.image.url" />
                  </div>
                  <p class="queue-chat__message-timestamp">
                    {{ getDate(message.created_at) }}
                  </p>
                </div>
                <div
                  class="atmo-avatar atmo-avatar--extra-small atmo-avatar--no-border"
                  :style="{ 'background-image': 'url(' + getFeaturedImage(message.user.images, 'thumb') + ')' }"
                  title="message.user.name"
                />
              </div>
            </perfect-scrollbar>
          </div>
          <form class="queue-chat__form" @submit.prevent="saveMessage(newMessageBody)">
            <div class="input-container">
              <input v-model="newMessageBody" class="queue-chat__text-input" placeholder="Type your message here" type="text" />
              <!-- File Upload Icon -->
              <label for="file-input" class="input-icon file-upload-icon">
                <img src="@/assets/images/icons/chat/upload_image.png" class="image-upload-icon" alt="Upload Image" />
              </label>
              <input id="file-input" type="file" @change="handleFileUpload" style="display: none" />
              <!-- Emoji Picker Icon -->
              <button type="button" class="input-icon emoji-picker-trigger" @click="isEmojiPickerOpen = true">☻</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import Vue from "vue";
import Axios from "axios";
import { sendMessage, setCallback as setChatCallback } from "@/cable-channels/liveListeningChat";
import {
  connectToLiveListeningPlayerChannel,
  disconnectFromLiveListeningPlayerChannel,
  setCallback as setPlayerCallback,
  sendRequestSync,
  sendSyncUpdate,
  ensureHowlerLoaded,
  syncPlayer,
} from "@/cable-channels/liveListeningPlayer";
import { sendSongsUpdate, setCallback as setSongsCallback } from "@/cable-channels/liveListeningSongs";
import EmojiPickerModal from "@/modals/emoji-picker-modal.vue";
import AtmoLibrarySongs from "@/components/atmo-library/library-songs";
import AtmoToggle from "@/components/atmo-toggle";
import AtmoPopover from "@/components/atmo-popover";
import devMockData from "@/devUtils/mockData.js";
import { formatDateToDayTimeYear, getFeaturedImage, findOtherUsersInRoom } from "@/helpers/utilityFunctions";
import AtmoLibraryBody from "@/components/atmo-library/atmo-library-body";
import LiveListeningPlayer from "@/components/live-listening-player-bar";
import HowlerManager from "@/helpers/services/HowlerManager";
import {
  callUserProfilesShow,
  callUsersShow,
  callChatRoomUsersCreate,
  callChatRoomUsersDelete,
  callChatRoomUsersUpdate,
  callSongReferencesCreate,
  callChatMessagesCreate,
  callChatMessagesIndex,
} from "@/helpers/axiosCalls";

export default {
  components: {
    EmojiPickerModal,
    AtmoLibrarySongs,
    AtmoToggle,
    AtmoPopover,
    AtmoLibraryBody,
    LiveListeningPlayer,
  },
  mounted() {
    setSongsCallback((data) => {
      if (data.event === "songs_updated") {
        console.log("Songs update event received:", data);
        this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomSongs", data.chat_room_id);
      }
    });
  },
  data() {
    return {
      artistImageArray: devMockData.artistImageArray,
      friendNameArray: devMockData.friendNameArray,
      userFriendships: [],
      chatRooms: [],
      messages: [],
      allChatRooms: [],
      userChatRoomId: null,
      friendChatRoomId: null,
      newMessageBody: "",
      search: "",
      devMockData,
      groupChatRooms: [],
      groupChatRoomSelected: false,
      currentGroupChatRoom: {},
      searchQuery: "",
      addSongsClicked: false,
      isLiveListeningEngaged: false,
      audioPlayer: null,
      isEmojiPickerOpen: false,
      playbackTimer: null,
      hasSynced: false, // Indicates whether the client has received the sync event
      lastSyncSent: 0, // Used if you ever decide to throttle sync updates from the creator
    };
  },
  computed: {
    sessionSongs() {
      return this.$store.state.liveListeningRooms.liveListeningChatRoomSongs.map((roomSong) => roomSong.song);
    },
    currentUserIsAdmin() {
      const currentUser = this.$store.state.liveListeningRooms.liveListeningChatRoomUsers.find((roomUser) => roomUser.user_id === this.$store.state.currentUserId);
      return currentUser ? currentUser.is_admin : false;
    },
    // New computed property for playback control (sync source)
    currentUserIsSyncSource() {
      const room = this.$store.state.liveListeningRooms.liveListeningChatRoom;
      const currentUserId = this.$store.state.currentUserId;
      // Log the values for debugging:
      console.log("Computed currentUserIsSyncSource - room:", room, "currentUserId:", currentUserId);
      return room && room.creator_id === currentUserId;
    },
    acceptedFriendships() {
      const acceptedFriendships = this.$store.state.chatRooms.chatUserFriendships.filter((friendship) => friendship.state === "accepted");
      const liveListeningChatUsers = this.$store.state.liveListeningRooms.liveListeningChatRoomUsers;
      const liveListeningUsers = liveListeningChatUsers.map((roomUser) => roomUser.user_id);
      return acceptedFriendships.filter((friendship) => !liveListeningUsers.includes(friendship.friend_id));
    },
    filteredFriendList() {
      return this.acceptedFriendships.filter((friendship) => friendship.friend.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    liveListeningUsersWithoutRoomCreator() {
      return this.$store.state.liveListeningRooms.liveListeningChatRoomUsers.filter((roomUser) => roomUser.user_id !== this.$store.state.liveListeningRooms.liveListeningChatRoom.id);
    },
    currentTrack() {
      return this.$store.getters["liveListeningRooms/currentTrack"];
    },
    isPlaying() {
      return this.$store.getters["liveListeningRooms/isPlaying"];
    },
    currentTimestamp() {
      return this.$store.getters["liveListeningRooms/currentTimestamp"];
    },
  },
  watch: {
    isPlaying(newVal) {
      if (newVal) {
        this.startPlaybackTimer();
      } else {
        this.stopPlaybackTimer();
      }
    },
    currentTrack(newTrack, oldTrack) {
      if (oldTrack) {
        HowlerManager.stopHowl(oldTrack);
      }
      if (newTrack && this.isPlaying) {
        HowlerManager.playHowl(newTrack);
      }
    },
    currentTimestamp(newTimestamp) {
      const track = this.currentTrack;
      if (track && this.isPlaying) {
        HowlerManager.seekHowl(track, newTimestamp);
      }
    },
  },
  beforeDestroy() {
    this.stopPlaybackTimer();
  },
  methods: {
    initializeChat() {
      console.log("Live Listening User modal opened. Initializing chat...");

      // Fetch messages when the modal opens
      this.getRoomMessages(this.$store.state.liveListeningRooms.liveListeningChatRoom.id);

      // Ensure callback is set so messages update in real-time
      setChatCallback(() => {
        console.log("New message received, updating state...");
        this.getRoomMessages(this.$store.state.liveListeningRooms.liveListeningChatRoom.id);
      });
    },
    startPlaybackTimer() {
      if (!this.currentUserIsSyncSource) return; // Only run if user is sync source
      if (this.playbackTimer) clearInterval(this.playbackTimer);

      this.playbackTimer = setInterval(() => {
        if (this.currentTrack && this.isPlaying) {
          if (!this.hasSynced) return;
          const howl = HowlerManager.getHowl(this.currentTrack);
          if (!howl) return;

          const soundId = HowlerManager.songSoundIds.get(this.currentTrack.id);
          if (!soundId || !howl.playing(soundId)) return;

          const localTime = HowlerManager.getCurrentTime(this.currentTrack);
          const channelTime = this.$store.getters["liveListeningRooms/currentTimestamp"];

          if (localTime < channelTime - 0.5) {
            console.log("Local time behind channel. Forcing seek to channel time:", channelTime);
            HowlerManager.seekHowl(this.currentTrack, channelTime);
          } else {
            console.log("Timer update, localTime:", localTime);
            this.$store.commit("liveListeningRooms/setCurrentTimestamp", localTime);
          }
        }
      }, 500);
    },
    stopPlaybackTimer() {
      if (this.playbackTimer) {
        clearInterval(this.playbackTimer);
        this.playbackTimer = null;
      }
    },
    onSelectEmoji(emoji) {
      this.newMessageBody += emoji;
      this.isEmojiPickerOpen = false;
      console.log("EMOJI", emoji);
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    addSongsToSession() {
      const axiosPromises = [];
      const roomId = this.$store.state.liveListeningRooms.liveListeningChatRoom.id;
      const userId = this.$store.state.currentUserId;
      this.$store.state.liveListeningRooms.liveListeningChatRoomPendingSongs.forEach((song) => {
        axiosPromises.push(
          callSongReferencesCreate({
            songable_id: roomId,
            songable_type: "ChatRoom",
            song_id: song.id,
            user_id: userId,
          })
        );
      });
      Promise.all(axiosPromises)
        .then((responses) => {
          this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomSongs", roomId);
          this.$store.commit("liveListeningRooms/setLiveListeningChatRoomPendingSongs", []);
          this.toggleAddSongs();
          sendSongsUpdate(roomId, userId);
          console.log("Songs update event sent:", responses);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleAddSongs() {
      this.addSongsClicked = !this.addSongsClicked;
      this.$store.commit("liveListeningRooms/setLiveListeningChatRoomPendingSongs", []);
    },
    addChatRoomUser(chatRoomId, req) {
      callChatRoomUsersCreate(chatRoomId, req)
        .then(() => {
          this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomUsers", chatRoomId);
        })
        .catch((error) => {
          throw error;
        });
    },
    updateChatRoomUser(chatRoomId, chatRoomUserId, req) {
      callChatRoomUsersUpdate(chatRoomId, chatRoomUserId, req)
        .then(() => {
          this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomUsers", chatRoomId);
        })
        .catch((error) => {
          throw error;
        });
    },
    deleteChatRoomUser(chatRoomId, chatRoomUserId) {
      callChatRoomUsersDelete(chatRoomId, chatRoomUserId)
        .then(() => {
          this.$store.dispatch("liveListeningRooms/getLiveListeningChatRoomUsers", chatRoomId);
        })
        .catch((error) => {
          throw error;
        });
    },
    testingClick() {
      window.alert("CLICKED");
    },
    formatMessage(message) {
      const urlPattern = /https?:\/\/[^\s]+/g;
      return message.replace(urlPattern, (url) => {
        return `<a class="chat-link" href="${url}" target="_blank">${url}</a>`;
      });
    },
    getDate(date) {
      return formatDateToDayTimeYear(date);
    },
    openChatImageModal(url) {
      this.$store.commit("chatRooms/setChatImageModalUrl", url);
      this.$store.commit("chatRooms/showChatImageModal");
    },
    filterList() {
      // Update filtered list based on search query.
    },
    async handleFileUpload(event) {
      const selectedFile = event.target.files[0];
      console.log("SELECTED FILE", selectedFile);
      if (!selectedFile) return;
      const emptyMessageBody = "";
      callChatMessagesCreate(this.$store.state.liveListeningRooms.liveListeningChatRoom.id, {
        chat_room_id: this.$store.state.liveListeningRooms.liveListeningChatRoom.id,
        user_id: this.$store.state.currentUserId,
        body: emptyMessageBody,
      })
        .then(async (res) => {
          const messageId = res.id;
          const formData = new FormData();
          formData.append("image", selectedFile);
          formData.append("chat_message_id", messageId);
          const response = await Axios({
            method: "post",
            url: `/api/v1/chat_message_images`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });
          console.log("RESPONSE", response);
          sendMessage(emptyMessageBody, this.$store.state.liveListeningRooms.liveListeningChatRoom.id, this.$store.state.currentUserId);
          this.newMessageBody = "";
          this.getRoomMessages(this.$store.state.liveListeningRooms.liveListeningChatRoom.id);
          setChatCallback(() => {
            this.getRoomMessages(this.$store.state.liveListeningRooms.liveListeningChatRoom.id);
          });
          const otherUsersInRoom = findOtherUsersInRoom(this.$store.state.liveListeningRooms.liveListeningChatRoom, this.$store);
          otherUsersInRoom.forEach((user) => {
            this.$store.dispatch("notifications/createNotification", {
              userId: user.user_id,
              payload: {
                sender_id: this.$store.state.currentUserId,
                receiver_id: user.user_id,
                notification_type_name: "Chat Message",
                status: "unread",
                notifiable_type: "ChatRoom",
                notifiable_id: this.$store.state.liveListeningRooms.liveListeningChatRoom.id,
              },
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openChatSettings() {
      this.$store.commit("toggleGroupChatSettingsModal");
    },
    uploadImageToChat() {
      window.alert("UPLOAD IMAGE");
    },
    toggleCreateGroupChatModal() {
      this.$store.commit("toggleCreateGroupChatModal");
    },
    openDeleteGroupChatRoomModal() {
      window.alert("OPEN DELETE GROUP CHAT ROOM MODAL");
    },
    toggleLiveListeningEngagement() {
      const roomId = this.$store.state.liveListeningRooms.liveListeningChatRoom.id;
      const isPlaying = this.$store.state.player.isPlaying;
      const currentSong = this.$store.state.player.currentSong;

      if (this.$store.getters["liveListeningRooms/isLiveListeningEngaged"]) {
        const currentTrack = this.$store.getters["liveListeningRooms/currentTrack"];
        if (currentTrack) {
          const lastTimestamp = HowlerManager.getCurrentTime(currentTrack);
          this.$store.commit("liveListeningRooms/setCurrentTimestamp", lastTimestamp);
          console.log("[Disengaging] Saved last timestamp:", lastTimestamp);
        }
      } else {
        if (currentSong && isPlaying) {
          console.log("[Engaging] Pausing playback...");
          this.$store.dispatch("player/pauseSong");
        }
      }

      this.$store.commit("liveListeningRooms/toggleLiveListeningEngaged");

      if (this.$store.getters["liveListeningRooms/isLiveListeningEngaged"]) {
        this.hasSynced = false;
        connectToLiveListeningPlayerChannel(roomId);
        setPlayerCallback(this.handleLiveListeningUpdates);

        if (!this.currentUserIsSyncSource) {
          console.log("Non-creator: Requesting sync update");
          sendRequestSync(roomId);
        } else {
          const restoredTimestamp = this.$store.getters["liveListeningRooms/currentTimestamp"];
          const currentTrack = this.$store.getters["liveListeningRooms/currentTrack"];
          console.log("[Re-engaging] Using saved timestamp:", restoredTimestamp);

          if (currentTrack) {
            sendSyncUpdate(roomId, currentTrack, restoredTimestamp, true);
          }
        }
      } else {
        const currentTrack = this.$store.getters["liveListeningRooms/currentTrack"];
        if (currentTrack) {
          HowlerManager.pauseHowl(currentTrack);
        }
        disconnectFromLiveListeningPlayerChannel();
      }
    },
    handleLiveListeningUpdates(data) {
      console.log("Received event:", data.event_type);

      if (!data.event_type) {
        console.warn("Received event with undefined event_type", data);
        return;
      }

      if (data.event_type === "request_sync") {
        console.log("Received request_sync event!");

        if (this.currentUserIsSyncSource) {
          console.log("Creator received request_sync. Preparing to send sync update...");

          const roomId = this.$store.state.liveListeningRooms.liveListeningChatRoom.id;
          const currentTrack = this.$store.getters["liveListeningRooms/currentTrack"];
          const isPlaying = this.$store.getters["liveListeningRooms/isPlaying"];
          let currentTimestamp = currentTrack ? HowlerManager.getCurrentTime(currentTrack) : 0;

          console.log("[SYNC UPDATE] Preparing to send sync update. Current Timestamp:", currentTimestamp, "Track:", currentTrack?.id);

          if (!currentTrack || currentTimestamp === 0) {
            setTimeout(() => {
              const delayedTimestamp = HowlerManager.getCurrentTime(currentTrack);
              console.log("[SYNC UPDATE] Delayed sync update with timestamp:", delayedTimestamp);
              sendSyncUpdate(roomId, currentTrack, delayedTimestamp, isPlaying);
            }, 500);
          } else {
            sendSyncUpdate(roomId, currentTrack, currentTimestamp, isPlaying);
          }
        }
        return;
      }

      if (data.event_type === "sync") {
        ensureHowlerLoaded(data.track);

        const currentTrack = this.$store.getters["liveListeningRooms/currentTrack"];
        const currentTimestamp = this.$store.getters["liveListeningRooms/currentTimestamp"];

        const isSameTrack = currentTrack && data.track && currentTrack.id === data.track.id;
        const isSameTimestamp = Math.abs(data.timestamp - currentTimestamp) < 0.1;

        if (isSameTrack && isSameTimestamp) {
          console.log("Ignoring duplicate sync event");
          return;
        }

        this.$store.commit("liveListeningRooms/setCurrentTrack", data.track);
        this.$store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
        this.$store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
        syncPlayer(data.track, data.timestamp, data.is_playing);

        if (!this.hasSynced) {
          this.hasSynced = true;
        }
      }

      switch (data.event_type) {
        case "play_pause":
          this.$store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
          this.$store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
          if (data.track) {
            this.$store.commit("liveListeningRooms/setCurrentTrack", data.track);
          }
          break;
        case "seek":
          this.$store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
          if (data.track) {
            this.$store.commit("liveListeningRooms/setCurrentTrack", data.track);
          }
          break;
        case "update_track":
          this.$store.commit("liveListeningRooms/setCurrentTrack", data.track);
          this.$store.commit("liveListeningRooms/setCurrentTimestamp", 0);
          this.$store.commit("liveListeningRooms/setPlaybackState", true);
          break;
        default:
          console.warn("Unhandled event:", data.event_type);
      }
    },
    backToLiveListeningIndex() {
      this.searchQuery = "";
      this.$store.commit("liveListeningRooms/closeLiveListeningUser");
      this.addSongsClicked = false;
      this.$store.commit("liveListeningRooms/openLiveListeningIndex");
    },
    messageClassFor(message) {
      const classes = ["queue-chat__message"];
      if (message.user_id === this.$store.state.currentUser.profile_id) {
        classes.push("queue-chat__message--current-user");
      }
      return classes.join(" ");
    },
    scrollToBottom() {
      console.log("TODO: FIX SCROLLING TO BOTTOM");
    },
    getRoomMessages(roomId) {
      callChatMessagesIndex(roomId)
        .then((response) => {
          this.messages = response;
          this.$store.commit("liveListeningRooms/setLiveListeningChatRoomMessages", this.messages);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    saveMessage(messageBody) {
      callChatMessagesCreate(this.$store.state.liveListeningRooms.liveListeningChatRoom.id, {
        chat_room_id: this.$store.state.liveListeningRooms.liveListeningChatRoom.id,
        user_id: this.$store.state.currentUserId,
        body: messageBody,
      })
        .then(() => {
          sendMessage(messageBody, this.$store.state.liveListeningRooms.liveListeningChatRoom.id, this.$store.state.currentUserId);
          this.newMessageBody = "";
          this.getRoomMessages(this.$store.state.liveListeningRooms.liveListeningChatRoom.id);
          setChatCallback(() => {
            this.getRoomMessages(this.$store.state.liveListeningRooms.liveListeningChatRoom.id);
          });
          const otherUsersInRoom = findOtherUsersInRoom(this.$store.state.liveListeningRooms.liveListeningChatRoom, this.$store);
          otherUsersInRoom.forEach((user) => {
            this.$store.dispatch("notifications/createNotification", {
              userId: user.user_id,
              payload: {
                sender_id: this.$store.state.currentUserId,
                receiver_id: user.user_id,
                notification_type_name: "Chat Message",
                status: "unread",
                notifiable_type: "ChatRoom",
                notifiable_id: this.$store.state.liveListeningRooms.liveListeningChatRoom.id,
              },
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.user-queue {
  .ps {
    // min-height: 20rem;
    // max-height: 14rem;
  }

  .atmo-library__body {
    height: 60vh;
  }

  .atmo-library__main-column {
    height: 60vh;
  }

  .main-column__scroll-container {
    max-height: 60vh;
  }

  .atmo-library__select-view {
    display: flex;
    gap: 1rem;
    font-size: 0.8rem;
    margin-left: 1.2rem;
    margin-bottom: 1rem;
  }

  .select-view__option {
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    display: inline-block;
    text-transform: uppercase;
    background: none;
    color: white;
  }

  .live-listening__hide-modal {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    opacity: 0.5;
    cursor: pointer;
  }

  .live-listening__header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 1rem;

    .modal-header-container {
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;
    }

    .modal-header-container__heading-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(96, 72, 117, 0.7);
      backdrop-filter: blur(5px);
      padding: 0.5rem 2rem 1.5rem 2rem;
      border-radius: 0px 0px 20px 20px;

      .atmo-page-header {
        margin: 0;
      }
    }

    .atmo-page-header-container__header-icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .atmo-page-header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
      margin-right: 0.5rem;
    }

    .header-icon-wrap__icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .atmo-popover__actions-trigger {
      height: 18px;
      cursor: pointer;
      padding: 5px 10px;
      box-sizing: content-box;
    }

    .icon-wrap__icon {
      height: 2rem;
    }
  }

  .disengage-queue-container {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(190, 167, 221, 0.3);
    padding: 0.5rem;
    border-radius: 5px;

    .disengage-queue-container__text {
      margin-right: 1rem;
    }
  }

  .queue-grid {
    // border: 1px solid white;
    display: grid;
    grid-template-columns: minmax(max-content, 20rem) 1fr minmax(max-content, 20rem);
    grid-gap: 1rem;
    // height: 100%;
  }

  .add-songs-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .container__heading-wrap {
    font-size: 1.5rem;
    color: $atmo-blue--medium;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2rem;
    // border: 1px solid white;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 3rem;

    .heading-wrap__text-wrap {
      margin-right: 0.5rem;
    }

    .heading-wrap__icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon-wrap__icon {
      height: 1.5rem;
      width: 1.5rem;
    }

    &.add-songs-clicked {
      color: white;
      cursor: pointer;

      .heading-wrap__icon-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.5rem;
      }

      .icon-wrap__icon {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }

  .queue-grid__friends-container {
    // border: 1px solid white;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
  }

  .friends-container__search-wrap {
    margin-bottom: 1rem;
  }

  .search-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    // width: 100%;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .friends-container__admins-wrap {
    margin-bottom: 1rem;
    height: 20rem;

    .ps {
      max-height: 18rem;
    }
  }

  .admins-wrap__header {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding: 0.3rem 0rem 0.3rem 0rem;
  }

  .members-wrap__header {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding: 0.3rem 0rem 0.3rem 0rem;
  }

  .users-wrap__user-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    // cursor: pointer;
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;

    &:hover {
      background-color: rgba(190, 167, 221, 0.3);

      .user-wrap__make-admin-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $atmo-blue--medium;
        font-weight: 500;
        cursor: pointer;
      }

      .user-wrap__delete-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 1rem;
        cursor: pointer;
      }
    }

    .image-wrap__image {
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 100%;
      margin-right: 0.5rem;
    }

    .user-wrap__name-wrap {
      font-size: 0.8rem;
      font-weight: 500;
      margin-right: 0.5rem;
    }

    .user-wrap__make-admin-wrap {
      display: none;
    }

    .user-wrap__delete-wrap {
      display: none;
    }

    .delete-wrap__icon {
      height: 0.7rem;
      opacity: 0.3;
    }
  }

  .friends-container__members-wrap {
    margin-bottom: 1rem;
    height: 18rem;

    .ps {
      max-height: 18rem;
    }
  }

  .friends-container__invite-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .invite-wrap__invite-button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 20px;
    padding: 0.5rem 3rem 0.5rem 3rem;
    margin-right: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  .queue-grid__songs-container {
    // border: 1px solid white;
    padding: 1rem;
  }

  .queue-grid__chat-container {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
  }

  .messages-container {
    height: 65vh;
    max-height: none;
  }

  .chat-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    // width: 100%;
    position: relative;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .chat-wrap__queue-chat {
    .ps {
      max-height: 40rem;
    }
  }

  .queue-chat__message {
    display: flex;
    padding: 7px 0;

    &--current-user {
      flex-direction: row-reverse;

      .queue-chat__message-content {
        padding: 7px 6px 3px;
        background: rgba($atmo-purple--light, 0.3);
        border-radius: 2px;
        margin-left: 5px;
        border: none;
      }

      .chat-link {
        color: $atmo-blue--medium;
      }

      .queue-chat__message-timestamp {
        align-self: flex-end;
        color: white;
      }
    }
  }

  .queue-chat__message-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 7px 6px 3px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    margin-right: 5px;

    .chat-link {
      color: $atmo-blue--medium;
    }
  }

  .queue-chat__message-image-wrap {
    display: flex;
    cursor: pointer;
    max-width: 19rem;
    margin-bottom: 0.5rem;
  }

  .queue-chat__message-image {
    object-fit: cover;
    width: 100%;
    border-radius: 2px;
    // height: 15rem;
    // width: 15rem;
  }

  .queue-chat__message-text {
    color: white;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .queue-chat__message-song {
    display: flex;
    cursor: pointer;
  }

  .queue-chat__message-song-icon {
    height: 15px;
    width: 15px;
  }

  .queue-chat__message-song-name {
    color: white;
    cursor: pointer;
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
    margin-bottom: 5px;

    &:hover {
      color: $atmo-purple--dark;
    }
  }

  .queue-chat__message-timestamp {
    color: white;
    font-size: 0.7rem;
    font-weight: 300;
  }

  .queue-chat__messages-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .queue-chat__form {
    width: 100%;
    margin: 5px 0;
  }

  .queue-chat__form {
    width: 100%;
    margin: 5px 0;
    // display: flex;
  }

  .input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .queue-chat__text-input {
    width: 100%;
    padding: 1rem 5rem 1rem 3rem; /* Adjust padding for space */
    color: white;
    font-size: 0.9rem;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;
  }

  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    background: transparent;
  }

  .file-upload-icon {
    left: 10px; /* Positioning inside the input */
  }

  .emoji-picker-trigger {
    //   position: absolute;
    left: 1rem;
    top: 23px;
    z-index: 1;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    font-size: 1.5rem;
    // border: 1px solid red;
  }

  .custom-file-input {
    // display: inline-block !important;
    // cursor: pointer;
    // position: absolute;
    // z-index: unset;
    // width: unset;
    // height: unset;
    // margin: unset;
    // overflow: unset;
    // opacity: unset;
  }

  .image-upload-icon {
    // position: absolute;
    // left: 2rem;
    // bottom: .55rem;
    height: 0.9rem;
    cursor: pointer;
  }

  .message-send-button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-right: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  textarea,
  textarea::placeholder {
    color: $atmo-white--medium-faded;
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
  }

  .songs-container__add-songs-container {
    position: relative;
    min-height: 72vh;
    // border: 1px solid white;
  }

  .add-to-session {
    position: absolute;
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    border: none;
    padding: 0.9rem 1.7rem 0.9rem 1.7rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(68, 174, 220, 0.8) 0%, rgba(217, 82, 167, 0.8) 100%);
    border-radius: 30px;
    font-size: 1rem;
    font-weight: 600;
    bottom: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
</style>
