<template>
  <AtmoModal id="atmo-search-results-modal" ref="atmoSearchResultsRef" v-model="isVisible" title="Search Results" customClass="atmo-search-results-modal">
    <header class="atmo-modal__header">
      <span class="atmo-modal__header-side">
        <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png" @click="closeModal" />
      </span>
      <div class="atmo-page-header-container">
        <ModalHeader heading="Search Results" marginBottom="2rem" />
      </div>
      <span class="atmo-modal__header-side" />
    </header>

    <div class="atmo-search-results-modal__body">
      <div class="results-categories-container">
        <div
          v-for="category in categories"
          :key="category.key"
          class="results-categories-container__results-category"
          :class="{ 'active-category': selectedCategory === category.key }"
          @click="selectCategory(category.key)"
        >
          {{ category.label }}
        </div>
      </div>

      <div class="results-container">
        <!-- No results message outside of scrollbar -->
        <div v-if="filteredResults.length === 0" class="no-results">No results found</div>

        <perfect-scrollbar v-else>
          <div class="results-container__results-wrap" v-for="(result, index) in filteredResults" :key="index">
            <router-link :to="getResultLink(result)" class="results-wrap__result-wrap" @click="closeModal">
              <div class="result-wrap__image-wrap">
                <img class="image-wrap__image" :src="result.image_url || defaultImage" alt="Result image" />
              </div>
              <div class="result-wrap__details-wrap">
                <div class="details-wrap__name">{{ result.name }}</div>
                <div class="details-wrap__category">{{ result.category }}</div>
              </div>
            </router-link>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import ModalHeader from "@/components/modal-header";

export default {
  components: {
    ModalHeader,
  },
  data() {
    return {
      selectedCategory: "all",
      categories: [
        { key: "all", label: "All" },
        { key: "users", label: "Users" },
        { key: "artists", label: "Artists" },
        { key: "songs", label: "Songs" },
        { key: "genres", label: "Genres" },
        { key: "moods", label: "Moods" },
        { key: "albums", label: "Albums" },
        // { key: "occasions", label: "Occasions" },
        { key: "labels", label: "Labels" },
        { key: "businesses", label: "Businesses" },
        { key: "publishers", label: "Publishers" },
      ],
      defaultImage: require("@/assets/images/default_pic.jpg"),
    };
  },
  computed: {
    ...mapState(["atmoSearchResultsModalOpen", "atmoSearchResults"]),
    isVisible: {
      get() {
        return this.atmoSearchResultsModalOpen;
      },
      set(value) {
        if (!value) this.closeModal();
      },
    },
    filteredResults() {
      if (!this.atmoSearchResults || typeof this.atmoSearchResults !== "object") {
        return [];
      }

      if (this.selectedCategory === "all") {
        return Object.entries(this.atmoSearchResults).flatMap(([key, items]) => (Array.isArray(items) ? items.map((item) => ({ ...item, category: key })) : []));
      }

      return Array.isArray(this.atmoSearchResults[this.selectedCategory])
        ? this.atmoSearchResults[this.selectedCategory].map((item) => ({ ...item, category: this.capitalizeCategory(this.selectedCategory) }))
        : [];
    },
  },
  methods: {
    ...mapMutations(["toggleAtmoSearchResultsModal", "setAtmoSearchResults", "clearAtmoSearchQuery"]),
    selectCategory(category) {
      this.selectedCategory = category;
    },
    closeModal() {
      this.selectedCategory = "all";
      this.toggleAtmoSearchResultsModal();

      // Reset search input by clearing Vuex search results
      this.setAtmoSearchResults({
        users: [],
        artists: [],
        songs: [],
        genres: [],
        moods: [],
        albums: [],
        occasions: [],
        labels: [],
        businesses: [],
        publishers: [],
      });

      this.clearAtmoSearchQuery();
    },
    capitalizeCategory(category) {
      return category.charAt(0).toUpperCase() + category.slice(1);
    },
    getResultLink(item) {
      if (!item) return {};

      switch (item.profile_type) {
        case "UserProfile":
          return { name: "user_profiles.show", params: { userProfileId: item.profile_id } };
        case "ArtistProfile":
          return { name: "artist_profiles.show", params: { artistProfileId: item.profile_id } };
        case "LabelProfile":
          return { name: "label_profiles.show", params: { labelProfileId: item.profile_id } };
        case "BusinessProfile":
          return { name: "business_profiles.show", params: { businessProfileId: item.profile_id } };
        case "PublisherProfile":
          return { name: "publisher_profiles.show", params: { publisherProfileId: item.profile_id } };
      }

      // Handle other types of search results
      switch (item.category) {
        case "songs":
          return { name: "songs.show", params: { songId: item.id } };
        case "albums":
          return { name: "albums.show", params: { albumId: item.id } };
        case "genres":
          return { name: "genres.show", params: { genreId: item.id } };
        case "moods":
          return { name: "moods.show", params: { moodId: item.id } };
        case "occasions":
          //THERE IS NO ROUTE FOR OCCASIONS YET
          return { name: "occasions.show", params: { occasionId: item.id } };
        default:
          return {}; // Fallback: return an empty route if no match
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $atmo-purple--medium-dark--overlay-faded;
  backdrop-filter: blur(5px);
  z-index: 999;
}

.atmo-modal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(96, 72, 117, 0.4);
  border-radius: 5px;
  padding: 0rem 1rem 1rem 1rem;
  z-index: 1000;
}

.atmo-modal {
  position: relative;
  z-index: 1000;

  &.atmo-modal--fullscreen {
    > .vfm__container {
      > .vfm__content {
        width: 100vw;
        height: 100vh;
        max-width: none;
        max-height: none;
        border-radius: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }
    }
  }
}

/* Modal Body */
.atmo-search-results-modal__body {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

/* Categories */
.results-categories-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;

  .results-categories-container__results-category {
    cursor: pointer;
    font-size: 0.9rem;
    font-weight: 700;
    text-transform: uppercase;

    &.active-category {
      border-bottom: 1px solid white;
    }
  }
}

.results-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 0; /* Ensures it can shrink properly */
  overflow: hidden;
  position: relative; /* Important for perfect-scrollbar */
}

.results-container .ps {
  flex: 1;
  min-height: 0;
  max-height: 100%;
  position: relative; /* Prevents layout issues */
}

.results-container perfect-scrollbar {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
  max-height: 100%;
  overflow: auto;
}

/* No results message */
.no-results {
  text-align: center;
  font-size: 1.2rem;
  color: white;
  padding: 2rem;
}

.results-container__results-wrap + .results-container__results-wrap {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* Result items */
.results-wrap__result-wrap {
  display: flex;
  gap: 2rem;
  width: 100%;
  padding: 1rem;
}

.result-wrap__image-wrap img {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 100%;
}

.result-wrap__details-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.details-wrap__name {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .atmo-modal--fullscreen {
    .atmo-modal-content {
      width: 100vw;
      height: 100vh;
    }
  }
}
</style>
