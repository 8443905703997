<template>
  <AtmoModal
    id="concerts-filter"
    ref="concertsFilterRef"
    title="Filter"
    customClass="concerts-filter"
    v-model="$store.state.filterConcertsModalOpen"
    @opened="setInitialFormProps"
    :closeFunction="() => $store.commit('toggleFilterConcertsModal')"
  >
    <header class="atmo-modal__header">
      <ModalHeader heading="Filter" marginBottom="2rem" />
    </header>
    <form class="atmo-form concerts-filter__form" @submit.prevent="submitForm">
      <fieldset class="concerts-filter__radio-group">
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'location' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="location" v-model="formSelectedFilterType" />
          </div>
          <div class="field-wrap">
            <label for="state">State</label>
            <select v-model="formSelectedState" @change="onSelectState" class="dropdown-group">
              <option value="" disabled selected>State</option>
              <option v-for="state in stateOptions" :key="state" :value="state">{{ state }}</option>
            </select>
          </div>
          <div class="field-wrap">
            <label for="city">city</label>
            <select v-model="formSelectedCity" :disabled="!formSelectedState" class="dropdown-group">
              <option value="" disabled selected>City</option>
              <option v-for="city in cityOptions" :key="city" :value="city">{{ city }}</option>
            </select>
          </div>
        </div>
        <div class="atmo-form__row">
          <div class="atmo-form__radio-container"></div>
          <hr class="atmo-form__hr" />
        </div>
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'artist' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="artist" v-model="formSelectedFilterType" />
          </div>
          <!-- <input type="text" v-model="formSelectedArtist" placeholder="Type an artist" list="artist-options" />
          <datalist id="artist-options">
            <option v-for="artist in artistOptions" :key="artist.name" :value="artist.name"></option>
          </datalist> -->
          <div class="atmo-form__row">
            <div class="field-wrap">
              <label for="artist">Artist</label>
              <select v-model="formSelectedArtist" class="dropdown-group">
                <option value="" disabled selected>Type an artist</option>
                <option v-for="artist in artistOptions" :key="artist.name" :value="artist.name">
                  {{ artist.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="atmo-form__row">
          <div class="atmo-form__radio-container"></div>
          <hr class="atmo-form__hr" />
        </div>
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'date' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="date" v-model="formSelectedFilterType" />
          </div>
          <div class="datepicker-container">
            <label for="start-date">Start Date</label>
            <DatePicker v-model="formSelectedStartDate" :format="'yyyy-MM-dd'" placeholder="Select Start Date" @update:modelValue="setFilterType('date')" />

            <label for="end-date">End Date</label>
            <DatePicker v-model="formSelectedEndDate" :format="'yyyy-MM-dd'" placeholder="Select End Date" @update:modelValue="setFilterType('date')" />
          </div>
        </div>
      </fieldset>
      <button type="submit" :disabled="!formSelectedFilterValue" class="atmo-button atmo-button--primary atmo-button--stretch">Search Now</button>
    </form>
  </AtmoModal>
</template>

<script>
import ModalHeader from "@/components/modal-header";
import DatePicker from "vue3-datepicker";

export default {
  components: {
    ModalHeader,
    DatePicker,
  },
  props: {
    selectedFilterType: { type: String, required: true },
    statesAndCities: { type: Object, required: true },
    artists: { type: Array, required: true },
    selectedState: { type: String, default: null },
    selectedCity: { type: String, default: null },
    selectedArtist: { type: Object, default: null },
    selectedStartDate: { type: String, default: null },
    selectedEndDate: { type: String, default: null },
    formatDates: { type: Function, required: true },
    onSubmit: { type: Function, required: true },
  },
  data() {
    return {
      formSelectedStartDate: null,
      formSelectedEndDate: null,
      formSelectedFilterType: "location",
      formSelectedState: null,
      formSelectedCity: null,
      formSelectedArtist: null,
    };
  },
  computed: {
    formSelectedFilterValue() {
      switch (this.formSelectedFilterType) {
        case "location":
          return this.formSelectedState;
        case "artist":
          return this.formSelectedArtist;
        case "date":
          return this.formSelectedDates;
        default:
          return null;
      }
    },
    stateOptions() {
      return Object.keys(this.statesAndCities);
    },
    cityOptions() {
      return this.statesAndCities[this.formSelectedState] || [];
    },
    artistOptions() {
      return this.artists;
    },
    formSelectedDates() {
      return this.formatDates(this.formSelectedStartDate, this.formSelectedEndDate);
    },
  },
  methods: {
    setInitialFormProps() {
      this.formSelectedFilterType = this.selectedFilterType;
      this.formSelectedState = this.selectedState;
      this.formSelectedCity = this.selectedCity;
      this.formSelectedArtist = this.selectedArtist;
      this.formSelectedStartDate = this.selectedStartDate;
      this.formSelectedEndDate = this.selectedEndDate;
    },
    setFilterType(newFilterType) {
      this.formSelectedFilterType = newFilterType;
    },
    onSelectState(event) {
      this.formSelectedState = event.target.value;
      this.formSelectedCity = "";
    },
    submitForm(evt) {
      evt.preventDefault();
      this.onSubmit({
        filterType: this.formSelectedFilterType,
        state: this.formSelectedState,
        city: this.formSelectedCity,
        artist: this.formSelectedArtist,
        startDate: this.formSelectedStartDate,
        endDate: this.formSelectedEndDate,
      });
      this.$store.commit("toggleFilterConcertsModal");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.concerts-filter {
  .atmo-modal__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__form {
    margin: 0 20px 25px;
  }

  &__radio-group {
    margin-bottom: 50px;
  }

  .multiselect {
    flex: 1;
  }

  .field-wrap {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }

  .dropdown-group {
    background: $atmo-purple--medium-dark;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    font-weight: 500rem;
    cursor: pointer;
  }

  .atmo-form__radio-container {
    display: none;
  }

  .atmo-form__row--blurred .asd__wrapper {
    display: none;
  }

  .datepicker-container {
    margin-top: 1rem;

    input {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 1rem 0.5rem 1rem;
      color: white;
      width: 100%;
      position: relative;
    }

    .v3dp__element__button__day {
      &.selected {
        span {
          background-color: $atmo-purple--medium-dark;
        }
      }

      &:hover {
        span {
          background-color: $atmo-purple--medium-dark;
        }
      }

      label {
        display: block;
      }
    }
  }
}
</style>
