<template>
  <div v-if="isLoading" class="loading-screen" :style="{ height }">
    <div class="loading-text">
      {{ currentMessage }}
    </div>
    <div class="progress-container">
      <div class="progress-bar" :style="{ width: progress + '%' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: { type: Boolean, required: true },
    loadingMessages: { type: Array, required: true },
    progress: { type: Number, required: true },
    simulateProgress: { type: Boolean, default: false }, // Enables automatic progress simulation
    height: { type: String, default: "70vh" },
  },
  data() {
    return {
      currentMessage: "Loading...",
      currentMessageIndex: 0,
      messageInterval: null,
      progressInterval: null,
    };
  },
  watch: {
    isLoading(newVal) {
      if (newVal) {
        this.startProgress();
        this.startTextCycling();
      } else {
        clearInterval(this.messageInterval);
        clearInterval(this.progressInterval);
      }
    },
    progress(newVal) {
      if (!this.loadingMessages.length) return;

      const totalMessages = this.loadingMessages.length;
      this.currentMessageIndex = Math.floor((newVal / 100) * totalMessages);
      this.currentMessage = this.loadingMessages[Math.min(this.currentMessageIndex, totalMessages - 1)];
    },
  },
  methods: {
    startProgress() {
      if (this.simulateProgress) {
        this.simulateProgressOverTime();
      }
    },

    simulateProgressOverTime() {
      this.progressInterval = setInterval(() => {
        if (this.progress < 95) {
          this.$emit("update:progress", this.progress + Math.random() * 8);
        } else {
          clearInterval(this.progressInterval);
        }
      }, 400);
    },

    startTextCycling() {
      if (!this.loadingMessages.length) return;

      const totalMessages = this.loadingMessages.length;
      const estimatedLoadTime = 6000;
      const intervalTime = estimatedLoadTime / totalMessages;

      this.currentMessageIndex = 0;
      this.currentMessage = this.loadingMessages[0];

      this.messageInterval = setInterval(() => {
        if (this.currentMessageIndex < totalMessages - 1) {
          this.currentMessageIndex++;
          this.currentMessage = this.loadingMessages[this.currentMessageIndex];
        } else {
          clearInterval(this.messageInterval);
        }
      }, intervalTime);
    },
  },
  beforeUnmount() {
    clearInterval(this.messageInterval);
    clearInterval(this.progressInterval);
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

/* Loading Screen */
.loading-screen {
  font-family: "Courier New", monospace;
  font-size: 18px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  /* Flashing Text */
  .loading-text {
    opacity: 0;
    animation: flashText 2.2s infinite alternate;
    text-align: center;
    max-width: 80%;
  }

  @keyframes flashText {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  /* Progress Bar */
  .progress-container {
    width: 80%;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 2rem;
  }

  .progress-bar {
    height: 100%;
    background-color: $atmo-pink--medium;
    border-radius: 5px;
    transition: width 0.4s linear;
  }
}
</style>
