import createChannel from '@/cable';

let callback; // Function to execute when receiving signaling messages
let channel = null; // Holds the channel instance



// Connect to the VideoCallsChannel using the room ID
function connectToVideoCallsChannel(roomId) {
    console.log("[Connecting to VideoCallsChannel] Room ID:", roomId);
    channel = createChannel(`VideoCallsChannel`, roomId, {
        received(data) {
            console.log("🎯 [Video Call Signal Received in `received()`]:", data);
            
            if (callback) {
                console.log("✅ Calling callback function:", callback);
                callback(data);
            } else {
                console.error("❌ Callback function is NOT set!");
            }
        }
    });
}

// Send signaling messages (offer, answer, ICE candidates)
function sendSignalingMessage(roomId, data) {
    if (!channel) {
        console.error('VideoCallsChannel not initialized. Did you call connectToVideoCallsChannel?');
        return;
    }
    channel.perform("send_signal", { chat_room_id: roomId, signal: data });
    console.log("Signaling message sent:", data);
}

// Set the callback that gets executed when a broadcast is received
function setCallback(fn) {
    callback = fn;
    console.log("Callback set for receiving video call signals.");
}

export { connectToVideoCallsChannel, sendSignalingMessage, setCallback };
