import router from "@/routes";
import { toast } from "vue3-toastify";
import { createStore } from 'vuex'; // Use createStore from vuex
import Axios from "axios";
import { setDefaultAxiosHeaders } from '@/helpers/axios';
import playerModule from "./store/player";
import opsModule from "./store/ops";
import eventsModule from "./store/events";
import addressModule from "./store/address";
import contestsModule from "./store/contests";
import productsModule from "./store/products";
import accomplishmentsModule from "./store/accomplishments";
import notificationsModule from "./store/notifications";
import userFriendshipsModule from "./store/userFriendships";
import userRelationshipsModule from "./store/userRelationships";
import profilesModule from "./store/profiles";
import postsModule from "./store/posts";
import liveListeningRoomsModule from "./store/liveListeningRooms";
import chatRoomsModule from "./store/chatRooms";
import musicProfilesModule from "./store/musicProfiles";
import userActivitiesModule from "./store/userActivities";
import playlistsModule from "./store/playlists";
import chartsModule from "./store/charts";
import atmoRadioStationsModule from "./store/atmoRadioStations";
import atmoQueuesModule from "./store/atmoQueues";
import librariesModule from "./store/libraries";
import videoPlayerModule from "./store/videoPlayer";
import paymentsModule from "./store/payments";
import donationsModule from "./store/donations";
import directoryProfilesModule from "./store/directoryProfiles";
import platformAdsModule from "./store/platformAds";
import directoryAdsModule from "./store/directoryAds";
// import User from "@/models/user";
import { callUserProfilesShow, 
  callArtistProfilesShow, 
  callLabelProfilesShow, 
  callBusinessProfilesShow, 
  callPublisherProfilesShow,
  callUsersShow, 
  callSongReferencesCreate, 
  callPostsCreate, 
  callChatRoomsCreate, 
  callChatRoomsIndex, 
  callChatMessagesCreate,
  callChatRoomUsersCreate
} from '@/helpers/axiosCalls';

Axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;


// Define default state as a function
const defaultState = () => ({
  hudLoadingProgress: 0,
  token: null,
  authenticated: false,
  authenticationPromise: null,
  currentUser: null,
  currentUserId: null,
  currentUserEmail: null,
  currentUserName: null,
  currentUserProfileId: null,
  currentUserProfileType: null,
  currentUserProfile: null,
  currentUserIsPaying: null,
  genreOptions: null,
  activeModal: null,
  AMIProcessing: false,
  atmoAdModalOpen: false,
  userMediaModalOpen: false,
  amiOptionsOpen: false,
  amiOptions: [],
  currentChatRoomId: null, // FOR AMI
  amiAudioMuted: false,
  amiSoundType: "fx",
  amiPosts: [],
  simulatedSpeechSentToAMI: false,
  simulatedAMISpeech: null,
  amiTestingOpen: false,
  sharePostModalOpen: false,
  sharePostModalPost: {},
  addSongsModalOpen: false,
  addSongsModalList: [],
  hudHeader: null,
  liveStreamModalOpen: false,
  liveStreamModalEventId: null,
  shareEventModalOpen: false,
  filterConcertsModalOpen: false,
  chooseContestWinnersModalOpen: false,
  removeContestWinnersModalOpen: false,
  friendPickerModalOpen: false,
  friendPickerModalType: null,
  friendPickerModalSelectedSong: null,
  playlistPickerModalOpen: false,
  playlistPickerModalSelectedSong: null,
  atmoSearchResultsModalOpen: false,
  atmoSearchResults: {
    users: [],
    artists: [],
    labels: [],
    songs: [],
    albums: [],
    genres: [],
    moods: [],
    occasions: [],
  },
  atmoSearchQuery: "",
});

// Create the Vuex store using createStore in Vue 3
export default createStore({
  modules: {
    player: playerModule,
    ops: opsModule,
    events: eventsModule,
    address: addressModule,
    contests: contestsModule,
    products: productsModule,
    accomplishments: accomplishmentsModule,
    notifications: notificationsModule,
    userFriendships: userFriendshipsModule,
    userRelationships: userRelationshipsModule,
    profiles: profilesModule,
    posts: postsModule,
    liveListeningRooms: liveListeningRoomsModule,
    chatRooms: chatRoomsModule,
    musicProfiles: musicProfilesModule,
    userActivities: userActivitiesModule,
    playlists: playlistsModule,
    charts: chartsModule,
    atmoRadioStations: atmoRadioStationsModule,
    atmoQueues: atmoQueuesModule,
    libraries: librariesModule,
    videoPlayer: videoPlayerModule,
    payments: paymentsModule,
    donations: donationsModule,
    directoryProfiles: directoryProfilesModule,
    platformAds: platformAdsModule,
    directoryAds: directoryAdsModule
  },
  state: defaultState(),
  mutations: {
    // eslint-disable-next-line no-unused-vars
    resetStore() {
      const defState = {
        ...defaultState(),
        player: playerModule.state,
        ops: opsModule.state,
        events: eventsModule.state,
        address: addressModule.state,
        contests: contestsModule.state,
        products: productsModule.state,
        accomplishments: accomplishmentsModule.state,
        notifications: notificationsModule.state,
        userFriendships: userFriendshipsModule.state,
        userRelationships: userRelationshipsModule.state,
        profiles: profilesModule.state,
        posts: postsModule.state,
        liveListeningRooms: liveListeningRoomsModule.state,
        chatRooms: chatRoomsModule.state,
        musicProfiles: musicProfilesModule.state,
        userActivities: userActivitiesModule.state,
        playlists: playlistsModule.state,
        charts: chartsModule.state,
        atmoRadioStations: atmoRadioStationsModule.state,
        atmoQueues: atmoQueuesModule.state,
        libraries: librariesModule.state,
        videoPlayer: videoPlayerModule.state,
        payments: paymentsModule.state,
        donations: donationsModule.state,
        directoryProfiles: directoryProfilesModule.state,
        platformAds: platformAdsModule.state,
        directoryAds: directoryAdsModule.state
      };
      this.replaceState(defState);
    },
    parseToken(state, token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const decodedToken = JSON.parse(window.atob(base64));
      const id = decodedToken.sub;
      const email = decodedToken.email;
      const name = decodedToken.name;
      const profile_id = decodedToken.profile_id;
      const profile_type = decodedToken.profile_type;
      const is_paying = decodedToken.is_paying;
  
      console.log("Parsed ID:", id);
  
      state.currentUserId = id;
      state.currentUserEmail = email;
      state.currentUserName = name;
      state.currentUserProfileId = profile_id;
      state.currentUserProfileType = profile_type;
      state.currentUserIsPaying = is_paying;
      state.token = token;
    },
    setAuthenticated(state, newValue) {
      state.authenticated = newValue;
    },
    setGenreOptions(state, genreOptions) {
      state.genreOptions = genreOptions;
    },
    showModal(state, name) {
      state.activeModal = name;
    },
    hideModal(state) {
      state.activeModal = null;
    },
    toggleSharePostModal(state) {
      state.sharePostModalOpen = !state.sharePostModalOpen;
    },
    toggleAddSongsModal(state) {
      state.addSongsModalOpen = true;
    },
    toggleFilterConcertsModal(state) {
      state.filterConcertsModalOpen = !state.filterConcertsModalOpen;
    },
    toggleChooseContestWinnersModal(state) {
      state.chooseContestWinnersModalOpen = !state.chooseContestWinnersModalOpen;
    },
    toggleRemoveContestWinnersModal(state) {
      state.removeContestWinnersModalOpen = !state.removeContestWinnersModalOpen;
    },
    setToken(state, token) {
      state.token = token;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setCurrentUserId(state, id) {
      state.currentUserId = Number(id);
    },
    setCurrentUserName(state, name) {
      state.currentUserName = name;
    },
    setCurrentUserEmail(state, email) {
      state.currentUserEmail = email;
    },
    setCurrentUserProfileId(state, profileId) {
      state.currentUserProfileId = profileId;
    },
    setCurrentUserProfileType(state, profileType) {
      state.currentUserProfileType = profileType;
    },
    setCurrentUserProfile(state, profile) {
      state.currentUserProfile = profile;
    },
    setCurrentUserPlanType(state, planType) {
      state.currentUserIsPaying = planType;
    },
    showAMIProcessing(state, duration = 1000) {
      state.AMIProcessing = true;
      setTimeout(() => {
        state.AMIProcessing = false;
      }, duration);
    },
    toggleAtmoAdModalOpen(state) {
      state.atmoAdModalOpen = !state.atmoAdModalOpen;
    },
    getUserAmiProfileAttributes(state) {
      Axios.get(`/api/v1/user_ami_profiles/${state.currentUserId}`)
        .then(response => {
          state.amiAudioMuted = response.data.ami_audio_muted;
          state.amiAudioSoundType = response.data.ami_sound_type;
        })
        .catch(error => {
          console.error(error);
        });
    },
    setAmiAudioState(state, muted) {
      Axios({
        method: "patch",
        url: `/api/v1/user_ami_profiles/${state.currentUserId}`,
        data: {
          ami_audio_muted: muted
        }
      })
        .then(response => {
          state.amiAudioMuted = response.data.ami_audio_muted;
        })
        .catch(error => {
          console.error(error);
        });
    },
    setAmiSoundType(state, type) {
      Axios({
        method: "patch",
        url: `/api/v1/user_ami_profiles/${state.currentUserId}`,
        data: {
          ami_sound_type: type
        }
      })
        .then(response => {
          state.amiSoundType = response.data.ami_sound_type;
        })
        .catch(error => {
          console.error(error);
        });
    },
    openAmiOptions(state, options) {
      state.amiOptions = options;
      state.amiOptionsOpen = true;
    },
    closeAmiOptions(state) {
      state.amiOptionsOpen = false;
    },
    openAmiTestingModal(state) {
      state.amiTestingOpen = true;
    },
    closeAmiTestingModal(state) {
      state.amiTestingOpen = false;
    },
    sendSimulatedAMISpeech(state, speech) {
      console.log("Firing in store");
      state.simulatedSpeechSentToAMI = true;
      state.simulatedAMISpeech = speech;
      console.log(state.simulatedSpeechSentToAMI, state.simulatedAMISpeech);
    },
    clearSimulatedAMISpeech(state) {
      state.simulatedSpeechSentToAMI = false;
      state.simulatedAMISpeech = null;
    },
    setChatRoomId(state, roomId) {
      state.currentChatRoomId = roomId;
    },
    setAmiPosts(state, posts) {
      state.amiPosts = posts;
    },
    setSharePostModalPost(state, value) {
      state.sharePostModalPost = value;
    },
    setAddSongsModalList(state, data) {
      state.addSongsModalList = data;
    },
    setHudHeader(state, header) {
      state.hudHeader = header;
    },
    showLiveStreamModal(state, eventId = null) {
      state.liveStreamModalOpen = true;
      state.liveStreamModalEventId = eventId;
    },
    hideLiveStreamModal(state) {
      state.liveStreamModalOpen = false;
    },
    toggleShareEventModal(state) {
      state.shareEventModalOpen = !state.shareEventModalOpen;
    },
    toggleAtmoSearchResultsModal(state) {
      state.atmoSearchResultsModalOpen = !state.atmoSearchResultsModalOpen;
    },
    setAtmoSearchResults(state, results) {
      state.atmoSearchResults = results;
    },
    setAtmoSearchQuery(state, query) {
      state.atmoSearchQuery = query;
    },
    clearAtmoSearchQuery(state) {
      state.atmoSearchQuery = "";  // Assuming searchQuery exists in your Vuex state
    },
    setHudLoadingProgress(state, progress) {
      state.hudLoadingProgress = progress;
    },
    displayArtist(state, { song }) {
      if (!song?.artist_profiles?.[0]?.id) {
        console.error("Invalid song data: Missing artistId");
        return;
      }
      router.push({ name: "artist_profiles.show", params: { artistProfileId: song.artist_profiles[0].id } });
    },
    displaySong(state, { song }) {
      if (!song?.id) {
        console.error("Invalid song data: Missing songId");
        return;
      }
      router.push({ name: "songs.show", params: { songId: song.id } });
    },
    displayAlbum(state, { song }) {
      if (!song?.artist_profiles?.[0]?.id || !song?.album_id) {
        console.error("Invalid song data: Missing artistId or albumId");
        return;
      }
      router.push({
        name: "albums.show",
        params: { albumId: song.album_id },
      });
    },
    toggleFriendPickerModal(state, payload) {
      if (!payload) {
        state.friendPickerModalOpen = false;
        state.friendPickerModalType = null;
        state.friendPickerModalSelectedSong = null;
        return;
      }
    
      const { song, type } = payload;
      state.friendPickerModalOpen = !state.friendPickerModalOpen;
      state.friendPickerModalType = type;
      state.friendPickerModalSelectedSong = song || null;
    },
    async saveChatUser({ commit }, { roomId, userId }) {
      try {
          await callChatRoomUsersCreate(roomId, {
              chat_room_id: roomId,
              user_id: userId,
              is_admin: true,
          });
          console.log(`SAVING USER ${userId} TO ROOM ${roomId} SUCCESSFUL`);
      } catch (error) {
          console.error(error);
      }
    },
    copySongLink(state, { song }) {
      if (!song?.id) {
        console.error("Invalid song data: Missing songId");
        return;
      }
    
      // Construct the full song URL
      const songUrl = `${window.location.origin}/songs/${song.id}`;
    
      // Copy to clipboard using the Clipboard API
      navigator.clipboard.writeText(songUrl);
    },
    togglePlaylistPickerModal(state, payload) {
      // Ensure payload is an object, or default it to an empty object
      const { song } = payload || {};
    
      if (!song) {
        state.playlistPickerModalOpen = false;
        state.playlistPickerModalSelectedSong = null;
        return;
      }
    
      state.playlistPickerModalOpen = !state.playlistPickerModalOpen;
      state.playlistPickerModalSelectedSong = song;
    },
  },
  actions: {
    async authenticate({ dispatch, state, commit }) {
      commit("setHudLoadingProgress", 10); // Step 1: Start authentication
    
      if (localStorage.token) {
        setDefaultAxiosHeaders(localStorage.token);
        commit("parseToken", localStorage.token);
    
        console.log("Current User ID after parsing token:", state.currentUserId);
    
        if (state.currentUserId) {
          commit("setHudLoadingProgress", 30); // Step 2: Token parsed
          await dispatch("getAndSetUserAttributes");
          commit("setHudLoadingProgress", 100); // Step 8: Done
          commit("setAuthenticated", true);
        } else {
          console.error("currentUserId is not set after parsing the token");
          commit("setAuthenticated", false);
          commit("setHudLoadingProgress", 100); // Still finish loading
        }
      } else {
        commit("setAuthenticated", false);
        commit("setHudLoadingProgress", 100);
      }
    },
    async getAndSetUserAttributes({ state, commit, dispatch }) {
      if (!state.currentUserId) {
        console.error("No currentUserId available, cannot fetch user attributes.");
        return;
      }
    
      try {
        // Fetch the current user
        commit("setHudLoadingProgress", 35); // Step 3: Start fetching user
    
        const currentUser = await callUsersShow(state.currentUserId);
        commit("setCurrentUser", currentUser);
    
        // Determine the appropriate profile call based on the profile type
        let currentUserProfile;
        switch (currentUser.profile_type) {
          case "UserProfile":
            currentUserProfile = await callUserProfilesShow(currentUser.profile_id);
            break;
          case "ArtistProfile":
            currentUserProfile = await callArtistProfilesShow(currentUser.profile_id);
            break;
          case "LabelProfile":
            currentUserProfile = await callLabelProfilesShow(currentUser.profile_id);
            break;
          case "BusinessProfile":
            currentUserProfile = await callBusinessProfilesShow(currentUser.profile_id);
            break;
          case "PublisherProfile":
            currentUserProfile = await callPublisherProfilesShow(currentUser.profile_id);
            break;
          default:
            console.error(`Unknown profile type: ${currentUser.profile_type}`);
            return;
        }
    
        // Commit the profile to the store
        commit("setHudLoadingProgress", 45); // Step 4: User profile loaded
        commit("setCurrentUserProfile", currentUserProfile);
        commit("setAuthenticated", true);
    
        // Fetch additional attributes and dispatch actions
        await dispatch("libraries/getLibrary");
        commit("setHudLoadingProgress", 55); // Step 5: Library loaded
    
        await dispatch("getGenreOptions");
        commit("setHudLoadingProgress", 60); // Step 6: Genres loaded
    
        await dispatch("atmoQueues/getAtmoQueue");
        await dispatch("userFriendships/fetchUserFriendships", currentUser.id);
        await dispatch("userFriendships/fetchCurrentUserFriendships", currentUser.id); //TODO: Fix this. We should not be calling twice. Use currentUserFriendships where necessary
        commit("setHudLoadingProgress", 65); // Step 7: Friends list loaded
    
        await dispatch("chatRooms/getChatRooms", currentUser.id);
        commit("setHudLoadingProgress", 70); // Step 8: Chat rooms loaded
    
        await dispatch("liveListeningRooms/getFriendLiveListeningChatRooms", currentUser.id);
        commit("setHudLoadingProgress", 75); // Step 9: Live listening loaded

        await dispatch("liveListeningRooms/getCurrentUserLiveListeningChatRoom", currentUser.id);
        await dispatch("liveListeningRooms/getLiveListeningChatRoomSongs", currentUser.id);
        commit("getUserAmiProfileAttributes");
    
        await dispatch("notifications/fetchUserNotifications", currentUser.id);
        await dispatch("notifications/connectToNotifications");
        await dispatch(
          "liveListeningRooms/connectToChatRoomUserRequests",
          currentUser.live_listening_chat_room.id
        );
        await dispatch("userActivities/connectToBroadcastPosts", {
          roomId: 1,
          params: {
            date_range: "all",
            limit: 30,
            show_current_user_posts: true,
          },
        });
        commit("setHudLoadingProgress", 80); // Step 10: Notifications loaded
    
        await dispatch("platformAds/fetchAds");
        commit("setHudLoadingProgress", 90); // Step 11: Ads loaded
    
        commit("setHudLoadingProgress", 100); // Step 12: HUD fully loaded
      } catch (error) {
        console.error("Error fetching user attributes:", error);
        commit("setHudLoadingProgress", 100); // Ensure the loading completes
      }
    },
    setToken(context, token) {
      context.commit("setToken", token);
    },
    async getGenreOptions({ state, commit }) {
      if (!state.genreOptions) {
        const response = await Axios.get("/api/v1/genres");
        commit("setGenreOptions", response.data);
      }
    },
    stubAction() {
      const promise = Promise.reject(new Error());

      promise.catch(() => {
        console.error("Action not implemented yet");
      });

      return promise;
    },
    async shareSongAsPost({ commit, dispatch, rootState }, { song }) {
      const payload = {
        content: song.name,
        user_id: rootState.currentUserId,
        billboard_id: rootState.currentUserId,
      };
    
      try {
        const response = await callPostsCreate(rootState.currentUserId, { post: payload });
        // TODO: Is it safe to assume billboard id is always friend user's id?
        if (rootState.currentUserId !== payload.billboard_id) {
          await dispatch(
            'notifications/createNotification',
            {
              userId: payload.billboard_id,
              payload: {
                sender_id: rootState.currentUserId,
                receiver_id: payload.billboard_id,
                notification_type_name: "Billboard Post",
                status: "unread",
                notifiable_type: "Post",
                notifiable_id: response.id,
              },
            },
            { root: true }
          );
        }
    
        await dispatch('userActivities/createUserActivity',
            {
                user_activity_type_name: "User Post",
                user_id: rootState.currentUserId,
                activityable_type: "Post",
                activityable_id: response.id
            },
            { root: true }
        );
    
        await callSongReferencesCreate({
          songable_id: response.id,
          songable_type: "Post",
          song_id: song.id,
          user_id: rootState.currentUserId,
        });
    
        await dispatch('accomplishments/createUserAccomplishments', {
          userId: rootState.currentUserId,
          accomplishmentData: {
            payloads: [
              {
                user_id: rootState.currentUserId,
                category: "posts",
                sub_category: "postNumber",
              },
              {
                user_id: rootState.currentUserId,
                category: "shares",
                sub_category: "songShareNumber",
              },
            ],
          },
        }, { root: true });

        router.push({ name: "billboards.show", params: { userId: rootState.currentUserId } });
        
      } catch (error) {
        console.error("Error creating post:", error);
        throw error;
      }
    },
    async shareSongAsMessage({ commit, dispatch, rootState }, friend) {
      try {
          let chatRoom = await callChatRoomsIndex({
              user_id: rootState.currentUserId,
              friend_id: friend.id,
          });
  
          if (Object.keys(chatRoom).length === 0) {
              let newChatRoom = await callChatRoomsCreate({
                  name: `${rootState.currentUserId}_${friend.id}`,
                  room_type: "friend",
                  roomable_type: "UserFriendship",
                  creator_id: rootState.currentUserId,
                  user_ids: [rootState.currentUserId, friend.id]
              });
  
              commit("chatRooms/setCurrentChatRoom", newChatRoom);
          } else {
              commit("chatRooms/setCurrentChatRoom", chatRoom);
          }
  
          let chatMessage = await callChatMessagesCreate(chatRoom.id, {
              chat_room_id: chatRoom.id,
              user_id: rootState.currentUserId,
              body: rootState.friendPickerModalSelectedSong.name,
          });
  
          await callSongReferencesCreate({
              songable_id: chatMessage.id,
              songable_type: "ChatMessage",
              song_id: rootState.friendPickerModalSelectedSong.id,
              user_id: rootState.currentUserId,
          });

          toast("Song Shared", { type: "success" });
  
          console.log("Message sent with song reference!");
      } catch (error) {
          toast("Error sharing song", { type: "error" });
          console.error("Error in shareSongAsMessage:", error);
      }
    },
    shareSongAsSms({ commit, dispatch, rootState }, friend) {
      toast(`SMS Service Stub Successful: ${friend.name}`, { type: "success" });
    }
  }
});
