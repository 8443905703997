<template>
  <div
    class="live-listening-player-bar__container"
    :class="{
      'player-bar--player': location === 'player',
      'player-bar--live-listening': location === 'live-listening',
    }"
    style="display: flex; justify-content: center; align-items: center; gap: 1rem"
  >
    <div class="song-info">
      <router-link
        v-if="currentTrack"
        class="song-info-item"
        :to="{
          name: 'songs.show',
          params: { songId: currentTrack.id },
        }"
      >
        {{ currentTrack.name }}
      </router-link>
      <router-link
        v-if="currentTrack"
        class="song-info-item"
        :to="{
          name: 'artist_profiles.show',
          params: { artistProfileId: currentTrack.primary_artist.id },
        }"
      >
        {{ (currentTrack.primary_artist.user || {}).name }}
      </router-link>
    </div>
    <a class="player-bar__play-pause" v-if="currentUserIsSyncSource">
      <img v-show="isPlaying" class="player-bar__icon player-bar__icon--play-pause" src="@/assets/images/icons/pause.png" @click="togglePlayPause" :class="{ disabled: !currentUserIsSyncSource }" />
      <img
        v-show="!isPlaying"
        class="player-bar__icon player-bar__icon--play-pause"
        :class="{ 'player-bar__icon--disabled': !currentTrack || !currentUserIsSyncSource }"
        src="@/assets/images/icons/play.png"
        @click="togglePlayPause"
      />
    </a>
    <div v-if="currentUserIsSyncSource">
      <p v-if="currentTrack" class="timestamp-info">{{ formatTime(currentTimestamp) }} / {{ formatTime(trackDuration) }}</p>
    </div>
  </div>
</template>

<script>
import HowlerManager from "@/helpers/services/HowlerManager";

export default {
  props: {
    location: {
      type: String,
      required: true,
      validator: (value) => ["player", "live-listening"].includes(value),
    },
  },
  computed: {
    currentTrack() {
      return this.$store.getters["liveListeningRooms/currentTrack"];
    },
    trackDuration() {
      if (!this.currentTrack || !this.currentTrack.song_profile) return "0:00";
      return this.currentTrack.song_profile.duration;
    },
    isPlaying() {
      return this.$store.getters["liveListeningRooms/isPlaying"];
    },
    currentTimestamp() {
      return this.$store.getters["liveListeningRooms/currentTimestamp"];
    },
    // This computed property returns true only if the current user is the room creator.
    currentUserIsSyncSource() {
      const room = this.$store.state.liveListeningRooms.liveListeningChatRoom;
      return room && room.creator_id === this.$store.state.currentUserId;
    },
  },
  methods: {
    togglePlayPause() {
      if (!this.currentUserIsSyncSource) {
        console.log("Only the room creator can control playback.");
        return;
      }
      // Also, do not attempt to toggle if there’s no track
      if (!this.currentTrack) {
        console.warn("No track available yet.");
        return;
      }
      this.$store.dispatch("liveListeningRooms/togglePlayPause");
    },
    formatTime(seconds) {
      if (!seconds || isNaN(seconds)) return "0:00";
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
    },
  },
  watch: {
    isPlaying(newState) {
      if (!this.currentTrack) {
        // Do nothing if there's no track yet.
        return;
      }
      if (newState) {
        HowlerManager.playHowl(this.currentTrack);
      } else {
        HowlerManager.pauseHowl(this.currentTrack);
      }
    },
    currentTrack(newTrack, oldTrack) {
      if (oldTrack) {
        HowlerManager.stopHowl(oldTrack);
      }
      if (newTrack) {
        HowlerManager.createHowl(newTrack);
        if (this.isPlaying) {
          HowlerManager.playHowl(newTrack);
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.live-listening-player-bar__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;

  .song-info {
    .song-info-item {
      display: block;
      font-size: 0.9rem;
      text-transform: none;
      font-weight: normal;
      color: white;
    }
    .song-info-item + .song-info-item {
      margin-top: 0.2rem;
    }
  }

  .player-bar__toggle {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  .player-bar__toggle:hover {
    background-color: #0056b3;
  }

  .timestamp-info {
    font-size: 0.9rem;
    text-transform: none;
    font-weight: normal;
    color: white;
  }
}

.player-bar--player {
  padding: 1rem;
  background-color: rgba($atmo-purple--extra-dark, 0.6);
  backdrop-filter: blur(5px);
  min-height: 5rem;
}

.player-bar--live-listening {
  background: none;
}

/* Disabled style for non-sync source users */
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
