import Axios from 'axios';

export function callBusinessProfilesShow(businessProfileId, queryParams = {}) {
    return Axios.get(`/api/v1/business_profiles/${businessProfileId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callBusinessProfilesUpdate(businessProfileId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/business_profiles/${businessProfileId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}