<template>
  <div class="label-profile" data-cy="label-profile">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">Label Profile</h2>
    </div> -->
    <atmo-back-link></atmo-back-link>
    <div class="label-profile-grid">
      <label-details-container
        v-if="user && profile"
        :user="user"
        :isMyProfile="isMyProfile"
        :dashboardRoute="{ name: 'label_dashboards.show', params: { labelProfileId: $route.params.labelProfileId } }"
        :editProfileRoute="{ name: 'label_profiles.edit', params: { labelProfileId: $route.params.labelProfileId } }"
        :donateRoute="{ name: 'donate', params: { profileId: $route.params.labelProfileId, profileType: 'label_profiles' } }"
      >
        <template v-slot:label-type>Label Type:</template>
        <template v-slot:label-name>{{ profile.label_type }}</template>
      </label-details-container>
      <ProfileLayout v-if="user && profileRoutes" :user="user" :smallCardRoutes="profileRoutes.smallCards" :largeCardRoutes="profileRoutes.largeCards" />
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import LabelDetailsContainer from "@/components/profiles/label-details-container";
import ProfileLayout from "@/components/profiles/profile-layout";
import { mapActions, mapGetters } from "vuex";
import { getFeaturedImage } from "@/helpers/utilityFunctions";

export default {
  components: {
    AtmoBackLink,
    LabelDetailsContainer,
    ProfileLayout,
  },
  computed: {
    ...mapGetters("profiles", ["getProfile", "getUser", "isLoading", "getProfileType", "getProfileRoutes"]),
    ...mapGetters("userFriendships", ["getUserFriendships", "getMutualFriends"]),
    ...mapGetters("userRelationships", ["getArtistSubscriptions", "getRelationships", "getCurrentUserIsFollowing", "getRelationshipId"]),
    profile() {
      return this.getProfile;
    },
    user() {
      return this.getUser;
    },
    currentUser() {
      return this.$store.state.user;
    },
    userFriendships() {
      return this.getUserFriendships;
    },
    artistSubscriptions() {
      return this.getArtistSubscriptions;
    },
    isMyProfile() {
      return this.$store.state.currentUserProfileId === this.labelProfileId;
    },
    labelProfileId() {
      return Number(this.$route.params.labelProfileId);
    },
    currentFriendship() {
      return this.userFriendships.find((friendship) => friendship.friend_id === this.currentUser.id);
    },
    currentUserCanFriendRequest() {
      const currentUserIsAlreadyFriend = this.currentFriendship;
      return !this.isMyProfile && !currentUserIsAlreadyFriend;
    },
    friendRequestPending() {
      return this.currentFriendship && this.currentFriendship.state === "pending";
    },
    currentUserIsFollowing() {
      return this.getCurrentUserIsFollowing;
    },
    mutualFriends() {
      return this.getMutualFriends;
    },
    profileRoutes() {
      return this.getProfileRoutes;
    },
  },
  async created() {
    await this.fetchProfile({ profileType: "LabelProfile", profileId: this.$route.params.labelProfileId });
    await this.fetchUserFriendships(this.labelProfileId);
    await this.fetchFollowedRelationships(this.labelProfileId);
    await this.fetchFollowerRelationships(this.$store.state.currentUserProfileId);
    await this.updateFollowingState(this.labelProfileId);
    this.setupActionCables();
    this.$store.commit("setHudHeader", "Label Profile");
  },
  methods: {
    ...mapActions("profiles", ["fetchProfile"]),
    ...mapActions("userFriendships", ["fetchUserFriendships", "fetchMutualFriends"]),
    ...mapActions("userRelationships", ["fetchFollowerRelationships", "fetchFollowedRelationships", "updateFollowingState", "connectToUserRelationships"]),
    getFeaturedImage,
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
    setupActionCables() {
      this.$store.dispatch("userFriendships/connectToUserFriendships");
      this.$store.dispatch("userRelationships/connectToUserRelationships");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.label-profile {
  margin: 0 75px;

  .grid-scroll-wrap {
    max-height: 22rem;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label-profile-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: 18rem 1fr;
  }
}
</style>
