<template>
  <div class="ad-campaign-dashboard">
    <div class="header-container">
      <h2 v-if="adData" class="atmo-page-header">
        {{ adData?.title || "Ad Campaign Dashboard" }}
      </h2>
    </div>

    <div v-if="adData">
      <!-- Ad Performance Section -->
      <div class="metrics-section">
        <h3>Performance Overview</h3>
        <p>
          Total Impressions: <strong>{{ adData?.ad_impressions?.length || 0 }}</strong>
        </p>
        <p>
          Clicks: <strong>{{ adData?.ad_clicks?.length || 0 }}</strong>
        </p>
        <p>
          CTR: <strong>{{ ctr.toFixed(2) }}%</strong>
        </p>
        <p>
          Remaining Budget: <strong>${{ budgetRemaining.toFixed(2) }}</strong>
        </p>
      </div>

      <!-- Budget and Spend Section -->
      <div class="metrics-section">
        <h3>Budget and Spend</h3>
        <p>
          Daily Budget: <strong>${{ dailyBudget.toFixed(2) }}</strong>
        </p>
        <p>
          Spend Today: <strong>${{ spendToday.toFixed(2) }}</strong>
        </p>
        <p>
          Total Budget: <strong>${{ adData?.budget || 0 }}</strong>
        </p>
        <p>
          Total Spend: <strong>${{ totalSpend.toFixed(2) }}</strong>
        </p>
      </div>

      <!-- Real-Time Metrics Section -->
      <div class="metrics-section">
        <h3>Real-Time Metrics</h3>
        <p>
          Impressions Today: <strong>{{ impressionsToday }}</strong>
        </p>
        <p>
          CPM: <strong>${{ cpm.toFixed(2) }}</strong>
        </p>
      </div>

      <!-- Campaign Actions -->
      <div class="campaign-actions">
        <router-link class="action-btn" :to="{ name: 'advertisements.edit', params: { adId: adData?.id || 0 } }"> Edit Ad </router-link>
      </div>
    </div>

    <div v-else>
      <p>Loading campaign data...</p>
    </div>
  </div>
</template>

<script>
import { callDirectoryAdsShow } from "@/helpers/axiosCalls";

export default {
  data() {
    return {
      adData: null,
      ctr: 0,
      cpm: 0,
      budgetRemaining: 0,
      dailyBudget: 0, // Now dynamically set
      spendToday: 0,
      totalSpend: 0,
      impressionsToday: 0,
    };
  },
  methods: {
    async fetchAdDetails(adId) {
      try {
        const adData = await callDirectoryAdsShow(this.$store.state.currentUserId, adId);

        // Ensure defaults if properties are missing
        this.adData = {
          ...adData,
          ad_impressions: adData?.ad_impressions || [],
          ad_clicks: adData?.ad_clicks || [],
          remaining_budget: parseFloat(adData?.remaining_budget) || 0,
        };

        this.calculateMetrics();
      } catch (error) {
        console.error("Error fetching ad details:", error);
        this.adData = null; // Reset in case of failure
      }
    },
    calculateMetrics() {
      if (!this.adData) return;

      const impressions = this.adData.ad_impressions || [];
      const clicks = this.adData.ad_clicks || [];

      // Calculate CTR (Click-Through Rate)
      this.ctr = impressions.length > 0 ? (clicks.length / impressions.length) * 100 : 0;

      // Calculate Total Spend (sum of all impression costs)
      this.totalSpend = impressions.reduce((sum, imp) => sum + parseFloat(imp?.cost || 0), 0);

      // Calculate Budget Remaining (ensure it's a number)
      this.budgetRemaining = this.adData.remaining_budget || 0;

      // Calculate CPM (Cost Per Mille)
      this.cpm = impressions.length > 0 ? (this.totalSpend / impressions.length) * 1000 : 0;

      // Calculate Impressions Today
      const today = new Date().toISOString().slice(0, 10);
      const todayImpressions = impressions.filter((imp) => imp?.created_at?.startsWith(today));
      this.impressionsToday = todayImpressions.length;

      // Calculate Spend Today
      this.spendToday = todayImpressions.reduce((sum, imp) => sum + parseFloat(imp?.cost || 0), 0);

      // Handle Daily Budget Logic (only set if budget_type is daily)
      this.dailyBudget = this.adData.budget_type === "daily" ? parseFloat(this.adData.budget) || 0 : 0;
    },
    pauseCampaign() {
      this.adData.is_live = !this.adData.is_live;
      alert(`Campaign ${this.adData.is_live ? "resumed" : "paused"}.`);
    },
    adjustBudget() {
      alert("Adjust Budget functionality coming soon!");
    },
    viewReport() {
      alert("View Report functionality coming soon!");
    },
  },
  mounted() {
    const adId = this.$route.params.adId || 66; // Replace with actual ID or fallback
    this.fetchAdDetails(adId);
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.ad-campaign-dashboard {
  padding: 1rem;
  border-radius: 8px;
  background-color: $atmo-purple--medium-dark--faded;
  max-width: 800px;
  margin: 1rem auto;
}
.header-container {
  text-align: center;
  margin-bottom: 1rem;
}
.metrics-section {
  margin-bottom: 1rem;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 6px;
}
.metrics-section h3 {
  margin-bottom: 10px;
}
.campaign-actions {
  text-align: center;
  margin-bottom: 1rem;
}
.action-btn {
  padding: 10px 1rem;
  font-size: 14px;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  background: none;
  display: block;
}
.action-btn:hover {
  background-color: $atmo-purple--medium-dark--faded;
}
</style>
