import Axios from 'axios';

export function callDirectoryAdsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/directory_ads`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryAdsShow(userId, adId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/directory_ads/${adId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryAdsCreate(userId, requestData, queryParams = {}) {
    return Axios({
        method: 'post',
        url: `/api/v1/users/${userId}/directory_ads`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
            queryParams
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryAdsUpdate(userId, adId, requestData, queryParams = {}) {
    return Axios({
        method: 'patch',
        url: `/api/v1/users/${userId}/directory_ads/${adId}`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
            queryParams
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryAdsDelete(userId, adId, queryParams = {}) {
    return Axios.delete(`/api/v1/users/${userId}/directory_ads/${adId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}