<template>
  <AtmoModal id="share-post-modal" ref="sharePostModalRef" v-model="$store.state.sharePostModalOpen" title="Share Post" customClass="share-post-modal">
    <header class="atmo-modal__header">
      <span class="atmo-modal__header-side">
        <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png" @click="$store.commit('toggleSharePostModal')" />
      </span>
      <div class="atmo-page-header-container">
        <h2 class="atmo-page-header">Share Post</h2>
      </div>
      <span class="atmo-modal__header-side" />
    </header>
    <div class="share-post-modal__body">
      <div class="body__new-post-input">
        <label class="new-post-input__input-wrapper">
          <div class="new-post-input__avatar-container">
            <div v-if="$store.state.user">
              <div class="atmo-avatar atmo-avatar--small atmo-avatar--border-white" :style="{ 'background-image': 'url(' + getFeaturedImage($store.state.user.images, 'full') + ')' }" />
            </div>
          </div>
          <input v-model="newPostContent" placeholder="Write something…" type="text" class="new-post-input__input" />
        </label>
        <div class="user-billboard__form-controls">
          <div v-for="(image, index) in images" class="user-billboard__pill-container" :key="`image-${index}`">
            <span class="user-billboard__pill">{{ image.name }}</span>
            <div aria-label="Remove" class="user-billboard__pill-remove" @click="removeImage(image)">&times;</div>
          </div>
          <div v-for="(video, index) in videos" class="user-billboard__pill-container" :key="`video-${index}`">
            <span class="user-billboard__pill">{{ video.name }}</span>
            <div aria-label="Remove" class="user-billboard__pill-remove" @click="removeVideo(video)">&times;</div>
          </div>
          <div v-for="(song, index) in $store.state.addSongsModalList" class="user-billboard__pill-container" :key="`song-${index}`">
            <span class="user-billboard__pill">{{ song.name }}</span>
            <div aria-label="Remove" class="user-billboard__pill-remove" @click="removeSong(song)">&times;</div>
          </div>
          <div class="user-billboard__button-group">
            <button class="atmo-button atmo-button--tertiary upload-song" @click="toggleAddSongsModal()">
              <img class="atmo-button__icon" src="@/assets/images/icons/upload_music.png" />
            </button>
            <label for="share-post-file-input" class="atmo-button atmo-button--tertiary post-image-upload">
              <img class="atmo-button__icon" src="@/assets/images/icons/attachment.png" />
            </label>
            <input id="share-post-file-input" type="file" @change="handleFileUpload" style="display: none" />
          </div>
          <input type="submit" class="atmo-button atmo-button--primary" value="Post" @click="submitNewPost()" />
        </div>
      </div>
      <div class="body__shared-post-container">
        <div class="atmo-post">
          <div class="atmo-post__avatar-container">
            <a>
              <div class="atmo-avatar" :style="{ 'background-image': `url(${getFeaturedImage(postUser?.images || [], 'full')})` }" />
            </a>
          </div>
          <div class="atmo-post__post-container">
            <div class="atmo-post__main-content-container">
              <div class="atmo-post__post-header">
                <div class="atmo-post__post-identifier">
                  <span class="atmo-post__post-owner">
                    <a>{{ postUser?.name || "Unknown User" }}</a>
                  </span>
                  <span class="atmo-post__post-date">
                    {{ getDate($store.state.sharePostModalPost.created_at) }}
                  </span>
                </div>
              </div>
              <div class="atmo-post__post-content">
                <div v-if="($store.state.sharePostModalPost.images || []).length > 0" class="post-content__post-pics-container">
                  <img v-for="(postImage, index) in $store.state.sharePostModalPost.images" :key="index" class="post-pics-container__post-pic" :src="postImage.image.url" alt="Post pic" />
                </div>
                <div>{{ $store.state.sharePostModalPost.content || $store.state.sharePostModalPost.text }}</div>
              </div>
              <div v-if="($store.state.sharePostModalPost.song_references || []).length > 0">
                <div class="atmo-post__post-footer" v-for="(songReference, index) in $store.state.sharePostModalPost.song_references" :key="index">
                  <div class="atmo-post__song-actions-popover-container">
                    <song-actions-popover :song="song" placement="left" :actions="['add-to-library', 'add-to-queue', 'add-to-playlist']" />
                  </div>
                  <span class="atmo-post__song-link">
                    {{ songReference.song.name }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import { utilsGetDate, getFeaturedImage } from "@/helpers/utilityFunctions";
import { callImagesCreate, callVideosCreate, callSongReferencesCreate, callPreviewImageCreate, callPostsCreate, callBillboardPostsIndex } from "@/helpers/axiosCalls";
import { mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      isOpen: true,
      newPostContent: "",
      images: [],
      videos: [],
    };
  },
  computed: {
    postUser() {
      return this.$store.state.sharePostModalPost.user || {}; // Default to an empty object
    },
  },
  methods: {
    ...mapActions("accomplishments", ["createUserAccomplishments"]),
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    toggleAddSongsModal() {
      this.$store.commit("toggleAddSongsModal");
    },
    removeSong(song) {
      const indexToRemove = this.$store.state.addSongsModalList.findIndex((item) => item === song);
      const newAddSongsModalSongs = this.$store.state.addSongsModalList.filter((item, index) => index !== indexToRemove);
      this.$store.commit("setAddSongsModalList", newAddSongsModalSongs);
    },
    removeVideo(file) {
      const indexToRemove = this.videos.findIndex((item) => item === file);
      this.videos = this.videos.filter((item, index) => index !== indexToRemove);
    },
    removeImage(file) {
      const indexToRemove = this.images.findIndex((item) => item === file);
      this.images = this.images.filter((item, index) => index !== indexToRemove);
    },
    async handleFileUpload(event) {
      const inputElement = event.target;
      const selectedFile = inputElement.files[0];

      if (!selectedFile) {
        return; // Handle no file selected error
      }

      const imageMimeTypes = ["image/jpeg", "image/png", "image/gif"];
      const videoMimeTypes = ["video/mp4", "video/mpeg", "video/quicktime"];

      if (imageMimeTypes.includes(selectedFile.type)) {
        this.images.push(selectedFile);
      } else if (videoMimeTypes.includes(selectedFile.type)) {
        this.videos.push(selectedFile);
      } else {
        console.error("Invalid file type. Please select a valid image or video file.");
        inputElement.value = null; // Reset the file input
      }
    },
    getPosts() {
      callBillboardPostsIndex(this.$store.state.currentUserId)
        .then((response) => {
          this.posts = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createNewPost(postPayload) {
      const request = callPostsCreate(this.$store.state.currentUserId, { post: postPayload });

      request
        .then(() => {
          this.$toast("Post Created", { type: "success" });
          this.getPosts();
          this.$store.dispatch("userActivities/createUserActivity", {
            user_activity_type_name: "Post Share",
            user_id: this.$store.state.currentUserId,
            activityable_type: "Post",
            activityable_id: request.id,
          });
        })
        .catch((error) => {
          this.$toast("Error Creating Post", { type: "error" });
          console.error(error);
        });

      return request;
    },
    submitNewPost() {
      this.createNewPost({
        content: this.newPostContent,
        original_post_id: this.$store.state.sharePostModalPost.id,
        billboard_id: this.$store.state.currentUserId,
        user_id: this.$store.state.currentUserId,
      }).then(async (response) => {
        const postId = response.id;
        let imagePromises = [];
        if (this.images.length > 0) {
          imagePromises = this.images.map(async (image) => {
            const formData = new FormData();
            formData.append("image", image);
            formData.append("imageable_id", postId);
            formData.append("imageable_type", "Post");
            formData.append("is_featured_image", false);
            formData.append("user_id", this.$store.state.currentUserId);
            return callImagesCreate(formData).catch((error) => console.error(error));
          });
        }

        let songPromises = [];
        if (this.$store.state.addSongsModalList.length > 0) {
          songPromises = this.$store.state.addSongsModalList.map(async (song) => {
            return callSongReferencesCreate({
              songable_id: postId,
              songable_type: "Post",
              song_id: song.id,
              user_id: this.$store.state.currentUserId,
            }).catch((error) => console.error(error));
          });
        }

        let newVideoIds = [];
        let videoPromises = [];
        if (this.videos.length > 0) {
          videoPromises = this.videos.map(async (video) => {
            const formData = new FormData();
            formData.append("video", video);
            formData.append("videoable_id", postId);
            formData.append("videoable_type", "Post");
            formData.append("user_id", this.$store.state.currentUserId);
            formData.append("is_featured_video", true);
            return callVideosCreate(formData)
              .then((response) => newVideoIds.push(response.id))
              .catch((error) => console.error(error));
          });
        }

        Promise.all([...imagePromises, ...songPromises, ...videoPromises]).then(() => {
          // Process preview image creation
          let previewImagePromises = newVideoIds.map(async (videoId) => callPreviewImageCreate({ video_id: videoId }));
          Promise.all([...previewImagePromises]).then(() => {
            this.$toast("Post Saved", { type: "success" });
            this.getPosts();
            this.newPostContent = "";
            this.images = [];
            this.videos = [];
            this.$store.commit("setAddSongsModalList", []);
          });

          // Increment user accomplishments
          this.createUserAccomplishments({
            userId: this.$store.state.currentUserId,
            accomplishmentData: {
              payloads: [
                { user_id: this.$store.state.currentUserId, category: "posts", sub_category: "postNumber" },
                { user_id: this.$store.state.currentUserId, category: "photos", sub_category: "photoNumber" },
                { user_id: this.$store.state.currentUserId, category: "videos", sub_category: "videoNumber" },
                { user_id: this.$store.state.currentUserId, category: "shares", sub_category: "songShareNumber" },
              ],
            },
          });
        });

        if (this.images.length === 0 && this.$store.state.addSongsModalList.length === 0 && this.videos.length === 0) {
          this.getPosts();
          this.newPostContent = "";
        }
        this.$store.commit("toggleSharePostModal");
      });
    },
    getDate(date) {
      return utilsGetDate(date);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

#share-post-modal {
  .atmo-modal-content {
    border-radius: 5px;
    border: none;
  }

  .ps {
  }
}

.share-post-modal {
  .modal-lg.modal-dialog {
  }

  .atmo-modal__header {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    margin-top: 2rem;
  }

  .atmo-modal__header-side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;

    img {
      height: 1.5rem;
    }
  }

  .atmo-page-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .body__new-post-input {
    margin-bottom: 1rem;
    width: 100%;
    padding: 0rem 1rem 0rem 1rem;
  }

  .new-post-input__input-wrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }

  &__input,
  input[type="text"] {
    flex-grow: 1;
    border: 1px solid white;
    min-width: 400px;
    height: 35px;
    border-radius: 5px;
    background: transparent;
    margin: 0;
    box-shadow: none;
    padding: 0 10px;
    font-size: 1em;
    font-weight: 300;
    color: white;

    &:focus {
      outline-color: $atmo-purple--medium;
    }
  }

  .body__shared-post-container {
    width: 100%;
    padding: 0rem 1rem 0rem 0rem;

    .atmo-post__main-content-container {
      background-color: rgba(190, 167, 221, 0.4);
    }

    .post-pics-container__post-pic {
      cursor: unset;
    }
  }
}
</style>
