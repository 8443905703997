<template>
  <div class="user-profile" data-cy="user-profile">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">User Profile</h2>
    </div> -->
    <atmo-back-link></atmo-back-link>
    <div class="user-profile-grid">
      <BusinessDetailsContainer
        v-if="user && profile"
        :user="user"
        :isMyProfile="isMyProfile"
        :dashboardRoute="{ name: 'business_dashboards.show', params: { businessProfileId: $route.params.businessProfileId } }"
        :editProfileRoute="{ name: 'business_profiles.edit', params: { businessProfileId: $route.params.businessProfileId } }"
        :donateRoute="{ name: 'donate', params: { profileId: $route.params.businessProfileId, profileType: 'business_profiles' } }"
      >
        <template v-slot:label-type>Business Type:</template>
        <template v-slot:label-name>{{ profile.business_profile_type.name }}</template>
      </BusinessDetailsContainer>
      <ProfileLayout v-if="user && profileRoutes" :user="user" :smallCardRoutes="profileRoutes.smallCards" :largeCardRoutes="profileRoutes.largeCards" />
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import ProfileLayout from "@/components/profiles/profile-layout";
import BusinessDetailsContainer from "@/components/profiles/business-details-container";
import { mapGetters, mapActions } from "vuex";
import { callPageVisitsCreate } from "@/helpers/axiosCalls";
import { UAParser } from "ua-parser-js";

export default {
  components: {
    AtmoBackLink,
    ProfileLayout,
    BusinessDetailsContainer,
  },
  computed: {
    ...mapGetters("profiles", ["getProfile", "getUser", "getProfileRoutes"]),
    user() {
      return this.getUser;
    },
    profile() {
      return this.getProfile;
    },
    profileRoutes() {
      return this.getProfileRoutes;
    },
    isMyProfile() {
      return this.$store.state.currentUserProfileId === this.businessProfileId;
    },
    businessProfileId() {
      return Number(this.$route.params.businessProfileId);
    },
    currentFriendship() {
      return this.userFriendships.find((friendship) => friendship.friend_id === this.currentUser.id);
    },
    currentUserCanFriendRequest() {
      const currentUserIsAlreadyFriend = this.currentFriendship;
      return !this.isMyProfile && !currentUserIsAlreadyFriend;
    },
    friendRequestPending() {
      return this.currentFriendship && this.currentFriendship.state === "pending";
    },
    currentUserIsFollowing() {
      return this.getCurrentUserIsFollowing;
    },
    mutualFriends() {
      return this.getMutualFriends;
    },
  },
  methods: {
    ...mapActions("profiles", ["fetchProfile"]),
    async createPageVisit() {
      const parser = new UAParser();
      const result = parser.getResult();

      await callPageVisitsCreate({
        visitable_type: "BusinessProfile",
        visitable_id: this.$route.params.businessProfileId,
        user_id: this.$store.state.currentUserId,
        device_type: result.device.type || "desktop",
        browser: result.browser.name,
        os: result.os.name,
        page_url: window.location.href,
      });
    },
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
  },
  async created() {
    await this.fetchProfile({ profileType: "BusinessProfile", profileId: this.$route.params.businessProfileId });
    this.$store.commit("setHudHeader", "Business Profile");
    await this.createPageVisit();
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.user-profile {
  margin: 0 75px;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .user-profile-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: 18rem 1fr;
  }
}
</style>
