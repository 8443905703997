<template>
  <div class="hud-header-search">
    <div class="atmo-input-container">
      <input class="atmo-input atmo-input--search hud-header-search__input" type="text" v-model="searchQuery" placeholder="Search..." @keypress.enter="onSearchSubmit" />
      <span v-if="searchQuery" class="hud-header-search__hide-results-icon" @click="clearSearch">
        <img style="cursor: pointer" src="@/assets/images/icons/close--pink.png" />
      </span>
    </div>
  </div>
</template>

<script>
import { callAtmoSearchResultsSearch } from "@/helpers/axiosCalls";
import { mapMutations, mapState } from "vuex";

export default {
  computed: {
    ...mapState(["atmoSearchQuery"]),
    searchQuery: {
      get() {
        return this.atmoSearchQuery;
      },
      set(value) {
        this.setAtmoSearchQuery(value);
      },
    },
  },
  methods: {
    ...mapMutations(["toggleAtmoSearchResultsModal", "setAtmoSearchResults", "setAtmoSearchQuery", "clearAtmoSearchQuery"]),

    async onSearchSubmit() {
      if (!this.searchQuery.trim()) {
        return;
      }

      try {
        const response = await callAtmoSearchResultsSearch({ query: this.searchQuery });

        const searchResults = {
          users: response.users || [],
          artists: response.artists || [],
          songs: response.songs || [],
          genres: response.genres || [],
          moods: response.moods || [],
          albums: response.albums || [],
          occasions: response.occasions || [],
          labels: response.labels || [],
          businesses: response.businesses || [],
          publishers: response.publishers || [],
        };

        this.setAtmoSearchResults(searchResults);
        this.toggleAtmoSearchResultsModal();
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    },

    clearSearch() {
      this.clearAtmoSearchQuery();
      this.clearResults();
    },

    clearResults() {
      this.setAtmoSearchResults({
        users: [],
        artists: [],
        songs: [],
        genres: [],
        moods: [],
        albums: [],
        occasions: [],
        labels: [],
        businesses: [],
        publishers: [],
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.hud-header-search {
  position: relative;
  margin-left: 20px;

  &__input {
    width: 200px;
    cursor: text;
  }

  &__hide-results-icon {
    margin: 5px 0 0 5px;

    img {
      height: 15px;
    }
  }
}
</style>
