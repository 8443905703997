<template>
  <div class="atmo-artist-songs">
    <div class="atmo-artist-songs__sidebar" @click="$emit('on-artist-click', artist)">
      <img class="atmo-artist-songs__artist-image" :src="artist.artist_image" :alt="artist.artist_name" />
      <div class="atmo-artist-songs__name">
        {{ artist.artist_name }}
      </div>
    </div>
    <div v-if="artist.songs == null">Loading...</div>
    <atmo-library-songs v-else :songs="artist.songs" :component-location="componentLocation" />
  </div>
</template>

<script>
import AtmoLibrarySongs from "@/components/atmo-library/library-songs";

export default {
  components: {
    AtmoLibrarySongs,
  },
  props: {
    artist: {
      type: Object,
      required: true,
    },
    componentLocation: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

.atmo-artist-songs {
  display: flex;

  & + & {
    margin-top: 2rem;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-left: 20px;

    &:hover {
      opacity: 0.6;
    }
  }

  &__artist-image {
    height: 15rem;
    width: 15rem;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.7);
    margin-top: 2.2rem;
  }

  &__name {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
  }
}
</style>
