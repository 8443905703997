import {
    callChatRoomsIndex,
    callChatMessagesIndex,
    callUserFriendshipsIndex
} from '@/helpers/axiosCalls';

export default {
    namespaced: true,
    state: {
        debug: false,
        maxChatOpen: false,
        chatImageModalOpen: false,
        chatImageModalUrl: "",
        currentChatTitleData: {
            name: "Messages",
            roomType: "group",
            profileType: null,
            profileId: null
        },
        currentChatRoom: {},
        chatRooms: [],
        currentChatRoomMessages: [],
        chatUserFriendships: [],
        createGroupChatModalOpen: false,
        deleteGroupChatModalOpen: false,
        groupChatSettingsModalOpen: false,
        chatCurrentView: "friends",
        currentChatRoomUsers: [],
    },
    mutations: {
        toggleMaxChatOpen(state) {
            state.maxChatOpen = !state.maxChatOpen;
            if (state.debug) console.log('Mutation: toggleMaxChatOpen', state.maxChatOpen);
        },
        showChatImageModal(state) {
            if (state.debug) console.log('Mutation: showChatImageModal');
            state.chatImageModalOpen = true;
        },
        hideChatImageModal(state) {
            if (state.debug) console.log('Mutation: hideChatImageModal');
            state.chatImageModalOpen = false;
        },
        setChatImageModalUrl(state, value) {
            if (state.debug) console.log('Mutation: setChatImageModalUrl', value);
            state.chatImageModalUrl = value;
        },
        setCurrentChatTitleData(state, value) {
            if (state.debug) console.log('Mutation: setCurrentChatTitleData', value);
            state.currentChatTitleData = value;
        },
        setCurrentChatRoom(state, value) {
            if (state.debug) console.log('Mutation: setCurrentChatRoom', value);
            state.currentChatRoom = value;
        },
        setAllChatRooms(state, rooms) {
            if (state.debug) console.log('Mutation: setAllChatRooms', rooms);
            state.chatRooms = rooms;
        },
        setChatRoomMessages(state, messages) {
            if (state.debug) console.log('Mutation: setChatRoomMessages', messages);
            state.currentChatRoomMessages = messages;
        },
        setChatUserFriendships(state, userFriendships) {
            if (state.debug) console.log('Mutation: setChatUserFriendships', userFriendships);
            state.chatUserFriendships = userFriendships;
        },
        toggleCreateGroupChatModal(state) {
            if (state.debug) console.log('Mutation: toggleCreateGroupChatModal');
            state.createGroupChatModalOpen = !state.createGroupChatModalOpen;
        },
        toggleDeleteGroupChatModal(state) {
            if (state.debug) console.log('Mutation: toggleDeleteGroupChatModal');
            state.deleteGroupChatModalOpen = !state.deleteGroupChatModalOpen;
        },
        toggleGroupChatSettingsModal(state) {
            if (state.debug) console.log('Mutation: toggleGroupChatSettingsModal');
            state.groupChatSettingsModalOpen = !state.groupChatSettingsModalOpen;
        },
        changeChatView(state, newView) {
            if (state.debug) console.log('Mutation: changeChatView', newView);
            state.chatCurrentView = newView;
        },
        setRoomUsers(state, updatedUsers) {
            if (state.debug) console.log('Mutation: setRoomUsers', updatedUsers);
            state.currentChatRoomUsers = updatedUsers;
        },
    },
    actions: {
        async getChatRooms({ commit, state }, userId) {
            if (state.debug) console.log("🟡 Fetching non-friendship chat rooms for user:", userId);

            try {
              const response = await callChatRoomsIndex({
                user_id: userId,
                rooms_for_atmo_chat: "true",
              });
          
              console.log("🟢 Non-Friendship Chat Rooms:", response);
              commit("setAllChatRooms", response);
            } catch (error) {
              console.error("🔴 Error fetching non-friendship chat rooms:", error);
            }
        },
        getRoomMessages({ commit, state }, roomId) {
            if (state.debug) console.log('Action: getRoomMessages', roomId);        
            callChatMessagesIndex(roomId)
                .then((response) => {
                    if (state.debug) console.log('Response: getRoomMessages', response);
                    commit("setChatRoomMessages", response);
                })
                .catch((error) => {
                    console.error('Error: getRoomMessages', error);
                });
        },
        getUserFriendships({ commit, state }, userId) {
            if (state.debug) console.log('Action: getUserFriendships', userId);
            callUserFriendshipsIndex({ user_id: userId })
                .then((response) => {
                    if (state.debug) console.log('Response: getUserFriendships', response);
                    commit('setChatUserFriendships', response);
                })
                .catch((error) => {
                    console.error('Error: getUserFriendships', error);
                });
        }
    },
    getters: {
        chatRoomsByType: state => type => {
            if (state.debug) console.log(`Getter: chatRoomsByType (${type})`);
            return state.chatRooms.filter(room => room.room_type === type);
        },
        groupChatRooms: state => {
            if (state.debug) console.log('Getter: groupChatRooms');
            return state.chatRooms.filter(room => room.room_type === "group");
        },
        friendChatRooms: state => {
            if (state.debug) console.log('Getter: friendChatRooms');
            return state.chatRooms.filter(room => room.room_type === "friend");
        },
        directoryAdChatRooms: state => {
            if (state.debug) console.log('Getter: directoryAdChatRooms');
            return state.chatRooms.filter(room => room.room_type === "directory_ad");
        },
        directoryProfileChatRooms: state => {
            if (state.debug) console.log('Getter: directoryProfileChatRooms');
            return state.chatRooms.filter(room => room.room_type === "directory_profile");
        },
        isMaxChatOpen: state => state.maxChatOpen,
        isChatImageModalOpen: state => state.chatImageModalOpen,
        chatImageModalUrl: state => state.chatImageModalUrl,
        currentChatTitleData: state => state.currentChatTitleData,
        currentChatRoom: state => state.currentChatRoom,
        currentChatRoomMessages: state => state.currentChatRoomMessages,
        chatUserFriendships: state => state.chatUserFriendships,
    }
};
