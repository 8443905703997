import {
    callSongReferencesIndex,
    callSongReferencesCreate,
    callSongReferencesDelete
} from '@/helpers/axiosCalls';
import { saveToLocalStorage, getFromLocalStorage } from '@/helpers/utilityFunctions.js'; // Adjust the path as necessary

export default {
    namespaced: true,
    state: {
        debug: false,
        queueLoading: true,
        queueProgress: 0,
        atmoQueue: [],
        atmoQueueEngaged: true,
        queueOpen: false,
        queueMode: 'queue', // 'queue', 'radio', 'liveListening'
        activeQueue: [],
    },
    mutations: {
        removeSongFromAtmoQueue(state, songReference) {
            // Filter out the removed song from the atmoQueue and activeQueue
            state.atmoQueue = state.atmoQueue.filter(songRef => songRef.song.id !== songReference.id);
            state.activeQueue = state.activeQueue.filter(song => song.id !== songReference.id);

            const currentSong = this.state.player.currentSong;

            if (currentSong && currentSong.id === songReference.song_id) {
                this.dispatch('player/stopCurrentSong', null, { root: true });
                this.commit('player/setCurrentSong', null);
            }

            if (state.debug) console.log('Song removed from both atmoQueue and activeQueue.');
        },
        setAtmoQueue(state, queue) {
            if (state.debug) console.log('Mutation: setAtmoQueue - queue:', queue);
            // Ensure that each item in atmoQueue has a song attribute
            state.atmoQueue = queue;

            state.queueLoading = false;

            // The activeQueue is flattened, only consisting of songs
            state.activeQueue = state.atmoQueue.map(ref => ref.song);
        },
        setQueueProgress(state, progress) {
            if (state.debug) console.log('Mutation: setQueueProgress - progress:', progress);
            state.queueProgress = progress;
        },    
        setQueueLoading(state, isLoading) {
            if (state.debug) console.log('Mutation: setQueueLoading - isLoading:', isLoading);
            state.queueLoading = isLoading;
        },
        engageLiveListeningQueue(state) {
            if (state.debug) console.log('Mutation: engageLiveListeningQueue - Engaging live listening queue');
            state.atmoQueueEngaged = false;
        },
        engageAtmoQueue(state) {
            if (state.debug) console.log('Mutation: engageAtmoQueue - Engaging atmo queue');
            state.atmoQueueEngaged = true;
        },
        toggleQueueOpen(state) {
            if (state.debug) console.log('Mutation: toggleQueueOpen - current state:', state.queueOpen);
            state.queueOpen = !state.queueOpen;
            if (state.debug) console.log('Mutation: toggleQueueOpen - new state:', state.queueOpen);
        },
        setQueueMode(state, mode) {
            if (state.debug) console.log('Mutation: setQueueMode - mode:', mode);
            state.queueMode = mode;
            state.activeQueue = mode === 'queue' ? state.atmoQueue : state.activeQueue;
        },
        setActiveQueue(state, queue) {
            if (state.debug) console.log('Mutation: setActiveQueue - queue:', queue);

            // Flatten the queue if necessary: only include the song object if it's nested, otherwise use the item itself
            state.activeQueue = queue.map(item => item.song || item);
        },
    },
    actions: {
        async addToQueueFromPopover({ dispatch, state, rootState }, { song }) {
            try {
                // Create the song reference
                const newSongReference = await callSongReferencesCreate({
                    songable_id: rootState.currentUserId,
                    songable_type: 'AtmoQueue',
                    song_id: song.id,
                    user_id: rootState.currentUserId,
                });

                console.log("newSongReference", newSongReference);

                // Add the new song reference to the end of the atmoQueue
                const updatedQueue = [...state.atmoQueue, newSongReference];
                state.atmoQueue = updatedQueue;
                state.activeQueue = updatedQueue.map(ref => ref.song);

                // Save the new order in local storage
                let savedOrder = getFromLocalStorage('queueOrder') || [];
                savedOrder = [...savedOrder, newSongReference.song_id];
                saveToLocalStorage('queueOrder', savedOrder);

                // Fetch the updated atmoQueue to ensure consistency
                await dispatch('getAtmoQueue');

                console.log('Song successfully added to the end of atmoQueue.');

            } catch (error) {
                console.error('Failed to add song to atmoQueue:', error);
            }
        },
        async addPlaylistToQueue({ commit, dispatch, state, rootState }, { playlist }) {
            try {          
              if (!playlist.song_references || playlist.song_references.length === 0) {
                console.warn("Playlist is empty or has no song references.");
                return;
              }
          
              // Create an array to store the newly created song references
              const newSongReferences = [];
          
              // Loop through each song in the playlist and add to the queue
              for (const songRef of playlist.song_references) {
                const song = songRef.song;
                
                if (!song || !song.id) {
                  console.warn("Skipping invalid song reference:", songRef);
                  continue;
                }
          
                // Create the song reference in the AtmoQueue
                const newSongReference = await callSongReferencesCreate({
                  songable_id: rootState.currentUserId,
                  songable_type: "AtmoQueue",
                  song_id: song.id,
                  user_id: rootState.currentUserId,
                });
          
                console.log("newSongReference", newSongReference);
                newSongReferences.push(newSongReference);
              }
          
              if (newSongReferences.length === 0) {
                console.warn("No valid songs were added to the queue.");
                return;
              }
          
              // Add the new song references to the end of the atmoQueue
              const updatedQueue = [...state.atmoQueue, ...newSongReferences];
              state.atmoQueue = updatedQueue;
              state.activeQueue = updatedQueue.map(ref => ref.song);
          
              // Save the new order in local storage
              let savedOrder = getFromLocalStorage("queueOrder") || [];
              savedOrder = [...savedOrder, ...newSongReferences.map(ref => ref.song_id)];
              saveToLocalStorage("queueOrder", savedOrder);
          
              // Fetch the updated atmoQueue to ensure consistency
              await dispatch("getAtmoQueue");
          
              console.log("Playlist successfully added to the atmoQueue.");
            } catch (error) {
              console.error("Failed to add playlist to atmoQueue:", error);
            }
        },          
        async removeFromAtmoQueue({ commit, dispatch, state }, songReference) {
            try {
                // Remove the song reference
                await callSongReferencesDelete(songReference.id);
                console.log(`Song with ID ${songReference.id} removed`);

                // Fetch the updated queue to ensure real-time sync
                await dispatch('getAtmoQueue');

                // Update activeQueue to reflect the changes
                commit('removeSongFromAtmoQueue', songReference);

                console.log('Queue updated after song removal.');

            } catch (error) {
                console.error('Failed to remove song from atmoQueue:', error);
            }
        },
        async addSongToAtmoQueue({ dispatch, state, rootState }, song) {
            try {
                // Create the song reference
                const newSongReference = await callSongReferencesCreate({
                    songable_id: rootState.currentUserId,
                    songable_type: 'AtmoQueue',
                    song_id: song.id,
                    user_id: rootState.currentUserId,
                });

                console.log("newSongReference", newSongReference);

                // Add the new song reference to the atmoQueue immediately
                const updatedQueue = [newSongReference, ...state.atmoQueue];
                state.atmoQueue = updatedQueue;
                state.activeQueue = updatedQueue.map(ref => ref.song);

                // Reorder queue in local storage
                let savedOrder = getFromLocalStorage('queueOrder') || [];
                savedOrder = [newSongReference.song_id, ...savedOrder];
                saveToLocalStorage('queueOrder', savedOrder);

                // Fetch the updated atmoQueue to ensure consistency
                await dispatch('getAtmoQueue');

                console.log('Song successfully added and atmoQueue updated.');

            } catch (error) {
                console.error('Failed to add song to atmoQueue:', error);
            }
        },
        async getAtmoQueue({ commit, state, rootState }) {
            if (state.debug) console.log('Action: getAtmoQueue - rootState.currentUserId:', rootState.currentUserId);
            commit('setQueueLoading', true);
            commit('setQueueProgress', 10); // Initial progress

            try {
                // Simulate a short delay to see progress (remove in production)
                await new Promise(resolve => setTimeout(resolve, 500));
        
                // Fetch the original song references
                const queue = await callSongReferencesIndex({
                    songable_id: rootState.currentUserId,
                    songable_type: 'AtmoQueue',
                });
        
                commit('setQueueProgress', 30); // Progress update after fetching data
        
                if (state.debug) console.log('Action: getAtmoQueue - queue fetched:', queue);
        
                // Simulate a short delay to allow progress to show
                await new Promise(resolve => setTimeout(resolve, 500));
        
                // Retrieve the saved order from localStorage
                const savedOrder = getFromLocalStorage('queueOrder');
                if (state.debug) console.log('Action: getAtmoQueue - savedOrder from localStorage:', savedOrder);
                let reorderedQueue;
                if (savedOrder && savedOrder.length > 0) {
                    // Reorder the queue based on the saved order
                    reorderedQueue = savedOrder
                        .map(songId => queue.find(songReference => songReference.song.id === songId))
                        .filter(songReference => songReference);// Filter out undefined if songId does not exist in current queue

                        if (state.debug) console.log('Action: getAtmoQueue - reorderedQueue:', reorderedQueue);
                } else {
                    reorderedQueue = queue; // Use the fetched queue directly if no saved order exists
                }
        
                commit('setQueueProgress', 60); // Progress update after reordering
        
                await new Promise(resolve => setTimeout(resolve, 500)); // Simulate delay
        
                // Set the atmoQueue with the reordered original queue
                commit('setAtmoQueue', reorderedQueue);
        
                // Set the activeQueue with the flattened reordered queue
                commit('setActiveQueue', reorderedQueue.map(songReference => songReference.song));
        
                commit('setQueueProgress', 90); // Almost done
            } catch (error) {
                console.error('Action: getAtmoQueue - Failed to fetch queue:', error);
                commit('setQueueProgress', 0); // Reset progress on failure
            } finally {
                setTimeout(() => {
                    commit('setQueueLoading', false);
                    commit('setQueueProgress', 100); // Ensure full progress before finishing
                }, 500);
            }
        },
        saveQueueOrder({ state }) {
            if (state.debug) console.log('Action: saveQueueOrder - Saving queue order');
            const order = state.atmoQueue.map(song => song.song.id);
            if (state.debug) console.log('Action: saveQueueOrder - Order to save:', order);

            saveToLocalStorage('queueOrder', order, 60); // Save for 60 minutes
        },
        setQueueMode({ commit, state }, mode) {
            if (state.debug) console.log('Action: setQueueMode - mode:', mode);
            commit('setQueueMode', mode);
            if (mode === 'queue') {
                commit('setActiveQueue', state.atmoQueue);
            }
        },
    },
    getters: {
        activeQueue: (state) => {
            if (state.debug) console.log('Getter: activeQueue - data:', state.activeQueue);
            return state.activeQueue;
        },
        atmoQueue: (state) => {
            if (state.debug) console.log('Getter: atmoQueue - data:', state.atmoQueue);
            return state.atmoQueue;
        },
        queueIsEmpty: (state) => {
            const isEmpty = state.atmoQueue.length === 0;
            if (state.debug) console.log('Getter: queueIsEmpty - result:', isEmpty);
            return isEmpty;
        },
        queueProgress: (state) => state.queueProgress,
        queueLoading: (state) => {
            if (state.debug) console.log('Getter: queueLoading - state:', state.queueLoading);
            return state.queueLoading;
        },
        queueOpen: (state) => {
            if (state.debug) console.log('Getter: queueOpen - state:', state.queueOpen);
            return state.queueOpen;
        },
        queueMode: (state) => {
            if (state.debug) console.log('Getter: queueMode - state:', state.queueMode);
            return state.queueMode;
        },
    },
};
