import Axios from 'axios';

export function callVideosIndex(queryParams = {}) {
    return Axios.get(`/api/v1/videos`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callVideosShow(videoId, queryParams = {}) {
    return Axios.get(`/api/v1/videos/${videoId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callVideosCreate(requestData, queryParams = {}) {
    return Axios({
        method: 'post',
        url: `/api/v1/videos`,
        data: requestData,
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
            queryParams
        }
    })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });

}

export function callVideosUpdate(videoId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/videos/${videoId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callVideosDelete(videoId, queryParams = {}) {
    return Axios.delete(`/api/v1/videos/${videoId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPreviewImageCreate(requestData, queryParams = {}) {
    return Axios.post('/api/v1/generate-preview-image', requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });

}