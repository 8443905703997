<template>
  <AtmoModal id="music-profile" v-model="$store.state.musicProfiles.musicProfileModalOpen" title="Music Profile" @opened="openMusicProfile" customClass="music-profile">
    <ModalHeader heading="Music Profile" marginBottom="2rem" />
    <img class="music-profile__close" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Profile" @click="toggleMusicProfileOpen" />

    <div class="music-profile__loading-container" v-if="!isDataReady">
      <h3>Loading Data...</h3>
    </div>

    <div class="music-profile__data-container" v-if="isDataReady">
      <div class="data-container__lib-habits-plays-container">
        <!-- Library Composition Chart -->
        <div class="lib-habits-plays-container__lib-comp-container">
          <div class="chart-header">Library Composition</div>
          <div v-if="libCompositionSeries.length > 0">
            <apexchart type="pie" width="380" :options="libCompositionOptions" :series="libCompositionSeries" />
          </div>
          <div v-else class="no-data-message">
            <h4>No data available</h4>
          </div>
        </div>

        <!-- Listening Habits Chart -->
        <div class="lib-habits-plays-container__listening-habits-container">
          <div class="chart-header">Listening Habits</div>
          <div v-if="listeningHabitsSeries.length > 0">
            <apexchart type="donut" width="380" :options="listeningHabitsOptions" :series="listeningHabitsSeries" />
          </div>
          <div v-else class="no-data-message">
            <h4>No data available</h4>
          </div>
        </div>

        <!-- Play History Chart -->
        <div class="lib-habits-plays-container__attribute-plays-container">
          <div class="chart-header">Play History</div>
          <div v-if="attributePlaysSeries.length > 0">
            <apexchart type="area" :options="attributePlaysOptions" :series="attributePlaysSeries" />
          </div>
          <div v-else class="no-data-message">
            <h4>No data available</h4>
          </div>
        </div>
      </div>

      <!-- Friends' Music Tastes Chart -->
      <div class="data-container__friends-misc-stats-container">
        <div class="friends-misc-stats-container__friends-music-container">
          <div class="chart-header">Friends' Music Tastes</div>
          <div v-if="friendsTastesSeries.length > 0">
            <apexchart type="bubble" height="380" width="700" :options="friendsTastesOptions" :series="friendsTastesSeries" />
          </div>
          <div v-else class="no-data-message">
            <h4>No data available</h4>
          </div>
        </div>

        <div class="friends-misc-stats-container__misc-stats-container">
          <!-- Most Played Songs Section -->
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Most Played Songs</div>
            <ul v-if="mostPlayedSongs.length > 0" class="misc-stats-wrap__list">
              <li v-for="song in mostPlayedSongs" :key="song.song_id" class="list-item">
                <router-link class="song-link" v-if="song" :to="{ name: 'songs.show', params: { songId: song.song_id } }" style="display: contents">
                  {{ song.title }}
                </router-link>
                <span> by </span>
                <router-link class="artist-link" v-if="song.artist_id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: song.artist_id } }" style="display: contents">
                  {{ song.artist }}
                </router-link>
                <span> - {{ song.number_of_plays }} plays</span>
              </li>
            </ul>
            <div v-else class="no-data-message">
              <h4>No data available</h4>
            </div>
          </div>

          <!-- Most Played Artists Section -->
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Most Played Artists</div>
            <ul v-if="mostPlayedArtists.length > 0" class="misc-stats-wrap__list">
              <li v-for="artist in mostPlayedArtists" :key="artist.artist_id">
                <router-link class="artist-link" v-if="artist.artist_id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: artist.artist_id } }">
                  {{ artist.name }}
                </router-link>
                - {{ artist.number_of_plays }} plays
              </li>
            </ul>
            <div v-else class="no-data-message">
              <h4>No data available</h4>
            </div>
          </div>

          <!-- Suggested Songs Section -->
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Suggested Songs</div>
            <ul v-if="suggestedSongs.length > 0" class="misc-stats-wrap__list">
              <li v-for="song in suggestedSongs" :key="song.song_id">
                <router-link class="song-link" v-if="song" :to="{ name: 'songs.show', params: { songId: song.song_id } }">
                  {{ song.name }}
                </router-link>
                by
                <router-link class="artist-link" v-if="song.artist_id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: song.artist_id } }">
                  {{ song.artist }}
                </router-link>
              </li>
            </ul>
            <div v-else class="no-data-message">
              <h4>No data available</h4>
            </div>
          </div>

          <!-- Suggested Artists Section -->
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Suggested Artists</div>
            <ul v-if="suggestedArtists.length > 0" class="misc-stats-wrap__list">
              <li v-for="artist in suggestedArtists" :key="artist.artist_id">
                <router-link class="artist-link" v-if="artist.artist_id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: artist.artist_id } }">
                  {{ artist.name }}
                </router-link>
              </li>
            </ul>
            <div v-else class="no-data-message">
              <h4>No data available</h4>
            </div>
          </div>
          <!-- <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Recently shared songs</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(songTitleArray) }} by
                {{ generateRandomString(artistNameArray) }}</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Friends with most similar music tastes</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(friendNameArray) }}</li>
            </ul>
          </div>
          <div class="misc-stats-container__misc-stat-wrap">
            <div class="misc-stats-wrap__title-wrap">Friends with least similar music tastes</div>
            <ul class="misc-stats-wrap__list">
              <li v-for="index in 3" :key="index">{{ generateRandomString(friendNameArray) }}</li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
//https://github.com/apexcharts/apexcharts.js/tree/main/samples/vue
import ModalHeader from "@/components/modal-header";

export default {
  components: {
    ModalHeader,
  },
  computed: {
    ...mapState("musicProfiles", [
      "libCompositionSeries",
      "libCompositionOptions",
      "listeningHabitsSeries",
      "listeningHabitsOptions",
      "attributePlaysSeries",
      "attributePlaysOptions",
      "mostPlayedSongs",
      "mostPlayedArtists",
      "suggestedSongs",
      "suggestedArtists",
      "friendsTastesSeries",
      "friendsTastesOptions",
    ]),
    isDataReady() {
      return [this.libCompositionSeries, this.listeningHabitsSeries, this.attributePlaysSeries, this.friendsTastesSeries].every((series) => series !== null && series !== undefined);
    },
  },
  methods: {
    ...mapActions("musicProfiles", ["fetchMusicProfileData"]),
    ...mapMutations("musicProfiles", ["toggleMusicProfileOpen"]),
    openMusicProfile() {
      this.fetchMusicProfileData(this.$store.state.currentUserId);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

#music-profile {
  .atmo-modal-content {
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    border: none;
    width: 100vw;
    height: 100vh;
  }
}

.music-profile {
  .no-data-message {
    font-size: 0.8rem;
    color: white;
    // text-align: center;
    // margin: 1rem 0;
    height: 40vh;
  }

  .music-profile__loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .chart-header {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .song-link {
    // color: $atmo-blue--medium;
    color: white;
  }

  .artist-link {
    // color: $atmo-pink--medium;
    color: white;
  }

  .apexcharts-legend-text {
    color: white !important;
  }

  .apexcharts-text {
    fill: white !important;
  }

  .apexcharts-menu-icon {
    color: white;

    svg {
      fill: white;
    }

    svg:hover {
      fill: $atmo-purple--dark;
    }
  }

  .apexcharts-menu {
    background: $atmo-purple--dark;
    border: none;
  }

  .apexcharts-menu-item:hover {
    background: $atmo-purple--medium;
  }

  .modal-lg.modal-dialog {
    max-width: 100vw;
    margin: 0;
  }

  .music-profile__close {
    position: absolute;
    top: 1rem;
    left: 1rem;
    width: 3rem;
    height: 3rem;
    padding: 10px;
    cursor: pointer;
  }

  .music-profile__data-container {
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 2rem 2rem;
  }

  .data-container__lib-habits-plays-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .data-container__friends-misc-stats-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .friends-misc-stats-container__misc-stats-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  .misc-stats-wrap__title-wrap {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .misc-stats-wrap__list {
    list-style-type: disc;

    ul {
      display: block;
    }

    li {
      display: block;
    }

    li + li {
      margin-bottom: 0.4rem;
    }
  }
}
</style>
