<template>
  <div class="atmo-directory">
    <div class="atmo-directory__header-container">
      <div class="header-container__ad-container">
        <atmo-directory-ad />
        <atmo-directory-ad />
      </div>
      <div class="header-container__ad-container">
        <atmo-directory-ad />
        <atmo-directory-ad />
      </div>
    </div>

    <section class="atmo-directory__body">
      <div class="atmo-directory__controls">
        <form class="atmo-form">
          <input type="text" placeholder="Search Directory" class="atmo-directory__search atmo-input atmo-input--search" :value="query" @input="onSearch" />
        </form>
        <div class="atmo-directory__filters">
          <atmo-filter-controls :selected-filter-value="selectedFilterValue" :selected-filter-type="selectedFilterType" :on-filter-button-click="showFilterModal" :on-reset="resetFilter" />
        </div>
      </div>

      <div class="atmo-directory__main-content">
        <div v-if="isLoading" class="atmo-directory__empty">
          <atmo-loading />
        </div>

        <div v-else-if="filteredProfiles.length === 0" class="atmo-directory__empty">
          <img class="atmo-empty-state__icon" :src="`${allProfiles.length === 0 ? getImgUrl('no-directory-profiles') : getImgUrl('no-results')}`" />
          <span v-if="allProfiles.length === 0" class="atmo-empty-state__label"> There are no directory profiles </span>
          <span v-else class="atmo-empty-state__label"> There are no profiles that match your search </span>
        </div>

        <template v-else>
          <div class="directory-container">
            <div class="directory-header">
              <div class="directory-header__cell">Name</div>
              <div class="directory-header__cell">Profession</div>
              <div class="directory-header__cell">Rate</div>
              <div class="directory-header__cell">Location</div>
              <div class="directory-header__cell">Contact</div>
              <div class="directory-header__cell">Website</div>
            </div>

            <perfect-scrollbar class="directory-body">
              <div v-for="(profile, index) in filteredProfiles" :key="profile?.id || index" :class="['directory-row', index % 2 !== 0 ? 'directory-row--dark' : 'directory-row--light']">
                <div class="directory-cell">
                  <router-link v-if="profile?.user_id" :to="{ name: 'directory_profiles.show', params: { userId: profile.user_id } }">
                    {{ profile?.name || "Unknown" }}
                  </router-link>
                  <span v-else>{{ profile?.name || "Unknown" }}</span>
                </div>
                <div class="directory-cell">
                  {{ profile?.directory_profession?.name || "N/A" }}
                </div>
                <div class="directory-cell">
                  <span v-if="profile?.hourly_rate"> {{ returnCurrencyFormat(profile.hourly_rate) }}/hr </span>
                  <span v-else>N/A</span>
                </div>
                <div class="directory-cell">
                  <span v-if="profile?.city || profile?.state || profile?.country">
                    {{ [profile?.city?.name, profile?.state?.name, profile?.country?.name].filter(Boolean).join(", ") }}
                  </span>
                  <span v-else>Not Provided</span>
                </div>
                <div class="directory-cell cell-message" data-cy="cell-email" @click="messageUser(profile)">Message User</div>
                <!-- FIGURE OUT MESSAGING/CHAT? -->
                <div class="directory-cell cell-website">
                  <a v-if="profile?.website" :href="profile.website" target="_blank">
                    {{ profile.website }}
                  </a>
                  <span v-else>N/A</span>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </template>
      </div>
    </section>

    <footer v-if="!isLoading" class="atmo-directory__footer">
      <button class="atmo-button atmo-button--tertiary" @click="$store.dispatch('directoryProfiles/toggleEditProfileModal')">Edit Profile</button>

      <router-link v-if="$store.state.currentUserId" class="atmo-button atmo-button--secondary" :to="{ name: 'advertisements.index', params: { userId: $store.state.currentUserId } }">
        <img class="atmo-button__icon" src="@/assets/images/icons/bullhorn.png" />
        <span>Create an Ad</span>
      </router-link>
    </footer>

    <edit-profile-modal
      v-if="!isLoading"
      :professions="professions"
      :selected-name="myProfile.name"
      :selected-rate="myProfile.hourly_rate"
      :selected-website="myProfile.website"
      :selected-country="myProfile.country"
      :selected-state="myProfile.state"
      :selected-city="myProfile.city"
      :selected-profession="myProfile.directory_profession"
      :selected-is-public="myProfile.is_public"
      :on-submit="onSubmitEditProfile"
      size="medium"
    />

    <filter-modal
      v-if="!isLoading"
      :selected-filter-type="selectedFilterType"
      :professions="professions"
      :selected-state="selectedFilterState"
      :selected-city="selectedFilterCity"
      :selected-profession="selectedFilterProfession"
      :selected-min-price="selectedFilterMinPrice"
      :selected-max-price="selectedFilterMaxPrice"
      :on-submit="onSubmitFilterModal"
      size="medium"
    />
  </div>
</template>

<script>
import Axios from "axios";
import AtmoLoading from "@/components/atmo-loading";
import AtmoFilterControls from "@/components/atmo-filter-controls";
import EditProfileModal from "./edit-profile-modal";
import FilterModal from "./filter-modal";
import AtmoDirectoryAd from "@/components/atmo-directory/atmo-directory-ad";
import { utilsGetImgUrl } from "@/helpers/utilityFunctions";
import devMockData from "@/devUtils/mockData.js";
import { callDirectoryProfilesIndex, callDirectoryProfilesShow, callDirectoryProfilesUpdate, callChatRoomsIndex, callChatRoomsCreate } from "@/helpers/axiosCalls";
import { connectToVideoCallsChannel, sendSignalingMessage, setCallback as setVideoCallback } from "@/cable-channels/videoCalls";

export default {
  components: {
    AtmoLoading,
    AtmoFilterControls,
    EditProfileModal,
    FilterModal,
    AtmoDirectoryAd,
  },

  data: function () {
    return {
      isLoading: false,
      publicProfiles: null,
      myProfile: null,
      concerts: null,
      query: null,
      selectedFilterType: "location",
      selectedFilterState: null,
      selectedFilterCity: null,
      selectedFilterProfession: null,
      selectedFilterMinPrice: null,
      selectedFilterMaxPrice: null,
      devMockData: devMockData,
      statesAndCities: [],
    };
  },

  computed: {
    selectedFilterValue() {
      // will be null if there is no filter selected
      switch (this.selectedFilterType) {
        case "location":
          return this.selectedFilterState;
        case "profession":
          return this.selectedFilterProfession.name;
        case "price":
          return this.formatPriceRange(this.selectedFilterMinPrice, this.selectedFilterMaxPrice);
        default:
          return null;
      }
    },
    allProfiles() {
      return [this.myProfile].concat(
        this.publicProfiles.filter((profile) => {
          return profile.id !== this.myProfile.id;
        })
      );
    },
    filteredProfiles() {
      return this.allProfiles.filter((profile) => {
        const matchesQuery = (profile.name || "").toLowerCase().includes((this.query || "").toLowerCase());
        const matchesFilter = this._matchesFilter(profile);
        return matchesQuery && matchesFilter;
      });
    },
    myProfession() {
      return this.professionById(this.myProfile.directory_profession_id);
    },
  },
  created() {
    this.getInitialData();
    this.$store.commit("setHudHeader", "Atmo Directory");
  },
  methods: {
    async messageUser(profile) {
      try {
        let chatRoom = await callChatRoomsIndex({
          type: "directory_profile",
          user_id: this.$store.state.currentUserId,
          friend_id: profile.user_id,
        });

        console.log("CHAT ROOM FOR DIRECTORY PROFILE", chatRoom);
        //IF WE DON'T FIND IT, create it, open modal, and select room
        //IF WE DO FIND IT, open modal and select room
        //POLYMORPHISM?

        if (Object.keys(chatRoom).length === 0) {
          let newChatRoom = await callChatRoomsCreate({
            name: `${this.$store.state.currentUser.name} & ${profile.user.name}`,
            room_type: "directory_profile",
            roomable_type: "DirectoryProfile",
            creator_id: this.$store.state.currentUserId,
            user_ids: [this.$store.state.currentUserId, profile.user_id],
          });

          this.$store.commit("chatRooms/setCurrentChatRoom", newChatRoom);
        } else {
          this.$store.commit("chatRooms/setCurrentChatRoom", chatRoom);
        }
      } catch (error) {
        this.$toast("Error messaging user", { type: "error" });
        console.error("Error in messageUser:", error);
      }

      this.$store.commit("chatRooms/toggleMaxChatOpen");

      if (this.$store.state.chatRooms.currentChatRoom) {
        connectToVideoCallsChannel(this.$store.state.chatRooms.currentChatRoom.id);
      }
    },
    getImgUrl(image) {
      return utilsGetImgUrl(image);
    },
    returnCurrencyFormat(number) {
      // Create number formatter (Javascript - Internationalization API)
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
    async getInitialData() {
      this.isLoading = true;
      await Promise.all([this.getProfessions(), this.getDirectoryProfiles()]);
      this.isLoading = false;
    },
    async getProfessions() {
      try {
        const response = await Axios.get("/api/v1/directory_professions");
        this.professions = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getDirectoryProfiles() {
      this.isLoading = true;
      await Promise.all([this.getPublicProfiles(), this.getMyProfile()]);
      this.isLoading = false;
    },
    async getPublicProfiles() {
      try {
        const response = await callDirectoryProfilesIndex({ public: true });
        this.publicProfiles = response;
      } catch (error) {
        console.error(error);
      }
    },
    async getMyProfile() {
      try {
        const response = await callDirectoryProfilesShow(this.$store.state.currentUserId);
        this.myProfile = response;
      } catch (error) {
        console.error(error);
      }
    },
    formatPriceRange(minPrice, maxPrice) {
      return `$${minPrice} - $${maxPrice}`;
    },
    onSearch(event) {
      this.query = event.currentTarget.value;
    },
    showFilterModal() {
      this.$store.dispatch("directoryProfiles/toggleFilterProfilesModal");
    },
    onSubmitFilterModal(newProps) {
      this.selectedFilterType = newProps.filterType;
      this.selectedFilterState = newProps.state;
      this.selectedFilterCity = newProps.city;
      this.selectedFilterProfession = newProps.profession;
      this.selectedFilterMinPrice = newProps.minPrice;
      this.selectedFilterMaxPrice = newProps.maxPrice;
      this.getDirectoryProfiles();
    },
    resetFilter() {
      this.selectedFilterType = "location";
      this.selectedFilterState = null;
      this.selectedFilterCity = null;
      this.selectedFilterProfession = null;
      this.selectedFilterMinPrice = null;
      this.selectedFilterMaxPrice = null;
      this.getDirectoryProfiles();
    },
    async onSubmitEditProfile(data) {
      data.user_id = this.$store.state.currentUserId;
      try {
        await callDirectoryProfilesUpdate(this.myProfile.id, data);
        this.$toast("Profile Saved", { type: "success" });
      } catch (error) {
        console.error(error);
        this.$toast("Error Saving Profile", { type: "error" });
      }
      await this.getDirectoryProfiles();
    },
    _matchesFilter(profile) {
      const selectedFilterValue = this.selectedFilterValue;
      const rate = Number(profile.hourly_rate);
      if (!selectedFilterValue) {
        return true;
      }
      switch (this.selectedFilterType) {
        case "location":
          return profile.location && profile.location.includes(selectedFilterValue);
        case "profession":
          return selectedFilterValue === (this.professionById(profile.directory_profession_id) || {}).name;
        case "price":
          return rate >= this.selectedFilterMinPrice && rate <= this.selectedFilterMaxPrice;
        default:
          return true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-directory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  .atmo-directory__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    .header-container__header-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
    }

    .header-container__ad-container {
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 5px;
      display: flex;
      gap: 1rem;
      padding: 1rem;
      min-height: 7rem; //FOR NOW
    }
  }

  &__header {
    // margin: 35px 0;
    min-width: 14ch;
    margin: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .directory-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    margin-top: 1rem;

    a {
      color: white;
    }
  }

  /* Apply Grid Layout for Consistent Column Alignment */
  .directory-header,
  .directory-row {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr 1fr 1.5fr; /* Define column widths */
    align-items: center;
    width: 100%;
    text-align: left;
  }

  .directory-header {
    background: $atmo-purple--medium-dark--faded;
    font-weight: bold;
    position: sticky;
    text-transform: uppercase;
    font-size: 1rem;
    top: 0;
    z-index: 10;
    padding: 12px 0;
  }

  .directory-header__cell {
    padding: 12px;
    text-align: left;
  }

  .cell-message {
    cursor: pointer;
  }

  .directory-body {
    height: 45vh; /* Enables scrolling */
    overflow-y: auto;
    width: 100%;
  }

  .directory-row {
    padding: 12px 0;
  }

  .directory-row--light {
    background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
  }

  .directory-cell {
    padding: 8px;
    text-align: left;
  }

  .cell-website {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__main-content {
    min-height: 300px;
    margin-bottom: 1rem;
  }

  &__search.atmo-input.atmo-input--search {
    height: 32px;
    min-width: 150px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
