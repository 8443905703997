<template>
  <AtmoModal id="chat-image-modal" ref="chatImageModal" v-model="isChatImageModalOpen" title="Chat Image Modal">
    <header class="chat-image-modal__header">
      <div class="header__hide-modal-wrap" @click="hideModal()">
        <img class="hide-modal-wrap__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Chat Image" />
      </div>
    </header>
    <div class="chat-image-modal__image-container">
      <div v-if="chatImageModalUrl" class="image-container__image-wrap">
        <img class="image-wrap__image" :src="chatImageModalUrl" />
      </div>
    </div>
  </AtmoModal>
</template>

<script>
export default {
  computed: {
    isChatImageModalOpen: {
      get() {
        return this.$store.getters["chatRooms/isChatImageModalOpen"];
      },
      set(value) {
        if (value) {
          this.$store.commit("chatRooms/showChatImageModal");
        } else {
          this.$store.commit("chatRooms/hideChatImageModal");
        }
      },
    },
    chatImageModalUrl() {
      return this.$store.getters["chatRooms/chatImageModalUrl"];
    },
  },
  methods: {
    hideModal() {
      this.$store.commit("chatRooms/hideChatImageModal");

      if (this.$store.state.imageModalUrl) {
        this.$store.commit("chatRooms/setChatImageModalUrl", null);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

#chat-image-modal {
  .modal-lg.modal-dialog {
    width: 98vw;
    margin: 1rem;
    max-width: unset;
  }

  .atmo-modal-content {
    backdrop-filter: blur(5px);
    border-radius: 5px;
    border: none;
    width: 100vw;
    height: 97vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .atmo-modal-body {
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .chat-image-modal__header {
    position: absolute;
    right: 0rem;
    height: 1rem;
    z-index: 1;
  }

  .header__hide-modal-wrap {
    height: 2.5rem;
    width: 2.5rem;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hide-modal-wrap__hide-modal {
    height: 1rem;
    width: auto;
    cursor: pointer;
  }

  .chat-image-modal__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1rem;

    .image-container__image-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}
</style>
