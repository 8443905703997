import Axios from 'axios';

export function callDirectoryAdClicksIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/directory_ad_clicks`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryAdClicksShow(userId, adClickId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/directory_ad_clicks/${adClickId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryAdClicksCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/directory_ad_clicks`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}