<template>
  <div class="atmo-contests-new">
    <!-- <h2 class="atmo-page-header atmo-contests-new__header">
      New Contest
    </h2> -->
    <div class="atmo-contests-new__main-wrap">
      <atmo-back-link></atmo-back-link>
      <div class="main-wrap__main">
        <atmo-loading v-if="isLoading" />
        <form v-else @submit.prevent="submitNewContest" class="atmo-form main-wrap__form">
          <div class="main-wrap__main-row">
            <div class="main-wrap__column image-file-types">
              <div class="field-wrap">
                <label for="contestImage">Contest Image</label>
                <atmo-upload-card
                  id="contestImage"
                  :image-src="formSelectedImage"
                  :is-dropzone="true"
                  :on-files-selected="onImageSelected"
                  alt="Upload Image"
                  componentStyle="width:15rem; height:9rem;"
                >
                  Contest Image
                </atmo-upload-card>
              </div>
              <div class="field-wrap">
                <label>Accepted File Types</label>
                <div class="atmo-form__checkbox-group" name="file-types">
                  <label class="file-type-label" v-for="fileType in fileTypes" :key="fileType.id">
                    {{ fileType.name }}
                    <input type="checkbox" v-model="formSelectedFileTypes" :value="fileType" />
                  </label>
                </div>
              </div>
            </div>
            <div class="main-wrap__column main-wrap__main-column">
              <div class="field-wrap">
                <label for="contestTitle">Contest title</label>
                <input
                  class="text-field"
                  id="contestTitle"
                  v-model="formSelectedTitle"
                  :aria-invalid="submitAttempted && !formSelectedTitle"
                  type="text"
                  placeholder="Design our next t-shirt and win free tickets"
                  data-cy="contest-title"
                />
              </div>
              <div class="field-wrap">
                <label for="contestDescription">Contest description</label>
                <textarea
                  class="text-field"
                  id="contestDescription"
                  v-model="formSelectedDescription"
                  rows="4"
                  no-resize
                  :aria-invalid="submitAttempted && !formSelectedDescription"
                  placeholder="Enter contest description"
                  data-cy="contest-description"
                ></textarea>
              </div>
              <div class="field-wrap">
                <label>Contest Duration</label>
                <div class="datepicker-trigger">
                  <div class="datepicker-wrap">
                    <DatePicker v-model="formSelectedStartDate" format="yyyy-MM-dd" placeholder="Select Start Date" />
                  </div>
                  <div class="datepicker-wrap">
                    <DatePicker v-model="formSelectedEndDate" format="yyyy-MM-dd" placeholder="Select End Date" />
                  </div>
                </div>
              </div>
            </div>
            <div class="main-wrap__side-column">
              <div class="field-wrap">
                <div class="prize-header-container">
                  <label class="prize-header" for="prizes">Prizes</label>
                  <div class="create-prize-button" @click="createPrize()">+</div>
                </div>
                <div>
                  <perfect-scrollbar>
                    <div v-for="(prize, index) in formSelectedPrizes" :key="index" class="form-prizes-container">
                      <form-prize
                        :key="index"
                        :index="index"
                        :prize="prize"
                        :prize-types="prizeTypes"
                        :selected-prize-type="defaultPrizeType"
                        :submit-attempted="submitAttempted"
                        :expanded="expandedPrizeIndex === index"
                        :on-header-click="() => onPrizeHeaderClick(index)"
                        :on-change="(properties) => onPrizeChange(index, properties)"
                        :edit-mode="false"
                      />
                      <div @click="removePrize(index)" class="remove-form-prize">Delete Prize</div>
                    </div>
                  </perfect-scrollbar>
                </div>
              </div>
            </div>
          </div>
          <div class="main-wrap__footer">
            <button type="submit" class="main-wrap__submit-button">Create Contest</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { callContestPrizeTypesIndex, callFileTypesIndex, callContestFileTypesCreate, callContestsCreate, callContestPrizesCreate } from "@/helpers/axiosCalls";
import AtmoLoading from "@/components/atmo-loading";
import AtmoBackLink from "@/components/atmo-back-link";
import AtmoUploadCard from "@/components/atmo-upload-card";
import FormPrize from "./form-prize";
import format from "date-fns/format";
import DatePicker from "vue3-datepicker";

export default {
  title: " - New Contest",

  components: {
    AtmoLoading,
    AtmoBackLink,
    AtmoUploadCard,
    FormPrize,
    DatePicker,
  },

  data: function () {
    return {
      isLoading: false,
      prizeTypes: null,
      fileTypes: null,
      defaultPrizeType: null,
      submitAttempted: false,
      dateFormat: "MMM D, YYYY",
      formSelectedImage: null,
      formSelectedImageFile: null,
      formSelectedFileTypes: [],
      formSelectedTitle: null,
      formSelectedDescription: null,
      formSelectedStartDate: null,
      formSelectedEndDate: null,
      formSelectedNumberOfWinners: 3,
      expandedPrizeIndex: 0,
      formSelectedPrizes: [],
    };
  },

  computed: {
    artistProfileId() {
      return this.$store.state.currentUserProfileId;
    },
    formSelectedDates() {
      return this.formatDates(this.formSelectedStartDate, this.formSelectedEndDate);
    },
  },

  created() {
    this.getPrizeTypes();
    this.getFileTypes();
    this.$store.commit("setHudHeader", "New Contest");
  },

  methods: {
    createPrize() {
      this.formSelectedPrizes.push({});
    },
    removePrize(index) {
      this.formSelectedPrizes.splice(index, 1);
    },
    async getPrizeTypes() {
      try {
        const response = await callContestPrizeTypesIndex();
        this.prizeTypes = response;
        this.defaultPrizeType = response.find((prizeType) => {
          return prizeType.name === "Merch";
        });
      } catch (error) {
        console.error(error);
      }
    },

    async getFileTypes() {
      try {
        const response = await callFileTypesIndex();
        this.fileTypes = response;
      } catch (error) {
        console.error(error);
      }
    },

    onImageSelected(files) {
      this.formSelectedImageFile = files[0];
    },
    formatDates(startDate, endDate) {
      let formattedDates = "";
      if (startDate) {
        formattedDates = format(startDate, this.dateFormat);
      }
      if (endDate) {
        formattedDates += " - " + format(endDate, this.dateFormat);
      }

      return formattedDates;
    },
    onPrizeHeaderClick(index) {
      if (this.expandedPrizeIndex === index) {
        this.expandedPrizeIndex = null;
      } else {
        this.expandedPrizeIndex = index;
      }
    },
    onPrizeChange(index, prize) {
      this.formSelectedPrizes[index] = prize;
      console.log("this.formSelectedPrizes", this.formSelectedPrizes);
    },
    async submitNewContest() {
      this.submitAttempted = true;
      if (!this.formSelectedTitle || !this.formSelectedDescription || !this.formSelectedStartDate || !this.formSelectedEndDate) {
        this.$toast("Missing required fields", { type: "error" });
        return false;
      }

      try {
        this.isLoading = true;
        const formData = new FormData();
        formData.append("user_id", this.$store.state.currentUserId);
        formData.append("name", this.formSelectedTitle);
        formData.append("description", this.formSelectedDescription);
        formData.append("image", this.formSelectedImageFile);
        formData.append("start_date", this.formSelectedStartDate);
        formData.append("end_date", this.formSelectedEndDate);
        formData.append("is_public", true);
        formData.append("ended", false);

        // Append contest prizes properly
        this.formSelectedPrizes.forEach((prize, index) => {
          formData.append(`prizes[${index}][name]`, prize.name);
          formData.append(`prizes[${index}][contest_prize_type_id]`, prize.contest_prize_type?.id || null);
          formData.append(`prizes[${index}][credit_amount]`, prize.contest_prize_type?.name === "Credits" ? prize.credits : null);
          formData.append(`prizes[${index}][merch_description]`, prize.contest_prize_type?.name === "Merch" ? prize.description : null);
          formData.append(`prizes[${index}][other_description]`, prize.contest_prize_type?.name !== "Merch" && prize.contest_prize_type?.name !== "Credits" ? prize.description : null);

          // Attach merch image properly
          if (prize.contest_prize_type?.name === "Merch" && prize.image) {
            formData.append(`prizes[${index}][merch_image]`, prize.image);
          }

          // Attach other image properly
          if (prize.contest_prize_type?.name !== "Merch" && prize.image) {
            formData.append(`prizes[${index}][other_image]`, prize.image);
          }
        });

        // Append contest file types
        this.formSelectedFileTypes.forEach((fileType, index) => {
          formData.append(`file_types[${index}][file_type_id]`, fileType.id);
        });

        // **Prevent sending "null" strings**
        for (let [key, value] of formData.entries()) {
          if (value === "null") {
            formData.set(key, ""); // Replace "null" with empty string
          }
        }

        // Call API with single request
        const response = await callContestsCreate(formData);
        this.isLoading = false;

        this.$toast("Contest created successfully!", { type: "success" });
        this.$router.push({ name: "contests.show", params: { contestId: response.id } });
      } catch (error) {
        this.isLoading = false;
        console.error(error);
        this.$toast("Error submitting contest", { type: "error" });
      }
    },
    async submitContestFileTypes(contestId) {
      this.formSelectedFileTypes.forEach(async (fileType) => {
        console.log("FIRING", {
          contest_id: contestId,
          file_type_id: fileType.id,
        });
        await callContestFileTypesCreate({
          contest_id: contestId,
          file_type_id: fileType.id,
        });
      });
    },
    async submitContestPrizes(contestId) {
      for (let index = 0; index < this.formSelectedPrizes.length; index++) {
        const prize = this.formSelectedPrizes[index];
        const { prizeType } = prize;
        if (!prizeType) {
          return;
        }
        const formData = new FormData();
        formData.append("name", prize.name);
        formData.append("contest_prize_type_id", prizeType.id);
        if (prizeType.name === "Merch") {
          formData.append("merch_description", prize.description);
          formData.append("merch_image", prize.image);
        } else if (prizeType.name === "Credits") {
          formData.append("credit_amount", prize.credits);
        } else {
          formData.append("other_description", prize.description);
          formData.append("other_image", prize.image);
        }

        await callContestPrizesCreate(contestId, formData);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-contests-new {
  display: flex;
  flex-direction: column;
  // max-width: 50rem;

  .prize-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .prize-header {
      margin-bottom: 0 !important;
    }

    .create-prize-button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 100%;
      background-color: white;
      color: $atmo-purple--extra-dark;
      font-size: 1rem;
      cursor: pointer;
    }
  }

  .ps {
    max-height: 20rem;
  }

  .remove-form-prize {
    cursor: pointer;
    margin-top: 0.5rem;
  }

  textarea,
  textarea::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    color: white;
  }

  input::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.4) !important;
  }

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .field-wrap {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .text-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.4);
    }

    .dropdown-group {
      background: $atmo-purple--medium-dark;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 5px;
      padding: 0.5rem;
      font-weight: 500rem;
      cursor: pointer;
    }

    .dropdown-field {
    }

    .radio-group {
      display: flex;
      padding: 0.6rem 0rem 0.6rem 0rem;
    }

    .radio-field-wrap {
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .radio-field {
      margin-right: 0.5rem;
      cursor: pointer;
    }

    .radio-field-label {
      margin: 2px 0px 0px 0px;
    }
  }

  .atmo-contests-new__main-wrap {
    display: flex;
    flex-direction: column;
    margin: 0 75px;
    height: 100%;
    max-width: 100vw;
  }

  .atmo-form__checkbox-group {
    display: flex;
    flex-direction: column;
    padding: 0.8rem;
  }

  .file-type-label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $atmo-purple--light;
    cursor: pointer;
    text-transform: unset !important;
    font-weight: 400 !important;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
  }

  .field-label {
    font-weight: 500;
    margin-bottom: 0.8rem;
  }

  .datepicker-trigger {
    input {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
      margin-bottom: 1rem;
    }
  }

  #numberOfWinners {
    label {
      margin: 0;
    }
  }

  .main-wrap__header {
    margin: 35px 0;
  }

  .main-wrap__breadcrumbs {
    align-self: flex-start;
    margin-bottom: 15px;
  }

  .main-wrap__main-row {
    display: flex;
    flex-wrap: wrap;
  }

  .main-wrap__column {
    display: flex;
    flex-direction: column;
  }

  .image-file-types {
    min-width: 11rem;
  }

  .main-wrap__main-column {
    flex: 1;
    margin: 0 20px;
  }

  #datepicker-trigger {
    border: 1px solid rgba(255, 255, 255, 0.4);
    text-transform: unset;
    cursor: pointer;
  }

  #datepicker-trigger.atmo-contests-new__datepicker-trigger {
    width: 100%;
    background-color: $atmo-purple--medium-dark;
    color: white;

    &::placeholder {
      color: white;
    }
  }

  .main-wrap__side-column {
    width: 16rem;
  }

  .number-of-winners-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .main-wrap__footer {
    display: flex;
  }

  .main-wrap__submit-button {
    margin-left: auto;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }
}
</style>
