<template>
  <div class="business-subscriptions" data-cy="publisher-subscriptions">
    <div class="business-subscriptions-container">
      <!-- Free Plan -->
      <div class="business-subscriptions__plan">
        <div class="plan__title-container">
          <p class="plan__header">Writer’s Circle</p>
        </div>
        <div class="plan__content-container">
          <div class="content__row">Create a Publisher Profile</div>
          <div class="content__row --dark">Add Unlimited Writers</div>
          <div class="content__row">Publish Unlimited Songs</div>
          <div class="content__row --dark">Basic Song Performance Analytics</div>
          <div class="content__row">Public Publisher Page</div>
          <div class="content__row --dark">Access Artist & Label Discovery</div>
          <div class="content__row">Community Pitch Boards</div>
        </div>
      </div>

      <!-- Pro Plan -->
      <div class="business-subscriptions__plan">
        <div class="plan__title-container premium">
          <p class="plan__header">Publishing Suite</p>
          <p class="plan__subheader">$49.99/month</p>
        </div>
        <perfect-scrollbar>
          <div class="plan__content-container">
            <div class="content__row">Everything in Writer’s Circle</div>
            <div class="content__row --dark">Full Publisher Dashboard</div>
            <div class="content__row">Top Writers & Song Insights</div>
            <div class="content__row --dark">Collaborator Tracking</div>
            <div class="content__row">Genre, Mood & Tag Editor</div>
            <div class="content__row --dark">Audience Demographics by Song</div>
            <div class="content__row">Priority Placement in Discovery</div>
            <div class="content__row --dark">Artist/Label Pitch Submissions</div>
          </div>
        </perfect-scrollbar>
        <div class="plan__button-container">
          <router-link class="button" :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: $store.state.currentUser.profile_id } }">Select Suite</router-link>
        </div>
      </div>

      <!-- Premium Plan -->
      <div class="business-subscriptions__plan">
        <div class="plan__title-container premium">
          <p class="plan__header">Global Rights HQ</p>
          <p class="plan__subheader">$149.99/month</p>
        </div>
        <perfect-scrollbar>
          <div class="plan__content-container">
            <div class="content__row">Everything in Publishing Suite</div>
            <div class="content__row --dark">AI Tagging for Sync Readiness</div>
            <div class="content__row">Song Pitch Logs & Export Tools</div>
            <div class="content__row --dark">Royalty Projection Insights</div>
            <div class="content__row">Dedicated Publisher Success Rep</div>
            <div class="content__row --dark">Early Access to Sync Briefs</div>
            <div class="content__row">Featured Publisher Listings</div>
            <div class="content__row --dark">Advanced Analytics Reports</div>
          </div>
        </perfect-scrollbar>
        <div class="plan__button-container">
          <router-link class="button" :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: $store.state.currentUser.profile_id } }">Select HQ</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit("setHudHeader", "Publisher Plans");
  },
};
</script>

<style lang="scss">
.business-subscriptions {
  margin: 0 75px;

  .business-subscriptions-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .business-subscriptions__plan {
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.09);
    background: rgba(255, 255, 255, 0.05);
  }

  .plan__title-container {
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    &.premium {
      background: #604875;
      color: white;
    }
  }

  .plan__header {
    font-size: 2rem;
    font-weight: 700;
  }

  .plan__subheader {
    font-size: 1.2rem;
    opacity: 0.8;
  }

  .plan__content-container {
    background: rgba(96, 72, 117, 0.15);
  }

  .content__row {
    padding: 0.8rem;
    font-weight: 500;
    text-align: center;
  }

  .--dark {
    opacity: 0.7;
    background: #604875;
  }

  .plan__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: rgba(96, 72, 117, 0.15);
  }

  .button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8), rgba(68, 174, 220, 0.8));
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
  }
}
</style>
