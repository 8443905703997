<template>
  <div class="edit-atmo-event" data-cy="edit-atmo-event">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">
        Edit Event
      </h2>
    </div> -->
    <atmo-back-link></atmo-back-link>
    <div class="edit-atmo-event__form-wrap">
      <div class="edit-atmo-event__form-container">
        <div>
          <atmo-media-uploader :existingMediaUrl="event?.image?.url" componentId="image-upload" mediaType="image" @selected-image-upload="handleUploadedImage" height="11rem" width="11rem" />
          <div class="event-types-container">
            <div class="field-wrap">
              <label for="event-type">Event Type</label>
              <select v-model="editedEvent.event_type" class="dropdown-group" id="event-type" name="event-type">
                <option v-for="type in eventTypeOptions" :key="type" :value="type">
                  {{ type }}
                </option>
              </select>
            </div>
            <div class="field-wrap">
              <label for="location-type">Location Type</label>
              <select v-model="editedEvent.location_type" class="dropdown-group" id="location-type" name="location-type">
                <option v-for="location in locationTypeOptions" :key="location" :value="location">
                  {{ location }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="edit-atmo-event__form-container__fields-container">
          <div class="three-column-grid">
            <div class="field-wrap">
              <label for="name">Event Name</label>
              <input class="text-field" v-model="editedEvent.name" type="text" id="name" name="name" placeholder="New Event" />
            </div>
            <div class="field-wrap">
              <label for="event-date">Event Date</label>
              <div class="datepicker-trigger">
                <DatePicker :key="datePickerKey" v-model="formSelectedDateTime" :format="'yyyy-MM-dd'" placeholder="Select Start Date" @update:modelValue="handleDatePickerChange" />
              </div>
            </div>
            <div class="field-wrap">
              <label for="details">Event Details</label>
              <textarea class="text-field" v-model="editedEvent.details" id="details" name="details" placeholder="Event Details"></textarea>
            </div>
          </div>

          <!-- Address Fields -->
          <div class="three-column-grid">
            <div class="field-wrap">
              <label for="address1">Address Line 1</label>
              <input class="text-field" v-if="editedEvent.address" v-model="editedEvent.address.line_1" type="text" id="address1" name="address1" placeholder="ex: 654 27th Ave N" />
            </div>
            <div class="field-wrap">
              <label for="address2">Address Line 2</label>
              <input class="text-field" v-if="editedEvent.address" v-model="editedEvent.address.line_2" type="text" id="address2" name="address2" placeholder="ex: Suite 100" />
            </div>
            <div class="field-wrap">
              <label for="country">Country</label>
              <select v-if="editedEvent.address" v-model="editedEvent.address.country_id" class="dropdown-group" id="country" name="country" @change="onCountryChange">
                <option disabled value="">Select Country</option>
                <option v-for="country in countries" :key="country.id" :value="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="three-column-grid">
            <div class="field-wrap">
              <label for="state">State</label>
              <select v-if="editedEvent.address" v-model="editedEvent.address.state_id" class="dropdown-group" id="state" name="state" @change="onStateChange">
                <option v-for="state in filteredStates" :key="state.id" :value="state.id">{{ state.name }}</option>
              </select>
            </div>

            <div class="field-wrap">
              <label for="city">City</label>
              <select v-if="editedEvent.address" v-model="editedEvent.address.city_id" class="dropdown-group">
                <option v-for="city in filteredCities" :key="city.id" :value="city.id">{{ city.name }}</option>
              </select>
            </div>
            <div class="field-wrap">
              <label for="zip_code">Zip Code</label>
              <input class="text-field" v-if="editedEvent.address" v-model="editedEvent.address.zip_code" type="text" id="zip_code" name="zip_code" placeholder="ex: 75001" />
            </div>
          </div>

          <!-- Ticket Creation -->
          <div class="create-ticket-container">
            <div class="create-ticket-container__header">Create Tickets</div>
            <form class="create-ticket-form" @submit.prevent="addNewTicket">
              <div class="three-column-grid">
                <div class="field-wrap">
                  <label for="ticket-title">Ticket Title</label>
                  <input v-model="newTicketTitle" class="text-field" type="text" id="ticket-title" name="event-ticket-title" placeholder="Ticket title" data-cy="ticket-title" required />
                </div>
                <div class="field-wrap">
                  <label for="ticket-price">Ticket Price</label>
                  <input
                    v-model="newTicketPrice"
                    class="text-field"
                    type="text"
                    id="ticket-price"
                    name="event-ticket-price"
                    placeholder="$"
                    data-cy="ticket-price"
                    @input="validateNumericInput"
                    :class="{ 'invalid-input': !ticketPriceIsValid }"
                    required
                  />
                </div>
                <div class="field-wrap">
                  <button type="submit" class="atmo-button atmo-button--secondary" style="margin-top: 1.3rem">Create Ticket</button>
                </div>
              </div>
              <div class="three-column-grid">
                <div class="field-wrap">
                  <label for="ticket-description">Ticket Description</label>
                  <textarea
                    v-model="newTicketDescription"
                    class="text-field"
                    id="ticket-description"
                    name="event-ticket-description"
                    placeholder="Ticket description"
                    data-cy="ticket-description"
                    required
                  ></textarea>
                </div>
              </div>
            </form>
            <div>
              <slot name="submit" />
            </div>
          </div>

          <!-- Save and Cancel Buttons -->
          <div class="fields-container__button-wrap">
            <div class="button-wrap__save-cancel-container">
              <button type="button" class="save-cancel-container__cancel-button" @click="goBack">Cancel</button>
              <button type="button" class="save-cancel-container__save-button" @click="saveNewEvent">Save</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Tickets Section -->
      <div class="edit-atmo-event__tickets-container">
        <div v-if="editedEvent?.atmo_event_tickets?.length === 0" class="mt-3">
          <div class="tickets-container__header">No tickets yet!</div>
        </div>
        <div v-else>
          <div class="tickets-container__header">Your Tickets</div>
        </div>
        <div class="tickets-scroll-wrap">
          <div class="event-ticket" v-for="ticket in filteredTickets" :key="ticket.id || ticket.title">
            <div class="event-ticket__decoration"></div>
            <div class="event-ticket__content">
              <div class="content__description-container">
                <div>
                  <div class="description-container__title-wrap">
                    <p>{{ ticket.title }}</p>
                  </div>
                  <div>{{ ticket.description }}</div>
                </div>
                <div class="description-container__price-delete-wrap">
                  <div class="price-delete-wrap__price-wrap">
                    <div class="event-ticket__price">
                      {{ returnCurrencyFormat(ticket.price) }}
                    </div>
                  </div>
                  <div class="price-delete-wrap__delete-wrap">
                    <div class="delete-wrap__icon-wrap" @click="removeNewTicket(ticket.id)">
                      <img src="@/assets/images/icons/delete.png" alt="Delete Ticket" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { callEventsShow, callEventsUpdate, callEventTicketsCreate, callEventTicketsDelete, callAddressesUpdate } from "@/helpers/axiosCalls";
import format from "date-fns/format";
import DatePicker from "vue3-datepicker";
import { formatDateToMonthDayYearHour, formatPriceAsCurrency, formatDateFromRailsToVue, isDeepEqual, deepCopy } from "@/helpers/utilityFunctions";
import AtmoBackLink from "@/components/atmo-back-link";
import Moment from "moment";
import { mapActions, mapGetters, mapState } from "vuex";
import AtmoMediaUploader from "@/components/atmo-media-uploader";

export default {
  components: {
    AtmoMediaUploader,
    DatePicker,
    AtmoBackLink,
  },
  data() {
    return {
      file: "",
      isLoading: false,
      formSelectedDateTime: null,
      event: {},
      editedEvent: {},
      newTicketTitle: "",
      newTicketDescription: "",
      newTicketPrice: "",
      newEventImage: null,
      ticketPriceIsValid: true,
      eventTypeOptions: ["concert"],
      locationTypeOptions: ["virtual", "physical", "hybrid"],
    };
  },
  computed: {
    ...mapState("address", ["countries", "states", "cities"]),
    ...mapGetters("address", ["getCountryById", "getStateById", "getCityById"]),
    filteredStates() {
      return this.editedEvent.address && this.editedEvent.address.country_id ? this.states.filter((state) => state.country_id === this.editedEvent.address.country_id) : [];
    },

    filteredCities() {
      return this.editedEvent.address && this.editedEvent.address.state_id ? this.cities.filter((city) => city.state_id === this.editedEvent.address.state_id) : [];
    },
    filteredTickets() {
      return this.editedEvent.atmo_event_tickets?.filter((ticket) => !ticket.delete) || [];
    },
  },
  created() {
    this.fetchCountries();
    this.getEvent();
    this.$store.commit("setHudHeader", "Edit Event");
  },
  methods: {
    ...mapActions("address", ["fetchCountries", "fetchStates", "fetchCities"]),
    async onCountryChange(event) {
      const selectedCountryId = event.target.value; // Extract the country ID from the select element

      if (selectedCountryId) {
        this.editedEvent.address.state_id = null;
        this.editedEvent.address.city_id = null;

        await this.fetchStates(selectedCountryId);
      }
    },
    onStateChange() {
      if (this.editedEvent.address) {
        this.editedEvent.address.city_id = null; // Reset city selection
        if (this.editedEvent.address.state_id) {
          this.fetchCities(this.editedEvent.address.state_id);
        }
      }
    },
    getEvent() {
      callEventsShow(this.$route.params.eventId)
        .then((response) => {
          this.event = deepCopy(response);
          this.editedEvent = deepCopy(response);

          // Ensure address is always an object
          this.editedEvent.address = this.editedEvent.address || {};

          // Fetch states if country exists
          if (this.editedEvent.address.country_id) {
            this.fetchStates(this.editedEvent.address.country_id).then(() => {
              // Fetch cities if state exists
              if (this.editedEvent.address.state_id) {
                this.fetchCities(this.editedEvent.address.state_id);
              }
            });
          }

          this.formSelectedDateTime = this.editedEvent.datetime ? new Date(this.editedEvent.datetime) : null;
        })
        .catch((error) => {
          console.error(error);
          this.$toast("Failed to load event details.", { type: "error" });
        });
    },
    validateNumericInput() {
      const numericRegex = /^-?\d*\.?\d+$/;
      this.ticketPriceIsValid = numericRegex.test(this.newTicketPrice);
    },
    getCountryName(countryId) {
      const country = this.getCountryById(countryId);
      return country ? country.name : "";
    },
    getStateName(stateId) {
      const state = this.getStateById(stateId);
      return state ? state.name : "";
    },
    getCityName(cityId) {
      const city = this.getCityById(cityId);
      return city ? city.name : "";
    },
    handleDatePickerClose() {
      console.log("Date picker closed.");
    },
    handleDatePickerChange(selectedDate) {
      if (selectedDate instanceof Date && !isNaN(selectedDate)) {
        this.formSelectedDateTime = selectedDate;
      } else {
        this.formSelectedDateTime = null; // Reset if invalid
      }
    },
    formatDateFromRailsToVue(inputDate) {
      return formatDateFromRailsToVue(inputDate);
    },
    formatDate(dateString) {
      return formatDateToMonthDayYearHour(dateString);
    },
    returnCurrencyFormat(price) {
      return formatPriceAsCurrency(price);
    },
    formatDates(startDate, endDate) {
      let formattedDates = "";
      if (startDate) {
        formattedDates = format(startDate, this.dateFormat);
      }
      if (endDate) {
        formattedDates += " - " + format(endDate, this.dateFormat);
      }
      return formattedDates;
    },
    addNewTicket() {
      if (!this.ticketPriceIsValid) {
        this.$toast("Please enter a valid ticket price.", { type: "error" });
        return;
      }

      if (!this.newTicketTitle || !this.newTicketDescription || !this.newTicketPrice) {
        this.$toast("Please fill out all ticket fields.", { type: "error" });
        return;
      }

      this.editedEvent.atmo_event_tickets = [
        ...this.editedEvent.atmo_event_tickets,
        {
          title: this.newTicketTitle,
          description: this.newTicketDescription,
          price: this.newTicketPrice,
        },
      ];

      this.newTicketTitle = "";
      this.newTicketDescription = "";
      this.newTicketPrice = "";
    },
    removeNewTicket(ticketId) {
      const ticketIndex = this.editedEvent.atmo_event_tickets.findIndex((ticket) => ticket.id === ticketId);

      if (ticketIndex !== -1) {
        // Mark ticket as deleted instead of removing
        this.editedEvent.atmo_event_tickets[ticketIndex].delete = true;
      }
    },
    goBack() {
      this.$router.back();
    },
    handleUploadedImage(value) {
      this.newEventImage = value;
    },
    async saveNewEvent() {
      const formData = new FormData();
      if (this.newEventImage) formData.append("image", this.newEventImage);
      formData.append("name", this.editedEvent.name);
      formData.append("datetime", this.formSelectedDateTime);
      formData.append("details", this.editedEvent.details);
      formData.append("user_id", this.$store.state.currentUserId);
      formData.append("event_type", this.editedEvent.event_type);
      formData.append("location_type", this.editedEvent.location_type);
      formData.append("is_draft", false);

      // ✅ Make sure deleted tickets are included in the payload
      formData.append("tickets", JSON.stringify(this.editedEvent.atmo_event_tickets));

      // Append address as JSON string
      formData.append(
        "address",
        JSON.stringify({
          line_1: this.editedEvent.address.line_1,
          line_2: this.editedEvent.address.line_2,
          city_id: this.editedEvent.address.city_id,
          state_id: this.editedEvent.address.state_id,
          zip_code: this.editedEvent.address.zip_code,
          country_id: this.editedEvent.address.country_id,
        })
      );

      try {
        await callEventsUpdate(this.editedEvent.id, formData);
        this.$toast("Event updated successfully!", { type: "success" });
        this.$router.push({ name: "events.admin_index", params: { userId: this.$store.state.currentUserId } });
      } catch (error) {
        console.error(error);
        this.$toast("Failed to update event.", { type: "error" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.edit-atmo-event {
  margin: 0 75px;

  textarea,
  textarea::placeholder {
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
  }

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-atmo-event__form-wrap {
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr 17rem;
  }

  .edit-atmo-event__form-container {
    display: grid;
    gap: 3rem;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 10rem 1fr;
    // border: 1px solid white;

    .event-file-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      cursor: pointer;
    }

    .event-types-container {
      .dropdown-group {
        margin-bottom: 1rem;
      }
    }

    .image-container__image-wrap {
      height: 11rem;
      width: 11rem;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-bottom: 1rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .image-wrap__icon-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .icon-wrap__icon {
      height: 4rem;
      margin-bottom: 1rem;
    }

    .icon-wrap__text {
      text-transform: uppercase;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-weight: 600;
    }

    .image-wrap__image {
      border-radius: 5px;
      margin-bottom: 0.8rem;
      box-sizing: border-box;
    }

    .editing-image {
      border: 3px dashed white;
    }

    .image-container__select-image-container {
      max-height: 12rem;
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
    }

    .select-image-container__image-wrap {
      height: 5rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-wrap__select-wrap {
      height: 1.5rem;
      width: 1.5rem;
      background: $atmo-purple--medium-dark--overlay-faded;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .two-column-grid {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 1.5rem;
    }

    .three-column-grid {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(3, 1fr);
      margin-bottom: 1.5rem;
    }

    .four-column-grid {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 1.5rem;
    }

    .field-wrap {
      display: flex;
      flex-direction: column;

      label {
        text-transform: uppercase;
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
        font-weight: 500;
      }

      .text-field {
        background: transparent;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        color: white;
      }

      .datepicker-trigger {
        input {
          background: transparent;
          border-radius: 5px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          padding: 0.5rem 0.5rem 0.5rem 0.5rem;
          color: white;
        }
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(255, 255, 255, 0.4);
        opacity: 1;
        /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(255, 255, 255, 0.4);
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(255, 255, 255, 0.4);
      }

      .dropdown-group {
        background: $atmo-purple--medium-dark;
        color: white;
        text-transform: uppercase;
        border: none;
        border-radius: 5px;
        padding: 0.5rem;
        font-weight: 500rem;
        cursor: pointer;
      }

      .dropdown-field {
      }

      .radio-group {
        display: flex;
        padding: 0.6rem 0rem 0.6rem 0rem;
      }

      .radio-field-wrap {
        margin-right: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .radio-field {
        margin-right: 0.5rem;
        cursor: pointer;
      }

      .radio-field-label {
        margin: 2px 0px 0px 0px;
      }
    }

    .create-ticket-container {
      margin-top: 2rem;
    }

    .create-ticket-container__header {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 1rem;
      color: $atmo-blue--medium;
    }

    .fields-container__button-wrap {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 1rem;
    }

    .button-wrap__save-cancel-container {
      display: flex;

      .save-cancel-container__save-button {
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: 0.5rem 2rem 0.5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
      }

      .save-cancel-container__cancel-button {
        border: 1px solid white;
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        border-radius: 5px;
        padding: 0.5rem 1.5rem 0.5rem 1.5rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }

    // .button-wrap__account-settings-container {
    //   display: flex;
    //   margin-left: auto;

    //   .account-settings-container__account-settings-button {
    //     font-size: 1rem;
    //     text-transform: uppercase;
    //     color: white;
    //     background-color: $atmo-purple--medium;
    //     border-radius: 5px;
    //     padding: .5rem 1.5rem .5rem 1.5rem;
    //     font-weight: 500;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   }

    //   .account-settings-button__image {
    //     height: 1rem;
    //     width: auto;
    //     margin-right: .5rem;
    //   }
    // }
  }

  .edit-atmo-event__tickets-container {
    .tickets-container__header {
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 0.4rem;
      text-transform: uppercase;
    }

    .event-ticket {
      background-color: rgba($atmo-purple--extra-dark, 0.3);
      backdrop-filter: blur(5px);
      min-height: 80px;
      font-size: $atmo-base-size;
      border-radius: 5px;
      position: relative;
      margin-bottom: 1rem;
      font-size: 0.8rem;

      &:hover {
        .event-ticket__decoration {
          background-color: $atmo-purple--medium;
        }
      }

      .event-ticket__decoration {
        background-color: white;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        width: 0.5rem;
        position: absolute;
        top: 0rem;
        bottom: 0rem;
        left: 0rem;
      }

      .event-ticket__content {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-left: 0.5rem;
        font-size: 0.8rem;
      }

      .content__description-container {
        padding: 0.5rem 0rem 0.5rem 0rem;
        display: flex;
        justify-content: space-between;
        // margin-bottom: 1rem;
        // border-bottom: 1px solid white;
      }

      .description-container__title-wrap {
        margin-bottom: 1rem;
        font-size: 0.8rem;
        color: $atmo-blue--medium;
        font-weight: 600;
      }

      .description-container__price-delete-wrap {
        max-width: 8rem;
      }

      .price-delete-wrap__price-wrap {
        margin-bottom: 1rem;
      }

      .price-delete-wrap__delete-wrap {
        height: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 1.2rem;
          cursor: pointer;
        }
      }

      .content__date-title-container {
        display: flex;
        justify-content: space-between;
      }

      &__price {
        // font-size: $atmo-subtitle-size;
        font-weight: bold;
        display: flex;
        flex-direction: row-reverse;
      }

      &__incrementor {
        img {
          max-width: 12px;
        }

        button {
          background: $atmo-purple--dark;
          border-color: $atmo-purple--dark;
        }

        &__left-button {
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;

          img {
            padding-bottom: 3px;
          }
        }

        &__right-button {
          border-top-right-radius: 25px;
          border-bottom-right-radius: 25px;
        }
      }

      &__date {
        border-color: white;

        img {
          vertical-align: middle;
          max-width: 12px;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
