<template>
  <div class="atmo-popover">
    <Popper :placement="placement" :arrow="arrow">
      <template #default>
        <div class="atmo-popover__trigger" tabindex="0">
          <slot name="trigger" />
        </div>
      </template>
      <template #content>
        <div class="atmo-popover__content">
          <slot name="content" />
        </div>
      </template>
    </Popper>
  </div>
</template>

<script>
import Popper from "vue3-popper";

export default {
  components: { Popper },
  props: {
    placement: {
      type: String,
      default: "right",
    },
    arrow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-popover {
  &__trigger {
    outline: none;
    cursor: pointer;
  }

  .atmo-popover__content {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    font-weight: 300;
    background-color: $atmo-purple--medium-dark;
    border-radius: 5px;
    border: none;
    box-shadow: 1px 1px 8px 0px rgba(black, 0.3);
    padding: 8px;
    cursor: pointer;
  }

  .hide-arrow .popper__arrow {
    display: none;
  }
}
</style>
