<template>
  <AtmoModal
    id="playlist-picker-modal"
    ref="emojiPickerModalRef"
    v-model="$store.state.playlistPickerModalOpen"
    title="Select a Playlist"
    customClass="playlist-picker-modal"
    size="small"
    :closeFunction="() => $store.commit('togglePlaylistPickerModal', null)"
  >
    <header class="playlist-picker-modal__header atmo-modal__header">
      <ModalHeader heading="Select Playlist" marginBottom="2rem" />
    </header>
    <div class="playlist-picker-modal__body">
      <perfect-scrollbar v-if="playlists.length">
        <ul>
          <li v-for="playlist in playlists" :key="playlist.id" @click="selectPlaylist(playlist)" class="playlist-item">
            {{ playlist.name }}
          </li>
        </ul>
      </perfect-scrollbar>
      <div v-else>No playlists found.</div>

      <div class="create-playlist">
        <input v-model="newPlaylistName" placeholder="New playlist name" class="playlist-input" />
        <button @click="createPlaylist" class="atmo-button atmo-button--primary playlist-button">Create</button>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import { mapActions, mapGetters } from "vuex";
import ModalHeader from "@/components/modal-header";

export default {
  components: {
    ModalHeader,
  },
  data() {
    return {
      newPlaylistName: "",
    };
  },
  computed: {
    ...mapGetters("playlists", ["currentUserPlaylists"]),
    playlists() {
      return this.currentUserPlaylists || [];
    },
  },
  methods: {
    ...mapActions("playlists", ["createPlaylist"]),
    async selectPlaylist(playlist) {
      await this.$store.dispatch("playlists/addToPlaylist", {
        playlistId: playlist.id,
        song: this.$store.state.playlistPickerModalSelectedSong,
      });
      this.$store.commit("togglePlaylistPickerModal", null);
    },
    async createPlaylist() {
      if (!this.newPlaylistName.trim()) {
        console.error("Playlist name is empty!");
        return;
      }

      try {
        console.log("Creating playlist with name:", this.newPlaylistName); // Debugging
        const newPlaylist = await this.$store.dispatch("playlists/createPlaylist", {
          name: this.newPlaylistName.trim(), // Ensure no extra spaces
        });

        if (newPlaylist) {
          this.selectPlaylist(newPlaylist);
        }
      } catch (error) {
        console.error("Error creating playlist:", error);
      }
    },
  },
};
</script>

<style lang="scss">
.playlist-picker-modal {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .atmo-modal__header {
    display: flex;
    justify-content: center;
  }

  .ps {
    height: 20vh;
  }
}

.playlist-item {
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
}

.playlist-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.create-playlist {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .playlist-input {
    width: 100%;
    margin-bottom: 0.5rem;
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    color: white;
  }

  .playlist-button {
    background-color: #1db954;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
  }

  .playlist-button:hover {
    background-color: #17a54a;
  }
}
</style>
