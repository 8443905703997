<template>
  <AtmoModal id="broadcast" ref="broadcastRef" v-model="isBroadcastModalOpen" title="broadcast" customClass="posts-modal" @opened="loadBroadcasts">
    <img class="broadcast__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Broadcast" @click="hideModal()" />
    <header class="broadcast__header atmo-modal__header">
      <div class="atmo-page-modal-header-container">
        <ModalHeader heading="Broadcast" marginBottom="0rem" />
      </div>
    </header>
    <div class="posts-grid">
      <div class="posts-grid__friends-container">
        <div class="container__heading-wrap">Filter Posts</div>
        <div>
          <div class="friends-container__search-wrap">
            <input class="search-field-rounded" type="text" placeholder="Search for a friend" v-model="searchQuery" @input="filterList" />
          </div>
          <div class="friends-container__friends-wrap">
            <div class="friends-wrap__header">Friends</div>
            <div class="users-wrap">
              <perfect-scrollbar>
                <div class="users-wrap__user-wrap" v-for="friendship in filteredFriendList" :key="friendship.id" @click="selectUserProfileLink">
                  <profile-link :profile-type="friendship.friend.profile_type" :profile-id="friendship.friend.profile_id" class="broadcast-friend">
                    <div class="user-wrap__image-wrap"><img class="image-wrap__image" :src="getFeaturedImage(friendship.friend.images, 'full')" /></div>
                    <div class="user-wrap__name-wrap">{{ friendship.friend.name }}</div>
                  </profile-link>
                </div>
              </perfect-scrollbar>
            </div>
          </div>
        </div>
      </div>
      <div class="posts-grid__posts-container">
        <div class="container__heading-wrap">
          <div class="heading-wrap__text-wrap">Posts</div>
        </div>
        <perfect-scrollbar class="posts-scroll-container">
          <atmo-posts v-if="$store.state.currentUserProfile" type="broadcast" :posts="filteredPosts" :user-profile="$store.state.currentUserProfile" />
        </perfect-scrollbar>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import AtmoPosts from "@/components/atmo-posts/atmo-posts";
import { getFeaturedImage } from "@/helpers/utilityFunctions";
import { mapActions, mapState, mapMutations } from "vuex";
import ProfileLink from "@/components/profile-link";
import ModalHeader from "@/components/modal-header";

export default {
  components: {
    AtmoPosts,
    ProfileLink,
    ModalHeader,
  },
  data: function () {
    return {
      searchQuery: "",
    };
  },
  computed: {
    ...mapState("userActivities", ["broadcastPosts", "postsLoading", "broadcastModalOpen"]),
    isBroadcastModalOpen: {
      get() {
        return this.$store.getters["userActivities/isBroadcastModalOpen"];
      },
      set(value) {
        if (value) {
          this.$store.commit("userActivities/openBroadcastModal");
        } else {
          this.$store.commit("userActivities/closeBroadcastModal");
        }
      },
    },
    filteredPosts() {
      return this.broadcastPosts.filter((post) => post.user.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    acceptedFriendships() {
      return this.$store.state.chatRooms.chatUserFriendships.filter((friendship) => friendship.state === "accepted");
    },
    filteredFriendList() {
      return this.acceptedFriendships.filter((friendship) => friendship.friend.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  methods: {
    ...mapActions("accomplishments", ["createUserAccomplishments"]),
    ...mapMutations("userActivities", ["closeBroadcastModal"]),
    loadBroadcasts() {
      this.$store.dispatch("userActivities/getBroadcastPosts", this.$store.state.currentUserId, {
        date_range: "all",
        limit: 50,
        show_current_user_posts: "true",
      });
    },
    selectUserProfileLink() {
      this.searchQuery = "";
      this.closeBroadcastModal();
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    hideModal() {
      this.searchQuery = "";
      this.closeBroadcastModal();
    },
    filterList() {
      // This method is bound to the input event and updates the filtered list
      // based on the search query in real-time.
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.posts-modal {
  .ps {
    max-height: 40rem;
  }

  .posts-scroll-container {
    max-height: 46rem;
  }

  .broadcast__hide-modal {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    opacity: 0.5;
    cursor: pointer;
  }

  .broadcast__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 2rem;

    .atmo-page-modal-header-container__header-icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .atmo-popover__actions-trigger {
      height: 18px;
      cursor: pointer;
      padding: 5px 10px;
      box-sizing: content-box;
    }

    .icon-wrap__icon {
      height: 2rem;
    }
  }

  .disengage-queue-container {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(190, 167, 221, 0.3);
    padding: 0.5rem;
    border-radius: 5px;

    .disengage-queue-container__text {
      margin-right: 1rem;
    }
  }

  .posts-grid {
    // border: 1px solid white;
    display: grid;
    grid-template-columns: minmax(max-content, 20rem) 1fr;
    grid-gap: 1rem;
    // height: 100%;
  }

  .container__heading-wrap {
    font-size: 1.5rem;
    color: $atmo-blue--medium;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2rem;
    // border: 1px solid white;
    width: 100%;
    display: flex;
    align-items: center;

    .heading-wrap__text-wrap {
      margin-right: 0.5rem;
    }

    .heading-wrap__icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .icon-wrap__icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .posts-grid__friends-container {
    // border: 1px solid white;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
  }

  .friends-container__search-wrap {
    margin-bottom: 1rem;
  }

  .search-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    // width: 100%;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .friends-container__friends-wrap {
    margin-bottom: 1rem;
  }

  .friends-wrap__header {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding: 0.3rem 0rem 0.3rem 0rem;
  }

  .members-wrap__header {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding: 0.3rem 0rem 0.3rem 0rem;
  }

  .users-wrap__user-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    // cursor: pointer;
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;

    &:hover {
      // background-color: rgba(190, 167, 221, .3);

      // .user-wrap__make-admin-wrap {
      //   display: flex;
      //   justify-content: center;
      //   align-items: center;
      //   color: $atmo-blue--medium;
      //   font-weight: 500;
      // }
    }

    .broadcast-friend {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-wrap__image {
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 100%;
      margin-right: 0.5rem;
    }

    .user-wrap__name-wrap {
      font-size: 0.8rem;
      font-weight: 500;
      margin-right: 0.5rem;
    }

    .user-wrap__make-admin-wrap {
      display: none;
    }
  }

  .posts-grid__posts-container {
    // border: 1px solid white;
    padding: 1rem;
  }

  .posts-grid__chat-container {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
  }

  .chat-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    // width: 100%;
    position: relative;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .chat-wrap__queue-chat {
    .ps {
      max-height: 38rem;
    }
  }

  .queue-chat__message {
    display: flex;
    padding: 7px 0;

    &--current-user {
      flex-direction: row-reverse;

      .queue-chat__message-content {
        padding: 7px 6px 3px;
        background: rgba($atmo-purple--light, 0.3);
        border-radius: 2px;
        margin-left: 5px;
        border: none;
      }

      .queue-chat__message-timestamp {
        align-self: flex-end;
        color: white;
      }
    }
  }

  .queue-chat__message-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 7px 6px 3px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    margin-right: 5px;
  }

  .queue-chat__message-text {
    color: white;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .queue-chat__message-song {
    display: flex;
    cursor: pointer;
  }

  .queue-chat__message-song-icon {
    height: 15px;
    width: 15px;
  }

  .queue-chat__message-song-name {
    color: white;
    cursor: pointer;
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
    margin-bottom: 5px;

    &:hover {
      color: $atmo-purple--dark;
    }
  }

  .queue-chat__message-timestamp {
    color: white;
    font-size: 0.7rem;
    font-weight: 300;
  }

  .queue-chat__messages-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .queue-chat__form {
    width: 100%;
    margin: 5px 0;
  }

  .queue-chat__text-input {
    width: 100%;
  }

  .atmo-post__main-content-container {
    background-color: rgba($atmo-purple--light, 0.4);
  }

  .atmo-avatar {
    width: 2.3rem;
    height: 2.3rem;
    min-width: unset;
    border: none;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 5px;
  }
}
</style>
