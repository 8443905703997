import Axios from 'axios';

export function callPublisherDashboardsIndex(publisherProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/publisher_profiles/${publisherProfileId}/publisher_dashboards`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callPublisherDashboardsFanInsights(publisherProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/publisher_profiles/${publisherProfileId}/publisher_dashboards/fan_insights`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callPublisherDashboardsRevenueInsights(publisherProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/publisher_profiles/${publisherProfileId}/publisher_dashboards/revenue_insights`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callPublisherDashboardsStreamingInsights(publisherProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/publisher_profiles/${publisherProfileId}/publisher_dashboards/streaming_insights`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}
