<template>
  <div class="ops-edit-page" v-if="campaign">
    <div class="ops-edit-page__wrap">
      <div>
        <div cols="3" class="ops-edit-page__navigate-back">
          <router-link v-if="$store.state.currentUserId" :to="{ name: 'users.ops_pages', params: { userId: $store.state.currentUserId } }">
            <img src="@/assets/images/icons/back.png" class="ops-edit-page__go-back-image" />
            <p>Back to campaigns</p>
          </router-link>
        </div>
      </div>

      <div class="atmo-ops-pages-campaign-form">
        <div class="campaign-form-wrap">
          <!-- Campaigns Image and Video -->
          <div class="picture-video-container">
            <div>
              <label>Campaign Picture</label>
            </div>
            <atmo-media-uploader
              :existingMediaUrl="getFeaturedImage(campaign?.images, 'full') || ''"
              componentId="image-upload"
              mediaType="image"
              @selected-image-upload="handleUploadedImage"
              height="11rem"
              width="13rem"
            />
            <div v-if="campaign?.videos">
              <label class="video-label">Campaign Video</label>
              <atmo-media-uploader
                :existingMediaUrl="featuredVideo?.preview_image?.url || ''"
                componentId="video-upload"
                mediaType="video"
                @selected-video-upload="handleUploadedVideo"
                height="11rem"
                width="13rem"
              />
            </div>
          </div>

          <!-- Campaign Details -->
          <div class="goal-title-description-container">
            <form class="description-form">
              <label>Campaign Goal ($US)</label>
              <input v-model="campaignToEdit.goal" class="form-control" type="text" placeholder="$100000" />

              <label class="mt-3">Campaign Title</label>
              <input v-model="campaignToEdit.title" class="form-control" type="text" placeholder="Enter campaign title" />

              <label for="campaign-duration">Campaign Duration</label>
              <div class="datepicker-trigger">
                <div class="datepicker-wrap">
                  <DatePicker :key="datePickerKey" v-model="formSelectedStartDate" :format="'yyyy-MM-dd'" placeholder="Select Start Date" @update:modelValue="handleDatePickerStartDateChange" />
                </div>
                <div class="datepicker-wrap">
                  <DatePicker :key="datePickerKey" v-model="formSelectedEndDate" :format="'yyyy-MM-dd'" placeholder="Select End Date" @update:modelValue="handleDatePickerEndDateChange" />
                </div>
              </div>

              <label class="mt-3">Description</label>
              <textarea v-model="campaignToEdit.description" class="form-control" placeholder="Enter your description"></textarea>
            </form>
          </div>

          <!-- Donation Options -->
          <div class="donation-container">
            <form autocomplete="off" @submit.prevent="addDonationOption">
              <label>Donation Amounts</label>
              <div class="new-donation-field-container">
                <input v-model="donateAmount" class="form-control text-center" type="text" placeholder="$" />
                <input v-model="donateLabel" class="form-control" type="text" placeholder="Enter prize description" />
                <button class="donate-action-create" type="submit">
                  <img src="@/assets/images/icons/plus--purple.png" />
                </button>
              </div>
              <perfect-scrollbar v-if="donateOptions?.length">
                <div v-for="(donateOption, index) in donateOptions" :key="index" class="new-donation-wrap">
                  <div class="donation-amount">
                    <span v-if="!editMode[index]">{{ returnCurrencyFormat(donateOption.amount) }}</span>
                    <input v-else v-model="donateOptions[index].editedAmount" @blur="saveEdit(index)" />
                  </div>
                  <div class="donation-description">
                    <span v-if="!editMode[index]">{{ donateOption.label }}</span>
                    <input v-else v-model="donateOptions[index].editedLabel" @blur="saveEdit(index)" />
                  </div>
                  <div class="donation-actions">
                    <button class="donate-action-edit" @click="toggleEditMode(index)">
                      <img src="@/assets/images/icons/edit--pencil.png" />
                    </button>
                    <button class="donate-action-delete" @click="removeDonationOption(index)">
                      <img src="@/assets/images/icons/delete.png" />
                    </button>
                  </div>
                </div>
              </perfect-scrollbar>
              <p v-else>No donation options available.</p>
            </form>
          </div>
        </div>

        <div class="ops-edit-page__edit-campaign-container">
          <button class="submit-button" @click="editOpsPage">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>Loading campaign...</div>
</template>

<script>
import format from "date-fns/format";
// import AtmoUploadCard from '@/components/atmo-upload-card';
// import AtmoOpsCampaignForm from "@/components/atmo-ops-campaign-form.vue";
import AtmoMediaUploader from "@/components/atmo-media-uploader";
import DatePicker from "vue3-datepicker";

import {
  callOpsPagesShow,
  callOpsPagesUpdate,
  callOpsPageDonationsCreate,
  callOpsPageDonationsUpdate,
  callOpsPageDonationsDelete,
  callImagesCreate,
  callVideosCreate,
  callPreviewImageCreate,
} from "@/helpers/axiosCalls";
import { deepCopy, getFeaturedImage, getFeaturedVideo } from "@/helpers/utilityFunctions";

export default {
  components: {
    AtmoMediaUploader,
    DatePicker,
    // AtmoUploadCard
    // AtmoOpsCampaignForm
  },
  data: function () {
    return {
      goal: null,
      title: null,
      description: null,
      donateAmount: null,
      donateLabel: null,
      donateOptions: [],
      editMode: [], // Array to keep track of edit mode for each item
      editedLabel: "", // Store the edited label temporarily
      dateFormat: "MMM D, YYYY",
      formSelectedStartDate: null,
      formSelectedEndDate: null,
      submitAttempted: false,
      formSelectedImageFile: null,
      formSelectedVideoFile: null,
      campaign: {},
      campaignToEdit: {},
      datePickerKey: 0,
    };
  },
  computed: {
    featuredVideo() {
      return this.getFeaturedVideo(this.campaign?.videos) || { preview_image: { url: "" }, name: "No Video" };
    },
    formSelectedDates() {
      return this.formatDates(this.formSelectedStartDate, this.formSelectedEndDate);
    },
    formattedStartDate() {
      return this.formSelectedStartDate instanceof Date ? format(this.formSelectedStartDate, "yyyy-MM-dd") : "";
    },
    formattedEndDate() {
      return this.formSelectedEndDate instanceof Date ? format(this.formSelectedEndDate, "yyyy-MM-dd") : "";
    },
  },
  created() {
    this.getOpsPage();
    this.$store.commit("setHudHeader", "Edit Campaign");
  },
  methods: {
    handleDatePickerStartDateChange(selectedDate) {
      if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
        this.formSelectedStartDate = selectedDate;
      } else {
        this.formSelectedStartDate = null; // Reset if invalid
      }
    },
    handleDatePickerEndDateChange(selectedDate) {
      if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
        this.formSelectedEndDate = selectedDate;
      } else {
        this.formSelectedEndDate = null; // Reset if invalid
      }
    },
    handleUploadedVideo(value) {
      this.formSelectedVideoFile = value;
    },
    handleUploadedImage(value) {
      this.formSelectedImageFile = value;
    },
    getFeaturedImage(images, type) {
      return getFeaturedImage(images, type);
    },
    getFeaturedVideo(videos) {
      return getFeaturedVideo(videos);
    },
    populateDatepickerWithCampaignDates(start_date, end_date) {
      this.formSelectedStartDate = start_date ? new Date(start_date) : null;
      this.formSelectedEndDate = end_date ? new Date(end_date) : null;
      this.datePickerKey++; // Force refresh
    },
    getOpsPage() {
      callOpsPagesShow(this.$route.params.opsPageId)
        .then((response) => {
          this.campaign = deepCopy(response);
          this.populateDatepickerWithCampaignDates(this.campaign.start_date, this.campaign.end_date);

          this.campaignToEdit = {
            goal: this.campaign?.goal,
            title: this.campaign?.campaign_name,
            description: this.campaign?.campaign_description,
            start_date: this.campaign?.start_date,
            end_date: this.campaign?.end_date,
            images: this.campaign?.images,
            videos: this.campaign?.videos,
          };

          this.donateOptions = this.campaign?.ops_page_donations?.map((donation) => {
            return { label: donation.description, amount: donation.amount };
          });

          console.log("CAMPAIGN TO EDIT", this.campaignToEdit);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatDates(startDate, endDate) {
      let formattedDates = "";
      if (startDate) {
        formattedDates = format(startDate, this.dateFormat);
      }
      if (endDate) {
        formattedDates += " - " + format(endDate, this.dateFormat);
      }

      return formattedDates;
    },
    returnCurrencyFormat(number) {
      // Create number formatter (Javascript - Internationalization API)
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
    addDonationOption() {
      this.donateOptions = this.donateOptions.concat({
        amount: this.donateAmount,
        label: this.donateLabel,
      });

      this.donateAmount = null;
      this.donateLabel = null;
    },
    toggleEditMode(index) {
      console.log("OPS PAGE DONATIONS", this.donateOptions[index]);
      // Toggle edit mode for the clicked item
      this.$set(this.editMode, index, !this.editMode[index]);
      // If entering edit mode, set the edited amount and label to the current values
      if (this.editMode[index]) {
        this.$set(this.donateOptions[index], "editedAmount", this.donateOptions[index].amount);
        this.$set(this.donateOptions[index], "editedLabel", this.donateOptions[index].label);
      }
    },
    saveEdit(index) {
      // Save the edited amount and label and exit edit mode
      this.$set(this.donateOptions[index], "amount", parseFloat(this.donateOptions[index].editedAmount));
      this.$set(this.donateOptions[index], "label", this.donateOptions[index].editedLabel);
      this.$set(this.editMode, index, false);
      // Optionally, you can perform additional actions here like updating your backend
    },
    removeDonationOption(index) {
      this.donateOptions.splice(index, 1);
    },
    async synchronizeDonations() {
      const donationsToDelete = this.campaign?.ops_page_donations.filter((oldDonation) => {
        return !this.donateOptions.some((newDonation) => newDonation.amount === oldDonation.amount && newDonation.label === oldDonation.description);
      });

      const donationsToUpdate = this.donateOptions.filter((newDonation) => {
        return this.campaign?.ops_page_donations.some((oldDonation) => newDonation.amount === oldDonation.amount && newDonation.label === oldDonation.description);
      });

      const donationsToCreate = this.donateOptions.filter((newDonation) => {
        return !this.campaign?.ops_page_donations.some((oldDonation) => newDonation.amount === oldDonation.amount && newDonation.label === oldDonation.description);
      });

      // Delete donations that were removed
      const deletePromises = donationsToDelete.map((donation) => {
        return callOpsPageDonationsDelete(this.campaign.id, donation.id);
      });

      await Promise.all(deletePromises);

      // Update donations that were modified
      const updatePromises = donationsToUpdate.map((newDonation) => {
        const oldDonation = this.campaign?.ops_page_donations.find((oldDonation) => newDonation.amount === oldDonation.amount && newDonation.label === oldDonation.description);
        return callOpsPageDonationsUpdate(this.campaign.id, oldDonation.id, {
          description: newDonation.label,
          amount: newDonation.amount,
          ops_page_id: this.campaign.id,
        });
      });

      await Promise.all(updatePromises);

      // Create new donations
      const createPromises = donationsToCreate.map((newDonation) => {
        return callOpsPageDonationsCreate(this.campaign.id, {
          description: newDonation.label,
          amount: newDonation.amount,
          ops_page_id: this.campaign.id,
        });
      });

      await Promise.all(createPromises);
    },
    async editOpsPage() {
      this.$toast("Saving campaign...");

      try {
        const formData = new FormData();
        formData.append("campaign_name", this.campaignToEdit.title);
        formData.append("campaign_description", this.campaignToEdit.description);
        formData.append("goal", this.campaignToEdit.goal);
        formData.append("user_id", this.$store.state.currentUserId);
        formData.append("start_date", this.formSelectedStartDate);
        formData.append("end_date", this.formSelectedEndDate);

        // Append donations correctly
        formData.append(
          "donations",
          JSON.stringify(
            this.donateOptions.map((donation) => ({
              amount: donation.amount,
              description: donation.label, // Ensure correct mapping
            }))
          )
        );

        // Append image if selected
        if (this.formSelectedImageFile) {
          formData.append("image", this.formSelectedImageFile);
          formData.append("is_featured_image", "true");
        }

        // Append video if selected
        if (this.formSelectedVideoFile) {
          formData.append("video", this.formSelectedVideoFile);
          formData.append("is_featured_video", "true");
        }

        // Send update request
        await callOpsPagesUpdate(this.campaign.id, formData);

        this.$toast("Campaign saved successfully", { type: "success" });
        this.$router.push({ name: "ops_pages.show", params: { opsPageId: this.campaign.id } });
      } catch (error) {
        console.error(error);
        this.$toast("Failed to save campaign", { type: "error" });
      }
    },
  },
};
</script>

<style lang="scss">
.ops-edit-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  &__navigate-back {
    margin-bottom: 1rem;

    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }

    p {
      display: inline;
      font-weight: bold;
      padding-left: 6px;
    }
  }

  &__edit-campaign-container {
    padding: 0.5rem 2.2rem;
    text-transform: none;
    margin-top: 2rem;
    float: right;
  }

  .atmo-ops-pages-campaign-form {
    .campaign-form-wrap {
      display: grid;
      grid-template-columns: auto 2fr 1fr;
      gap: 2rem;
    }

    .ps {
      max-height: 19.5rem;
    }

    label {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .form-control {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.4);
    }

    textarea {
      min-height: 7.4rem;
    }

    textarea,
    textarea::placeholder {
      font-family: "Roboto", sans-serif;
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.4);
    }

    .picture-video-container {
      border-radius: 5px;
    }

    .campaign-image-container {
      text-align: center;
      border: 2px dashed rgba(255, 255, 255, 0.4);
      border-radius: 2px;
      min-height: 15rem;
      min-width: 15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      img {
        height: 5rem;
      }
    }

    .upload-video-label {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin: 1rem 0rem 1rem 0rem;

      .video-label {
        margin-bottom: 0rem;
      }

      img {
        height: 1rem;
      }
    }

    .goal-title-description-container {
      border-radius: 5px;
    }

    .description-form {
      display: flex;
      flex-direction: column;
    }

    input {
      margin-bottom: 1rem;
    }

    .datepicker-trigger {
      input {
        background: transparent;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        color: white;
      }
    }

    .donation-container {
      border-radius: 5px;
    }

    .new-donation-field-container {
      display: flex;
      // justify-content: space-between;
      margin-bottom: 1rem;
      gap: 1rem;
    }

    .amount-container {
      max-width: 5rem;
      margin-right: 0.5rem;
    }

    .description-container {
      margin-right: 0.5rem;
    }

    .add-amount-container {
      width: auto;
    }

    .donate-action-create {
      background-color: white;
      border-radius: 5px;
      border: none;
      height: 2.3rem;
      width: 2.3rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 1rem;
      }
    }

    .new-donation-wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem; // Add spacing instead of forcing overflow
      overflow: hidden; // Prevent horizontal scrolling
    }

    .donation-actions {
      display: flex;
      gap: 0.5rem;
    }

    .donation-amount {
      font-size: 1.2rem;
      font-weight: bold;
      /* background-color: $atmo-purple--medium-dark--faded; */
      min-width: 5rem;
      display: flex;
      height: 3rem;
      align-items: flex-start;
      margin-right: 1rem;

      .amount-wrap {
        background-color: rgba(96, 72, 117, 0.3);
        border-radius: 5px;
        padding: 0.5rem;
        min-width: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .donate-action-edit {
      /* background-color: $atmo-purple--medium-dark; */
      background-color: #604875;
      border-radius: 100%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin-left: 0.5rem;
      border: none;

      img {
        height: 0.6rem;
      }
    }

    .donate-action-delete {
      /* background-color: $atmo-invalid; */
      background-color: #dc3545;
      margin-left: 6px;
      border-radius: 100%;
      height: 1.5rem;
      width: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;

      img {
        height: 0.8rem;
      }
    }

    .submit-button {
      color: white;
      background-color: transparent;
      background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
      border-radius: 5px;
      font-weight: 500;
      font-size: 1rem;
      border: none;
      padding: 0.6rem 1rem 0.6rem 1rem;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}
</style>
