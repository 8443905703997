// frontend/client/cable.js
// import cable from "actioncable";

// const ACTION_CABLE_URL = process.env.VUE_APP_ACTION_CABLE_URL || "ws://localhost:3000/cable";

// let consumer;

// function createChannel(channelName, roomId, acCallback) {
//   if (!consumer) {
//     consumer = cable.createConsumer(ACTION_CABLE_URL);
//   }
//   return consumer.subscriptions.create({ channel: channelName, room: roomId }, acCallback);
// }

// export default createChannel;


import cable from "actioncable";

let consumer;

function createChannel(channelName, roomId, acCallback) {
  if (!consumer) {
    consumer = cable.createConsumer();
  }
  return consumer.subscriptions.create({channel: channelName, room: roomId}, acCallback);
}

export default createChannel;
