import Axios from 'axios';

export function callSongsIndex(queryParams = {}) {
    return Axios.get(`/api/v1/songs`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongsExplore(queryParams = {}) {
    return Axios.get(`/api/v1/songs/explore`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongsNewReleases(queryParams = {}) {
    return Axios.get(`/api/v1/songs/new_releases`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongsSuggested(queryParams = {}) {
    return Axios.get(`/api/v1/songs/suggested`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongsShow(songId, queryParams = {}) {
    return Axios.get(`/api/v1/songs/${songId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}


export function callSongsCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/songs`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongsUpdate(songId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/songs/${songId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callSongsDelete(songId, queryParams = {}) {
    return Axios.delete(`/api/v1/songs/${songId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}
