import {
    callUserFriendshipsIndex,
    callUserFriendshipsCreate,
    callUserFriendshipsDelete,
    callUserFriendshipsAccept,
    callUserFriendshipsDecline,
    callUserFriendshipsMutualFriends
} from '@/helpers/axiosCalls';
import { connectToUserFriendshipsChannel, setCallback as setFriendshipsCallback } from '@/cable-channels/userFriendships';

export default {
    namespaced: true,
    state: {
        debug: false,
        userFriendships: [],
        currentUserFriendships: [],
        mutualFriends: [],
        isConnected: false // Track connection status
    },
    mutations: {
        setUserFriendships(state, friendships) {
            if (state.debug) console.log('Mutation: setUserFriendships', friendships);
            state.userFriendships = friendships;
        },
        //TODO: Clean this up. Duplicate of setUserFriendships
        setCurrentUserFriendships(state, friendships) {
            if (state.debug) console.log('Mutation: setCurrentUserFriendships', friendships);
            state.currentUserFriendships = friendships;
        },
        setMutualFriends(state, friends) {
            if (state.debug) console.log('Mutation: setMutualFriends', friends);
            state.mutualFriends = friends;
        },
        setConnectionStatus(state, status) {
            if (state.debug) console.log('Mutation: setConnectionStatus', status);
            state.isConnected = status;
        }
    },
    actions: {
        async fetchUserFriendships({ commit, state }, userId) {
            try {
                if (state.debug) console.log(`Fetching user friendships for user ID: ${userId}`);
                const response = await callUserFriendshipsIndex({ user_id: userId });
                commit('setUserFriendships', response);
            } catch (error) {
                console.error(error);
            }
        },
        async fetchCurrentUserFriendships({ commit, state }, userId) {
            try {
                if (state.debug) console.log(`Fetching user friendships for user ID: ${userId}`);
                const response = await callUserFriendshipsIndex({ user_id: userId });
                commit('setCurrentUserFriendships', response);
            } catch (error) {
                console.error(error);
            }
        },
        async fetchMutualFriends({ commit, state }, { user1_id, user2_id }) {
            try {
                if (state.debug) console.log(`Fetching mutual friends between user1 ID: ${user1_id} and user2 ID: ${user2_id}`);
                const response = await callUserFriendshipsMutualFriends({ user1_id, user2_id });
                commit('setMutualFriends', response);
            } catch (error) {
                console.error(error);
            }
        },
        async sendFriendRequest({ dispatch, rootState, state }, friendId) {
            try {
                if (state.debug) console.log(`Sending friend request from user ID: ${rootState.currentUserId} to friend ID: ${friendId}`);
                const createdFriendships = await callUserFriendshipsCreate({
                    user_id: rootState.currentUserId,
                    friend_id: friendId
                });

                dispatch('fetchUserFriendships', rootState.currentUserId);

                await dispatch('accomplishments/createUserAccomplishments', {
                    userId: rootState.currentUserId,
                    accomplishmentData: {
                        payloads: [
                            {
                                user_id: rootState.currentUserId,
                                category: 'friendRequests',
                                sub_category: 'friendRequestNumber'
                            }
                        ]
                    }
                }, { root: true });

                await dispatch('notifications/createNotification', {
                    userId: friendId,
                    payload: {
                        sender_id: rootState.currentUserId,
                        receiver_id: friendId,
                        notification_type_name: 'Friend Request',
                        status: 'unread',
                        notifiable_type: 'UserFriendship',
                        notifiable_id: createdFriendships.user_friendship.id
                    }
                }, { root: true });
            } catch (error) {
                console.error(error);
            }
        },
        async cancelPendingFriendRequest({ dispatch, rootState, state }, friendshipId) {
            try {
                if (state.debug) console.log(`Canceling pending friend request with ID: ${friendshipId}`);
                await callUserFriendshipsDelete(friendshipId);
                dispatch('fetchUserFriendships', rootState.currentUserId);
            } catch (error) {
                console.error(error);
            }
        },
        async unfriendUser({ dispatch, rootState, state }, friendshipId) {
            try {
                if (state.debug) console.log(`Unfriending user with friendship ID: ${friendshipId}`);
                await callUserFriendshipsDelete(friendshipId);
                dispatch('fetchUserFriendships', rootState.currentUserId);
            } catch (error) {
                console.error(error);
            }
        },
        async acceptFriendRequest({ dispatch, rootState, state }, friendshipId) {
            try {
                if (state.debug) console.log(`Accepting friend request with ID: ${friendshipId}`);
                await callUserFriendshipsAccept(friendshipId);
                dispatch('fetchUserFriendships', rootState.currentUserId);

                dispatch('accomplishments/createUserAccomplishments', {
                    userId: rootState.currentUserId,
                    accomplishmentData: {
                        payloads: [
                            {
                                user_id: rootState.currentUserId,
                                category: 'friends',
                                sub_category: 'friendNumber'
                            }
                        ]
                    }
                }, { root: true });
            } catch (error) {
                console.error(error);
            }
        },
        async declineFriendRequest({ dispatch, rootState, state }, friendshipId) {
            try {
                if (state.debug) console.log(`Declining friend request with ID: ${friendshipId}`);
                await callUserFriendshipsDecline(friendshipId);
                dispatch('fetchUserFriendships', rootState.currentUserId);
            } catch (error) {
                console.error(error);
            }
        },
        connectToUserFriendships({ commit, dispatch, rootState, state }) {
            const userId = rootState.currentUserId;
            if (userId && !state.isConnected) {
                if (state.debug) console.log(`Connecting to user friendships channel for user ID: ${userId}`);
                connectToUserFriendshipsChannel(userId);
                setFriendshipsCallback(() => {
                    if (state.debug) console.log("Callback triggered: fetching user friendships");
                    dispatch('fetchUserFriendships', userId);
                });
                commit('setConnectionStatus', true);
            }
        }
    },
    getters: {
        getUserFriendships: state => {
            if (state.debug) console.log('Getter: getUserFriendships', state.userFriendships);
            return state.userFriendships;
        },
        getCurrentUserFriendships: state => {
            if (state.debug) console.log('Getter: getCurrentUserFriendships', state.currentUserFriendships);
            return state.currentUserFriendships;
        },
        getMutualFriends: state => {
            if (state.debug) console.log('Getter: getMutualFriends', state.mutualFriends);
            return state.mutualFriends;
        }
    }
};
