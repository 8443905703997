import Axios from 'axios';

export function callPublisherProfilesShow(publisherProfileId, queryParams = {}) {
    return Axios.get(`/api/v1/publisher_profiles/${publisherProfileId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPublisherProfilesUpdate(publisherProfileId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/publisher_profiles/${publisherProfileId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callPublisherProfilesCatalog(publisherProfileId, queryParams = {}) {
    return Axios.get(`/api/v1/publisher_profiles/${publisherProfileId}/catalog`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}