import HowlerManager from "@/helpers/services/HowlerManager";
import createChannel from "@/cable";

let callback;
let liveListeningChannel = null;

/**
 * Connects to the LiveListeningPlayerChannel for a given room.
 */
function connectToLiveListeningPlayerChannel(roomId) {
  console.log("[Connecting to LiveListeningPlayerChannel] Room ID:", roomId);
  liveListeningChannel = createChannel(`LiveListeningPlayerChannel`, roomId, {
    received(data) {
      console.log("[Live Listening Update Received]:", data);
      
      if (data.event_type === "request_sync" && typeof callback === "function") {
        callback({ event_type: "request_sync", room: data.room });
      } else if (callback) {
        console.log("Timestamp from channel data:", data.timestamp);
        callback(data);
      }
    }
  });
}

function disconnectFromLiveListeningPlayerChannel() {
  if (liveListeningChannel) {
    liveListeningChannel.unsubscribe();
    console.log("[Disconnected from LiveListeningPlayerChannel]");
    liveListeningChannel = null;
  }
}

/**
 * Sets a callback to be invoked whenever data is received.
 */
function setCallback(fn) {
  callback = fn;
}

/**
 * Ensures Howler instance is loaded before syncing playback.
 */
function ensureHowlerLoaded(track) {
  if (!track || !track.url) return;

  if (!HowlerManager.getHowl(track)) {
    console.log("🔄 Creating new Howl instance for:", track.name);
    HowlerManager.createHowl(track);
  }
}

/**
 * Processes the incoming playback update.
 * (This helper is provided in case you wish to call it from Vuex actions.)
 */
function handleLiveListeningUpdates(store, data) {
  console.log("[Live Listening Update Received]", data);
  switch (data.event_type) {
    case "sync":
      store.commit("liveListeningRooms/setCurrentTrack", data.track);
      store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
      store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
      break;
    case "play_pause":
      // If the received timestamp is 0 but we have a nonzero local timestamp, keep the local one.
      if (data.timestamp === 0 && this.$store.getters["liveListeningRooms/currentTimestamp"] > 0) {
        this.$store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
      } else {
        this.$store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
        this.$store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
      }
      break;      
    case "seek":
      store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
      if (data.track) {
        store.commit("liveListeningRooms/setCurrentTrack", data.track);
      }
      break;
    case "update_track":
      store.commit("liveListeningRooms/setCurrentTrack", data.track);
      store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
      store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
      break;
    default:
      console.warn("Unhandled live listening event_type:", data.event_type);
  }
}

/**
 * Sends a track update message.
 * Call this only when the user explicitly changes the track.
 */
function sendTrackUpdate(roomId, track) {
  if (liveListeningChannel) {
    liveListeningChannel.send({
      event_type: "update_track", // this key is used on the Rails side to route the message
      room: roomId,
      track: track  // Ensure the track object includes at least an id and a url.
    });
  } else {
    console.error("Live listening channel is not connected.");
  }
}

/**
 * Syncs the player's playback based on received timestamp.
 */
function syncPlayer(track, timestamp, isPlaying) {
  if (!track) {
      console.warn("⚠️ No track provided for sync!");
      return;
  }

  if (!HowlerManager.getHowl(track)) {
      console.log("🔄 Howl instance not ready. Waiting for load...");
      
      // Wait for Howler to load, then try syncing again
      setTimeout(() => syncPlayer(track, timestamp, isPlaying), 500);
      return;
  }

  // Delay seeking slightly to ensure Howler is ready
  setTimeout(() => {
      const offset = 0.5; // Adjust for network lag
      const targetTime = timestamp + offset;
      console.log(`[🔄 Adjusted Sync] Seeking to ${targetTime} (original: ${timestamp})`);
      
      HowlerManager.seekHowl(track, targetTime);
      if (isPlaying) {
          HowlerManager.playHowl(track);
      } else {
          HowlerManager.pauseHowl(track);
      }
  }, 500);
}

/**
 * Sends a play/pause event.
 * Call this only when the user explicitly clicks play or pause.
 * Now includes the current track data.
 */
function sendPlaybackState(roomId, isPlaying, timestamp, track, store) {
  if (liveListeningChannel) {
    liveListeningChannel.send({
      event_type: "play_pause",
      room: roomId,
      is_playing: isPlaying,
      timestamp: timestamp,
      track: track || store.getters["liveListeningRooms/currentTrack"]
    });
  } else {
    console.error("Live listening channel is not connected.");
  }
}

/**
 * Sends a request to sync playback.
 */
function sendRequestSync(roomId) {
  console.log("SENDING REQUEST SYNC FROM CHANNEL");
  if (liveListeningChannel) {
    liveListeningChannel.send({ event_type: "request_sync", room: roomId });
  } else {
    console.error("Live listening channel is not connected.");
  }
}

/**
 * Sends a sync update with the latest track state.
 */
function sendSyncUpdate(roomId, track, timestamp, isPlaying) {
  console.log("[sendSyncUpdate] Called with Room ID:", roomId, "Track ID:", track?.id, "Timestamp:", timestamp, "isPlaying:", isPlaying);

  ensureHowlerLoaded(track);

  if (track) {
    const latestTimestamp = HowlerManager.getCurrentTime(track);
    timestamp = latestTimestamp > 0 ? latestTimestamp : timestamp;
  }

  console.log("[sendSyncUpdate] Sending Sync Event - Updated Timestamp:", timestamp);

  if (liveListeningChannel) {
    liveListeningChannel.send({
      event_type: "sync",
      room: roomId,
      track: track,
      timestamp: timestamp,
      is_playing: isPlaying
    });
  } else {
    console.error("[sendSyncUpdate] Live listening channel is not connected.");
  }
}

export {
  connectToLiveListeningPlayerChannel,
  disconnectFromLiveListeningPlayerChannel,
  setCallback,
  handleLiveListeningUpdates,
  sendTrackUpdate,
  sendPlaybackState,
  sendRequestSync,
  sendSyncUpdate,
  syncPlayer,
  ensureHowlerLoaded
};
