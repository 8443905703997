import { toast } from "vue3-toastify";
import { callSongReferencesIndex, callSongReferencesCreate, callSongReferencesDelete, callPlaylistsIndex, callPlaylistsCreate } from "@/helpers/axiosCalls";

export default {
  namespaced: true,
  state: {
    debug: false,
    currentUserPlaylists: [],
    currentPlaylistSongs: [],
    playlistLoaded: false,
  },
  mutations: {
    setCurrentUserPlaylists(state, playlists) {
      state.currentUserPlaylists = playlists;
      state.playlistLoaded = true;
    },
    setCurrentPlaylistSongs(state, playlistSongs) {
      state.currentPlaylistSongs = playlistSongs;
    },
    //NONE OF THIS WAS IN STORE:
    // addPlaylist(state, playlist) {
    //     state.currentUserPlaylists.push(playlist);
    // },
    // updatePlaylist(state, updatedPlaylist) {
    //     const index = state.currentUserPlaylists.findIndex(p => p.id === updatedPlaylist.id);
    //     if (index !== -1) {
    //         state.currentUserPlaylists.splice(index, 1, updatedPlaylist);
    //     }
    // },
    // deletePlaylist(state, playlistId) {
    //     const index = state.currentUserPlaylists.findIndex(p => p.id === playlistId);
    //     if (index !== -1) {
    //         state.currentUserPlaylists.splice(index, 1);
    //     }
    // }
  },
  actions: {
    async addToPlaylist({ dispatch, rootState }, { playlistId, song }) {
      try {
        await callSongReferencesCreate({
          songable_id: playlistId,
          songable_type: "Playlist",
          song_id: song.id,
          user_id: rootState.currentUserId,
        });

        toast("Song Added To Playlist", { type: "success" });
        dispatch("loadPlaylistSongs", playlistId);
      } catch (error) {
        toast("Error sharing song", { type: "error" });
        console.error("Error in shareSongAsMessage:", error);
      }
    },
    async loadUserPlaylists({ commit, rootState }) {
      // API call to fetch user playlists
      const response = await callPlaylistsIndex(rootState.currentUserId);
      commit("setCurrentUserPlaylists", response);
    },
    async loadPlaylistSongs({ commit }, playlistId) {
      // API call to fetch songs in a specific playlist
      const response = await callSongReferencesIndex({
        songable_id: playlistId,
        songable_type: "Playlist",
      });
      commit("setCurrentPlaylistSongs", response);
    },
    async createPlaylist({ commit, rootState }, playlistData) {
      try {
        const requestData = {
          name: playlistData.name,
          user_id: rootState.currentUserId,
          is_public: true,
        };

        const newPlaylist = await callPlaylistsCreate(rootState.currentUserId, requestData);
        if (newPlaylist) {
          commit("setCurrentUserPlaylists", [...rootState.playlists.currentUserPlaylists, newPlaylist]);
          toast("Playlist Created!", { type: "success" });
        }

        return newPlaylist;
      } catch (error) {
        toast("Error creating playlist", { type: "error" });
        console.error("Error in createPlaylist:", error);
      }
    },
    async createPlaylistFromStation({ commit, state, rootState }, { station }) {
      try {
        if (!station.station_songs || station.station_songs.length === 0) {
          console.warn("Station is empty or has no songs.");
          return;
        }

        const capitalType = station.type = station.type.charAt(0).toUpperCase() + station.type.slice(1);

        const newPlaylist = await callPlaylistsCreate(rootState.currentUserId, {
          name: `Station: ${capitalType} - ${station.query}`,
          user_id: rootState.currentUserId,
          is_public: true,
        });

        if (newPlaylist) {
          commit("setCurrentUserPlaylists", [...rootState.playlists.currentUserPlaylists, newPlaylist]);
          toast("Playlist Created!", { type: "success" });
        }

        // Loop through each song in the station and add to the queue
        for (const song of station.station_songs) {
          // Create the song reference in the AtmoQueue
          await callSongReferencesCreate({
            songable_id: newPlaylist.id,
            songable_type: "Playlist",
            song_id: song.id,
            user_id: rootState.currentUserId,
          });
        }

        console.log("Playlist successfully created from station.");
      } catch (error) {
        console.error("Failed to create station:", error);
      }
    },
    // async removeFromPlaylist({ dispatch }, { playlistId, song }) {
    //     const response = await callSongReferencesDelete(song.id);
    //     if (response) {
    //         dispatch('loadPlaylistSongs', playlistId); // Refresh playlist songs
    //     }
    // },
    // async editPlaylist({ commit }, updatedPlaylist) {
    //     // Logic for updating a playlist
    //     commit('updatePlaylist', updatedPlaylist);
    // },
    // async deletePlaylist({ commit }, playlistId) {
    //     // Logic for deleting a playlist
    //     commit('deletePlaylist', playlistId);
    // }
  },
  getters: {
    currentUserPlaylists: (state) => state.currentUserPlaylists,
    currentPlaylistSongs: (state) => state.currentPlaylistSongs,
    playlistLoaded: (state) => state.playlistLoaded,
  },
};
