export default {
    namespaced: true,
    state: {
      isEditProfileModalOpen: false,
      isFilterProfilesModalOpen: false,
    },
    mutations: {
      toggleEditProfileModal(state) {
        state.isEditProfileModalOpen = !state.isEditProfileModalOpen;
      },
      toggleFilterProfilesModal(state) {
        state.isFilterProfilesModalOpen = !state.isFilterProfilesModalOpen;
      },
    },
    actions: {
      toggleEditProfileModal({ commit }) {
        commit('toggleEditProfileModal', true);
      },
      toggleFilterProfilesModal({ commit }) {
        commit('toggleFilterProfilesModal', true);
      },
    },
    getters: {
      isEditProfileModalOpen: (state) => state.isEditProfileModalOpen,
      isFilterProfilesModalOpen: (state) => state.isFilterProfilesModalOpen,
    },
  };
  