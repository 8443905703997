import Axios from 'axios';

export function callLabelDashboardsIndex(labelProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/label_profiles/${labelProfileId}/label_dashboards`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callLabelDashboardsFanInsights(labelProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/label_profiles/${labelProfileId}/label_dashboards/fan_insights`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callLabelDashboardsRevenueInsights(labelProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/label_profiles/${labelProfileId}/label_dashboards/revenue_insights`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}

export function callLabelDashboardsStreamingInsights(labelProfileId, queryParams = {}) {
  return Axios.get(`/api/v1/label_profiles/${labelProfileId}/label_dashboards/streaming_insights`, { params: queryParams })
    .then(response => response.data)
    .catch(error => { throw error; });
}
