<template>
  <AtmoModal
    id="edit-directory-profile"
    ref="editDirectoryProfileRef"
    title="Edit Profile"
    customClass="edit-directory-profile"
    v-model="$store.state.directoryProfiles.isEditProfileModalOpen"
    @opened="populateDropdowns"
    :closeFunction="() => $store.dispatch('directoryProfiles/toggleEditProfileModal')"
  >
    <header class="atmo-modal__header">
      <ModalHeader heading="Edit Profile" marginBottom="2rem" />
    </header>

    <form v-if="formSelectedName" class="atmo-form edit-directory-profile__form" @submit.prevent="submitForm">
      <div class="atmo-form__row">
        <div class="field-wrap">
          <label for="name">Name</label>
          <input id="name" v-model="formSelectedName" type="text" placeholder="Name" class="text-field" />
        </div>
        <div class="field-wrap">
          <label for="rate">Hourly Rate ($)</label>
          <input id="rate" v-model="formSelectedRate" type="number" placeholder="Hourly Rate" class="text-field" min="0" />
        </div>
      </div>

      <div class="atmo-form__row">
        <div class="field-wrap">
          <label for="website">Website</label>
          <input id="website" v-model="formSelectedWebsite" type="text" placeholder="Website URL" class="text-field" />
        </div>
      </div>

      <!-- Location Dropdowns -->
      <div class="atmo-form__row">
        <div class="field-wrap">
          <label for="country">Country</label>
          <select id="country" v-model="formSelectedCountry" @change="onCountryChange" class="dropdown-group">
            <option value="" disabled>Select Country</option>
            <option v-for="country in countries" :key="country.id" :value="country.id">
              {{ country.name }}
            </option>
          </select>
        </div>

        <div class="field-wrap">
          <label for="state">State</label>
          <select id="state" v-model="formSelectedState" :disabled="!stateOptions.length" @change="onStateChange" class="dropdown-group">
            <option value="" disabled>{{ stateOptions.length ? "Select State" : "No States Available" }}</option>
            <option v-for="state in stateOptions" :key="state.id" :value="state.id">
              {{ state.name }}
            </option>
          </select>
        </div>

        <div class="field-wrap">
          <label for="city">City</label>
          <select id="city" v-model="formSelectedCity" :disabled="!cityOptions.length" class="dropdown-group">
            <option value="" disabled>Select City</option>
            <option v-for="city in cityOptions" :key="city.id" :value="city.id">
              {{ city.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Profession -->
      <div class="atmo-form__row">
        <div class="field-wrap">
          <label for="profession">Profession</label>
          <select v-model="formSelectedProfession" class="dropdown-group">
            <option value="" disabled>Select Profession</option>
            <option v-for="profession in professions" :key="profession.id" :value="profession.id">
              {{ profession.name }}
            </option>
          </select>
        </div>
      </div>

      <!-- Public Toggle & Submit -->
      <div class="atmo-form__row">
        <div class="edit-directory-profile__public-toggle">
          <input type="checkbox" v-model="formIsPublic" />
          <span class="checkbox-label">{{ formIsPublic ? "Public" : "Private" }}</span>
        </div>
        <button type="submit" class="atmo-button atmo-button--primary atmo-button--stretch">Save Changes</button>
      </div>
    </form>
  </AtmoModal>
</template>

<script>
import ModalHeader from "@/components/modal-header";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    ModalHeader,
  },
  props: {
    professions: { type: Array, required: true },
    selectedName: { type: String, default: "" },
    selectedRate: { type: [Number, String], default: "0" }, // Accept String or Number
    selectedWebsite: { type: String, default: "" },
    selectedCountry: { type: Object, default: null },
    selectedState: { type: Object, default: null },
    selectedCity: { type: Object, default: null },
    selectedProfession: { type: Object, default: null },
    selectedIsPublic: { type: Boolean, default: false },
    onSubmit: { type: Function, required: true },
  },
  data() {
    return {
      formSelectedName: this.selectedName,
      formSelectedRate: Number(this.selectedRate) || 0, // Ensure it's a Number
      formSelectedWebsite: this.selectedWebsite || "",
      formSelectedCountry: this.selectedCountry?.id ?? null,
      formSelectedState: this.selectedState?.id ?? null,
      formSelectedCity: this.selectedCity?.id ?? null,
      formSelectedProfession: this.selectedProfession?.id ?? null,
      formIsPublic: this.selectedIsPublic || false,
    };
  },
  computed: {
    ...mapState("address", ["countries", "states", "cities"]),
    ...mapGetters("address", ["getCountryById", "getStateById", "getCityById"]),

    stateOptions() {
      return this.states.filter((state) => state.country_id === this.formSelectedCountry);
    },
    cityOptions() {
      return this.cities.filter((city) => city.state_id === this.formSelectedState);
    },
  },
  watch: {
    selectedCountry(newVal) {
      this.formSelectedCountry = newVal?.id ?? null;
    },
    selectedState(newVal) {
      this.formSelectedState = newVal?.id ?? null;
    },
    selectedCity(newVal) {
      this.formSelectedCity = newVal?.id ?? null;
    },
    selectedProfession(newVal) {
      this.formSelectedProfession = newVal?.id ?? null;
    },
  },
  methods: {
    ...mapActions("address", ["fetchCountries", "fetchStates", "fetchCities"]),
    async populateDropdowns() {
      await this.fetchCountries();
      if (this.formSelectedCountry) {
        await this.fetchStates(this.formSelectedCountry);
      }
      if (this.formSelectedState) {
        await this.fetchCities(this.formSelectedState);
      }
    },
    onCountryChange() {
      this.formSelectedState = null;
      this.formSelectedCity = null;
      if (this.formSelectedCountry) {
        this.fetchStates(this.formSelectedCountry);
      }
    },
    onStateChange() {
      this.formSelectedCity = null;
      if (this.formSelectedState) {
        this.fetchCities(this.formSelectedState);
      }
    },
    submitForm() {
      this.onSubmit({
        name: this.formSelectedName,
        hourly_rate: this.formSelectedRate,
        website: this.formSelectedWebsite,
        country_id: this.formSelectedCountry,
        state_id: this.formSelectedState,
        city_id: this.formSelectedCity,
        directory_profession_id: this.formSelectedProfession,
        is_public: this.formIsPublic,
      });
      this.$store.dispatch("directoryProfiles/toggleEditProfileModal");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.edit-directory-profile {
  .atmo-modal__header {
    display: flex;
    justify-content: center;
  }

  &__h2 {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__form {
    margin: 0 20px 25px;
  }

  .atmo-form__row {
    margin-bottom: 0.5rem;
  }

  .multiselect {
    flex: 1;
  }

  .field-wrap {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 0.5rem;
      font-weight: 500;
    }
  }

  .dropdown-group {
    background: $atmo-purple--medium-dark;
    color: white;
    text-transform: uppercase;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    font-weight: 500rem;
    cursor: pointer;
  }

  .text-field {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    color: white;
  }

  &__public-toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }

  //https://www.sliderrevolution.com/resources/css-checkbox/
  input[type="checkbox"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      top: 2px;
      left: 6px;
      width: 4px;
      height: 10px;
      border-style: solid;
      border-color: $atmo-purple--dark;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border-color: $atmo-blue--medium;
      background: $atmo-blue--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .checkbox-label {
    font-weight: 500;
    text-transform: uppercase;
  }
}
</style>
