<template>
  <div class="info-container__button-wrap">
    <div v-if="isMyProfile" class="button-wrap__current-user">
      <router-link class="profile-button" :to="{ name: 'user_profiles.edit', params: { userProfileId: userProfileId } }">
        <img class="profile-button__image edit" src="@/assets/images/icons/profile/edit.png" />
        Edit Profile
      </router-link>
    </div>
    <div v-else class="button-wrap__other-user">
      <button class="profile-button" v-if="friendRequestPending" data-cy="cancel-friend-request" @click="handleCancelPendingFriendRequest">
        <img class="profile-button__image unfriend" src="@/assets/images/icons/profile/delete.png" />
        Cancel Request
      </button>
      <button class="profile-button" v-else-if="canAcceptFriendRequest" @click="handleAcceptFriendRequest">
        <img class="profile-button__image add" src="@/assets/images/icons/profile/add_friend.png" />
        Accept Friend Request
      </button>
      <button class="profile-button" v-else-if="currentUserCanFriendRequest && currentUser?.profile_type === 'UserProfile'" data-cy="send-friend-request" @click="handleSendFriendRequest">
        <img class="profile-button__image add" src="@/assets/images/icons/profile/add_friend.png" />
        Add Friend
      </button>
      <button class="profile-button" v-else-if="isFriend && currentUser?.profile_type === 'UserProfile'" @click="handleUnfriendUser">
        <img class="profile-button__image unfriend" src="@/assets/images/icons/profile/delete.png" />
        Unfriend
      </button>
      <button class="profile-button" v-if="(currentUser?.profile_type === 'ArtistProfile' || currentUser?.profile_type === 'LabelProfile') && !currentUserIsFollowing" @click="handleFollowUser">
        <img class="profile-button__image add" src="@/assets/images/icons/profile/follow.png" />
        Follow
      </button>
      <button class="profile-button" v-if="(currentUser?.profile_type === 'ArtistProfile' || currentUser?.profile_type === 'LabelProfile') && currentUserIsFollowing" @click="handleUnfollowUser">
        <img class="profile-button__image unfriend" src="@/assets/images/icons/profile/delete.png" />
        Unfollow
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    user: Object,
  },
  computed: {
    ...mapGetters("userFriendships", ["getUserFriendships"]),
    ...mapGetters("userRelationships", ["getCurrentUserIsFollowing"]),
    currentUser() {
      return this.$store.state.user;
    },
    isMyProfile() {
      return this.$store.state.currentUserId == this.user.id;
    },
    userProfileId() {
      return Number(this.$route.params.userProfileId);
    },
    currentFriendship() {
      if (!this.user.id || !this.$store.state.currentUserId) {
        return undefined;
      }

      return this.getUserFriendships.find((friendship) => friendship.friend_id == this.user.id && friendship.user_id == this.$store.state.currentUserId);
    },
    currentUserCanFriendRequest() {
      return !this.isMyProfile && !this.currentFriendship;
    },
    friendRequestPending() {
      return this.currentFriendship && this.currentFriendship.state === "requested";
    },
    canAcceptFriendRequest() {
      return this.getUserFriendships.some((friendship) => friendship.friend_id == this.user.id && friendship.state == "pending");
    },
    isFriend() {
      return this.getUserFriendships.some((friendship) => friendship.friend_id == this.user.id && friendship.state == "accepted");
    },
    currentUserIsFollowing() {
      return this.getCurrentUserIsFollowing;
    },
  },
  methods: {
    ...mapActions("userFriendships", ["sendFriendRequest", "cancelPendingFriendRequest", "unfriendUser", "acceptFriendRequest"]),
    ...mapActions("userRelationships", ["followUser", "unfollowUser"]),
    async handleSendFriendRequest() {
      try {
        await this.sendFriendRequest(this.user.id);
        this.$toast("Friend request sent!", { type: "success" });
      } catch (error) {
        console.error(error);
        this.$toast("Error sending friend request", { type: "error" });
      }
    },
    async handleCancelPendingFriendRequest() {
      try {
        await this.cancelPendingFriendRequest(this.currentFriendship.id);
        this.$toast("Friend request canceled", { type: "warning" });
      } catch (error) {
        console.error(error);
        this.$toast("Error canceling friend request", { type: "error" });
      }
    },
    async handleUnfriendUser() {
      try {
        await this.unfriendUser(this.currentFriendship.id);
        this.$toast("Unfriended", { type: "warning" });
      } catch (error) {
        console.error(error);
        this.$toast("Error unfriending user", { type: "error" });
      }
    },
    async handleFollowUser() {
      try {
        await this.followUser(this.user.id);
        this.$toast("Followed user!", { type: "success" });
      } catch (error) {
        console.error(error);
        this.$toast("Error following user", { type: "error" });
      }
    },
    async handleUnfollowUser() {
      try {
        await this.unfollowUser(this.user.id);
        this.$toast("Unfollowed", { type: "warning" });
      } catch (error) {
        console.error(error);
        this.$toast("Error unfollowing user", { type: "error" });
      }
    },
    async handleAcceptFriendRequest() {
      try {
        await this.acceptFriendRequest(this.currentFriendship.id);
        this.$toast("Friend request accepted!", { type: "success" });
      } catch (error) {
        console.error("Error accepting friend request:", error);
        this.$toast("Error accepting friend request", { type: "error" });
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.info-container__button-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .profile-button {
    background: $atmo-purple--medium-dark;
    padding: 0.5rem 1.4rem 0.5rem 1.4rem;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 30px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .profile-button__image {
    width: auto;
    margin-right: 0.5rem;

    &.edit {
      height: 1rem;
    }

    &.add {
      height: 0.7rem;
    }

    &.unfriend {
      height: 0.7rem;
    }
  }
}
</style>
