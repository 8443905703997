<template>
  <div class="live-stream-container">
    <div class="stream-wrapper">
      <!-- Video Stream Section -->
      <div class="video-container">
        <video ref="liveVideo" autoplay playsinline controls></video>
      </div>

      <!-- Chat Section -->
      <div class="chat-container">
        <h3>Live Chat</h3>
        <perfect-scrollbar class="chat-messages">
          <div v-for="message in chatMessages" :key="message.id" :class="['chat-message', message.user.id === $store.state.currentUserId ? 'chat-message--current-user' : 'chat-message--other-user']">
            <!-- User Avatar (Outside Message Bubble) -->
            <img class="chat-message__avatar" :src="getProfileImage(message.user)" alt="User Avatar" />

            <!-- Message Bubble -->
            <div class="chat-message__bubble">
              <div class="chat-message__header">
                <span class="chat-message__username">{{ message.user.name }}</span>
                <span class="chat-message__timestamp">{{ formatTimestamp(message.created_at) }}</span>
              </div>
              <p class="chat-message__text">{{ message.content }}</p>
            </div>
          </div>
        </perfect-scrollbar>

        <!-- Chat Input -->
        <div class="chat-input">
          <input v-model="newMessage" placeholder="Type a message..." @keyup.enter="sendChatMessage" />
          <button @click="sendChatMessage">Send</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { callLiveStreamsShow, callCommentsIndex, callCommentsCreate } from "@/helpers/axiosCalls";
import { connectToLiveStreamChatChannel, sendMessage, setCallback } from "@/cable-channels/liveStreamChat";
import Hls from "hls.js"; // Import HLS.js at the top

export default {
  data() {
    return {
      liveStream: null,
      chatMessages: [],
      newMessage: "",
      liveStreamId: null,
    };
  },
  async created() {
    await this.fetchLiveStream();
    this.$store.commit("setHudHeader", "Live Stream");
  },
  watch: {
    "$route.params.userId": "fetchLiveStream",
  },
  mounted() {
    this.initHlsPlayer();
  },
  methods: {
    async fetchLiveStream() {
      try {
        const userId = this.$route.params.userId;
        const response = await callLiveStreamsShow(userId);
        if (response) {
          this.liveStream = response;
          this.liveStreamId = response.id;
          await this.fetchChatMessages();
          this.setupLiveChat();
          this.initHlsPlayer();
        }
      } catch (error) {
        console.error("Error fetching live stream:", error);
      }
    },
    setupLiveChat() {
      console.log("✅ Connecting to live stream chat...", this.liveStreamId);
      connectToLiveStreamChatChannel(this.liveStreamId);

      setCallback(() => {
        console.log("📩 Chat update received via WebSocket!");
        this.fetchChatMessages(); // 🔄 Fetch messages when a new one arrives
      });
    },
    initHlsPlayer() {
      if (!this.liveStream?.playback_url) return;

      const video = this.$refs.liveVideo;
      const streamUrl = this.liveStream.playback_url;

      if (Hls.isSupported()) {
        const hls = new Hls();
        hls.loadSource(streamUrl);
        hls.attachMedia(video);

        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          // Force HLS to jump to the latest live segment
          hls.startLevel = hls.levels.length - 1; // Pick the highest quality
          hls.on(Hls.Events.LIVE_BACK_BUFFER_REACHED, () => {
            video.currentTime = video.duration; // Seek to live position
          });

          this.attemptToPlayVideo(video);
        });
      } else if (video.canPlayType("application/vnd.apple.mpegurl")) {
        video.src = streamUrl;
        video.addEventListener("loadedmetadata", () => {
          video.currentTime = video.duration; // Seek to live position
          this.attemptToPlayVideo(video);
        });
      } else {
        console.error("HLS is not supported in this browser.");
      }
    },
    attemptToPlayVideo(video) {
      const playPromise = video.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log("✅ Video is playing");
          })
          .catch((error) => {
            console.warn("⚠️ Video play blocked. Waiting for user interaction...");
            // Wait for user click before playing
            video.muted = true; // Mute to allow autoplay in some cases
            video.addEventListener(
              "click",
              () => {
                video.muted = false;
                video.play();
              },
              { once: true }
            );
          });
      }
    },
    async fetchChatMessages() {
      if (!this.liveStreamId) return;
      try {
        const response = await callCommentsIndex({
          commentable_id: this.liveStreamId,
          commentable_type: "LiveStream",
        });
        console.log("COMMENTS SJ", response);
        this.chatMessages = response;
      } catch (error) {
        console.error("Error fetching chat messages:", error);
      }
    },
    async sendChatMessage() {
      await callCommentsCreate({ content: this.newMessage, user_id: this.$store.state.currentUserId, commentable_id: this.liveStreamId, commentable_type: "LiveStream" }).then(() => {
        sendMessage(this.newMessage, this.liveStreamId, this.$store.state.currentUserId);
        setCallback(() => {
          console.log("📩 Chat update received via WebSocket!");
          this.fetchChatMessages(); // 🔄 Fetch messages when a new one arrives
        });
      });
      this.newMessage = "";
    },
    getProfileImage(user) {
      if (user.images && user.images.length > 0) {
        return user.images[0].image.url;
      }
      // return require("@/assets/images/default-avatar.png"); // Default avatar
    },
    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.live-stream-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
}

.stream-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  width: 100%;
  gap: 20px;
}

.video-container {
  flex: 3;
  max-width: 70%;
}

video {
  width: 100%;
  border-radius: 2px;
}

.chat-container {
  flex: 1;
  max-width: 30%;
  background-color: $atmo-purple--medium-dark--faded;
  padding: 15px;
  border-radius: 2px;
  height: 500px;
  display: flex;
  flex-direction: column;
}

.chat-container h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.chat-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  border-radius: 2px;
  height: 350px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Chat Message */
.chat-message {
  display: flex;
  align-items: center;
  max-width: 80%;
  gap: 10px;
}

/* Positioning */
.chat-message--current-user {
  align-self: flex-end;
  flex-direction: row-reverse;
}

.chat-message--other-user {
  align-self: flex-start;
}

/* User Avatar */
.chat-message__avatar {
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 50%;
  object-fit: cover;
}

/* Message Bubble */
.chat-message__bubble {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 2px;

  /* Fixed width for consistency */
  min-width: 250px;
  max-width: 100%;
  width: 100%;

  word-wrap: break-word;
}

/* Styling for current user's messages */
.chat-message--current-user .chat-message__bubble {
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: none;
}

/* Message Header */
.chat-message__header {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.8);
}

/* Username */
.chat-message__username {
  font-weight: bold;
  color: white;
  margin-right: 0.2rem;
}

/* Timestamp */
.chat-message__timestamp {
  font-size: 0.75rem;
}

/* Message Text */
.chat-message__text {
  color: white;
  font-size: 0.8rem;
  margin-top: 5px;
}

/* Chat Input */
.chat-input {
  display: flex;
  margin-top: 10px;
}

.chat-input input {
  flex: 1;
  padding: 8px;
  color: white;
  font-size: 0.9rem;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 2px;
  margin-right: 0.5rem;
}

button {
  padding: 10px 15px;
  cursor: pointer;
  color: white;
  font-size: 0.9rem;
  background: transparent;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 2px;
}
</style>
