<template>
  <div class="range-slider-container">
    <label @click="$emit('selectFilter')">{{ label }}</label>
    <div
      class="range-slider"
      :style="{
        '--min': ((modelValue[0] - min) / (max - min)) * 100,
        '--max': ((modelValue[1] - min) / (max - min)) * 100,
      }"
    >
      <input type="range" v-model.number="localValue[0]" :min="min" :max="max" :step="step" @input="validatePrice" />
      <input type="range" v-model.number="localValue[1]" :min="min" :max="max" :step="step" @input="validatePrice" />
    </div>

    <div class="min-max-container">
      <div>{{ min }}</div>
      <div>{{ max }}</div>
    </div>
    <div class="selected-min-max-container">
      <p>Min: ${{ localValue[0] }} - Max: ${{ localValue[1] }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: { type: Array, required: true }, // [min, max]
    min: { type: Number, required: true },
    max: { type: Number, required: true },
    step: { type: Number, default: 10 },
    label: { type: String, default: "Price ($/hr)" },
  },
  data() {
    return {
      localValue: [...this.modelValue],
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler(newValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(this.modelValue)) {
          this.$emit("update:modelValue", newValue);
        }
      },
    },
    modelValue: {
      deep: true,
      handler(newValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(this.localValue)) {
          this.localValue = [...newValue];
        }
      },
    },
  },
  methods: {
    validatePrice() {
      if (this.localValue[0] > this.localValue[1]) {
        [this.localValue[0], this.localValue[1]] = [this.localValue[1], this.localValue[0]];
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.range-slider-container {
  width: 100%;

  label {
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }

  label {
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: bold;
  }

  /* Remove focus outline when clicking the slider thumbs */
  .range-slider input:focus,
  .range-slider input:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }

  /* Remove focus styles for Chrome, Safari, Edge, Opera */
  .range-slider input::-webkit-slider-thumb:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  /* Remove focus styles for Firefox */
  .range-slider input::-moz-range-thumb:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .range-slider {
    position: relative;
    width: 100%;
    height: 8px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .range-slider input {
    position: absolute;
    width: 100%;
    appearance: none;
    background: transparent;
    pointer-events: none;
    top: -18px;
  }

  .range-slider input::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: 1px solid $atmo-pink--medium;
    cursor: pointer;
    pointer-events: all;
    position: relative;
    z-index: 2;
  }

  .range-slider input::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    border: 1px solid $atmo-pink--medium;
    cursor: pointer;
    pointer-events: all;
    z-index: 2;
  }

  .range-slider::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    background: $atmo-pink--medium;
    border-radius: 5px;
    z-index: 1;
    left: calc(var(--min) * 1%);
    right: calc(100% - (var(--max) * 1%));
  }

  .min-max-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    div {
      background-color: rgba(255, 255, 255, 0.2);
      color: rgba(0, 0, 0, 0.4);
      border-radius: 5px;
      padding: 0.3rem;
      font-weight: bold;
    }
  }

  .selected-min-max-container {
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }
}
</style>
