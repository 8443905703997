<template>
  <div>
    <template>
      <div v-if="amiDiagnosticsInitialized && amiTestingModalVisible" style="border: 2px solid white; border-radius: 5px; display: inline-block; padding: 15px; margin-bottom: 40px">
        <div style="margin-bottom: 5px; font-size: 18px; text-align: center">TESTING TOOLS</div>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="amiOpenSilentTestingModal()"
        >
          OPEN TESTING MODAL
        </button>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="amiResendCommand()"
        >
          RESEND LAST COMMAND
        </button>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="testAmiEngineGetConnection()"
        >
          TEST ENGINE CONNECTION (GET)
        </button>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="testAmiEnginePostConnection()"
        >
          TEST ENGINE CONNECTION (POST)
        </button>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="toggleSpeechRecognition(webSpeech)"
        >
          {{ speechRecognitionInit ? "STOP" : "START" }}
        </button>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="testAmiSpeech"
        >
          TEST SPEECH
        </button>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="testAmiFX"
        >
          TEST FX
        </button>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="hideTestingModal()"
        >
          HIDE TESTING TOOLS
        </button>
        <button
          style="color: white; cursor: pointer; padding: 15px; background-color: #403e54; border-radius: 5px; border: none"
          v-if="amiDiagnosticsInitialized && amiTestingModalVisible"
          @click="testingVolume(0.2)"
        >
          TEST VOLUME
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Axios from "axios";
import buzz from "buzz";
import AWS from "aws-sdk";
import { callUserFriendshipsIndex, callSongReferencesIndex, callSongReferencesDelete, callSongReferencesCreate } from "@/helpers/axiosCalls";

export default {
  props: {
    logout: {
      type: Function,
      required: true,
    },
    webSpeech: {
      required: true,
    },
  },

  data: function () {
    return {
      userChatRoomId: null,
      friendChatRoomId: null,
      roomId: null,
      allCollaboratorPlaylists: [],
      combinedPlaylists: [],
      userAndCollaboratorPlaylists: [],
      friendsFuzzySet: null,
      songsFuzzySet: null,
      albumsFuzzySet: null,
      artistsFuzzySet: null,
      playlistsFuzzySet: null,
      amiSongGrabbed: false,
      amiGrabbedSong: {},
      artistProfileId: null,
      artistProfiles: [],
      artistName: null,
      amiRespondedNoToIfInLibrary: false,
      amiRespondedYesToIfInLibrary: false,
      librarySongId: null,
      queueSongId: null,
      playlistName: null,
      playlistId: null,
      playlistSongId: null,
      amiFriendGrabbed: false,
      amiGrabbedFriend: {},
      amiGrabbedFriendPostContentRecorded: false,
      amiPostContent: null,
      amiPlaylistGrabbed: false,
      amiGrabbedPlaylist: null,
      amiPlaylistCollaboratorAdditionRequested: false,
      amiPlaylistCollaboratorRemovalRequested: false,
      amiPlaylistCollaboratorId: null,
      amiPlaylistCopyRequested: false,
      postsFriend: null,
      amiCreatePostRequested: false,
      amiPostContentRecordedForPost: false,
      currentUserProfileId: this.$store.state.currentUserProfileId,
      amiPreReqsMet: false,
      amiBroadcastModalOpen: this.$store.state.broadcastModalOpen,
      amiAlbumGrabbed: false,
      amiGrabbedAlbum: null,
      amiGenreGrabbed: false,
      amiGrabbedGenre: null,
      amiGenreDataArray: [],
      amiArtistDataArray: [],
      matchingCommand: null,
      amiTestingModalVisible: true,
      amiSpeaking: false,
      polly: null,
      chosenCommand: null,
      amiAudioMuted: null,
      amiSoundType: null,
      userSpokenName: null,
      availableCommandsForAmi: null,
      preAvailableCommandsForAmi: [],
      matchingCommandForAmi: {},
      amiInitializedForAmi: false,
      activeChainForAmi: [],
      commandLogForAmi: [],
      lastLinkInActiveChainForAmi: {},
      lastCommandIdentifiedForAmi: {},
      amiDiagnosticsInitialized: false,
      availableCommandObjects: {},
      preAvailableCommands: [],
      lastPostRequestForAmi: {},
      connectionAlert: {},
      userAuthenticated: this.$store.state.authenticated,
      amiListening: false,
      speechRecognitionInit: false,
      buzzFXOrPollySpeech: null,
      playerVolumeBeforeMute: null,
      userAmiApps: [],
    };
  },

  watch: {
    simulatedSpeechSentToAMI: function () {
      if (this.simulatedSpeechSentToAMI == true) {
        this.sendSimulatedSpeechToAmiEngine(this.simulatedAMISpeech);
        this.$store.commit("clearSimulatedAMISpeech");
      }
    },
    // amiSpeaking: function(newVal, oldVal) {
    //   // watch this.amiSpeaking
    //   console.log('AMIIIIIS SPEAKING changed: ', newVal, ' | was: ', oldVal)
    // }
  },

  computed: {
    simulatedSpeechSentToAMI() {
      return this.$store.state.simulatedSpeechSentToAMI;
    },
    simulatedAMISpeech() {
      return this.$store.state.simulatedAMISpeech;
    },
  },

  created() {
    // this.loadAndSetFuzzySets();
    if (this.userAuthenticated) {
      this.adminDiagnosticDefaultModeInit();
      this.retrieveAWSCreds();
      this.retrieveAmiUserProfileAttributes();
      this.initializeWebSpeech();
      this.getUserAmiApps();
    }
  },
  methods: {
    getUserAmiApps() {
      Axios.get(`/api/v1/users/1/user_ami_apps/`, {})
        .then((response) => {
          this.userAmiApps = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    testingVolume(volumeValue) {
      console.log("TESTING VOLUME");
      this.$store.dispatch("setVolume", volumeValue);
    },
    testAmiSpeech() {
      this.pollySynthesizeSpeech("Welcome back Mr. Jones. Nice to see you again.");
    },
    testAmiFX() {
      let soundFile = require("@/assets/sound_files/AMI_FX/AMI_init_short.mp3");
      this.buzzFXOrPollySpeech = new buzz.sound(soundFile);
      console.log("this.buzzFXOrPollySpeech", this.buzzFXOrPollySpeech);
      this.buzzFXOrPollySpeech.setVolume(50);
      this.buzzFXOrPollySpeech.play();
    },
    initializeWebSpeech() {
      if ("webkitSpeechRecognition" in window) {
        console.log("Speech Recognition Available");
        let finalTranscript = "";
        this.webSpeech.continuous = true;
        this.webSpeech.interimResults = false;
        this.webSpeech.lang = "en-US";
        let stoppedForNoSpeech = false;

        this.webSpeech.onstart = () => {
          console.log("Speech Recognition Started");
        };
        this.webSpeech.onerror = (e) => {
          console.log("Speech Recognition Error", e);
          //Recognition will disconnect if there is silence -> set flag
          if (e.error === "no-speech") {
            stoppedForNoSpeech = true;
          }
        };
        this.webSpeech.onend = (e) => {
          console.log("Speech Recognition Ended", e);
          this.speechRecognitionInit = false;
          //Recognition will disconnect if there is silence -> restart
          if (stoppedForNoSpeech) {
            this.webSpeech.start();
            this.speechRecognitionInit = true;
            stoppedForNoSpeech = false;
          }
        };
        this.webSpeech.onresult = (e) => {
          finalTranscript = e.results[e.results.length - 1][0].transcript;
          this.sendSpeechToAmiEngine(finalTranscript);
        };
      } else {
        console.log("Speech Recognition Not Available");
      }
    },
    toggleSpeechRecognition(sr) {
      if (this.speechRecognitionInit) {
        sr.stop();
        this.speechRecognitionInit = false;
      } else {
        sr.start();
        this.speechRecognitionInit = true;
      }
    },
    // testAmiEngineGetConnection() {
    //   Axios.get(`/api/v1/ami_engine_test_get`, {})
    //     .then(response => {
    //       this.connectionAlert = response.data;
    //       window.alert(this.connectionAlert.message);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },

    // testAmiEnginePostConnection() {
    //   Axios.post(`/api/v1/ami_engine_test_post`, {})
    //     .then(response => {
    //       this.connectionAlert = response.data;
    //       window.alert(this.connectionAlert.message);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },

    setAmiStateAndDisplayProcessingPrompt(dataFromEngine) {
      if (!dataFromEngine) {
        this.showAMIProcessing("Error with engine. Please try again");
        return;
      }

      if (dataFromEngine.error) {
        console.error(dataFromEngine.error);
        this.showAMIProcessing(dataFromEngine.processing_message_for_atmo_device);
      } else {
        this.preAvailableCommandsForAmi = dataFromEngine.pre_available_commands_for_atmo_device;
        this.amiInitializedForAmi = dataFromEngine.ami_initialized_for_atmo_device;
        this.activeChainForAmi = dataFromEngine.active_chain_for_atmo_device;
        this.commandLogForAmi = dataFromEngine.command_log_for_atmo_device;
        this.lastLinkInActiveChainForAmi = dataFromEngine.last_link_in_active_chain_for_atmo_device;
        this.lastCommandIdentifiedForAmi = dataFromEngine.last_command_identified_for_atmo_device;
        this.availableCommandObjects = dataFromEngine.available_commands_for_atmo_device; // not sure this will work since it's a fuzzy sent over json
        this.preAvailableCommands = dataFromEngine.pre_available_commands_for_atmo_device;

        //if this causes problems, don't send this data when its not used
        this.amiFriendGrabbed = dataFromEngine.ami_friend_grabbed;
        this.amiGrabbedFriend = dataFromEngine.ami_grabbed_friend;
        this.amiSongGrabbed = dataFromEngine.ami_song_grabbed;
        this.amiGrabbedSong = dataFromEngine.ami_grabbed_song;
        this.amiAlbumGrabbed = dataFromEngine.ami_album_grabbed;
        this.amiGrabbedAlbum = dataFromEngine.ami_grabbed_album;
        this.amiPlaylistGrabbed = dataFromEngine.ami_playlist_grabbed;
        this.amiGrabbedPlaylist = dataFromEngine.ami_grabbed_playlist;

        //sent to AMI
        this.matchingCommandForAmi = dataFromEngine.matching_command_for_atmo_device;
        // from AMI
        this.matchingCommand = dataFromEngine.matching_command_for_atmo_device;
        let matchingFunction = this.matchingCommandForAmi.command_function;
        //ONLY PRESENT ON THIRD PARTY COMMANDS
        let matchingAppType = this.matchingCommandForAmi.ami_app_type;

        //if function is a grab function, set appropriate variables
        // is this necessary?
        // switch(matchingFunction) {
        //   case 'amiGrabFriendNamed':
        //     this.amiFriendGrabbed = dataFromEngine.ami_friend_grabbed;
        //     this.amiGrabbedFriend = dataFromEngine.ami_grabbed_friend;
        //     break;
        //   case 'amiGrabSongByArtist':
        //     this.amiSongGrabbed = dataFromEngine.ami_song_grabbed;
        //     this.amiGrabbedSong = dataFromEngine.ami_grabbed_song;
        //     break;
        //   case 'amiGrabAlbumByArtist':
        //     this.amiAlbumGrabbed = dataFromEngine.ami_album_grabbed;
        //     this.amiGrabbedAlbum = dataFromEngine.ami_grabbed_album;
        //     break;
        //   case 'amiGrabPlaylistNamed':
        //     this.amiPlaylistGrabbed = dataFromEngine.ami_playlist_grabbed;
        //     this.amiGrabbedPlaylist = dataFromEngine.ami_grabbed_playlist;
        //     break;
        //   default:
        //     console.log(`matchingCommand not a grab function`);
        // }

        let argumentsForMatchingCommandFunction = dataFromEngine.matching_command_function_arguments_for_atmo_device;
        let tryAgainFromAmi = dataFromEngine.try_again_for_atmo_device;

        // init function is not actually a function
        if (matchingFunction !== "init") {
          // execute proper function
          // if engine passed along arguments, place them in function (looks like there are 4 max arguments. will scale later)
          if (argumentsForMatchingCommandFunction.length > 0) {
            let arg1 = argumentsForMatchingCommandFunction[0] || null;
            let arg2 = argumentsForMatchingCommandFunction[1] || null;
            let arg3 = argumentsForMatchingCommandFunction[2] || null;
            let arg4 = argumentsForMatchingCommandFunction[3] || null;

            switch (argumentsForMatchingCommandFunction.length) {
              case 4:
                this[`${matchingFunction}`](arg1, arg2, arg3, arg4);
                break;
              case 3:
                this[`${matchingFunction}`](arg1, arg2, arg3);
                break;
              case 2:
                this[`${matchingFunction}`](arg1, arg2);
                break;
              case 1:
                this[`${matchingFunction}`](arg1);
                break;
            }
            // clear prerequisite states if AMI stops listening
            if (this.matchingCommand.ami_init === false) {
              this.amiFriendGrabbed = false;
              this.amiGrabbedFriend = {};
            }
          } else {
            if (matchingAppType === "thirdParty") {
              console.log(`Executing third party function for ${this.matchingCommand.name} command.`);
            } else {
              this[`${matchingFunction}`]();
            }
            // clear prerequisite states if AMI stops listening
            if (this.matchingCommand.ami_init === false) {
              this.amiFriendGrabbed = false;
              this.amiGrabbedFriend = {};
            }
          }
        }

        // display proper command prompt
        let amiCommandProcessingPrompts = this.matchingCommandForAmi.ami_command_processing_prompts;
        let length = amiCommandProcessingPrompts.length;
        let randomNumber = Math.floor(Math.random() * length) + 0;
        let chosenCommand = amiCommandProcessingPrompts[randomNumber];
        let chosenSpeechPrompt = dataFromEngine.processing_message_for_atmo_device || chosenCommand.speech;

        this.showAMIProcessing(`${chosenSpeechPrompt}`);

        if (tryAgainFromAmi) {
          this.tryAgainAudioPlay();
        } else {
          let soundFile = require(`@/assets/sound_files/AMI_FX/${this.matchingCommandForAmi.fx}`);
          this.buzzFXOrPollySpeech = new buzz.sound(soundFile);
          console.log("this.buzzFXOrPollySpeech", this.matchingCommandForAmi.fx);
          // buzz library binding to audio play - don't listen for commands while AMI is speaking
          // this.buzzFXOrPollySpeech
          //   .bind("playing", () => {
          //     // amiSpeaking = true;
          //     this.amiInitializedForAmi = false;
          //   })
          //   .bind("ended", () => {
          //     // amiSpeaking = false;
          //     this.amiInitializedForAmi = this.matchingCommandForAmi.ami_init;
          //   });
          console.log("AMI SOUND TYPE IN AMI", this.amiSoundType);
          if (!this.amiAudioMuted && this.amiSoundType == "speech") {
            this.pollySynthesizeSpeech(chosenSpeechPrompt);
          } else if (!this.amiAudioMuted && this.amiSoundType == "fx") {
            this.buzzFXOrPollySpeech.setVolume(50);
            this.buzzFXOrPollySpeech.play();
          }
        }
      }
    },

    // `lastSentence` is the last sentence before the pause
    // `transcription` is the full array of sentences
    sendSpeechToAmiEngine(lastSentence) {
      let speechForEngine = lastSentence;
      console.log("USER SPEECH FOR AMI", speechForEngine);
      console.log("AMI INITIALIZED FOR AMI", this.amiInitializedForAmi);

      const currentUserToken = localStorage.getItem("token");

      let postRequest = {
        user_token: currentUserToken,
        user_speech: speechForEngine,
        user_id: this.$store.state.currentUserId,
        user_ami_apps: this.userAmiApps,
        user_profile_id: this.$store.state.currentUserProfileId,
        chat_room_id: this.$store.state.currentChatRoomId,
        pre_available_commands_for_ami: this.preAvailableCommandsForAmi,
        matching_command_for_ami: this.matchingCommandForAmi,
        ami_initialized_for_ami: this.amiInitializedForAmi,
        active_chain_for_ami: this.activeChainForAmi,
        command_log_for_ami: this.commandLogForAmi,
        last_link_in_active_chain_for_ami: this.lastLinkInActiveChainForAmi,
        last_command_identified_for_ami: this.lastCommandIdentifiedForAmi,
        ami_grabbed_friend: this.amiGrabbedFriend,
        ami_friend_grabbed: this.amiFriendGrabbed,
        ami_grabbed_song: this.amiGrabbedSong,
        ami_song_grabbed: this.amiSongGrabbed,
        ami_grabbed_album: this.amiGrabbedAlbum,
        ami_album_grabbed: this.amiAlbumGrabbed,
        ami_grabbed_playlist: this.amiGrabbedPlaylist,
        ami_playlist_grabbed: this.amiPlaylistGrabbed,
        available_command_objects_for_ami: this.availableCommandObjects,

        // friend_chat_room_id: friendChatRoomId,
        // ami_initialized: amiInitialized,
        // ami_song_grabbed: amiSongGrabbed,
        // ami_grabbed_song: amiGrabbedSong,
        // artist_profile_id: artistProfileId,
        // library_song_id: librarySongId,
        // queue_song_id: queueSongId,
        // playlist_name: playlistName,
        // playlist_id: playlistId,
        // playlist_song_id: playlistSongId,
        // ami_friend_grabbed: amiFriendGrabbed,
        // ami_grabbed_friend: amiGrabbedFriend,
        // ami_grabbed_friend_post_content_recorded: amiGrabbedFriendPostContentRecorded,
        // ami_post_content: amiPostContent,
        // ami_playlist_grabbed: amiPlaylistGrabbed,
        // ami_grabbed_playlist: amiGrabbedPlaylist,
        // ami_playlist_collaborator_addition_requested: amiPlaylistCollaboratorAdditionRequested,
        // ami_playlist_collaborator_removal_requested: amiPlaylistCollaboratorRemovalRequested,
        // ami_playlist_collaborator_id: amiPlaylistCollaboratorId,
        // ami_playlist_copy_requested: amiPlaylistCopyRequested,
        // posts_friend: postsFriend,
        // ami_create_post_requested: amiCreatePostRequested,
        // ami_post_content_recorded_for_post: amiPostContentRecordedForPost,
        // ami_pre_reqs_met: amiPreReqsMet,
        // ami_broadcast_modal_open: amiBroadcastModalOpen,
        // ami_album_grabbed: amiAlbumGrabbed,
        // ami_grabbed_album: amiGrabbedAlbum,
        // ami_genre_grabbed: amiGenreGrabbed,
        // ami_grabbed_genre: amiGrabbedGenre,
        // ami_diagnostics_initialized: amiDiagnosticsInitialized,
        // ami_testing_modal_visible: amiTestingModalVisible,
        // ami_speaking: amiSpeaking,
        // first_command_already_sent: firstCommandAlreadySent
      };

      this.lastPostRequestForAmi = postRequest;

      Axios.post(`/api/v1/ami_engine`, postRequest)
        .then((response) => {
          let dataFromEngine = response.data;
          this.setAmiStateAndDisplayProcessingPrompt(dataFromEngine);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    sendSimulatedSpeechToAmiEngine(simulatedSpeech) {
      let speechForEngine = simulatedSpeech;
      console.log("USER SPEECH FOR AMI", speechForEngine);
      console.log("AMI INITIALIZED FOR AMI", this.amiInitializedForAmi);

      const currentUserToken = localStorage.getItem("token");

      let postRequest = {
        user_token: currentUserToken,
        user_speech: speechForEngine,
        user_id: this.$store.state.currentUserId,
        user_ami_apps: this.userAmiApps,
        user_profile_id: this.$store.state.currentUserProfileId,
        chat_room_id: this.$store.state.currentChatRoomId,
        pre_available_commands_for_ami: this.preAvailableCommandsForAmi,
        matching_command_for_ami: this.matchingCommandForAmi,
        ami_initialized_for_ami: this.amiInitializedForAmi,
        active_chain_for_ami: this.activeChainForAmi,
        command_log_for_ami: this.commandLogForAmi,
        last_link_in_active_chain_for_ami: this.lastLinkInActiveChainForAmi,
        last_command_identified_for_ami: this.lastCommandIdentifiedForAmi,
        ami_grabbed_friend: this.amiGrabbedFriend,
        ami_friend_grabbed: this.amiFriendGrabbed,
        ami_grabbed_song: this.amiGrabbedSong,
        ami_song_grabbed: this.amiSongGrabbed,
        ami_grabbed_album: this.amiGrabbedAlbum,
        ami_album_grabbed: this.amiAlbumGrabbed,
        ami_grabbed_playlist: this.amiGrabbedPlaylist,
        ami_playlist_grabbed: this.amiPlaylistGrabbed,
        available_command_objects_for_ami: this.availableCommandObjects,
      };

      this.lastPostRequestForAmi = postRequest;

      Axios.post(`/api/v1/ami_engine`, postRequest)
        .then((response) => {
          let dataFromEngine = response.data;
          this.setAmiStateAndDisplayProcessingPrompt(dataFromEngine);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    async retrieveAWSCreds() {
      const SESConfig = {
        accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
        region: "us-west-2",
      };
      AWS.config.update(SESConfig);

      this.polly = new AWS.Polly();
    },

    retrieveAmiUserProfileAttributes() {
      let userId = this.$store.state.currentUserId;

      Axios.get(`/api/v1/user_ami_profiles/${userId}`, {})
        .then((response) => {
          this.amiAudioMuted = response.data.ami_audio_muted;
          this.amiSoundType = response.data.ami_sound_type;
          this.userSpokenName = response.data.user_spoken_name;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    hideTestingModal() {
      this.amiTestingModalVisible = false;
    },

    // CHAT FUNCTIONS
    getRoomMessages() {
      Axios.get(`api/v1/chat_rooms/${this.$store.state.currentChatRoomId}/chat_messages`, {})
        .then((response) => {
          let messages = response.data;
          this.$store.commit("setChatRoomMessages", messages);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUserFriendships() {
      callUserFriendshipsIndex({ user_id: this.$store.state.currentUserId })
        .then((response) => {
          let userFriendships = response;
          this.$store.commit("setChatUserFriendships", userFriendships);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    createRoom() {
      console.log("ROOM NOT FOUND... CREATING NOW");
      // CREATE CHAT ROOM W/USER FRIENDSHIP ID (USER_FRIEND IDs)
      this.roomId = this.userChatRoomId + "0" + this.friendChatRoomId;

      Axios.post(`/api/v1/chat_rooms`, {
        id: this.userChatRoomId + "0" + this.friendChatRoomId,
        user_friendship_id: this.userChatRoomId + "0" + this.friendChatRoomId,
      })
        .then(() => {
          console.log("Room Created!");
          this.retrieveRoom(this.roomId);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    retrieveRoom(chatRoomId) {
      this.roomId = chatRoomId;
      // roomUserFriendshipId = $scope.chatRooms[i].id;
      this.$store.commit("changeChatView", "messages");
      console.log("FOUND ROOM WITH USER_FRIENSHIP_ID: " + this.roomId + ". RETRIEVING NOW.");

      Axios.get(`api/v1/chat_rooms/${this.roomId}/chat_messages`, {})
        .then((response) => {
          let messages = response.data;
          this.$store.commit("setChatRoomMessages", messages);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    setChatRoomAttributes(userChatRoomId, friendChatRoomId) {
      this.userChatRoomId = userChatRoomId;
      this.friendChatRoomId = friendChatRoomId;
      Axios.get(`/api/v1/chat_rooms`, {})
        .then((response) => {
          this.chatRooms = response.data;
          if (this.chatRooms.length == 0) {
            console.log("0 ROOMS");
            this.createRoom();
          } else {
            console.log("MORE THAN 0 ROOMS");
            let foundRoom = false;
            for (let i = 0; i < this.chatRooms.length; ++i) {
              if (this.chatRooms[i].id == parseInt("" + this.userChatRoomId + "0" + this.friendChatRoomId)) {
                this.roomId = this.chatRooms[i].id;
                this.$store.commit("setChatRoomId", this.roomId);
                this.retrieveRoom(this.roomId);
                foundRoom = true;
                break;
              } else if (this.chatRooms[i].id == parseInt("" + this.friendChatRoomId + "0" + this.friendChatRoomId)) {
                this.roomId = this.chatRooms[i].id;
                this.$store.commit("setChatRoomId", this.roomId);
                this.retrieveRoom(this.roomId);
                foundRoom = true;
                break;
              }
            }
            if (foundRoom == false) {
              this.createRoom();
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    saveMessage(mB) {
      if (this.amiFriendGrabbed == true) {
        this.amiSendGrabbedFriendMessage(mB);
      } else {
        Axios.post(`/api/v1/chat_rooms/${this.$store.state.currentChatRoomId}/chat_messages`, {
          chat_room_id: this.$store.state.currentChatRoomId,
          user_id: this.$store.state.currentUserId,
          body: mB,
        })
          .then(() => {
            this.getRoomMessages(this.$store.state.currentChatRoomId);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },

    // LIBRARY FUNCTIONS
    changeView(newView) {
      this.$store.commit("changeMiniLibraryView", newView);
    },

    setPlaylistAttributes(playlistId) {
      callSongReferencesIndex({
        songable_id: playlistId,
        songable_type: "Playlist",
      })
        .then((response) => {
          let playlistSongs = response.map((songReference) => {
            return songReference.song;
          });
          this.$store.commit("setCurrentPlaylistSongs", playlistSongs);
        })
        .catch((error) => {
          console.error(error);
        });

      this.changeView("playlist-songs");
    },

    deletePlaylist(playlistId) {
      Axios.delete(`/api/v1/users/${this.$store.state.currentUserId}/playlists/${playlistId}`, {})
        .then((response) => {
          console.log(response);
          this.getUserPlaylists();
        })
        .catch((error) => {
          console.error(error);
        });
    },

    removeDuplicates(originalArray) {
      return originalArray.filter(function (item, index) {
        return originalArray.indexOf(item) >= index;
      });
    },

    // GET ALL COLLAB PLAYLISTS [playlist, playlist, ...]
    getAllCollabPlaylists(playlists) {
      Axios.get(`/api/v1/users/${this.$store.state.currentUserId}/collaborator_playlists`, {})
        .then((response) => {
          let allPlaylists = response.data;
          if (allPlaylists.length > 0) {
            // GET COLLABORATOR ARRAY IN EACH PLAYLIST [[collaborator, collaborator], [collaborator, collaborator], ...]
            for (let i = 0; i < allPlaylists.length; i++) {
              let playlistCollaborators = allPlaylists[i].playlist_collaborators;
              if (playlistCollaborators.length > 0) {
                //ITERATE OVER EACH COLLABORATOR IN A PLAYLIST playlist: [collaborator, collaborator, collaborator, ...]
                for (let j = 0; j < playlistCollaborators.length; j++) {
                  let playlistCollaboratorId = playlistCollaborators[j].user.id;
                  let playlistCollaboratorPlaylistId = playlistCollaborators[j].playlist_id;
                  //IF COLLABORATOR MATCHES CURRENT USER, GET PLAYLIST FOR COLLABORATOR
                  if (playlistCollaboratorId == this.$store.state.currentUserId) {
                    Axios.get(`/api/v1/users/${playlistCollaboratorId}/playlists/${playlistCollaboratorPlaylistId}`, {})
                      .then((response) => {
                        let collaboratorPlaylist = response.data;
                        // PUSH PLAYLIST TO NEW ARRAY (PLAYLISTS USER IS A COLLABORATOR ON) & COMBINE WITH USER PLAYLISTS
                        this.allCollaboratorPlaylists.push(collaboratorPlaylist);
                        this.combinedPlaylists = playlists.concat(this.allCollaboratorPlaylists);
                        this.userAndCollaboratorPlaylists = this.removeDuplicates(this.combinedPlaylists);
                        this.$store.commit("setCurrentUserPlaylists", this.userAndCollaboratorPlaylists);
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                }
              } else {
                this.combinedPlaylists = playlists;
                this.userAndCollaboratorPlaylists = this.removeDuplicates(this.combinedPlaylists);
                this.$store.commit("setCurrentUserPlaylists", this.userAndCollaboratorPlaylists);
              }
            }
          } else {
            this.combinedPlaylists = [];
            this.userAndCollaboratorPlaylists = this.removeDuplicates(this.combinedPlaylists);
            this.$store.commit("setCurrentUserPlaylists", this.userAndCollaboratorPlaylists);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    getUserPlaylists() {
      console.log("-----------------------------------getting user playlists...", this.$store.state.currentUserId);
      Axios.get(`/api/v1/users/${this.$store.state.currentUserId}/playlists`, {})
        .then((response) => {
          this.playlists = response.data;
          this.getAllCollabPlaylists(this.playlists);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    // QUEUE FUNCTIONS
    saveQueueAsPlaylist() {
      let now = new Date();
      let nowString = now.toDateString();
      let hour = now.getHours();
      let minutes = now.getMinutes();

      let nowPlaylistString = `${nowString} (${hour}:${minutes})`;

      Axios({
        method: "post",
        url: `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
        data: {
          user_id: this.$store.state.currentUserId,
          name: `Queue Playlist - ${nowPlaylistString}`,
        },
      })
        .then((response1) => {
          console.log(response1);
          this.$toast("Playlist Created", { type: "success" });
          this.$store.state.currentUserPlaylists.push(response1.data);
          callSongReferencesIndex({
            songable_id: this.$store.state.currentUserId,
            songable_type: "AtmoQueue",
          })
            .then((response2) => {
              console.log(response2);
              let queueSongs = response2;
              //save playlist here
              for (let i = 0; queueSongs.length; i++) {
                callSongReferencesCreate({
                  songable_id: response1.data.id,
                  songable_type: "Playlist",
                  song_id: queueSongs[i].song_id,
                  user_id: this.$store.state.currentUserId,
                })
                  .then((response3) => {
                    console.log(response3);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addToQueue() {
      Axios({
        method: "post",
        url: `/api/v1/users/${this.$store.state.currentUserId}/atmo_queue/atmo_queue_song_references`,
        data: {
          atmo_queue_id: this.$store.state.currentUserId,
          song_id: this.amiGrabbedSong.id,
        },
      })
        .then(() => {
          this.dispatch("atmoQueues/getAtmoQueue");
        })
        .catch((error) => {
          console.error(error);
        });
    },

    removeSongFromQueue() {
      let queueSongFound = false;

      callSongReferencesIndex({
        songable_id: this.$store.state.currentUserId,
        songable_type: "AtmoQueue",
      })
        .then((response) => {
          let queueSongs = response;
          for (let i = 0; i < queueSongs.length; i++) {
            if (queueSongs[i].song_id == this.amiGrabbedSong.id) {
              queueSongFound = true;
              this.queueSongId = queueSongs[i].id;
              break;
            }
          }
          if (queueSongFound == true) {
            callSongReferencesDelete(this.queueSongId)
              .then((response) => {
                console.log(response);
                this.dispatch("atmoQueues/getAtmoQueue");
                // this.successAudioPlay();
                this.showAMIProcessing(`Song successfully removed from queue.`);
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            this.showAMIProcessing(`Song not in your queue. Cannot remove.`);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addToLiveListeningQueue(songId) {
      Axios({
        method: "post",
        url: `/api/v1/users/${this.$store.state.currentUserId}/live_listening_queue/live_listening_queue_song_references`,
        data: {
          live_listening_queue_id: this.$store.state.currentUserId,
          song_id: songId,
        },
      })
        .then(() => {
          this.$store.commit("getLiveListeningQueue");
        })
        .catch((error) => {
          console.error(error);
        });
    },

    storeFunctionWithArgument(matchingString, storeFunctionName, storeArgument) {
      this.$store.commit(storeFunctionName, storeArgument);
      // this.successAudioPlay();
    },

    storeFunctionWithDynamicArgument(matchingString, storeFunctionName, storeArgument) {
      this.$store.commit(storeFunctionName, storeArgument);
      // this.successAudioPlay();
    },

    storeFunctionWithoutArgument(matchingString, storeFunctionName) {
      this.$store.commit(storeFunctionName);
      // this.successAudioPlay();
    },

    // setFuzzies(response) {
    // loadAndSetFuzzySets() {
    //   let fuzzySetFriendsArray = [];
    //   let fuzzySetSongsArray = [];
    //   let fuzzySetAlbumsArray = [];
    //   let fuzzySetArtistsArray = [];
    //   let fuzzySetPlaylistsArray = [];

    //   // GET ALL AMI COMMANDS
    //   Axios.get(`/api/v1/ami_commands?device=desktop`, {})
    //     .then(response => {
    //       this.setFuzzies(response);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });

    //   // GET ALL USER'S FRIENDSHIPS
    // callUserFriendshipsIndex(this.$store.state.currentUserId)
    //     .then(response => {
    //       let userFriendships = response;
    //       for (let i = 0; i < userFriendships.length; i++) {
    //         fuzzySetFriendsArray.push(
    //           userFriendships[i].friend.name.toLowerCase()
    //         );
    //       }
    //       this.friendsFuzzySet = FuzzySet(fuzzySetFriendsArray);
    //       console.log("----FuzzySet friends loaded.", this.friendsFuzzySet);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });

    //   // GET ALL SONGS
    //   Axios.get(`/api/v1/songs`, {})
    //     .then(response => {
    //       let allSongs = response.data;
    //       for (let i = 0; i < allSongs.length; i++) {
    //         fuzzySetSongsArray.push(allSongs[i].name.toLowerCase());
    //       }
    //       this.songsFuzzySet = FuzzySet(fuzzySetSongsArray);
    //       console.log("----FuzzySet songs loaded.", this.songsFuzzySet);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });

    //   // GET ALL ARTISTS
    //   Axios.get(`/api/v1/ami_artists`, {})
    //     .then(response => {
    //       let allArtists = response.data;
    //       for (let i = 0; i < allArtists.length; i++) {
    //         fuzzySetArtistsArray.push(allArtists[i].name.toLowerCase());
    //       }
    //       this.artistsFuzzySet = FuzzySet(fuzzySetArtistsArray);
    //       console.log("----FuzzySet artists loaded.", this.artistsFuzzySet);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });

    //   // GET ALL ALBUMS
    //   Axios.get(`/api/v1/ami_albums`, {})
    //     .then(response => {
    //       let allAlbums = response.data;
    //       for (let i = 0; i < allAlbums.length; i++) {
    //         fuzzySetAlbumsArray.push(allAlbums[i].name.toLowerCase());
    //       }
    //       this.albumsFuzzySet = FuzzySet(fuzzySetAlbumsArray);
    //       console.log("----FuzzySet albums loaded.", this.albumsFuzzySet);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });

    //   // GET USER PLAYLISTS
    //   Axios.get(
    //     `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
    //     {}
    //   )
    //     .then(response => {
    //       let userPlaylists = response.data;
    //       for (let i = 0; i < userPlaylists.length; i++) {
    //         fuzzySetPlaylistsArray.push(userPlaylists[i].name.toLowerCase());
    //       }
    //       this.playlistsFuzzySet = FuzzySet(fuzzySetPlaylistsArray);
    //       console.log("----FuzzySet playlists loaded.", this.playlistsFuzzySet);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // },

    amiGoToRouteWithoutParams(matchingString, routeName) {
      this.$router.push({ name: routeName });
      // this.successAudioPlay();
    },

    amiGoToRouteWithParams(matchingString, routeName, paramName, paramValue) {
      //this is passed from amiEngine as string
      console.log("amiGoToRouteWithParams");
      console.log(paramValue);
      console.log("GO TO ROUTE", this.amiGrabbedFriend);
      if (paramValue === "amiGrabbedFriend.profile_id") {
        paramValue = this.amiGrabbedFriend.profile_id;
      }

      this.$router.push({
        name: routeName,
        params: { [paramName]: paramValue },
      });
      // this.successAudioPlay();
    },

    amiSpeak() {
      // this.successAudioPlay();
    },

    amiDeletePlaylist() {
      console.log("amiDeletePlaylist() called");
      // let playlists = this.$store.state.currentUserPlaylists;
      // let lastPlaylist = playlists[playlists.length - 1];
      // this.deletePlaylist(lastPlaylist.id);
    },

    adminDiagnosticDefaultModeInit() {
      // if (this.$store.state.currentUserId == 1) {
      //   this.amiDiagnosticsInitialized = true;
      // }

      this.amiDiagnosticsInitialized = false;
    },

    amiInitializeDiagnostics() {
      if (this.$store.state.currentUserId == 1) {
        this.amiDiagnosticsInitialized = true;
      } else {
        this.showAMIProcessing("Sorry, you do not have the necessary privileges to execute this command.");
      }
    },

    amiDeinitializeDiagnostics() {
      if (this.$store.state.currentUserId == 1) {
        this.amiDiagnosticsInitialized = false;
      } else {
        this.showAMIProcessing("Sorry, you do not have the necessary privileges to execute this command.");
      }
    },

    grabbedAudioPlay() {
      let soundFile = require("@/assets/sound_files/AMI_FX/AMI_success_short.mp3");
      this.buzzFXOrPollySpeech = new buzz.sound(soundFile);
      if (!this.amiAudioMuted && this.amiSoundType == "speech") {
        this.pollySynthesizeSpeech(this.matchingCommand);
      } else if (!this.amiAudioMuted && this.amiSoundType == "fx") {
        this.buzzFXOrPollySpeech.setVolume(50);
        this.buzzFXOrPollySpeech.play();
      }
    },

    songInLibraryPlay() {
      let soundFile = require("@/assets/sound_files/AMI_FX/AMI_success_short.mp3");
      this.buzzFXOrPollySpeech = new buzz.sound(soundFile);
      if (!this.amiAudioMuted && this.amiSoundType == "speech") {
        this.pollySynthesizeSpeech(this.matchingCommand);
      } else if (!this.amiAudioMuted && this.amiSoundType == "fx") {
        this.buzzFXOrPollySpeech.setVolume(50);
        this.buzzFXOrPollySpeech.play();
      }
    },

    songNotInLibraryPlay() {
      let soundFile = require("@/assets/sound_files/AMI_FX/AMI_success_short.mp3");
      this.buzzFXOrPollySpeech = new buzz.sound(soundFile);
      if (!this.amiAudioMuted && this.amiSoundType == "speech") {
        this.pollySynthesizeSpeech(this.matchingCommand);
      } else if (!this.amiAudioMuted && this.amiSoundType == "fx") {
        this.buzzFXOrPollySpeech.setVolume(50);
        this.buzzFXOrPollySpeech.play();
      }
    },

    tryAgainAudioPlay() {
      let soundFile = require("@/assets/sound_files/AMI_FX/AMI_tryagain_short.mp3");
      this.buzzFXOrPollySpeech = new buzz.sound(soundFile);

      let tryAgainObject = {
        desktop: true,
        smart_speaker: true,
        command_family: "utility",
        command_function: "amiSpeak",
        fx: "@/assets/sound_files/AMI_FX/AMI_tryagain_short.mp3",
        ami_init: true,
        parent: null,
        sequence_type: "utility",
        sequence_order: 1,
        //suggestionPrompts: ['I\'m not sure I understand. Did you mean "${suggestion}?"', 'Sorry, did you mean "${suggestion}?"'],
        next_link_accepts_literal_speech: false,
        ami_command_speech_triggers: [
          {
            speech: "------try&again",
          },
        ],
        ami_command_processing_prompts: [{ speech: "I'm not sure I understand. Please try again" }, { speech: "Come again?" }, { speech: "What was that?" }],
      };

      if (!this.amiAudioMuted && this.amiSoundType == "speech") {
        this.pollySynthesizeSpeech(tryAgainObject);
      } else if (!this.amiAudioMuted && this.amiSoundType == "fx") {
        this.buzzFXOrPollySpeech.setVolume(50);
        this.buzzFXOrPollySpeech.play();
      }
    },

    amiOpenChat(matchingString) {
      let found = false;
      let name = matchingString;
      this.getUserFriendships();

      for (let i = 0; i < this.$store.state.chat_rooms.chatUserFriendships.length; i++) {
        let friendName = this.$store.state.chat_rooms.chatUserFriendships[i].friend.name.toLowerCase();
        if (friendName === name) {
          this.setChatRoomAttributes(this.$store.state.currentUserId, this.$store.state.chat_rooms.chatUserFriendships[i].friend.id);
          found = true;
          this.amiInitializedForAmi = false;
          // this.successAudioPlay(); // this doesn't seem right. should see why this wasn't playing without this
        }
      }
      if (found == false) {
        this.showAMIProcessing(`No friend found with that name. Please try again.`);
      }
    },

    amiWhatTimeIsIt() {
      console.log("amiWhatTimeIsIt() called");
    },

    amiWhatIsTheDate() {
      console.log("amiWhatIsTheDate() called");
    },

    amiSendTextMessageTo() {
      console.log("amiSendTextMessageTo() called");
    },

    amiAskGoogle() {
      console.log("amiAskGoogle() called");
    },

    amiSendChatMessage(lowerCaseUserSpeech) {
      let messageString = lowerCaseUserSpeech;
      this.saveMessage(messageString);
      this.amiInitializedForAmi = false;
      this.showAMIProcessing(`"${messageString}" successfully sent`);
    },

    amiPlaySongByArtist() {
      console.log("amiPlaySongByArtist() called");
      console.log("SONG", this.amiGrabbedSong);
      this.$store.dispatch("playSong", this.amiGrabbedSong);
    },

    // other things:
    // show me my billboard posts
    // show me <friend_names>'s billboard -> post on billboard -> what should it say? -> include song? -> song by artist -> DONE
    // show me my broadcast
    // filter broadcast by date -> which date?
    // filter broadcast by friend -> which friend?

    // create billboard post -> what should it say? -> include song? -> song by artist -> DONE
    // create broadcast post -> what should it say? -> include song? -> song by artist -> DONE

    // grab other things:
    // grab song [X]
    // play song [x]
    // is this song in my library -> yes/no -> would you like me to save it? [x]
    // save song to library [x]
    // what are my options? [x]
    // add song to queue [x]
    // remove song from queue [x]
    // remove song from library [x]
    // save song to playlist named.. [x]
    // share song to friend ->
    //via message [x]
    //via billboard [x]
    //via live listening []
    // share song to my billboard [x]

    // grab friend [x]
    // display grabbed friend [x]
    // share song to billboard [x]
    // share song in message [x]
    // send chat message <message content> [x]
    // post on billboard [x]
    // go to their profile [x]
    // add as collaborator to playlist named []

    // create playlist [x]

    // grab playlist [x]
    // share to friend (in message) []
    // on your billboard [] (? need to build this out first)
    // on friend's billboard [] (? need to build this out first)
    // copy playlist [x] can use save as queue functionality
    // delete playlist [x]
    // edit playlist name [x]
    // add a collaborator [x]
    // remove a collaborator [x]

    // display all broadcast posts [x]
    // filter posts [x]
    // filter posts by friend name [x]
    // remove filters [x]
    // filter posts by friend billboard [x]
    // filter posts by date []
    // show me my billboard [x]
    // create a post [x] -> need to ask which billboard it goes on (?)
    // with song [x]
    // without song [x]

    // grab album [x]
    // add to library [x] // WEIRD ERRORS - need to test if they are only in dev
    // add to playlist [x] // WEIRD ERRORS - need to test if they are only in dev
    // share album to billboard [x]
    // share to friend in message (link URL on post)
    // share to friend in post (link URL on post)

    // billboard []
    // display artist's billboard []
    // display friend's billboard []
    // post on artist's billboard []
    // post on friend's billboard []

    // grab post [] <--- HOW WOULD YOU DO THAT? (last post, or last post from friend name, then skip +1 or -1 beween them?)
    // comment on post []
    // like post []
    // dislike post []
    // share post? []

    // artists []
    // list albums (?)
    // grab album named
    // navigate to Profile []
    // navigate to album index page []
    // navigate to album song page []
    // navigate to billboard page []
    // navigate to ops pages page []
    // navigate to playlists page []
    // navigate to artist store []
    // fan artist []
    // post on artist's billboard []
    // donate []
    // buy album []

    // create a shared playlist named [name]
    //got it. what friends would you like to add to the playlist? (displays friends?) or (say Cody Greenleaf and she asks if anyone else)
    //ok. got it. anyone else? (repeats)
    //(ok, would you like me to add songs to this playlist for you?)

    // build a playlist named  > have a genre in mind? (yes/no) > is this for a specific occasion (yes/no) > upbeat or slow or any tempo?
    // MAYBE > would you like to share this playlist? with which friends? > would you like me to make recommendations who would like this?
    //
    // build a shared playlist named > which friends? > is this for a specific occasion?
    //
    // (looks for mutual songs in parameters (maybe top ranked/top played), then depending on occasion filter out)

    // think about AMI notifying you of things (global event listener?)
    // contiguous mode, AMI is always init
    // make prompts for create/delete (are you sure?/did you mean?)

    amiGrabSongByArtist() {
      console.log("amiGrabSongByArtist called");
      console.log("amiSongGrabbed", this.amiSongGrabbed);
      console.log("amiGrabbedSong", this.amiGrabbedSong);
      //set amiSongGrabbed
    },

    amiPlayGrabbedSong() {
      this.$store.dispatch("playSong", this.amiGrabbedSong);
    },

    amiGrabFriendNamed() {
      console.log("amiGrabFriendNamed called");
      // console.log("amiFriendGrabbed", this.amiFriendGrabbed);
      // console.log("amiGrabbedFriend", this.amiGrabbedFriend);
      // let friendFound = false;
      // let userSpeechFriendName = lowerCaseUserSpeech;

      // callUserFriendshipsIndex(this.$store.state.currentUserId)
      //   .then(response => {
      //     let userFriendships = response;
      //     for (let i = 0; i < userFriendships.length; i++) {
      //       if (
      //         userFriendships[i].friend.name.toLowerCase() ==
      //         userSpeechFriendName
      //       ) {
      //         friendFound = true;
      //         this.amiGrabbedFriend = userFriendships[i].friend;
      //         break;
      //       }
      //     }
      //     if (friendFound === true) {
      //       this.amiFriendGrabbed = true;
      //       // this.successAudioPlay();
      //       this.showAMIProcessing(
      //         `${this.amiGrabbedFriend.name} grabbed. What next?`
      //       );
      //     } else {
      //       this.showAMIProcessing(
      //         `No friends found with that name. Please try again.`
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your friends. Please try again.`
      //     );
      //   });
    },

    songInLibrary() {
      console.log("songInLibrary() called");

      // let songFoundInLibrary = false;
      // let librarySongs = this.$store.state.librarySongs;
      // for (let i = 0; i < librarySongs.length; i++) {
      //   if (this.amiGrabbedSong.name == librarySongs[i].song.name) {
      //     songFoundInLibrary = true;
      //   }
      // }
      // if (songFoundInLibrary) {
      //   this.songInLibraryPlay();
      //   this.showAMIProcessing(`Yes, this song is in your library.`);
      //   this.amiRespondedYesToIfInLibrary = true;
      // } else {
      //   this.songNotInLibraryPlay();
      //   this.showAMIProcessing(
      //     `No, this song is not in your library. Would you like me to add it for you?.`
      //   );
      //   this.amiRespondedNoToIfInLibrary = true;
      // }
      // we should add an amiGrabbed attribute to every song playing through the library, so you can immediately interact with it
    },

    saveSongToLibrary() {
      console.log("saveSongToLibrary() called");

      // Axios({
      //   method: "post",
      //   url: `/api/v1/users/${this.$store.state.currentUserId}/library/library_song_references`,
      //   data: {
      //     song_id: this.amiGrabbedSong.id,
      //     library_id: this.$store.state.currentUserId
      //   }
      // })
      //   .then(() => {
      //     this.$store.dispatch("getLibrary");
      //     this.amiInitializedForAmi = false;
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },

    removeSongFromLibrary() {
      console.log("removeSongFromLibrary called");

      // let librarySongFound = false;

      // Axios.get(
      //   `/api/v1/users/${this.$store.state.currentUserId}/library/library_song_references`,
      //   {}
      // )
      //   .then(response => {
      //     let librarySongs = response.data;
      //     for (let i = 0; i < librarySongs.length; i++) {
      //       if (librarySongs[i].song.song_id == this.amiGrabbedSong.id) {
      //         librarySongFound = true;
      //         this.librarySongId = librarySongs[i].song.id;
      //         break;
      //       }
      //     }
      //     if (librarySongFound == true) {
      //       Axios.delete(
      //         `/api/v1/users/${this.$store.state.currentUserId}/library/library_song_references/${this.librarySongId}`,
      //         {}
      //       )
      //         .then(response => {
      //           console.log(response);
      //           this.$store.dispatch("getLibrary");
      //           // this.successAudioPlay();
      //           this.showAMIProcessing(
      //             `Song successfully removed from library.`
      //           );
      //         })
      //         .catch(error => {
      //           console.error(error);
      //         });
      //     } else {
      //       this.showAMIProcessing(`Song not in your library. Cannot remove.`);
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },

    /* eslint-disable no-unused-vars */
    amiSaveSongToPlaylistNamed(lowerCaseUserSpeech) {
      console.log("amiSaveSongToPlaylistNamed called");

      // let playlistFound = false;
      // let userSpeechPlaylistName = lowerCaseUserSpeech;

      // Axios.get(
      //   `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
      //   {}
      // ).then(response => {
      //   let playlists = response.data;
      //   for (let i = 0; i < playlists.length; i++) {
      //     this.playlistName = playlists[i].name.toLowerCase();
      //     if (this.playlistName == userSpeechPlaylistName) {
      //       this.playlistId = playlists[i].id;
      //       playlistFound = true;
      //       break;
      //     }
      //   }
      //   if (playlistFound == true) {
      //     Axios.post(
      //       `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.playlistId}/playlist_song_references`,
      //       {
      //         song_id: this.amiGrabbedSong.id,
      //         playlist_id: this.playlistId
      //       }
      //     )
      //       .then(() => {
      //         this.showAMIProcessing(
      //           `Song "${this.amiGrabbedSong.name}" added to playlist "${this.playlistName}"`
      //         );
      //         this.getPlaylistSongs(this.playlistId);
      //         // this.successAudioPlay(); // using this because it's a generic success message
      //       })
      //       .catch(error => {
      //         console.error(error);
      //       });
      //   } else {
      //     this.showAMIProcessing(
      //       `No playlist found with that name. Please try again.`
      //     );
      //   }
      // });
    },

    getPlaylistSongs(playlistId) {
      console.log("getPlaylistSongs called");

      // Axios.get(
      //   `/api/v1/users/${this.$store.state.currentUserId}/playlists/${playlistId}/playlist_songs`,
      //   {}
      // )
      //   .then(response => {
      //     let playlistSongs = response.data;
      //     this.$store.commit("setCurrentPlaylistSongs", playlistSongs);
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },

    amiRemoveSongFromPlaylistNamed(lowerCaseUserSpeech) {
      console.log("amiRemoveSongFromPlaylistNamed called");

      // let playlistFound = false;
      // let playlistSongFound = false;
      // let userSpeechPlaylistName = lowerCaseUserSpeech;

      // Axios.get(
      //   `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
      //   {}
      // ).then(response => {
      //   let playlists = response.data;
      //   for (let i = 0; i < playlists.length; i++) {
      //     this.playlistName = playlists[i].name.toLowerCase();
      //     if (this.playlistName == userSpeechPlaylistName) {
      //       this.playlistId = playlists[i].id;
      //       playlistFound = true;
      //       break;
      //     }
      //   }
      //   if (playlistFound == true) {
      //     Axios.get(
      //       `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.playlistId}/playlist_song_references`,
      //       {}
      //     )
      //       .then(response => {
      //         let playlistSongs = response.data;
      //         for (let i = 0; i < playlistSongs.length; i++) {
      //           let playlistSongSongId = playlistSongs[i].song_id; // playlist_song.song_id
      //           if (playlistSongSongId == this.amiGrabbedSong.id) {
      //             this.playlistSongId = playlistSongs[i].id; // playlist_song.id
      //             playlistSongFound = true;
      //             break;
      //           }
      //         }
      //         if (playlistSongFound === true) {
      //           Axios.delete(
      //             `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.playlistId}/playlist_song_references/${this.playlistSongId}`,
      //             {}
      //           )
      //             .then(() => {
      //               // this.successAudioPlay(); // using this because it's a generic success message
      //               this.showAMIProcessing(
      //                 `Song "${this.amiGrabbedSong.name}" deleted from playlist "${this.playlistName}"`
      //               );
      //               this.getPlaylistSongs(this.playlistId);
      //             })
      //             .catch(error => {
      //               console.error(error);
      //             });
      //         } else {
      //           this.showAMIProcessing(
      //             `No song named "${this.amiGrabbedSong.name}" was found in playlist "${this.playlistName}." Please try again.`
      //           );
      //         }
      //       })
      //       .catch(error => {
      //         console.error(error);
      //       });
      //   } else {
      //     this.showAMIProcessing(
      //       `No playlist found with that name. Please try again.`
      //     );
      //   }
      // });
    },

    amiShareSongWithFriendNamed(lowerCaseUserSpeech) {
      console.log("amiShareSongWithFriendNamed called");
      // if (this.amiSongGrabbed) {
      //   let friendFound = false;
      //   let userSpeechFriendName = lowerCaseUserSpeech;

      // callUserFriendshipsIndex(this.$store.state.currentUserId)
      //     .then(response => {
      //       let userFriendships = response;
      //       for (let i = 0; i < userFriendships.length; i++) {
      //         if (
      //           userFriendships[i].friend.name.toLowerCase() ==
      //           userSpeechFriendName
      //         ) {
      //           friendFound = true;
      //           this.amiGrabbedFriend = userFriendships[i].friend;
      //           break;
      //         }
      //       }
      //       if (friendFound === true) {
      //         this.amiFriendGrabbed = true;
      //         // this.successAudioPlay();
      //         this.showAMIProcessing(
      //           `${this.amiGrabbedFriend.name} grabbed. How would you like to share this song?`
      //         );
      //       } else {
      //         this.showAMIProcessing(
      //           `No friends found with that name. Please try again.`
      //         );
      //       }
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       this.showAMIProcessing(
      //         `There was an error when attempting to load your friends. Please try again.`
      //       );
      //     });
      // } else {
      //   this.showAMIProcessing(
      //     `You must grab a song in order to execute this command. Please try again.`
      //   );
      // }
    },

    amiShareSongToFriendsBillboard() {
      console.log("amiShareSongToFriendsBillboard called");

      //REFACTOR TO SONG REFERENCES

      // if (!this.amiFriendGrabbed && this.amiSongGrabbed) {
      //   this.showAMIProcessing(
      //     `You haven't grabbed a friend to share this song to yet. Please do this first.`
      //   );
      // } else if (!this.amiSongGrabbed && this.amiFriendGrabbed) {
      //   this.showAMIProcessing(
      //     `You haven't grabbed a song to share to your friend yet. Please do this first.`
      //   );
      // } else if (this.amiFriendGrabbed && this.amiSongGrabbed) {
      //   Axios.post(
      //     `/api/v1/users/${this.amiGrabbedFriend.id}/billboard_posts`,
      //     {
      //       post: {
      //         content: `${this.amiGrabbedSong.name} by ${this.amiGrabbedsong.primary_artist.user.name}`,
      //         user_id: this.$store.state.currentUserId,
      //         billboard_id: this.amiGrabbedFriend.id
      //       }
      //     }
      //   )
      //     .then(response => {
      //       let billboardPostId = response.data.id;
      //       Axios.post(`/api/v1/post_songs`, {
      //         post_id: billboardPostId,
      //         song_id: this.amiGrabbedSong.id
      //       })
      //         .then(() => {
      //           // this.successAudioPlay(); // using this because it's a generic success message
      //           this.showAMIProcessing(
      //             `${this.amiGrabbedSong.name} successfully shared to ${this.amiGrabbedFriend.name}'s billboard.`
      //           );
      //         })
      //         .catch(error => {
      //           console.error(error);
      //           this.showAMIProcessing(
      //             `There was an error when attempting to share the song. Please try again.`
      //           );
      //         });
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       this.showAMIProcessing(
      //         `There was an error when attempting to share the song. Please try again.`
      //       );
      //     });
      // } else {
      //   this.showAMIProcessing(
      //     `You must grab both a song and a friend in order to execute this command. Please do both first.`
      //   );
      // }
    },

    amiSetPostContent(pB) {
      console.log("amiSetPostContent called");

      // let userSpeechPostContent = pB;

      // console.log("AMI SET POST CONTENT TRIGGERED =======================");

      // if (this.amiFriendGrabbed) {
      //   this.amiPostContent = userSpeechPostContent;
      //   this.amiGrabbedFriendPostContentRecorded = true;
      //   // this.showAMIProcessing(
      //   //   `Got it. Recorded ${
      //   //     this.amiPostContent
      //   //   }. Would you like to include a song with your post?`
      //   // );
      //   // this.successAudioPlay();
      // } else {
      //   this.showAMIProcessing(
      //     `You must grab both a song and a friend in order to execute this command. Please try again.`
      //   );
      // }
    },

    amiCreatePostWithoutSong() {
      console.log("amiCreatePostWithoutSong called");

      // Axios.post(`/api/v1/users/${this.amiGrabbedFriend.id}/billboard_posts`, {
      //   post: {
      //     content: this.amiPostContent,
      //     user_id: this.$store.state.currentUserId,
      //     billboard_id: this.amiGrabbedFriend.id
      //   }
      // })
      //   .then(() => {
      //     // this.successAudioPlay(); // using this because it's a generic success message
      //     this.showAMIProcessing(
      //       `Post successfully shared to ${this.amiGrabbedFriend.name}'s billboard.`
      //     );
      //     this.amiGrabbedFriendPostContentRecorded = false;
      //     this.amiPostContent = null;
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to share the song. Please try again.`
      //     );
      //   });
    },

    amiCreatePostWithSong() {
      console.log("amiCreatePostWithSong called");

      // Axios.post(`/api/v1/users/${this.amiGrabbedFriend.id}/billboard_posts`, {
      //   post: {
      //     content: this.amiPostContent,
      //     user_id: this.$store.state.currentUserId,
      //     billboard_id: this.amiGrabbedFriend.id
      //   }
      // })
      //   .then(response => {
      //     let billboardPostId = response.data.id;
      //     Axios.post(`/api/v1/post_songs`, {
      //       post_id: billboardPostId,
      //       song_id: this.amiGrabbedSong.id
      //     })
      //       .then(() => {
      //         // this.successAudioPlay(); // using this because it's a generic success message
      //         this.showAMIProcessing(
      //           `${this.amiGrabbedSong.name} successfully shared to ${this.amiGrabbedFriend.name}'s billboard.`
      //         );
      //         this.showAMIProcessing(
      //           `Post successfully shared to ${this.amiGrabbedFriend.name}'s billboard.`
      //         );
      //         this.amiGrabbedFriendPostContentRecorded = false;
      //         this.amiPostContent = null;
      //       })
      //       .catch(error => {
      //         console.error(error);
      //         this.showAMIProcessing(
      //           `There was an error when attempting to create this post. Please try again.`
      //         );
      //       });
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to create this post. Please try again.`
      //     );
      //   });
    },

    amiSetPostSong(lowerCaseUserSpeech) {
      console.log("amiSetPostSong called");

      // let a = lowerCaseUserSpeech.split("by artist ").pop();
      // let b = a.split(a.indexOf(" "))[0];
      // let userSpeechArtistName = b.toLowerCase();

      // let c = lowerCaseUserSpeech.split("include song ").pop();
      // let d = c.split("by artist ")[0];
      // let e = d.split(d.indexOf(" "))[0];
      // let f = e.substring(0, e.length - 1);
      // let userSpeechSongName = f.toLowerCase();

      // let artistFound = false;

      // //GLOBAL: SEARCH THROUGH ALL ARTISTS
      // Axios.get(`/api/v1/artist_profiles`, {}).then(response => {
      //   this.artistProfiles = response.data;
      //   for (let i = 0; i < this.artistProfiles.length; i++) {
      //     this.artistName = this.artistProfiles[i].user.name.toLowerCase();
      //     if (this.artistName == userSpeechArtistName) {
      //       this.artistProfileId = this.artistProfiles[i].id;
      //       artistFound = true;
      //       break;
      //     }
      //   }

      //   if (artistFound == true) {
      //     // find songs by found artist (may need to make an endpoint for this)
      //     Axios.get(
      //       `/api/v1/artist_profiles/${this.artistProfileId}/songs`,
      //       {}
      //     ).then(response => {
      //       this.songs = response.data;
      //       // will need to match song names by artist vs song names by artist in library (?) / could also have global song play option which finds them all / if you like it, grab song and add it/share it
      //       let songFound = false;
      //       for (let i = 0; i < this.songs.length; i++) {
      //         let songName = this.songs[i].name.toLowerCase();
      //         if (songName == userSpeechSongName) {
      //           songFound = true;
      //           this.amiSongGrabbed = true; // if SongGrabbed -> play sound fx/AMI saying what next?
      //           this.amiGrabbedSong = this.songs[i];
      //           // this.successAudioPlay(); // just using generic success message
      //           this.amiCreatePostWithSong();
      //         }
      //       }
      //       if (songFound == false) {
      //         this.showAMIProcessing(
      //           `No song found with that name. Please try again.`
      //         );
      //       }
      //     });
      //   } else {
      //     this.showAMIProcessing(
      //       `No artist found with that name. Please try again.`
      //     );
      //   }
      // });
    },

    // FUNCTION CREATES MESSAGE AND MESSAGE SONG
    amiShareSongInMessage(roomId) {
      console.log("amiShareSongInMessage called");

      // Axios.post(
      //   `/api/v1/chat_rooms/${this.$store.state.currentChatRoomId}/chat_messages`,
      //   {
      //     chat_room_id: roomId,
      //     user_id: this.$store.state.currentUserId,
      //     body: `${this.amiGrabbedSong.name} by ${this.amiGrabbedsong.primary_artist.user.name}.`
      //   }
      // )
      //   .then(response => {
      //     //create message song with message's ID
      //     let messageId = response.data.id;
      //     Axios.post(`/api/v1/chat_message_songs`, {
      //       chat_message_id: messageId,
      //       song_id: this.amiGrabbedSong.id
      //     })
      //       .then(() => {
      //         this.getRoomMessages(this.$store.state.currentChatRoomId);
      //         // this.successAudioPlay();
      //         this.showAMIProcessing(
      //           `${this.amiGrabbedSong.name} successfully shared to ${this.amiGrabbedFriend.name}'s chat.`
      //         );
      //       })
      //       .catch(error => {
      //         console.error(error);
      //         this.showAMIProcessing(
      //           `There was an error when attempting to share the song in message. Please try again.`
      //         );
      //       });
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to share the song. Please try again.`
      //     );
      //   });
    },

    // FUNCTION CREATES ROOM AND SENDS MESSAGE IF NO ROOMS/NO ROOMS MATCHING CRITERIA FOUND
    amiCreateRoomAndSendMessage(mB) {
      console.log("amiCreateRoomAndSendMessage called");

      // console.log("ROOM NOT FOUND... CREATING NOW");
      // // CREATE CHAT ROOM W/USER FRIENDSHIP ID (USER_FRIEND IDs)
      // this.roomId = this.userChatRoomId + "0" + this.friendChatRoomId;

      // Axios.post(`/api/v1/chat_rooms`, {
      //   id: this.userChatRoomId + "0" + this.friendChatRoomId,
      //   user_friendship_id: this.userChatRoomId + "0" + this.friendChatRoomId
      // })
      //   .then(() => {
      //     console.log("Room Created!");
      //     this.retrieveRoom(this.roomId);
      //     this.amiSendMessageToGrabbedFriend(this.roomId, mB);
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },

    //FUNCTION EXECUTES SEARCH LOGIC FOR ROOMS BASED OFF OF FRIEND NAME
    amiShareSongToFriendInMessage() {
      console.log("amiShareSongToFriendInMessage() called");

      // if (this.amiFriendGrabbed && this.amiSongGrabbed) {
      //   this.userChatRoomId = this.$store.state.currentUserId;
      //   this.friendChatRoomId = this.amiGrabbedFriend.id;

      //   Axios.get(`/api/v1/chat_rooms`, {})
      //     .then(response => {
      //       this.chatRooms = response.data;
      //       // if no rooms, create room
      //       if (this.chatRooms.length == 0) {
      //         console.log("0 ROOMS");
      //         this.amiCreateRoomAndSendMessage();
      //       } else {
      //         console.log("MORE THAN 0 ROOMS");
      //         // if rooms, begin searching fooms for one possible room id combination
      //         let foundRoom = false;
      //         for (let i = 0; i < this.chatRooms.length; ++i) {
      //           if (
      //             this.chatRooms[i].id ==
      //             parseInt(
      //               "" + this.userChatRoomId + "0" + this.friendChatRoomId
      //             )
      //           ) {
      //             foundRoom = true;
      //             this.roomId = this.chatRooms[i].id;
      //             this.$store.commit("setChatRoomId", this.roomId);
      //             this.retrieveRoom(this.roomId);
      //             this.amiShareSongInMessage(this.roomId);
      //             break;
      //             // if no rooms with previous combination, begin searching fooms for other possible room id combination
      //           } else if (
      //             this.chatRooms[i].id ==
      //             parseInt(
      //               "" + this.friendChatRoomId + "0" + this.friendChatRoomId
      //             )
      //           ) {
      //             foundRoom = true;
      //             this.roomId = this.chatRooms[i].id;
      //             this.$store.commit("setChatRoomId", this.roomId);
      //             this.retrieveRoom(this.roomId);
      //             this.amiShareSongInMessage(this.roomId);
      //             break;
      //           }
      //         }
      //         // if no rooms with id found, create room
      //         if (foundRoom == false) {
      //           this.amiCreateRoomAndSendMessage();
      //         }
      //       }
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       this.showAMIProcessing(
      //         `There was an error when attempting to share the song. Please try again.`
      //       );
      //     });
      // } else {
      //   this.showAMIProcessing(
      //     `You must grab both a song and a friend in order to execute this command. Please try again.`
      //   );
      // }
    },

    // FUNCTION CREATES MESSAGE AND MESSAGE SONG
    amiSendMessageToGrabbedFriend(roomId, mB) {
      console.log("amiSendMessageToGrabbedFriend called");

      // Axios.post(
      //   `/api/v1/chat_rooms/${this.$store.state.currentChatRoomId}/chat_messages`,
      //   {
      //     chat_room_id: roomId,
      //     user_id: this.$store.state.currentUserId,
      //     body: mB
      //   }
      // )
      //   .then(() => {
      //     this.getRoomMessages(this.$store.state.currentChatRoomId);
      //     // this.successAudioPlay();
      //     this.showAMIProcessing(
      //       `Message successfully sent to friend ${this.amiGrabbedFriend.name}`
      //     );
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to share the song. Please try again.`
      //     );
      //   });
    },

    // FUNCTION CREATES ROOM AND SENDS MESSAGE IF NO ROOMS/NO ROOMS MATCHING CRITERIA FOUND
    amiCreateRoomAndSendMessageToGrabbedFriend() {
      console.log("amiCreateRoomAndSendMessageToGrabbedFriend called");

      // console.log("ROOM NOT FOUND... CREATING NOW");
      // // CREATE CHAT ROOM W/USER FRIENDSHIP ID (USER_FRIEND IDs)
      // this.roomId = this.userChatRoomId + "0" + this.friendChatRoomId;

      // Axios.post(`/api/v1/chat_rooms`, {
      //   id: this.userChatRoomId + "0" + this.friendChatRoomId,
      //   user_friendship_id: this.userChatRoomId + "0" + this.friendChatRoomId
      // })
      //   .then(() => {
      //     console.log("Room Created!");
      //     this.retrieveRoom(this.roomId);
      //     this.amiShareSongInMessage(this.roomId);
      //   })
      //   .catch(error => {
      //     console.error(error);
      //   });
    },

    //FUNCTION EXECUTES SEARCH LOGIC FOR ROOMS BASED OFF OF FRIEND NAME (AFTER FRIEND IS GRABBED)
    amiSendGrabbedFriendMessage(mB) {
      console.log("amiSendGrabbedFriendMessage called");

      // this.userChatRoomId = this.$store.state.currentUserId;
      // this.friendChatRoomId = this.amiGrabbedFriend.id;

      // Axios.get(`/api/v1/chat_rooms`, {})
      //   .then(response => {
      //     this.chatRooms = response.data;
      //     // if no rooms, create room
      //     if (this.chatRooms.length == 0) {
      //       console.log("0 ROOMS");
      //       this.amiCreateRoomAndSendMessageToGrabbedFriend(mB);
      //     } else {
      //       console.log("MORE THAN 0 ROOMS");
      //       // if rooms, begin searching fooms for one possible room id combination
      //       let foundRoom = false;
      //       for (let i = 0; i < this.chatRooms.length; ++i) {
      //         if (
      //           this.chatRooms[i].id ==
      //           parseInt("" + this.userChatRoomId + "0" + this.friendChatRoomId)
      //         ) {
      //           foundRoom = true;
      //           this.roomId = this.chatRooms[i].id;
      //           this.$store.commit("setChatRoomId", this.roomId);
      //           this.retrieveRoom(this.roomId);
      //           this.amiSendMessageToGrabbedFriend(this.roomId, mB);
      //           break;
      //           // if no rooms with previous combination, begin searching fooms for other possible room id combination
      //         } else if (
      //           this.chatRooms[i].id ==
      //           parseInt(
      //             "" + this.friendChatRoomId + "0" + this.friendChatRoomId
      //           )
      //         ) {
      //           foundRoom = true;
      //           this.roomId = this.chatRooms[i].id;
      //           this.$store.commit("setChatRoomId", this.roomId);
      //           this.retrieveRoom(this.roomId);
      //           this.amiSendMessageToGrabbedFriend(this.roomId, mB);
      //           break;
      //         }
      //       }
      //       // if no rooms with id found, create room
      //       if (foundRoom == false) {
      //         this.amiCreateRoomAndSendMessageToGrabbedFriend(mB);
      //       }
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to share the song. Please try again.`
      //     );
      //   });
    },

    amiShareSongToMyBillboard() {
      console.log("amiShareSongToMyBillboard called");

      // Axios.post(
      //   `/api/v1/users/${this.$store.state.currentUserId}/billboard_posts`,
      //   {
      //     post: {
      //       content: `${this.amiGrabbedSong.name} by ${this.amiGrabbedsong.primary_artist.user.name}`,
      //       user_id: this.$store.state.currentUserId,
      //       billboard_id: this.$store.state.currentUserId
      //     }
      //   }
      // )
      //   .then(response => {
      //     let billboardPostId = response.data.id;
      //     Axios.post(`/api/v1/post_songs`, {
      //       post_id: billboardPostId,
      //       song_id: this.amiGrabbedSong.id
      //     })
      //       .then(() => {
      //         // this.successAudioPlay(); // using this because it's a generic success message
      //         this.showAMIProcessing(
      //           `${this.amiGrabbedSong.name} successfully shared to your billboard.`
      //         );
      //       })
      //       .catch(error => {
      //         console.error(error);
      //         this.showAMIProcessing(
      //           `There was an error when attempting to share the song. Please try again.`
      //         );
      //       });
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to share the song. Please try again.`
      //     );
      //   });
    },

    amiCreatePlaylistNamed(playlistName) {
      console.log("amiCreatePlaylistNamed called");

      // let userSpeechPlaylistName =
      //   playlistName.charAt(0).toUpperCase() + playlistName.slice(1);

      // Axios({
      //   method: "post",
      //   url: `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
      //   data: {
      //     user_id: this.$store.state.currentUserId,
      //     name: userSpeechPlaylistName
      //   }
      // })
      //   .then(response => {
      //     let createdPlaylist = response.data;
      //     let createdPlaylistName = response.data.name;

      //     this.$store.state.currentUserPlaylists.push(createdPlaylist);

      //     // this.successAudioPlay(); // using this because it's a generic success message
      //     this.showAMIProcessing(
      //       `${createdPlaylistName} successfully created.`
      //     );
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to create the playlist. Please try again.`
      //     );
      //   });
    },

    amiGrabPlaylistNamed(lowerCaseUserSpeech) {
      console.log("amiGrabPlaylistNamed called");
      // let playlistFound = false;
      // let userSpeechPlaylistName = lowerCaseUserSpeech;

      // Axios.get(
      //   `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
      //   {}
      // )
      //   .then(response => {
      //     let userPlaylists = response.data;
      //     for (let i = 0; i < userPlaylists.length; i++) {
      //       if (userPlaylists[i].name.toLowerCase() == userSpeechPlaylistName) {
      //         playlistFound = true;
      //         this.amiGrabbedPlaylist = userPlaylists[i];
      //         break;
      //       }
      //     }
      //     if (playlistFound === true) {
      //       this.amiPlaylistGrabbed = true;
      //       // this.successAudioPlay(); // using this because it's a generic success message
      //       this.showAMIProcessing(
      //         `Playlist "${this.amiGrabbedPlaylist.name}" grabbed. What next?`
      //       );
      //     } else {
      //       this.showAMIProcessing(
      //         `No playlists found with that name. Please try again.`
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your playlists. Please try again.`
      //     );
      //   });
    },

    amiDeleteGrabbedPlaylist() {
      console.log("amiDeleteGrabbedPlaylist called");

      // Axios.delete(
      //   `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.amiGrabbedPlaylist.id}`,
      //   {}
      // )
      //   .then(response => {
      //     console.log(response);
      //     // this.successAudioPlay(); // using this because it's a generic success message
      //     this.showAMIProcessing(
      //       `Playlist "${this.amiGrabbedPlaylist.name}" deleted successfully.`
      //     );
      //     this.getUserPlaylists();
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to delete playlist. Please try again.`
      //     );
      //   });
    },

    amiRenameGrabbedPlaylist(lowerCaseUserSpeech) {
      console.log("amiRenameGrabbedPlaylist called");

      // let userSpeechPlaylistName = lowerCaseUserSpeech;

      // console.log("userSpeechPlaylistName ", userSpeechPlaylistName);

      // Axios({
      //   method: "patch",
      //   url: `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.amiGrabbedPlaylist.id}`,
      //   data: {
      //     name: userSpeechPlaylistName
      //   }
      // })
      //   .then(response => {
      //     console.log(response);
      //     // this.successAudioPlay(); // using this because it's a generic success message
      //     this.showAMIProcessing(
      //       `Playlist "${this.amiGrabbedPlaylist.name}" renamed to ${userSpeechPlaylistName} successfully.`
      //     );
      //     this.getUserPlaylists();
      //     this.amiGrabbedPlaylist = response.data;
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to rename playlist. Please try again.`
      //     );
      //   });
    },

    amiRemoveCollaboratorFromGrabbedPlaylist(collaboratorFriendId) {
      console.log("amiRemoveCollaboratorFromGrabbedPlaylist called");

      // let collaboratorFound = false;
      // Axios.get(
      //   `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.amiGrabbedPlaylist.id}/playlist_collaborators`,
      //   {}
      // )
      //   .then(response => {
      //     let playlistCollaborators = response.data;
      //     for (let i = 0; i < playlistCollaborators.length; i++) {
      //       if (playlistCollaborators[i].user_id == collaboratorFriendId) {
      //         collaboratorFound = true;
      //         this.amiPlaylistCollaboratorId = playlistCollaborators[i].id;
      //         break;
      //       }
      //     }
      //     if (collaboratorFound === true) {
      //       Axios.delete(
      //         `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.amiGrabbedPlaylist.id}/playlist_collaborators/${this.amiPlaylistCollaboratorId}`,
      //         {}
      //       )
      //         .then(response => {
      //           console.log(response);
      //           // this.successAudioPlay(); // generic success message
      //           this.showAMIProcessing(
      //             `Collaborator, ${this.amiGrabbedFriend.name}, successfully removed from playlist.`
      //           );
      //         })
      //         .catch(error => {
      //           console.error(error);
      //           this.showAMIProcessing(
      //             `There was an error removing friend from playlist. Please try again.`
      //           );
      //         });
      //     } else {
      //       this.showAMIProcessing(
      //         `No collaborators found with that name. Please try again.`
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your playlist collaborators. Please try again.`
      //     );
      //   });
    },

    amiAddCollaboratorToGrabbedPlaylist(collaboratorId) {
      console.log("amiAddCollaboratorToGrabbedPlaylist called");
      // Axios.post(
      //   `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.amiGrabbedPlaylist.id}/playlist_collaborators`,
      //   {
      //     user_id: collaboratorId,
      //     playlist_id: this.amiGrabbedPlaylist.id
      //   }
      // )
      //   .then(response => {
      //     console.log(response);
      //     // this.successAudioPlay(); // generic success message
      //     this.showAMIProcessing(
      //       `Collaborator, ${this.amiGrabbedFriend.name}, successfully added to playlist.`
      //     );
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error adding friend to playlist. Please try again.`
      //     );
      //   });
    },

    amiRemoveCollaboratorNamed(lowerCaseUserSpeech) {
      console.log("amiRemoveCollaboratorNamed");
      // let friendFound = false;
      // let userSpeechFriendName = lowerCaseUserSpeech;
      // console.log("userSpeechFriendName ", userSpeechFriendName);

      // callUserFriendshipsIndex(this.$store.state.currentUserId)
      //   .then(response => {
      //     let userFriendships = response;
      //     for (let i = 0; i < userFriendships.length; i++) {
      //       console.log(userFriendships[i].friend.name);
      //       console.log(userSpeechFriendName);
      //       if (
      //         userFriendships[i].friend.name.toLowerCase() ==
      //         userSpeechFriendName
      //       ) {
      //         friendFound = true;
      //         this.amiGrabbedFriend = userFriendships[i].friend;
      //         break;
      //       }
      //     }
      //     if (friendFound === true) {
      //       this.amiFriendGrabbed = true;
      //       this.amiRemoveCollaboratorFromGrabbedPlaylist(
      //         this.amiGrabbedFriend.id
      //       );
      //     } else {
      //       this.showAMIProcessing(
      //         `No friends found with that name. Please try again.`
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your friends. Please try again.`
      //     );
      //   });
    },

    amiAddCollaboratorNamed(lowerCaseUserSpeech) {
      console.log("amiAddCollaboratorNamed called");
      // let friendFound = false;
      // let userSpeechFriendName = lowerCaseUserSpeech;
      // console.log("userSpeechFriendName ", userSpeechFriendName);

      // callUserFriendshipsIndex(this.$store.state.currentUserId)
      //   .then(response => {
      //     let userFriendships = response;
      //     for (let i = 0; i < userFriendships.length; i++) {
      //       console.log(userFriendships[i].friend.name);
      //       console.log(userSpeechFriendName);
      //       if (
      //         userFriendships[i].friend.name.toLowerCase() ==
      //         userSpeechFriendName
      //       ) {
      //         friendFound = true;
      //         this.amiGrabbedFriend = userFriendships[i].friend;
      //         break;
      //       }
      //     }
      //     if (friendFound === true) {
      //       this.amiFriendGrabbed = true;
      //       this.amiAddCollaboratorToGrabbedPlaylist(this.amiGrabbedFriend.id);
      //     } else {
      //       this.showAMIProcessing(
      //         `No friends found with that name. Please try again.`
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your friends. Please try again.`
      //     );
      //   });
    },

    amiCopyPlaylist(userSpeechPlaylistName) {
      console.log("amiCopyPlaylist called");

      // let playlistName = userSpeechPlaylistName;
      // let newPlaylist;
      // let grabbedPlaylist = this.amiGrabbedPlaylist;

      // Axios({
      //   method: "post",
      //   url: `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
      //   data: {
      //     user_id: this.$store.state.currentUserId,
      //     name: playlistName
      //   }
      // })
      //   .then(response1 => {
      //     console.log(response1);
      //     newPlaylist = response1.data;
      //     this.showAMIProcessing(
      //       `${grabbedPlaylist.name} copied, and ${newPlaylist.name} successfully created.`
      //     );
      //     // this.showAMIProcessing("Playlist successfully copied");
      //     this.$store.state.currentUserPlaylists.push(response1.data);
      //     Axios.get(
      //       `/api/v1/users/${this.$store.state.currentUserId}/playlists/${grabbedPlaylist.id}/playlist_songs`,
      //       {}
      //     )
      //       .then(response2 => {
      //         console.log(response2);
      //         let playlistSongs = response2.data;
      //         //save playlist here
      //         for (let i = 0; playlistSongs.length; i++) {
      //           let playlistSongId = playlistSongs[i].id;
      //           Axios({
      //             method: "post",
      //             url: `/api/v1/users/${this.$store.state.currentUserId}/playlists/${newPlaylist.id}/playlist_songs`,
      //             data: {
      //               playlist_id: newPlaylist.id,
      //               song_id: playlistSongId
      //             }
      //           })
      //             .then(response3 => {
      //               console.log(response3);
      //             })
      //             .catch(error => {
      //               console.error(error);
      //             });
      //         }
      //       })
      //       .catch(error => {
      //         console.error(error);
      //       });
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error copying your playlist. Please try again.`
      //     );
      //   });
      // this.amiGrabbedPlaylist = newPlaylist;
    },

    amiFilterByFriendNamed(lowerCaseUserSpeech) {
      console.log("amiFilterByFriendNamed called");
      // let friendFound = false;
      // let userSpeechFriendName = lowerCaseUserSpeech;
      // console.log("userSpeechArtistName ", userSpeechFriendName);

      // callUserFriendshipsIndex(this.$store.state.currentUserId)
      //   .then(response => {
      //     let userFriendships = response;
      //     for (let i = 0; i < userFriendships.length; i++) {
      //       console.log(userFriendships[i].friend.name);
      //       console.log(userSpeechFriendName);
      //       if (
      //         userFriendships[i].friend.name.toLowerCase() ==
      //         userSpeechFriendName
      //       ) {
      //         friendFound = true;
      //         this.postsFriend = userFriendships[i].friend;
      //         break;
      //       }
      //     }
      //     if (friendFound === true) {
      //       console.log(
      //         "----------------- POST FRIEND ID:",
      //         this.postsFriend.id
      //       );
      //       Axios.get(`/api/v1/users/${this.postsFriend.id}/posts`, {})
      //         .then(response2 => {
      //           let billboardPosts = response2.data;
      //           this.$store.commit("setAmiPosts", billboardPosts);
      //           // this.successAudioPlay();
      //           this.showAMIProcessing(
      //             `${this.postsFriend.name}'s posts isolated.`
      //           );
      //         })
      //         .catch(error => {
      //           console.error(error);
      //           this.showAMIProcessing(
      //             `There was an error when attempting to load your posts. Please try again.`
      //           );
      //         });
      //     } else {
      //       this.showAMIProcessing(
      //         `No friends found with that name. Please try again.`
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your friends. Please try again.`
      //     );
      //   });
    },

    amiClosePosts() {
      this.$store.commit("closeBroadcastModal");
      this.$store.commit("getAmiPosts");
    },

    amiRemovePostFilters() {
      this.$store.commit("getAmiPosts");
    },

    amiSetPostContentForPosts(pB) {
      console.log("amiSetPostContentForPosts called");
      //   let userSpeechPostContent = pB.charAt(0).toUpperCase() + pB.slice(1);

      //   this.amiPostContent = userSpeechPostContent;
      //   this.amiPostContentRecordedForPost = true;
      //   this.showAMIProcessing(
      //     `Got it. Wrote ${this.amiPostContent}. Would you like to include a song with your post?`
      //   );
      //   // this.successAudioPlay();
      // },

      // amiCreatePostWithoutSongForPost() {
      //   Axios.post(
      //     `/api/v1/users/${this.$store.state.currentUserId}/billboard_posts`,
      //     {
      //       post: {
      //         content: this.amiPostContent,
      //         user_id: this.$store.state.currentUserId,
      //         billboard_id: this.$store.state.currentUserId
      //       }
      //     }
      //   )
      //     .then(() => {
      //       this.$store.commit("getAmiPosts");
      //       // this.successAudioPlay(); // using this because it's a generic success message
      //       this.showAMIProcessing(`Post successfully shared to your billboard.`);
      //     })
      //     .catch(error => {
      //       console.error(error);
      //       this.showAMIProcessing(
      //         `There was an error when attempting to create the post. Please try again.`
      //       );
      //     });
    },

    amiCreatePostWithSongForPost() {
      console.log("amiCreatePostWithSongForPost() called");
      // Axios.post(
      //   `/api/v1/users/${this.$store.state.currentUserId}/billboard_posts`,
      //   {
      //     post: {
      //       content: this.amiPostContent,
      //       user_id: this.$store.state.currentUserId,
      //       billboard_id: this.$store.state.currentUserId
      //     }
      //   }
      // )
      //   .then(response => {
      //     let billboardPostId = response.data.id;
      //     Axios.post(`/api/v1/post_songs`, {
      //       post_id: billboardPostId,
      //       song_id: this.amiGrabbedSong.id
      //     })
      //       .then(() => {
      //         // this.successAudioPlay(); // using this because it's a generic success message
      //         this.$store.commit("getAmiPosts");
      //         this.showAMIProcessing(
      //           `${this.amiGrabbedSong.name} successfully shared to billboard.`
      //         );
      //         this.showAMIProcessing(`Post successfully shared to billboard.`);
      //         this.amiPost;
      //         this.amiCreatePostRequested = false;
      //         this.amiPostContentRecordedForPost = false;
      //         this.amiPostContent = null;
      //       })
      //       .catch(error => {
      //         console.error(error);
      //         this.showAMIProcessing(
      //           `There was an error when attempting to create this post. Please try again.`
      //         );
      //       });
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to create this post. Please try again.`
      //     );
      //   });
    },

    amiSetPostSongForPost() {
      console.log("amiSetSongForPost called");
    },

    amiFilterByBillboard(lowerCaseUserSpeech) {
      console.log("amiFilterByBillboard");

      // let friendFound = false;

      // let userSpeechFriendName = lowerCaseUserSpeech;
      // console.log("userSpeechArtistName ", userSpeechFriendName);

      // callUserFriendshipsIndex(this.$store.state.currentUserId)
      //   .then(response => {
      //     let userFriendships = response;
      //     for (let i = 0; i < userFriendships.length; i++) {
      //       if (
      //         userFriendships[i].friend.name.toLowerCase() ==
      //         userSpeechFriendName
      //       ) {
      //         friendFound = true;
      //         this.postsFriend = userFriendships[i].friend;
      //         break;
      //       }
      //     }
      //     if (friendFound === true) {
      //       Axios.get(
      //         `/api/v1/users/${this.postsFriend.id}/billboard_posts`,
      //         {}
      //       )
      //         .then(response2 => {
      //           let billboardPosts = response2.data;
      //           this.$store.commit("setAmiPosts", billboardPosts);
      //           // this.successAudioPlay(); // generic success message
      //           this.showAMIProcessing(
      //             `${this.postsFriend.name}'s billboard posts isolated.`
      //           );
      //         })
      //         .catch(error => {
      //           console.error(error);
      //           this.showAMIProcessing(
      //             `There was an error when attempting to load your posts. Please try again.`
      //           );
      //         });
      //     } else {
      //       this.showAMIProcessing(
      //         `No friends found with that name. Please try again.`
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your friends. Please try again.`
      //     );
      //   });
    },

    amiRefreshPage() {
      window.location.reload(true);
    },

    amiShowMeMyBillboard() {
      console.log("amiShowMeMyBillboard() called");
      // Axios.get(
      //   `/api/v1/users/${this.$store.state.currentUserId}/billboard_posts`,
      //   {}
      // )
      //   .then(response2 => {
      //     let billboardPosts = response2.data;
      //     this.$store.commit("setAmiPosts", billboardPosts);
      //     // this.successAudioPlay(); // using this because it's a generic success message
      //     this.showAMIProcessing(`Your billboard posts isolated successfully.`);
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your posts. Please try again.`
      //     );
      //   });
    },

    amiGrabAlbumByArtist(lowerCaseUserSpeech, userSpeechArtistName, userSpeechAlbumName) {
      console.log("================ AMI GRAB ALBUM BY ARTIST FIRING");
    },

    amiAddAlbumToLibrary() {
      console.log("amiAddAlbumToLibrary() called");

      // Axios.get(
      //   `/api/v1/artist_profiles/${this.amiGrabbedalbum.primary_artist_id}/albums/${this.amiGrabbedAlbum.id}/album_songs`,
      //   {}
      // )
      //   .then(response => {
      //     let albumSongs = response.data;
      //     for (let i = 0; i < albumSongs.length; i++) {
      //       let albumId = albumSongs[i].id;
      //       Axios.post(
      //         `/api/v1/users/${this.$store.state.currentUserId}/library/library_song_references`,
      //         {
      //           song_id: albumId,
      //           library_id: this.$store.state.currentUserId
      //         }
      //       )
      //         .then(() => {
      //           console.log("song added");
      //         })
      //         .catch(error => {
      //           console.error(error);
      //           this.showAMIProcessing(
      //             `There was an error when attempting to save ${albumSongs[i].name} to your library. Please try again.`
      //           );
      //         });
      //     }
      //     this.$store.dispatch("getLibrary");
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your album songs. Please try again.`
      //     );
      //   });
      // this.showAMIProcessing(`Album songs successfully added to library.`);
      // this.amiInitializedForAmi = false;
    },

    amiAddAlbumToPlaylist(userSpeech) {
      console.log("amiAddAlbumToPlaylist called");

      // let userSpeechPlaylistName = userSpeech;
      // let playlistFound = false;

      // Axios.get(
      //   `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
      //   {}
      // )
      //   .then(response => {
      //     let playlists = response.data;
      //     for (let i = 0; i < playlists.length; i++) {
      //       let playlistName = playlists[i].name.toLowerCase();
      //       if (playlistName == userSpeechPlaylistName) {
      //         this.amiGrabbedPlaylist = playlists[i];
      //         playlistFound = true;
      //         break;
      //       }
      //     }
      //     if (playlistFound == true) {
      //       Axios.get(
      //         `/api/v1/artist_profiles/${this.amiGrabbedalbum.primary_artist_id}/albums/${this.amiGrabbedAlbum.id}/album_songs`,
      //         {}
      //       ).then(response => {
      //         let albumSongs = response.data;
      //         for (let i = 0; i < albumSongs.length; i++) {
      //           Axios.post(
      //             `/api/v1/users/${this.$store.state.currentUserId}/playlists/${this.amiGrabbedPlaylist.id}/playlist_song_references`,
      //             {
      //               song_id: albumSongs[i].id,
      //               playlist_id: this.amiGrabbedPlaylist.id
      //             }
      //           )
      //             .then(() => {
      //               console.log("album added");
      //             })
      //             .catch(error => {
      //               console.error(error);
      //               this.showAMIProcessing(
      //                 `There was an error when attempting to save ${albumSongs[i].name} to your playlist ${this.amiGrabbedPlaylist.name}. Please try again.`
      //               );
      //             });
      //         }
      //       });
      //     } else {
      //       this.showAMIProcessing(
      //         `No playlist found with that name. Please try again.`
      //       );
      //     }
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to load your playlists. Please try again.`
      //     );
      //   });
      // this.showAMIProcessing(`Album songs successfully added to playlist.`);
      // this.amiGrabbedPlaylist = null;
      // this.amiInitializedForAmi = false;
    },

    amiShareAlbumToMyBillboard() {
      console.log("amiShareAlbumToMyBillboard() called");

      // Axios.post(
      //   `/api/v1/users/${this.$store.state.currentUserId}/billboard_posts`,
      //   {
      //     post: {
      //       content: `${this.amiGrabbedAlbum.name} by ${this.amiGrabbedalbum.primary_artist.user.name}`,
      //       user_id: this.$store.state.currentUserId,
      //       billboard_id: this.$store.state.currentUserId
      //     }
      //   }
      // )
      //   .then(response => {
      //     let billboardPostId = response.data.id;
      //     Axios.post(`/api/v1/post_links`, {
      //       post_id: billboardPostId,
      //       link_type: "album",
      //       param_value_1: this.amiGrabbedalbum.primary_artist_id,
      //       param_value_2: this.amiGrabbedAlbum.id
      //     })
      //       .then(() => {
      //         // this.successAudioPlay(); // using this because it's a generic success message
      //         this.showAMIProcessing(
      //           `${this.amiGrabbedAlbum.name} successfully shared to your billboard.`
      //         );
      //         //this.$store.commit('setAmiPosts', billboardPosts); need to save this info to store
      //       })
      //       .catch(error => {
      //         console.error(error);
      //         this.showAMIProcessing(
      //           `There was an error when attempting to share the album. Please try again.`
      //         );
      //       });
      //   })
      //   .catch(error => {
      //     console.error(error);
      //     this.showAMIProcessing(
      //       `There was an error when attempting to share the album. Please try again.`
      //     );
      //   });
    },

    amiWhatGenreIsThis() {
      console.log("amiWhatGenreIsThis()=====================");
      // this.successAudioPlay(); // using this because it's a generic success message
      // this.showAMIProcessing(
      //   `This song's genre is: ${this.amiGrabbedSong.genre.name}`
      // );
      // this.amiGrabbedGenre = this.amiGrabbedSong.genre.name;
    },

    amiCreateMeAPlaylistLikeThis() {
      console.log("amiCreateMeAPlaylistLikeThis()================");
      // need to make logic
      // this.successAudioPlay(); // using this because it's a generic success message
      // this.showAMIProcessing(`Got it. Playlist created.`);
    },

    amiShowMeThisSongsArtistProfile() {
      console.log("amiShowMeThisSongsArtistProfile()================");
      // need to make logic
      // this.successAudioPlay(); // using this because it's a generic success message
      // this.showAMIProcessing(`Got it. Displaying profile now.`);
    },

    amiShowMeSongsLikeThis() {
      console.log("amiShowMeSongsLikeThis()================");
      // need to make logic
      // this.successAudioPlay(); // using this because it's a generic success message
      // this.showAMIProcessing(`Got it. Displaying songs now.`);
    },

    amiShowMeArtistsLikeThis() {
      console.log("amiShowMeArtistsLikeThis()================");
      // need to make logic
      // this.successAudioPlay(); // using this because it's a generic success message
      // this.showAMIProcessing(`Got it. Displaying artist now.`);
    },

    amiShowMeMyMusicProfile() {
      console.log("amiShowMeMyMusicProfile()================");
      // this.$store.state.allMusicProfileGroups;
      // this.$store.commit("openMusicProfileModal");
    },

    amiShowMeLogs() {
      let preAvailableCommands = [];

      this.availableCommandObjects.forEach((command) => {
        command.ami_command_speech_triggers.forEach((trigger) => {
          preAvailableCommands.push(trigger.speech);
        });
      });

      if (this.amiDiagnosticsInitialized == true) {
        if (!this.activeChainIsEmpty()) {
          console.log(`%cLAST LINK: ${this.lastLinkInActiveChainForAmi.ami_command_speech_triggers}`, "color: white; background-color: #6c67bf; padding: 2px 5px; border-radius: 2px");

          // console.log(`%cCURRENT LINK: ${this.lastCommandIdentified.speechTrigger}`, 'color: white; background-color: #da4e50; padding: 2px 5px; border-radius: 2px');

          let commandsConsoleArray = [];

          for (let c = 0; c < preAvailableCommands.length; c++) {
            commandsConsoleArray.push(` ${c + 1}) ${preAvailableCommands[c]}`);
          }

          console.log(`%cNEXT LINK COMMANDS: ${commandsConsoleArray}`, "color: white; background-color: #00e89c; padding: 2px 5px; border-radius: 2px");

          console.log(`%cACTIVE CHAIN--------`, "color: white; background-color: #4b2674; padding: 2px 5px; border-radius: 2px");
          console.table(this.activeChainForAmi);
        } else {
          console.log(`%cACTIVE CHAIN IS EMPY`, "color: white; background-color: #6c67bf; padding: 2px 5px; border-radius: 2px");
        }

        console.log(`%cSESSION COMMAND LOG--------`, "color: white; background-color: #ff5824; padding: 2px 5px; border-radius: 2px");
        console.table(this.commandLogForAmi);
      } else {
        this.showAMIProcessing("You are not authorized to view logs.");
      }
    },

    amistopRecognition() {
      if (!this.activeChainIsEmpty()) {
        // clear active chain if command is not prerequisite. if it is, we want those commands available when ami is initialized
        if (this.lastLinkInActiveChainForAmi.sequence_type !== "prerequisite") {
          this.activeChainForAmi = [];
          // NEED TO FIND A WAY TO SET THESE TO UTILITYANDSEQUENCE1
          this.availableCommandsForAmi = [];
          this.preAvailableCommandsForAmi = [];
        } else {
          return;
        }
      } else {
        return;
      }
    },

    amiClearActiveChain() {
      let utilityCommandArray = this.utilityCommandObjects;
      let sequence1CommandArray = this.sequence1CommandObjects;
      let utilityAndSequence1Commands = utilityCommandArray.concat(sequence1CommandArray);

      if (this.amiDiagnosticsInitialized == true) {
        if (!this.activeChainIsEmpty()) {
          this.activeChainForAmi = [];
          this.availableCommandObjects = utilityAndSequence1Commands;
          console.table(this.activeChainForAmi);
          console.table(this.commandLogForAmi);
        } else {
          this.showAMIProcessing("Chain is already clear.");
        }
      } else {
        this.showAMIProcessing("You are not authorized to execute this command.");
      }
    },

    amiNevermind() {
      this.activeChainForAmi = [];
    },

    amiWhatAreMyOptions() {
      console.log("-------------------------this.preAvailableCommands", this.preAvailableCommands);
      this.$store.commit("openAmiOptions", this.preAvailableCommands);
    },

    amiCloseOptions() {
      this.$store.commit("closeAmiOptions");
    },

    amiOpenSilentTestingModal() {
      if (this.amiDiagnosticsInitialized == true) {
        this.$store.commit("openAmiTestingModal");
      }
    },

    amiCloseSilentTestingModal() {
      if (this.amiDiagnosticsInitialized == true) {
        this.$store.commit("closeAmiTestingModal");
      }
    },

    amiRunTraceOnLastCommand() {
      // last command will always be "amy", so skips over that to last actionable command
      let lastCommand = this.commandLogForAmi[this.commandLogForAmi.length - 3];

      if (this.amiDiagnosticsInitialized == true && lastCommand !== null) {
        // STUBBED OUT FOR NOW
        console.log("STUBBED");
      } else {
        console.log("LAST COMMAND IS NULL. PLEASE SAY A COMMAND FIRST.");
      }
    },
    amiRepeatCommand() {
      console.log("=========================== REPEAT COMMAND FIRING");
      //get the first speechTrigger of parent Command and run it through commandLookup
      this.sendSpeechToAmiEngine(this.matchingCommand.parentSpeechTriggers[0]);
    },

    amiResendCommand() {
      console.log("amiResendCommand()===========================");

      if (Object.keys(this.lastPostRequestForAmi).length === 0) {
        this.showAMIProcessing("Command history empty. Aborting.");
        return;
      }

      //send last post request to engine
      Axios.post(`/api/v1/ami_engine`, this.lastPostRequestForAmi)
        .then((response) => {
          let dataFromEngine = response.data;
          this.setAmiStateAndDisplayProcessingPrompt(dataFromEngine);
        })
        .catch((error) => {
          console.error(error);
        });
    },

    amiPauseSong() {
      console.log("amiPauseSong() called");
      this.$store.dispatch("pauseSong", this.amiGrabbedSong);
    },

    amiStopSong() {
      console.log("amiStopSong() called");
      this.$store.dispatch("stopSong", this.amiGrabbedSong);
    },

    amiResumeSong() {
      console.log("amiResumeSong() called");
      this.$store.dispatch("resumeSong", this.amiGrabbedSong);
    },

    amiMuteVolume() {
      console.log("amiMuteVolume() called");
      this.playerVolumeBeforeMute = this.$store.state.player.currentVolume;
      this.$store.dispatch("setVolume", 0);
    },

    amiUnmuteVolume() {
      console.log("amiUnmuteVolume() called");
      this.$store.dispatch("setVolume", this.playerVolumeBeforeMute);
    },

    amiFullVolume() {
      console.log("amiFullVolume() called");
      this.$store.dispatch("setVolume", 1);
    },

    /////////////////////////////// AMI ENGINE UTILITY FUNCTIONS ///////////////////////////////
    displayDiagnosticsLog(type, message) {
      if (this.amiDiagnosticsInitialized == true) {
        console[`${type}`](message);
      } else {
        return;
      }
    },
    activeChainIsEmpty() {
      // doing this because reactive this.activeChainForAmi array length is always 0
      let activeChain = [];
      for (let i = 0; i < this.activeChainForAmi.length; i++) {
        activeChain.push(this.activeChainForAmi[i]);
      }
      if (activeChain.length == 0) {
        return true;
      } else {
        return false;
      }
    },

    commandIncludesSpeechTrigger(line, command, string) {
      if (!command) {
        console.log("no command; false");
        console.log("problem line:", line);
        return false;
      }
      let speechTrigger;
      for (let i = 0; i < command.ami_command_speech_triggers.length; i++) {
        speechTrigger = command.ami_command_speech_triggers[i];
        if (speechTrigger.speech == string) {
          return true;
        }
      }
    },
    findSpeechTriggerMatchForSpeech(userSpeech, availableCommands) {
      availableCommands.forEach((command) =>
        command.ami_command_speech_triggers.forEach((speechTrigger) => {
          // console.log(speechTrigger.speech, matchingStringForAllWordsInSpeech);
          if (speechTrigger.speech == userSpeech) {
            this.matchingCommand = command;
          }
        })
      );
    },
    showAMIProcessing(title) {
      this.$store.commit("showAMIProcessing");
      this.$toast(title, { type: "success" });
    },
    pollySynthesizeSpeech(matchingCommandPrompt) {
      let params = {};
      let modifiedCommand = null;
      // let suggestedCommand = null;

      // LOGIC FOR VARIABLES IN PROMPTS (DYNAMIC SPEECH)
      // if (this.firstTwoWordsForTryAgain !== null) {
      //   this.setChosenCommand(this.matchingCommand.suggestionPrompts); // this is not going to work (need to figure this out)
      //   this.matchingStringForFirstTwoWords == "grab song" ||
      //     this.matchingStringForFirstTwoWords == "play song" ||
      //     this.matchingStringForFirstTwoWords == "include song";
      //   switch (this.firstTwoWordsForTryAgain) {
      //     case "play song":
      //       suggestedCommand = "Play song <song name> by artist <artist name>";
      //       break;
      //     case "grab song":
      //       suggestedCommand = "Grab song <song name> by artist <artist name>";
      //       break;
      //     case "include song":
      //       suggestedCommand =
      //         "Include song <song name> by artist <artist name>";
      //       break;
      //     case "grab album":
      //       suggestedCommand =
      //         "Grab album <album name> by artist <artist name>";
      //       break;
      //   }
      //   modifiedCommand = this.chosenCommand.speech.replace(
      //     "${suggestion}",
      //     suggestedCommand
      //   );
      //   //AWS SPEECH SYNTHESIS
      //   params = {
      //     OutputFormat: "mp3",
      //     Text: `${modifiedCommand}`,
      //     TextType: "text",
      //     VoiceId: "Emma"
      //   };
      // } else {
      console.log("ELSE IN POLLY SYNTH SPEECH");

      // this.setChosenCommand(
      //   this.matchingCommand.ami_command_processing_prompts
      // );

      if (matchingCommandPrompt.includes("${variable}")) {
        console.log("============INCLUDES", matchingCommandPrompt.includes("${variable}"));
        modifiedCommand = matchingCommandPrompt.replace("${variable}", this.amiPostContent);
        //AWS SPEECH SYNTHESIS
        params = {
          OutputFormat: "mp3",
          Text: `${modifiedCommand}`,
          TextType: "text",
          VoiceId: "Emma",
        };
      } else {
        //AWS SPEECH SYNTHESIS
        params = {
          OutputFormat: "mp3",
          Text: `${matchingCommandPrompt}`,
          TextType: "text",
          VoiceId: "Emma",
        };
      }
      // }
      this.polly.synthesizeSpeech(params, (err, data) => {
        if (err) {
          // an error occurred
          console.log(err, err.stack);
        } else {
          let uInt8Array = new Uint8Array(data.AudioStream);
          let arrayBuffer = uInt8Array.buffer;
          let blob = new Blob([arrayBuffer]);
          let url = URL.createObjectURL(blob);
          this.buzzFXOrPollySpeech = new buzz.sound(url); //URL of audio file
          this.buzzFXOrPollySpeech.setVolume(100);
          this.buzzFXOrPollySpeech.play();
          //stop AMI from listening during this.buzzFXOrPollySpeech
          this.buzzFXOrPollySpeech
            .bind("playing", () => {
              console.log("AMI SPEAKING WHILE PLAYING");
              // this.amiSpeaking = true;
              this.webSpeech.stop();
            })
            .bind("ended", () => {
              console.log("AMI SPEAKING DONE");
              // this.amiSpeaking = false;
              this.webSpeech.start();
            });
        }
      });
    },
    // setChosenCommand(prompts) {
    //   let length = prompts.length;
    //   let randomNumber = Math.floor(Math.random() * length) + 0;
    //   this.chosenCommand = prompts[randomNumber];
    // },
    successAudioPlay() {
      // check this to make sure things aren't screwy with amiInit
      this.amiInitializedForAmi = this.matchingCommand.ami_init;
      let soundFile = require(`@/assets/sound_files/AMI_FX/${this.matchingCommandForAmi.fx}`);
      this.buzzFXOrPollySpeech = new buzz.sound(soundFile);
      let modifiedCommand = null;
      // sets this.chosenCommand
      this.setChosenCommand(this.matchingCommand.ami_command_processing_prompts);
      // buzz library binding to audio play - don't listen for commands while AMI is speaking
      this.buzzFXOrPollySpeech
        .bind("playing", () => {
          // this.amiSpeaking = true;
          this.webSpeech.stop();
        })
        .bind("ended", () => {
          // this.amiSpeaking = false;
          this.webSpeech.start();
        });
      if (this.commandIncludesSpeechTrigger("3432", this.matchingCommand, "mute yourself")) {
        if (this.amiSoundType == "speech") {
          this.pollySynthesizeSpeech(this.matchingCommand); // creates speech and prompt (this.chosenCommand)
        } else if (this.amiSoundType == "fx") {
          this.buzzFXOrPollySpeech.setVolume(50);
          this.buzzFXOrPollySpeech.play();
        }
      }
      if (!this.amiAudioMuted && this.amiSoundType == "speech") {
        this.pollySynthesizeSpeech(this.matchingCommand);
      } else if (!this.amiAudioMuted && this.amiSoundType == "fx") {
        // this.showAMIProcessing(this.chosenCommand.speech);
        this.buzzFXOrPollySpeech.setVolume(50);
        this.buzzFXOrPollySpeech.play();
      }
      if (this.chosenCommand.speech.includes("${variable}")) {
        modifiedCommand = this.chosenCommand.speech.replace("${variable}", this.amiPostContent);
        this.showAMIProcessing(modifiedCommand);
      } else {
        this.showAMIProcessing(this.chosenCommand.speech);
      }
    },
    /////////////////////////////// AMI ENGINE UTILITY FUNCTIONS END ///////////////////////////////
  },
};
</script>
