<template>
  <AtmoModal id="remove-contest-winners" title="Remove Contest Winners" customClass="remove-contest-winners" ref="removeWinnersRef" v-model="$store.state.removeContestWinnersModalOpen">
    <header class="atmo-modal__header">
      <span class="atmo-modal__header-side">
        <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png" @click="$store.commit('toggleRemoveContestWinnersModal')" />
      </span>
      <div class="atmo-page-header-container">
        <h2 class="atmo-page-header">Remove Winners</h2>
      </div>
      <span class="atmo-modal__header-side" />
    </header>
    <div class="remove-contest-winners__body">
      <!-- <button @click="updateWinner">Update Winner</button> -->
      <perfect-scrollbar class="body__winners-container">
        <div class="winners-container__winner-wrap" v-for="(winner, index) in prizeWinners" :key="index" @click="removeWinner(winner)">
          <div class="winner-wrap__container">
            <div class="winner-wrap__image-wrap">
              <img :src="winner.contest_submission.user.profile_image.image.url" />
            </div>
            <div class="winner-wrap__name">{{ winner.contest_submission.user.name }}</div>
            <div class="winner-wrap__remove">Remove</div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </AtmoModal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { callContestPrizeWinnersDelete } from "@/helpers/axiosCalls";

export default {
  props: {
    value: Boolean, // v-model prop
    prizeWinners: Array,
  },
  computed: {
    ...mapState("contests", ["contestPrizeWinners"]),
  },
  data() {
    return {
      prizes: [],
    };
  },
  methods: {
    ...mapActions("contests", ["fetchContestPrizeWinners"]),
    removeWinner(winner) {
      callContestPrizeWinnersDelete(winner.contest_submission.contest_id, winner.id).then(async (response) => {
        //FETCH CONTEST PRIZE WINNERS AGAIN
        console.log(response);
        await this.fetchContestPrizeWinners(winner.contest_submission.contest_id);
        // this.$store.commit('toggleRemoveContestWinnersModal');
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheets/_variables.scss";

#remove-contest-winners {
  .atmo-modal-content {
    border-radius: 5px;
    border: none;
    // width: 100vw;
    // height: 97vh;
  }

  .ps {
    max-height: 30vh;
  }
}

.remove-contest-winners {
  .modal-lg.modal-dialog {
    // max-width: 100vw;
    // margin: 1rem;
  }

  .atmo-modal__header {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }

  .atmo-modal__header-side {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;

    img {
      height: 1.5rem;
    }
  }

  .atmo-page-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__body {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  .body__winners-container {
    margin-bottom: 2rem;
    width: 60%;
    min-height: 5rem;
  }

  .winners-container__winner-wrap {
    font-size: 1rem;
    cursor: pointer;
    padding: 0.8rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    // background-color: rgba(255, 255, 255, .1);

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);

      .winner-wrap__remove {
        display: flex;
      }
    }
  }

  .winner-wrap__container {
    display: flex;
    gap: 1rem;
    // padding: 1rem;
  }

  .winner-wrap__image-wrap {
    display: flex;
    align-items: center;

    img {
      height: 2rem;
      width: 2rem;
      border-radius: 100%;
    }
  }

  .winner-wrap__name {
    min-width: 8rem;
    display: flex;
    align-items: center;
  }

  .winner-wrap__remove {
    display: flex;
    align-items: center;
    margin-left: auto;
    display: none;
    font-size: 0.8rem;
  }
}
</style>
