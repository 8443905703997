<template>
  <div class="label-dashboard" data-cy="label-dashboard">
    <atmo-back-link></atmo-back-link>
    <perfect-scrollbar class="label-dashboard__grid-container">
      <!-- Left Column -->
      <div class="label-dashboard__summary-container">
        <!-- Summary Metrics -->
        <div class="label-dashboard__section-header">Summary</div>
        <div class="label-dashboard__metrics-grid">
          <div class="label-dashboard__metric-box">
            <p class="metric-box__title">Total Fans</p>
            <p class="metric-box__value">{{ summary?.total_fans?.toLocaleString?.() || "—" }}</p>
            <p class="text-sm">
              {{ monthlyPercentChanges?.fans != null ? `${monthlyPercentChanges.fans > 0 ? "+" : ""}${monthlyPercentChanges.fans}% this month` : "—" }}
            </p>
          </div>

          <div class="label-dashboard__metric-box">
            <p class="metric-box__title">Total Plays</p>
            <p class="metric-box__value">{{ summary?.total_plays?.toLocaleString?.() || "—" }}</p>
            <p class="text-sm">
              {{ monthlyPercentChanges?.plays != null ? `${monthlyPercentChanges.plays > 0 ? "+" : ""}${monthlyPercentChanges.plays}% this month` : "—" }}
            </p>
          </div>

          <div class="label-dashboard__metric-box">
            <p class="metric-box__title">Top Artist</p>
            <p class="metric-box__value">{{ summary?.top_artist || "—" }}</p>
            <p class="text-sm">{{ summary?.top_artist_plays ? `${summary.top_artist_plays} plays` : "—" }}</p>
          </div>

          <div class="label-dashboard__metric-box">
            <p class="metric-box__title">Merch Revenue</p>
            <p class="metric-box__value">${{ summary?.merch_revenue?.toLocaleString?.() || "—" }}</p>
            <p class="text-sm">
              {{ monthlyPercentChanges?.revenue != null ? `${monthlyPercentChanges.revenue > 0 ? "+" : ""}${monthlyPercentChanges.revenue}% this month` : "—" }}
            </p>
          </div>
        </div>

        <!-- Fan Growth -->
        <div class="label-dashboard__section-header">Fan Growth</div>
        <div class="label-dashboard__card">
          <div class="label-dashboard__card-title">Last 30 Days</div>
          <apexchart width="100%" height="200" type="line" :options="fanGrowthChartOptions" :series="fanGrowthSeries" />
        </div>
      </div>

      <!-- Right Column -->
      <div class="label-dashboard__performance-container">
        <!-- Artist Performance -->
        <div class="label-dashboard__section-header">Artist Performance</div>
        <div class="label-dashboard__card-scrollable">
          <perfect-scrollbar class="artists-container">
            <div class="label-dashboard__row label-dashboard__row--header">
              <div class="row__col">Artist</div>
              <div class="row__col">Fans</div>
              <div class="row__col">Plays</div>
              <div class="row__col">Merch Revenue</div>
            </div>
            <template v-if="artists.length">
              <div v-for="(artist, index) in artists" :key="artist.id" :class="index % 2 === 0 ? 'label-dashboard__row --dark' : 'label-dashboard__row --light'">
                <div class="row__col">
                  <router-link class="artist-link" v-if="artist" :to="{ name: 'artist_profiles.show', params: { artistProfileId: artist.id } }">{{ artist.name }}</router-link>
                </div>
                <div class="row__col">{{ artist.fans }} fans</div>
                <div class="row__col">{{ artist.plays }} plays</div>
                <div class="row__col">${{ artist.revenue.toLocaleString() }}</div>
              </div>
            </template>
            <p v-else>No artist data yet.</p>
          </perfect-scrollbar>
        </div>

        <!-- Breakout Songs -->
        <div class="label-dashboard__section-header">Breakout Songs</div>
        <div class="label-dashboard__card">
          <ul v-if="breakoutSongs.length" class="label-dashboard__list">
            <li v-for="song in breakoutSongs" :key="song.id">
              <span>
                <router-link v-if="song" :to="{ name: 'songs.show', params: { songId: song.id } }">{{ song.title }}</router-link> by
                <router-link v-if="song.artist_id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: song.artist_id } }">{{ song.artist }}</router-link> —
                {{ song.plays?.toLocaleString?.() || 0 }} plays this week
              </span>
            </li>
          </ul>
          <p v-else class="text-sm">No breakout songs yet.</p>
        </div>

        <!-- Atmo Impact -->
        <div class="label-dashboard__section-header">Atmo Platform Impact</div>
        <div class="label-dashboard__card">
          <ul class="label-dashboard__list">
            <li>
              Total Fans Acquired via Atmo: <strong>{{ atmoImpact?.fans_acquired?.toLocaleString?.() || "—" }}</strong>
            </li>
            <li>
              Streams Driven by Discovery: <strong>{{ atmoImpact?.discovery_streams?.toLocaleString?.() || "—" }}</strong>
            </li>
            <li>
              Revenue via Atmo Features: <strong>${{ atmoImpact?.revenue_via_atmo?.toLocaleString?.() || "—" }}</strong>
            </li>
          </ul>
        </div>

        <!-- Nav Links -->
        <div class="label-dashboard__section-header">Explore More</div>
        <div class="label-dashboard__links">
          <router-link :to="{ name: 'label_dashboards.fans', params: { labelProfileId } }">View Fan Insights</router-link>
          <router-link :to="{ name: 'label_dashboards.plays', params: { labelProfileId } }">View Streaming Trends</router-link>
          <router-link :to="{ name: 'label_dashboards.revenue', params: { labelProfileId } }">View Revenue Breakdown</router-link>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callLabelDashboardsIndex } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoBackLink,
  },
  created() {
    this.$store.commit("setHudHeader", "Label Dashboard");
    this.fetchLabelDashboard();
  },
  data() {
    return {
      labelProfileId: this.$route.params.labelProfileId,
      artists: [],
      breakoutSongs: [],
      summary: {},
      atmoImpact: {},
      monthlyPercentChanges: {},
      fanGrowthSeries: [
        {
          name: "Fans",
          data: [],
        },
      ],
      fanGrowthChartOptions: {
        chart: {
          type: "line",
          height: 200,
          toolbar: { show: false },
        },
        stroke: {
          curve: "smooth",
          width: 4,
        },
        xaxis: {
          categories: Array.from({ length: 30 }, (_, i) => `Day ${i + 1}`),
          labels: { show: false },
        },
        yaxis: {
          labels: { style: { colors: "#ddd" } },
        },
        tooltip: {
          theme: "dark",
        },
        colors: ["#44AEDC"],
      },
    };
  },

  methods: {
    async fetchLabelDashboard() {
      try {
        const res = await callLabelDashboardsIndex(this.$route.params.labelProfileId);

        // Assign to Vue res
        this.artists = res.artist_performance;
        this.breakoutSongs = res.breakout_songs;
        this.fanGrowthSeries[0].data = res.growth_chart.map((point) => point.fans);
        this.fanGrowthChartOptions.xaxis.categories = res.growth_chart.map((point) => point.day);

        this.summary = res.summary;
        this.atmoImpact = res.atmo_impact;
        this.monthlyPercentChanges = res.monthly_percent_changes;
      } catch (err) {
        console.error("Failed to fetch label dashboard:", err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.label-dashboard {
  margin: 0 75px;
  height: 74vh;
  display: flex;
  flex-direction: column;

  .label-dashboard__grid-container {
    flex: 1;
    overflow: hidden;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    //HOW TO GET PS SCROLL TO FILL UP ALL SPACE
    height: 100%;
    max-height: 100%;
  }

  .label-dashboard__section-header {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
  }

  .label-dashboard__summary-container,
  .label-dashboard__performance-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .label-dashboard__metrics-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .label-dashboard__metric-box {
    background: rgba(161, 125, 187, 0.6);
    border-radius: 10px;
    padding: 1rem;

    .metric-box__title {
      text-transform: uppercase;
      font-size: 0.8rem;
      color: $atmo-gray--light--faded;
      margin-bottom: 0.5rem;
    }

    .metric-box__value {
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }

  .label-dashboard__card {
    background: rgba(161, 125, 187, 0.6);
    padding: 1rem;
    border-radius: 10px;

    .label-dashboard__card-title {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
    }

    .label-dashboard__chart-placeholder {
      height: 12rem;
      background-color: #4f3d63;
      border-radius: 10px;
    }
  }

  .label-dashboard__row--header {
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  .artists-container {
    height: 10rem;
  }

  .label-dashboard__row {
    display: flex;
    font-weight: 500;
    padding: 0.8rem 1rem;
    border-radius: 25px 0 0 25px;

    .row__col {
      flex: 1;
    }

    &.--light {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    }
  }

  .label-dashboard__list {
    padding-left: 1rem;
    list-style: disc;
  }

  .label-dashboard__links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: white;
      text-decoration: underline;
      font-size: 0.875rem;

      &:hover {
        color: $atmo-purple--light;
      }
    }
  }

  .stat-count {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}
</style>
