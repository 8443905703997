<template>
  <div class="directory-ad-page" v-if="adData">
    <router-link class="back-container" :to="{ name: 'directory_profiles.index' }">
      <div class="back-container__icon-container">
        <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
      </div>
      <div class="back-container__text-container">Back To Directory</div>
    </router-link>

    <div class="directory-ad-grid">
      <div
        class="directory-ad-grid__image-container"
        :style="{
          'background-image': adData.image?.url
            ? `linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%), url(${adData.image?.url})`
            : 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)',
        }"
      ></div>

      <div class="directory-ad-grid__details-container">
        <div class="details-container__ad-info-container">
          <div class="ad-info-container__ad-detail-wrap">
            <div class="text-wrap copy-container">{{ adData.copy }}</div>
          </div>
          <div class="ad-info-container__ad-detail-wrap">
            <div class="text-wrap message-user" @click="messageUser(ad)">Message User</div>
          </div>
        </div>
      </div>

      <!-- Ads Section -->
      <div class="directory-ad-grid__ads-container">
        <atmo-directory-ad v-for="index in 4" :key="index" />
      </div>
    </div>
  </div>

  <!-- Show Loading or Error if ad is Missing -->
  <div v-else class="directory-ad-page__error">
    <p>Ad not found or loading failed.</p>
  </div>
</template>

<script>
import AtmoDirectoryAd from "@/components/atmo-directory/atmo-directory-ad";
import { callDirectoryAdsShow, callChatRoomsIndex, callChatRoomsCreate } from "@/helpers/axiosCalls";
import { connectToVideoCallsChannel, sendSignalingMessage, setCallback as setVideoCallback } from "@/cable-channels/videoCalls";

export default {
  data() {
    return {
      adData: null,
    };
  },
  components: {
    AtmoDirectoryAd,
  },
  methods: {
    async fetchAdDetails(adId) {
      try {
        const adData = await callDirectoryAdsShow(this.$store.state.currentUserId, adId);
        this.adData = adData;
      } catch (error) {
        console.error("Error fetching ad details:", error);
        this.adData = null;
      }
    },
    async messageUser(ad) {
      try {
        let chatRoom = await callChatRoomsIndex({
          type: "directory_ad",
          user_id: this.$store.state.currentUserId,
          friend_id: this.adData.user_id,
        });

        console.log("CHAT ROOM FOR DIRECTORY ad", chatRoom);
        //IF WE DON'T FIND IT, create it, open modal, and select room
        //IF WE DO FIND IT, open modal and select room
        //POLYMORPHISM?

        if (Object.keys(chatRoom).length === 0) {
          let newChatRoom = await callChatRoomsCreate({
            name: `Ad: ${this.$store.state.currentUser.name} & ${this.adData.user.name}`,
            room_type: "directory_ad",
            roomable_type: "DirectoryAd",
            creator_id: this.$store.state.currentUserId,
            user_ids: [this.$store.state.currentUserId, this.adData.user_id],
          });

          this.$store.commit("chatRooms/setCurrentChatRoom", newChatRoom);
        } else {
          this.$store.commit("chatRooms/setCurrentChatRoom", chatRoom);
        }
      } catch (error) {
        this.$toast("Error messaging user", { type: "error" });
        console.error("Error in messageUser:", error);
      }

      this.$store.commit("chatRooms/toggleMaxChatOpen");

      if (this.$store.state.chatRooms.currentChatRoom) {
        connectToVideoCallsChannel(this.$store.state.chatRooms.currentChatRoom.id);
      }
    },
  },
  mounted() {
    const adId = this.$route.params.adId;
    this.fetchAdDetails(adId);
    this.$store.commit("setHudHeader", "Directory Ad");
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.directory-ad-page {
  margin: 0 75px;
}

.back-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  max-width: 10rem;
  margin-bottom: 1rem;
}

.back-container__icon-container {
  margin-right: 0.5rem;
}

.icon-container__back-icon {
  height: 1rem;
}

.directory-ad-grid {
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  gap: 2rem;
}

.directory-ad-grid__image-container {
  width: 100%;
  max-height: 30rem;
  min-width: 20rem;
  background-size: cover;
  position: relative;
  padding: 1rem;
}

.details-container__links-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.details-container__ad-info-container {
  .text-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .copy-container {
    font-size: 1.2rem;
  }

  .message-user {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-right: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  .ad-info-container__ad-detail-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: 500;
  }
}

.directory-ad-grid__ads-container {
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .directory-ad-container__directory-ad-wrap + .directory-ad-container__directory-ad-wrap {
    margin-top: 1rem;
  }
}

.directory-ad-page__error {
  text-align: center;
  font-size: 1.2rem;
  color: red;
  margin-top: 2rem;
}
</style>
