<template>
  <div class="ops-page" v-if="campaign">
    <div>
      <div class="ops-page__navigate-back">
        <router-link v-if="campaign?.user?.id" :to="{ name: 'users.ops_pages', params: { userId: campaign?.user?.id } }">
          <img src="@/assets/images/icons/back.png" class="ops-page__go-back-image" />
          <p>Back to campaigns</p>
        </router-link>
      </div>
    </div>
    <div class="ops-page-content-container">
      <!-- campaigns image and actions -->
      <div>
        <div>
          <div class="ops-page__campaign-image">
            <img
              v-if="campaign?.images?.length"
              :src="getFeaturedImage(campaign.images, 'full')"
              alt="Campaign image"
              style="width: 100%"
              @click="
                openUserMediaModal({
                  type: 'Image',
                  caption: campaign?.images?.[0]?.name || 'No Image',
                  url: campaign?.images?.[0]?.image?.url || '',
                  id: campaign?.images?.[0]?.id || null,
                })
              "
            />
          </div>
        </div>
        <div>
          <div>
            <h3 class="ops-page__campaign-name">
              {{ campaign?.campaign_name || "Untitled Campaign" }}
            </h3>
          </div>
          <div class="ops-page-dates">
            <div class="ops-page-dates__label-wrap">Start Date</div>
            <div class="ops-page-dates__badge-wrap">
              <atmo-badge :label="getDate(campaign?.start_date) || 'N/A'" />
            </div>
            <div class="ops-page-dates__label-wrap">End Date</div>
            <div class="ops-page-dates__badge-wrap">
              <atmo-badge :label="getDate(campaign?.end_date) || 'N/A'" />
            </div>
          </div>
        </div>
        <div class="ops-page__edit-delete-button">
          <div class="edit-delete-button__edit-button-container">
            <span class="ops-page__campaign-actions">
              <router-link v-if="campaign?.id" :to="{ name: 'ops_pages.edit', params: { opsPageId: campaign?.id } }">
                <button class="ops-page__edit-campaign">Edit</button>
              </router-link>
            </span>
          </div>
          <div class="edit-delete-button__delete-button-container">
            <div class="ops-page__delete-campaign" v-if="campaign?.id" @click="deletePage(campaign?.id)">
              <img src="@/assets/images/icons/delete-red.png" data-cy="delete-campaign" />
            </div>
          </div>
        </div>
      </div>
      <!-- video, goal and campaign description -->
      <div class="ops-page__about-container">
        <div>
          <div>
            <div
              class="ops-page__watch-video-container"
              :style="{
                'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + (featuredVideo?.preview_image?.url || '') + ')',
              }"
              v-if="featuredVideo?.video?.url"
              @click="
                openUserMediaModal({
                  type: 'Video',
                  caption: featuredVideo?.name || 'No Video',
                  url: featuredVideo?.video?.url || '',
                  id: featuredVideo?.id || null,
                })
              "
            >
              <img class="ops-page__video-icon" src="@/assets/images/icons/ops/watch_video.png" />
              <p>Watch video</p>
            </div>
          </div>
        </div>
        <!-- donation goal -->
        <div class="row ops-page__donation-progress">
          <div class="donation-progress__text-container">
            <div>Raised: US ${{ campaign?.raised || "0" }}</div>
            <div class="ops-page__donation-goal-text">
              <img class="ops-page__goal-icon" src="@/assets/images/icons/goal.png" alt="Campaign goal" />
              <p class="d-inline">Goal: US ${{ campaign?.goal || "0" }}</p>
            </div>
          </div>
          <div class="ops-page__donation-progress-meter">
            <atmo-progress-bar :percent="calculatePercent(campaign)" />
          </div>
        </div>
        <div>
          <h3>About:</h3>
        </div>
        <div>
          <p>{{ campaign?.campaign_description || "No description available." }}</p>
        </div>
      </div>
      <!-- donation details -->
      <div class="ops-page__donations-container">
        <h3>Donation amounts:</h3>
        <perfect-scrollbar v-if="campaign?.ops_page_donations?.length">
          <atmo-ops-donation
            v-for="donation in campaign?.ops_page_donations"
            :key="donation.id"
            :campaign="campaign"
            :description="donation?.description || 'No Description'"
            :amount="donation?.amount || 0"
          />
        </perfect-scrollbar>
        <p v-else>No donations available.</p>
      </div>
    </div>
  </div>
  <div v-else>Loading campaign...</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AtmoProgressBar from "@/components/atmo-progress-bar.vue";
import AtmoOpsDonation from "@/components/atmo-ops-donation.vue";
import AtmoBadge from "@/components/atmo-badge";
import { utilsGetDate, getFeaturedImage, getFeaturedVideo } from "@/helpers/utilityFunctions";

export default {
  components: {
    AtmoBadge,
    AtmoProgressBar,
    AtmoOpsDonation,
  },
  computed: {
    ...mapGetters({
      campaign: "ops/currentPage",
    }),
    featuredVideo() {
      return this.campaign?.videos?.length ? this.getFeaturedVideo(this.campaign.videos) : { preview_image: { url: "" }, name: "No Video", video: { url: "" }, id: null };
    },
  },
  created() {
    if (this.$route.params.opsPageId) {
      this.setPage(this.$route.params.opsPageId);
    }
    this.$store.commit("setHudHeader", "Atmo Campaigns");
  },
  methods: {
    ...mapActions("ops", ["setPage", "deletePage"]),
    openUserMediaModal(userMediaObj) {
      if (!userMediaObj.url) return;
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", userMediaObj);
      this.$store.commit("videoPlayer/setUserMediaModalObj", userMediaObj);
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
    getDate(date) {
      return date ? utilsGetDate(date) : "N/A";
    },
    getFeaturedImage(images, type) {
      return images?.length ? getFeaturedImage(images, type) : "@/assets/images/default-thumbnail.png";
    },
    getFeaturedVideo(videos) {
      return getFeaturedVideo(videos);
    },
    calculatePercent(campaign) {
      // return 75;
      return campaign?.goal && campaign?.raised ? (campaign.raised / campaign.goal) * 100 : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.ops-page {
  margin: 0 auto;

  &__navigate-back {
    margin-bottom: 12px;

    img {
      width: 24px;
      height: 24px;
      vertical-align: middle;
    }

    p {
      display: inline;
      font-weight: bold;
      padding-left: 6px;
    }
  }

  .ops-page-content-container {
    display: flex;
    gap: 1rem;
  }

  &__campaign-image {
    margin-top: 4px;
    cursor: pointer;

    img {
      width: 100%;
      border-radius: 5px;
      // box-shadow: 2px 2px 2px 2px rgba(black, 0.2);
    }
  }

  &__campaign-name {
    font-weight: bold;
    padding: 24px 0px;
  }

  .ops-page-dates {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .ops-page-dates__label-wrap {
    margin-bottom: 0.5rem;
  }

  // .ops-page-dates__badge-wrap {
  // }

  .atmo-badge {
    border-radius: 5px;
    font-weight: 500;
    background-color: $atmo-purple--medium-dark--overlay-faded;
    padding: 7px;
    margin-bottom: 0.5rem;
  }

  &__delete-campaign {
    background: white;
    border: 1px solid white;
    border-radius: 5px;
    margin-left: 0.1rem;
    min-height: 2.6rem;
    min-width: 2.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
      height: 1.2rem;
    }
  }

  .ops-page__edit-delete-button {
    display: flex;
    gap: 0.3rem;
  }

  &__edit-campaign {
    background-color: transparent;
    color: white;
    cursor: pointer;
    text-align: center;
    border: 1px solid white;
    border-radius: 5px;
    padding: 0.74rem 3rem 0.74rem 3rem;
  }

  &__watch-video-container {
    min-height: 100px;
    line-height: 100px;
    background-position: center center;
    background-size: cover;
    border-radius: 0;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;

    div {
      background-color: $atmo-purple--medium-dark--overlay-faded;
    }

    img {
      max-width: 50px;
      vertical-align: middle;
      padding-bottom: 8px; // to align text and icon
      border-radius: 5px;
    }

    p {
      text-transform: uppercase;
      display: inline;
      font-weight: 450;
      padding-left: 6px;
      font-size: $atmo-subtitle-size;
    }
  }

  &__about-container {
    h3 {
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 24px;
      margin-bottom: 12px;
    }

    p {
      line-height: 1.5;
    }
  }

  &__donation-progress {
    margin-top: 24px;
    font-weight: bold;
    text-transform: uppercase;

    .donation-progress__text-container {
      display: flex;
      justify-content: space-between;
    }

    &-meter {
      margin-top: 12px;
    }
  }

  &__donation-goal-text {
    display: flex;
    gap: 0.5rem;
  }

  &__donation-goal-text img {
    height: 18px;
    width: 18px;
    vertical-align: middle;
  }

  &__donations-container {
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    padding: 0rem 1rem 1rem 1rem;
    max-width: 20vw;

    h3 {
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 24px;
      margin-bottom: 12px;
    }
  }
}
</style>
