<template>
  <AtmoModal v-model="$store.state.liveStreamModalOpen" title="Live Stream" id="live-stream-modal" class="live-stream-modal" @opened="startStreaming" customClass="live-stream-modal">
    <ModalHeader heading="Live Stream" marginBottom="2rem" />
    <div class="modal-content">
      <div class="video-chat-container">
        <!-- Video Streaming Section -->
        <div class="video-container">
          <video ref="liveVideo" autoplay playsinline controls></video>
        </div>

        <!-- Chat Section -->
        <div class="chat-container">
          <h3>Live Chat</h3>
          <perfect-scrollbar class="chat-messages">
            <div
              v-for="message in chatMessages"
              :key="message.id"
              :class="['chat-message', message.user.id === $store.state.currentUserId ? 'chat-message--current-user' : 'chat-message--other-user']"
            >
              <!-- User Avatar -->
              <img class="chat-message__avatar" :src="getProfileImage(message.user)" alt="User Avatar" />

              <!-- Message Bubble -->
              <div class="chat-message__bubble">
                <div class="chat-message__header">
                  <span class="chat-message__username">{{ message.user.name }}</span>
                  <span class="chat-message__timestamp">{{ formatTimestamp(message.created_at) }}</span>
                </div>
                <p class="chat-message__text">{{ message.content }}</p>
              </div>
            </div>
          </perfect-scrollbar>

          <!-- Chat Input -->
          <div class="chat-input">
            <input v-model="newMessage" placeholder="Type a message..." @keyup.enter="sendChatMessage" />
            <button @click="sendChatMessage">Send</button>
          </div>
        </div>
      </div>
      <!-- Close Modal Button -->
      <button v-if="isStreaming" class="close-btn" @click="closeLiveStreamModal">🛑 Stop Streaming</button>
    </div>
  </AtmoModal>
</template>

<script>
import { callLiveStreamsCreate, callLiveStreamsEndStream, callLiveStreamsStream, callCommentsIndex, callCommentsCreate } from "@/helpers/axiosCalls";
import { connectToLiveStreamChatChannel, sendMessage, setCallback } from "@/cable-channels/liveStreamChat";
import ModalHeader from "@/components/modal-header";

export default {
  components: {
    ModalHeader,
  },
  data() {
    return {
      isStreaming: false,
      mediaStream: null,
      streamKey: "",
      chatMessages: [],
      newMessage: "",
      liveStreamId: null,
    };
  },
  async created() {
    await this.fetchChatMessages();
  },
  watch: {
    liveStreamId(newVal) {
      if (newVal) {
        this.setupLiveChat();
      }
    },
  },
  methods: {
    async startStreaming() {
      try {
        this.mediaStream = await navigator.mediaDevices.getUserMedia({
          video: { width: 1280, height: 720 },
          audio: true,
        });

        this.$refs.liveVideo.srcObject = this.mediaStream;

        const payload = {};

        if (this.$store.state.liveStreamModalEventId) {
          payload.atmo_event_id = this.$store.state.liveStreamModalEventId;
        }

        const response = await callLiveStreamsCreate(this.$store.state.currentUserId, payload);

        this.streamKey = response.rtmp_url;
        this.liveStreamId = response.id;
        this.isStreaming = true;

        // 🔥 Send WebRTC Stream to RTMP with FFmpeg
        this.sendWebRTCToRTMP(this.mediaStream, this.streamKey);

        if (this.$store.state.liveStreamModalEventId) {
          this.$store.dispatch("notifications/createNotification", {
            userId: "ticket_holders", // This tells the backend to send to all ticket_holders
            payload: {
              sender_id: this.$store.state.currentUserId,
              receiver_id: "ticket_holders", // This ensures the backend processes it as a group notification
              notification_type_name: "Event Live Stream Start",
              status: "unread",
              notifiable_type: "AtmoEvent",
              notifiable_id: this.$store.state.liveStreamModalEventId,
            },
          });
        } else {
          this.$store.dispatch("userActivities/createUserActivity", {
            user_activity_type_name: `Live Stream Start`,
            user_id: this.$store.state.currentUserId,
            activityable_type: "User",
            activityable_id: this.$store.state.currentUserId,
          });

          this.$store.dispatch("notifications/createNotification", {
            userId: "friends", // This tells the backend to send to all friends
            payload: {
              sender_id: this.$store.state.currentUserId,
              receiver_id: "friends", // This ensures the backend processes it as a group notification
              notification_type_name: "Live Stream Start",
              status: "unread",
              notifiable_type: "User",
              notifiable_id: this.$store.state.currentUserId,
            },
          });
        }

        await this.fetchChatMessages();
        this.setupLiveChat();
      } catch (error) {
        console.error("❌ Error starting stream:", error);
      }
    },
    setupLiveChat() {
      console.log("✅ Connecting to live stream chat...", this.liveStreamId);
      connectToLiveStreamChatChannel(this.liveStreamId);

      setCallback(() => {
        console.log("📩 Chat update received via WebSocket!");
        this.fetchChatMessages(); // 🔄 Fetch messages when a new one arrives
      });
    },
    async sendWebRTCToRTMP(mediaStream, streamKey) {
      const mediaRecorder = new MediaRecorder(mediaStream, {
        mimeType: "video/webm; codecs=vp9",
      });

      mediaRecorder.ondataavailable = async (event) => {
        if (event.data.size > 0) {
          const formData = new FormData();
          formData.append("videoChunk", event.data, "chunk.webm");
          formData.append("stream_key", streamKey);

          try {
            await callLiveStreamsStream(formData);
            console.log("📡 Sent chunk to server");
          } catch (error) {
            console.error("❌ Error sending chunk:", error);
          }
        }
      };

      mediaRecorder.start(2000); // 🔥 Capture and send every 2 seconds
      this.mediaRecorder = mediaRecorder; // Store reference for stopping later
    },
    async stopStreaming() {
      if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
        console.log("🛑 Stopping recording...");
        this.mediaRecorder.stop();
      }

      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach((track) => track.stop()); // Stop camera/mic
      }

      if (this.streamKey) {
        const key = this.streamKey.match(/[^/]+$/)[0];
        await callLiveStreamsEndStream({ stream_key: key });
      }

      this.isStreaming = false;
    },
    async fetchChatMessages() {
      if (!this.liveStreamId) return;
      try {
        const response = await callCommentsIndex({
          commentable_id: this.liveStreamId,
          commentable_type: "LiveStream",
        });
        console.log("COMMENTS SJ", response);
        this.chatMessages = response;
      } catch (error) {
        console.error("Error fetching chat messages:", error);
      }
    },

    async sendChatMessage() {
      if (!this.newMessage.trim() || !this.liveStreamId) return;

      await callCommentsCreate({
        content: this.newMessage,
        user_id: this.$store.state.currentUserId,
        commentable_id: this.liveStreamId,
        commentable_type: "LiveStream",
      }).then(() => {
        sendMessage(this.newMessage, this.liveStreamId, this.$store.state.currentUserId);
        setCallback(() => {
          console.log("📩 Chat update received via WebSocket!");
          this.fetchChatMessages(); // 🔄 Fetch messages when a new one arrives
        });
      });

      this.newMessage = "";
    },
    getProfileImage(user) {
      return user.images && user.images.length > 0 ? user.images[0].image.url : require("@/assets/images/default_pic.jpg");
    },

    formatTimestamp(timestamp) {
      const date = new Date(timestamp);
      return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
    },

    closeLiveStreamModal() {
      this.$store.commit("hideLiveStreamModal");
      if (this.isStreaming) this.stopStreaming();
      if (this.$refs.liveVideo) this.$refs.liveVideo.src = "";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

#live-stream-modal {
  .atmo-modal-content {
    border-radius: 5px;
    border: none;
    width: 100vw;
    height: 97vh;
  }

  .modal-lg.modal-dialog {
    width: 98vw;
    margin: 1rem;
    max-width: unset;
  }

  .video-chat-container {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }

  .video-container {
    flex: 3;
  }

  .video-container video {
    width: 100%;
    border-radius: 8px;
  }

  .chat-container {
    max-width: 30%;
    background-color: $atmo-purple--medium-dark--faded;
    padding: 15px;
    border-radius: 8px;
    height: 80vh;
    display: flex;
    flex-direction: column;
  }

  .chat-container h3 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .chat-messages {
    flex: 1; /* Takes up remaining space */
    overflow-y: auto; /* Enables scrolling if content overflows */
    padding: 10px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 65vh;
  }
  /* Chat Message */
  .chat-message {
    display: flex;
    align-items: center;
    max-width: 80%;
    gap: 10px;
  }

  /* Positioning */
  .chat-message--current-user {
    align-self: flex-end;
    flex-direction: row-reverse;
  }

  .chat-message--other-user {
    align-self: flex-start;
  }

  /* User Avatar */
  .chat-message__avatar {
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    object-fit: cover;
  }

  /* Message Bubble */
  .chat-message__bubble {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2px;

    /* Fixed width for consistency */
    min-width: 250px;
    max-width: 100%;
    width: 100%;

    word-wrap: break-word;
  }

  /* Styling for current user's messages */
  .chat-message--current-user .chat-message__bubble {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: none;
  }

  /* Message Header */
  .chat-message__header {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.8);
  }

  /* Username */
  .chat-message__username {
    font-weight: bold;
    color: white;
    margin-right: 0.2rem;
  }

  /* Timestamp */
  .chat-message__timestamp {
    font-size: 0.75rem;
  }

  /* Message Text */
  .chat-message__text {
    color: white;
    font-size: 0.8rem;
    margin-top: 5px;
  }

  /* Chat Input */
  /* Ensures the input remains at the bottom */
  .chat-input {
    display: flex;
    align-items: center;
    margin-top: auto; /* Pushes it to the bottom */
  }

  .chat-input input {
    flex: 1;
    padding: 8px;
    color: white;
    font-size: 0.9rem;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 8px;
    margin-right: 0.5rem;
  }

  button {
    padding: 10px 15px;
    cursor: pointer;
  }
}
</style>
