<template>
  <div class="modal-header-container" :style="{ marginBottom: marginBottom }">
    <div class="modal-header-container__heading-wrap">
      <h2 class="atmo-page-header">{{ heading }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      required: true,
    },
    marginBottom: {
      type: String,
      default: "0rem",
    },
  },
};
</script>

<style scoped>
.modal-header-container {
  display: flex;
  justify-content: center;
}

.modal-header-container__heading-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(96, 72, 117, 0.7);
  backdrop-filter: blur(5px);
  padding: 0.5rem 2rem 1.5rem 2rem;
  border-radius: 0px 0px 20px 20px;
}

.atmo-page-header {
  margin: 0;
}
</style>
