<template>
  <div class="dashboard-content__broadcast">
    <div class="broadcast__title-container">Broadcast</div>
    <div class="broadcast__content-container">
      <div v-if="posts.length === 0" class="content-container__empty">No posts at this time</div>
      <perfect-scrollbar v-else>
        <div>
          <div v-for="(post, index) in posts" :key="`post-${index}`" class="content-container__broadcast-item">
            <router-link :to="getProfileRoute(post.user, getProfileShowRouteName(post.user.profile_type))">
              <img class="broadcast-item__image" :src="getFeaturedImage(post.user.images, 'full')" />
            </router-link>
            <div class="broadcast-item__content">
              <div class="content__name-time">
                <div class="name-time__name">
                  <router-link :to="getProfileRoute(post.user, getProfileShowRouteName(post.user.profile_type))" class="friend-white">
                    {{ post.user.name }}
                  </router-link>
                </div>
                <div class="name-time__time">{{ getTimeAgo(post.created_at) }}</div>
              </div>
              <broadcast-post :post="post" />
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { getFeaturedImage, timeAgo, getProfileRoute, getProfileShowRouteName } from "@/helpers/utilityFunctions";
import BroadcastPost from "@/components/atmo-dashboards/broadcast-post";

export default {
  components: {
    BroadcastPost,
  },
  props: {
    posts: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getTimeAgo(date) {
      return timeAgo(date);
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    getProfileRoute,
    getProfileShowRouteName,
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";
@import "~@/assets/stylesheets/mixins";

.dashboard-content__broadcast {
  .broadcast__title-container {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .broadcast__content-container {
    height: 100%;
  }

  .content-container__empty {
    height: 100%;
    color: rgba(255, 255, 255, 0.5);
    font-style: italic;
  }

  .content-container__broadcast-item {
    padding: 0.5rem 0rem 0.5rem 0rem;
    // border-top: 1px solid white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
  }

  .content-container__broadcast-item:nth-child(1) {
    padding: 0rem 0rem 0.5rem 0rem;
  }

  .broadcast-item__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    background-color: $atmo-purple--dark;
    height: 2rem;
    width: 2rem;
    border-radius: 100%;
    object-fit: cover;
  }

  .broadcast-item__content {
    width: 100%;
  }

  .content__name-time {
    display: flex;
    margin-bottom: 0.5rem;
  }

  .name-time__name {
    font-weight: 700;
    font-size: 0.8rem;
  }

  .name-time__time {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.3);
    margin-left: auto;
  }
}
</style>
