<template>
  <div class="business-dashboard" data-cy="business-dashboard">
    <atmo-back-link></atmo-back-link>
    <perfect-scrollbar class="business-dashboard__grid-container">
      <!-- Left Column -->
      <div class="business-dashboard__summary-container">
        <div class="business-dashboard__section-header">Summary</div>
        <div class="business-dashboard__metrics-grid">
          <div class="business-dashboard__metric-box">
            <p class="metric-box__title">Page Visits</p>
            <p class="metric-box__value">{{ summary?.visits?.toLocaleString?.() || "—" }}</p>
          </div>
          <div class="business-dashboard__metric-box">
            <p class="metric-box__title">Revenue</p>
            <p class="metric-box__value">${{ summary?.revenue?.toLocaleString?.() || "—" }}</p>
          </div>
        </div>

        <div class="business-dashboard__section-header">Revenue (Last 15 Days)</div>
        <div class="business-dashboard__card">
          <apexchart width="100%" height="200" type="line" :options="revenueChartOptions" :series="revenueChartSeries" />
        </div>

        <div class="business-dashboard__section-header">Platform Impact</div>
        <div class="business-dashboard__card">
          <ul class="business-dashboard__list">
            <li>
              Fans Reached: <strong>{{ platformImpact?.fans_reached?.toLocaleString?.() || "—" }}</strong>
            </li>
            <li>
              Artists Interacted: <strong>{{ platformImpact?.artist_interactions?.toLocaleString?.() || "—" }}</strong>
            </li>
          </ul>
        </div>
      </div>

      <!-- Right Column -->
      <div class="business-dashboard__performance-container">
        <div class="business-dashboard__section-header">Commerce Overview</div>
        <div class="business-dashboard__card">
          <ul class="business-dashboard__list">
            <li>
              Total Items Sold: <strong>{{ commerce?.total_items_sold || "—" }}</strong>
            </li>
            <li>
              Top Product: <strong>{{ commerce?.top_product || "—" }}</strong>
            </li>
            <li>
              Revenue This Month: <strong>${{ commerce?.revenue_this_month?.toLocaleString?.() || "—" }}</strong>
            </li>
          </ul>
        </div>

        <div class="business-dashboard__section-header">Audience Reach</div>
        <div class="business-dashboard__card">
          <ul class="business-dashboard__list">
            <li>
              Top Region: <strong>{{ audienceReach?.top_region || "—" }}</strong>
            </li>
            <li>
              Demographic: <strong>{{ audienceReach?.top_demographic || "—" }}</strong>
            </li>
          </ul>
        </div>

        <div class="business-dashboard__section-header">Explore More</div>
        <div class="business-dashboard__links">
          <router-link :to="{ name: 'business_dashboards.revenue', params: { businessProfileId } }">View Revenue Insights</router-link>
          <router-link :to="{ name: 'business_dashboards.fans', params: { businessProfileId } }">View Fan Insights</router-link>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callBusinessDashboardsIndex } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoBackLink,
  },
  data() {
    return {
      businessProfileId: this.$route.params.businessProfileId,
      summary: {},
      platformImpact: {},
      commerce: {},
      audienceReach: {},
      revenueChartSeries: [],
      revenueChartOptions: {
        chart: { type: "line", height: 200, toolbar: { show: false } },
        stroke: { curve: "smooth", width: 4 },
        xaxis: {
          categories: [],
          labels: { style: { colors: "#ddd" } },
        },
        yaxis: {
          labels: { style: { colors: "#ddd" } },
        },
        tooltip: { theme: "dark" },
        colors: ["#44AEDC"],
      },
    };
  },
  created() {
    this.$store.commit("setHudHeader", "Business Dashboard");
    this.fetchDashboardData();
  },
  methods: {
    async fetchDashboardData() {
      try {
        const res = await callBusinessDashboardsIndex(this.businessProfileId);
        this.summary = res.summary;
        this.platformImpact = res.platform_impact;
        this.commerce = res.commerce;
        this.audienceReach = res.audience_reach;

        this.revenueChartSeries = [
          {
            name: "Revenue",
            data: res.revenue_growth_chart.map((point) => point.revenue),
          },
        ];
        this.revenueChartOptions.xaxis.categories = res.revenue_growth_chart.map((point) => point.day);
      } catch (err) {
        console.error("Failed to fetch business dashboard:", err);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.business-dashboard {
  margin: 0 75px;
  height: 74vh;
  display: flex;
  flex-direction: column;

  .business-dashboard__grid-container {
    flex: 1;
    overflow: hidden;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    height: 100%;
    max-height: 100%;
  }

  .business-dashboard__section-header {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 700;
  }

  .business-dashboard__summary-container,
  .business-dashboard__performance-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .business-dashboard__metrics-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .business-dashboard__metric-box {
    background: rgba(161, 125, 187, 0.6);
    border-radius: 10px;
    padding: 1rem;

    .metric-box__title {
      text-transform: uppercase;
      font-size: 0.8rem;
      color: $atmo-gray--light--faded;
      margin-bottom: 0.5rem;
    }

    .metric-box__value {
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
  }

  .business-dashboard__card {
    background: rgba(161, 125, 187, 0.6);
    padding: 1rem;
    border-radius: 10px;
  }

  .business-dashboard__list {
    padding-left: 1rem;
    list-style: disc;
  }

  .business-dashboard__links {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    a {
      color: white;
      text-decoration: underline;
      font-size: 0.875rem;

      &:hover {
        color: $atmo-purple--light;
      }
    }
  }
}
</style>
