import { callDirectoryAdsIndex, callDirectoryAdImpressionsCreate, callDirectoryAdClicksCreate } from "@/helpers/axiosCalls";
import { UAParser } from "ua-parser-js";

export default {
  namespaced: true,
  state: {
    directoryAds: [],
    displayedAds: new Set(), // Keeps track of already displayed ads
  },
  mutations: {
    setDirectoryAds(state, ads) {
      state.directoryAds = ads;
      state.displayedAds = new Set(); // Reset displayed ads when fetching new data
    },
    addDisplayedAd(state, adId) {
      state.displayedAds.add(adId);
    },
    resetDisplayedAds(state) {
      state.displayedAds.clear();
    },
  },
  actions: {
    async fetchDirectoryAds({ commit, state }, userId) {
      if (state.directoryAds.length > 0) return; // Prevent multiple API calls
      try {
        const response = await callDirectoryAdsIndex(userId, { filter: true });
        commit("setDirectoryAds", response || []);
      } catch (error) {
        console.error("Error fetching directory ads:", error);
      }
    },
    async logAdImpression(_, { userId, ad }) {
      try {
        const parser = new UAParser();
        const result = parser.getResult();

        await callDirectoryAdImpressionsCreate(userId, {
          ad_type: "DirectoryAd",
          ad_id: ad.id,
          user_id: userId,
          device_type: result.device.type || "desktop",
          browser: result.browser.name,
          os: result.os.name,
          page_url: window.location.href,
        });
      } catch (error) {
        console.error("Error logging ad impression:", error);
      }
    },
    async logAdClick(_, { userId, ad }) {
      try {
        const parser = new UAParser();
        const result = parser.getResult();

        await callDirectoryAdClicksCreate(userId, {
          ad_type: "DirectoryAd",
          ad_id: ad.id,
          user_id: userId,
          device_type: result.device.type || "desktop",
          browser: result.browser.name,
          os: result.os.name,
          page_url: window.location.href,
        });
      } catch (error) {
        console.error("Error logging ad click:", error);
      }
    },
  },
  getters: {
    getUniqueAd: (state, getters, rootState, rootGetters) => {
      let availableAds = state.directoryAds.filter((ad) => !state.displayedAds.has(ad.id));

      // If all ads have been displayed, reset the displayed set
      if (availableAds.length === 0 && state.directoryAds.length > 0) {
        availableAds = state.directoryAds; // Make all ads available again
        state.displayedAds.clear(); // Reset displayed ads tracking
      }

      if (availableAds.length === 0) return null; // Still no ads available

      const randomAd = availableAds[Math.floor(Math.random() * availableAds.length)];
      return randomAd;
    },
  },
};