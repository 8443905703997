import Axios from 'axios';

export function callDirectoryAdImpressionsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/directory_ad_impressions`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryAdImpressionsShow(userId, adImpressionId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/directory_ad_impressions/${adImpressionId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callDirectoryAdImpressionsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/directory_ad_impressions`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}