import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './routes';
import Axios from 'axios';
import { createI18n } from 'vue-i18n';
import '@/assets/stylesheets/style.css';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';
import { globalMixin } from './devUtils/devFunctions';
import VideoBackground from 'vue-responsive-video-background-player';
import VueApexCharts from "vue3-apexcharts";
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css'; 
import Vue3Toastify, { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

import AtmoModal from '@/components/atmo-modal.vue';

Axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

const app = createApp(App);

app.component('video-background', VideoBackground);
app.component('AtmoModal', AtmoModal);

app.mixin(globalMixin);

app.use(store);
app.use(router);

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
});

app.use(VueApexCharts);
app.use(i18n);
app.use(PerfectScrollbarPlugin);
app.use(FloatingVue);
app.use(Vue3Toastify, { autoClose: 3000, position: toast.POSITION.TOP_CENTER, theme: "dark" });

app.config.globalProperties.$toast = toast;

app.mount('#vue-app');
