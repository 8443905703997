<template>
  <div class="artist-profile" data-cy="artist-profile">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">Artist Profile</h2>
    </div> -->
    <atmo-back-link></atmo-back-link>
    <div class="artist-profile-grid">
      <artist-details-container
        v-if="user && profile"
        :user="user"
        :isMyProfile="isMyProfile"
        :dashboardRoute="{ name: 'artist_dashboards.show', params: { artistProfileId: $route.params.artistProfileId } }"
        :editProfileRoute="{ name: 'artist_profiles.edit', params: { artistProfileId: $route.params.artistProfileId } }"
        :venueRequestRoute="{ name: 'artist_profiles.venue_request.new', params: { artistProfileId: $route.params.artistProfileId } }"
        :donateRoute="{ name: 'donate', params: { profileId: $route.params.artistProfileId, profileType: 'artist_profiles' } }"
      >
        <template v-slot:label-name v-if="profile?.label_profile?.user">
          <router-link class="label-profile-link" :to="{ name: 'label_profiles.show', params: { labelProfileId: profile.label_profile.id } }">
            {{ profile.label_profile.user.name }}
          </router-link>
        </template>
      </artist-details-container>
      <ProfileLayout v-if="user && profileRoutes" :user="user" :smallCardRoutes="profileRoutes.smallCards" :largeCardRoutes="profileRoutes.largeCards" />
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import ArtistDetailsContainer from "@/components/profiles/artist-details-container";
import ProfileLayout from "@/components/profiles/profile-layout";
import { mapActions, mapGetters } from "vuex";
import { getFeaturedImage } from "@/helpers/utilityFunctions";

export default {
  components: {
    AtmoBackLink,
    ArtistDetailsContainer,
    ProfileLayout,
  },
  computed: {
    ...mapGetters("profiles", ["getProfile", "getUser", "isLoading", "getProfileType", "getProfileRoutes"]),
    ...mapGetters("userFriendships", ["getUserFriendships", "getMutualFriends"]),
    ...mapGetters("userRelationships", ["getArtistSubscriptions", "getRelationships", "getCurrentUserIsFollowing", "getRelationshipId"]),
    profile() {
      return this.getProfile;
    },
    user() {
      return this.getUser;
    },
    currentUser() {
      return this.$store.state.user;
    },
    userFriendships() {
      return this.getUserFriendships;
    },
    artistSubscriptions() {
      return this.getArtistSubscriptions;
    },
    isMyProfile() {
      return this.$store.state.currentUserProfileId === this.userProfileId;
    },
    userProfileId() {
      return Number(this.$route.params.artistProfileId);
    },
    currentFriendship() {
      return this.userFriendships.find((friendship) => friendship.friend_id === this.currentUser.id);
    },
    currentUserCanFriendRequest() {
      const currentUserIsAlreadyFriend = this.currentFriendship;
      return !this.isMyProfile && !currentUserIsAlreadyFriend;
    },
    friendRequestPending() {
      return this.currentFriendship && this.currentFriendship.state === "pending";
    },
    currentUserIsFollowing() {
      return this.getCurrentUserIsFollowing;
    },
    mutualFriends() {
      return this.getMutualFriends;
    },
    profileRoutes() {
      return this.getProfileRoutes;
    },
  },
  async created() {
    this.$store.commit("setHudHeader", "Artist Profile");
    await this.fetchProfile({ profileType: "ArtistProfile", profileId: this.$route.params.artistProfileId });
    console.log("Profile fetched:", this.profile); // This should show populated data
    await this.fetchUserFriendships(this.userProfileId);
    await this.fetchFollowedRelationships(this.userProfileId);
    await this.fetchFollowerRelationships(this.$store.state.currentUserProfileId);
    await this.updateFollowingState(this.userProfileId);
    this.setupActionCables();
  },
  methods: {
    ...mapActions("profiles", ["fetchProfile"]),
    ...mapActions("userFriendships", ["fetchUserFriendships", "fetchMutualFriends"]),
    ...mapActions("userRelationships", ["fetchFollowerRelationships", "fetchFollowedRelationships", "updateFollowingState", "connectToUserRelationships"]),
    getFeaturedImage,
    openUserMediaModal(userMediaObj) {
      this.$store.dispatch("videoPlayer/fetchUserMediaModalComments", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/setUserMediaModalObj", { type: userMediaObj.type, caption: userMediaObj.caption, id: userMediaObj.id, url: userMediaObj.url });
      this.$store.commit("videoPlayer/toggleUserMediaModal");
    },
    setupActionCables() {
      this.$store.dispatch("userFriendships/connectToUserFriendships");
      this.$store.dispatch("userRelationships/connectToUserRelationships");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.artist-profile {
  margin: 0 75px;

  .grid-scroll-wrap {
    max-height: 22rem;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .artist-profile-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: 18rem 1fr;
  }
}
</style>
