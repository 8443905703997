<template>
  <AtmoModal id="atmo-library" ref="atmoLibraryRef" v-model="$store.state.libraries.libraryOpen" title="Library" @opened="getAlbumsAndPlaylists" customClass="atmo-library">
    <notifications group="main" position="top right" />
    <header class="atmo-modal__header">
      <span class="atmo-modal__header-side">
        <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png" @click="toggleLibraryOpen()" />
      </span>
      <ModalHeader heading="Atmo Library" marginBottom="0rem" />
      <span class="atmo-modal__header-side" />
    </header>

    <ProgressLoading v-if="isLoading" :isLoading="isLoading" :loadingMessages="loadingMessages" :progress.sync="progress" :simulateProgress="true" />

    <atmo-library-body v-else component-location="atmoLibrary" :albums="albums" :playlists="playlists" />
  </AtmoModal>
</template>

<script>
import AtmoLibraryBody from "@/components/atmo-library/atmo-library-body";
import { mapMutations } from "vuex";
import { callAtmoLibrariesSongsByAlbum, callPlaylistsIndex } from "@/helpers/axiosCalls";
import ModalHeader from "@/components/modal-header";
import ProgressLoading from "@/components/progress-loading";

export default {
  components: {
    AtmoLibraryBody,
    ModalHeader,
    ProgressLoading,
  },
  data() {
    return {
      albums: [],
      playlists: [],
      isLoading: true,
      progress: 0,
      loadingMessages: [
        "Initializing Personal Audio Archive...",
        "Decrypting Playlist Metadata...",
        "Retrieving Saved Tracks and Albums...",
        "Synchronizing Recent Play History...",
        "Restoring Favorite Artists and Genres...",
        "Compiling Personalized Soundwave Data...",
        "Optimizing Audio Caching for Seamless Playback...",
        "Analyzing Listening Trends and Insights...",
        "Fetching High-Resolution Cover Art...",
        "Calibrating Smart Recommendations Engine...",
        "Reconstructing Offline Music Library...",
        "Linking Cloud-Based Music Collections...",
      ],
    };
  },
  methods: {
    ...mapMutations("libraries", ["toggleLibraryOpen"]),
    async getAlbumsAndPlaylists() {
      this.progress = 0;
      this.isLoading = true;

      try {
        const totalSteps = this.loadingMessages.length;
        const progressIncrement = 100 / totalSteps;
        let currentStep = 0;

        const updateProgress = () => {
          this.progress = Math.min(100, Math.floor((++currentStep / totalSteps) * 100));
        };

        updateProgress(); // Step 1: Initial progress

        // Fetch albums
        const albumsResponse = await this.getAlbums();
        this.albums = albumsResponse;
        updateProgress();

        // Fetch playlists
        const playlistsResponse = await this.getPlaylists();
        this.playlists = playlistsResponse.sort((a, b) => a.name.localeCompare(b.name));
        updateProgress();

        // Ensure progress reaches 100% at the end
        this.progress = 100;
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } catch (error) {
        console.error("Error loading library:", error);
        this.progress = 100;
      }
    },

    async getAlbums() {
      if (!this.$store.state.currentUserProfileId) return [];
      try {
        return await callAtmoLibrariesSongsByAlbum(this.$store.state.currentUserProfileId);
      } catch (error) {
        console.error("Error fetching albums:", error);
        return [];
      }
    },

    async getPlaylists() {
      if (!this.$store.state.currentUserId) return [];
      try {
        return await callPlaylistsIndex(this.$store.state.currentUserId);
      } catch (error) {
        console.error("Error fetching playlists:", error);
        return [];
      }
    },
  },
};
</script>
