<template>
  <AtmoModal id="ami-testing" ref="amiTestingRef" v-model="$store.state.amiTestingOpen" title="Ami Testing" customClass="atmo-queue">
    <header class="atmo-queue__header atmo-modal__header">
      <img class="atmo-queue__hide-queue" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Queue" @click="this.$store.commit('closeAmiTestingModal')" />
      <h2>AMI Testing</h2>
    </header>

    <div>
      <p>Enter Speech Here:</p>

      <input v-model="simulatedAMISpeech" type="text" placeholder="Simulate Speech" @keyup.enter="onEnter(simulatedAMISpeech)" />

      <button @click="$store.commit('sendSimulatedAMISpeech', simulatedAMISpeech)">Test</button>
    </div>
  </AtmoModal>
</template>

<script>
export default {
  data() {
    return {
      isOpen: true,
      simulatedAMISpeech: null,
    };
  },
  methods: {
    hideModal() {
      this.$store.commit("hideModal");
    },
    onEnter: function (simulatedAMISpeech) {
      this.$store.commit("sendSimulatedAMISpeech", simulatedAMISpeech);
      this.simulatedAMISpeech = "";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-library {
  .modal .modal-lg.modal-dialog {
    max-width: 1000px;
  }

  &__body {
    display: flex;
  }

  &__main-column {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 0 20px 10px 30px;
  }

  &__sidebar-section {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
  }

  &__back-to-library {
    display: flex;
    align-items: flex-end;
    font-size: 1.1em;
    font-weight: 300;
    cursor: pointer;

    img {
      height: 12px;
      margin-right: 3px;
    }
  }
}
</style>
