<template>
  <div class="default-post-content">
    <router-link :to="postLink" class="post-link">
      {{ post.content }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  computed: {
    postLink() {
      if (!this.post.user_activity_type) {
        return { name: "posts.show", params: { postId: this.post.id } };
      }

      switch (this.post.user_activity_type.type_name) {
        case "Post Like":
          return { name: "posts.show", params: { postId: this.post.activityable.id } };
        case "Post Dislike":
          return { name: "posts.show", params: { postId: this.post.id } };
        case "Song Like":
          return { name: "songs.show", params: { artistProfileId: this.post.activityable.artist_profile_id, songId: this.post.activityable.id } };
        case "Song Dislike":
          return { name: "songs.show", params: { songId: this.post.activityable_id } };
        case "Album Like":
          return { name: "albums.show", params: { albumId: this.post.activityable.id } };
        case "Album Dislike":
          return { name: "albums.show", params: { albumId: this.post.activityable.id } };
        case "Comment Like":
          return { name: "songs.show", params: { songId: this.post.activityable_id } };
        case "Comment Dislike":
          return { name: "songs.show", params: { songId: this.post.activityable_id } };
        case "Post Comment":
          return { name: "posts.show", params: { postId: this.post.id } };
        case "Playlist Like":
          return { name: "playlists.show", params: { playlistId: this.post.activityable_id } };
        case "Playlist Dislike":
          return { name: "playlists.show", params: { playlistId: this.post.activityable_id } };
        case "Live Stream Start":
          return { name: "live_streams.show", params: { userId: this.post.activityable_id } };
        default:
          return null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.default-post-content {
  .post-link {
    color: white;
    // text-decoration: underline;
  }
}
</style>
