<template>
  <div class="advertisements" data-cy="advertisements">
    <div class="advertisements__create-filter-search-container">
      <router-link class="create-filter-search-container__create-ads-wrap" :to="{ name: 'advertisements.new', params: { userId: this.$route.params.userId } }">
        <div class="create-ads-wrap__icon-wrap">
          <img class="icon-wrap__icon" src="@/assets/images/icons/add.png" />
        </div>
        <div class="create-ads-wrap__text-wrap">Create Ad</div>
      </router-link>
      <div class="create-filter-search-container__search-wrap">
        <div class="search-wrap__search-input-wrap">
          <input class="search-input-wrap__search-field-rounded" type="text" placeholder="Search genres" />
        </div>
      </div>
    </div>

    <perfect-scrollbar class="advertisements-grid-container">
      <div v-for="ad in advertisements" :key="ad.id" class="advertisements-grid-container__card">
        <div
          class="card__image-wrap"
          :style="{
            'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + 'url(' + `${ad.image.url}` + ')',
          }"
        >
          <div class="image-wrap__status-wrap">
            <div class="status-wrap__status" :class="ad.is_live ? 'active' : 'paused'">
              {{ ad.is_live ? "Active" : "Paused" }}
            </div>
          </div>
        </div>
        <div class="card__text-wrap">
          <div class="text-wrap__title-wrap">{{ ad.title }}</div>
          <div class="text-wrap__body-wrap">{{ ad.copy }}</div>
        </div>
        <div class="text-wrap__button-popover-wrap">
          <div class="button-popover-wrap__button-wrap">
            <router-link class="button-wrap__button" :to="{ name: 'advertisements.edit', params: { adId: ad.id } }"> See Details </router-link>
          </div>
          <div class="button-popover-wrap__button-wrap">
            <router-link class="button-wrap__button" :to="{ name: 'advertisements.metrics', params: { adId: ad.id } }"> View Metrics </router-link>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import Axios from "axios";
import { callDirectoryAdsIndex } from "@/helpers/axiosCalls";

export default {
  data: function () {
    return {
      advertisements: [],
    };
  },
  created() {
    this.getAdvertisements();
    this.$store.commit("setHudHeader", "My Ads");
  },
  methods: {
    getAdvertisements() {
      callDirectoryAdsIndex(this.$store.state.currentUserId).then((response) => {
        this.advertisements = response;
      });
    },
    capitalizeFirstLetter(str) {
      if (!str) return str; // Check if the string is empty or undefined
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.advertisements {
  margin: 0 75px;

  .temp-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .advertisements__create-filter-search-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem 0rem 1rem 0rem;
    align-items: center;
  }

  .create-filter-search-container__create-ads-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .create-ads-wrap__icon-wrap {
    width: auto;
    margin-right: 0.5rem;

    img {
      height: 2rem;
      width: auto;
    }
  }

  .create-ads-wrap__text-wrap {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .create-filter-search-container__search-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.4);
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.4);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.4);
  }

  .search-input-wrap__search-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .advertisements-grid-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(4, 1fr);
  }

  .advertisements-grid-container__card {
    background: rgba(255, 255, 255, 0.1);
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 1rem;
    border-radius: 5px;
  }

  .card__image-wrap {
    height: 7rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .image-wrap__status-wrap {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .status-wrap__status {
    border-radius: 15px;
    padding: 0.5rem 1.5rem;
    font-size: 0.8rem;
  }

  .status-wrap__status.active {
    background-color: $atmo-blue--medium;
  }

  .status-wrap__status.paused {
    background-color: $atmo-pink--medium;
  }

  .card__text-wrap {
    margin-top: 1rem;
  }

  .text-wrap__title-wrap {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .text-wrap__body-wrap {
    margin-bottom: 1rem;
  }

  .text-wrap__button-popover-wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .button-wrap__button {
    display: inline-block;
    color: white;
    padding: 0.3rem 0.5rem 0.3rem 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid white;
    text-transform: uppercase;
  }

  .button-popover-wrap__popover-wrap {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }

  .popover-wrap__icon-wrap {
    height: 1.5rem;
    width: 1.5rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .icon-wrap__icon {
    height: 0.2rem;
  }
}
</style>
