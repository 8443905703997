<template>
  <div>
    <div v-if="!$store.state.videoPlayer.backgroundVidPlaying" class="mini-library hud-sidebar__module">
      <div class="mini-library__header">
        <input type="text" class="atmo-input atmo-input--search mini-library__search-input" :placeholder="searchPlaceholder" v-model="searchQuery" @input="filterList" />
        <img @click="toggleLibraryOpen" style="cursor: pointer" src="@/assets/images/icons/max_library.png" alt="Show Max Library" class="mini-library__icon" />
      </div>

      <!-- LIBRARY SONG VIEW -->
      <div v-if="currentView === 'songs'">
        <div v-if="libraryLoading" class="hud-sidebar__loading-container">
          <atmo-loading />
        </div>
        <div v-else class="hud-sidebar__scrollable-content">
          <perfect-scrollbar v-if="library.length > 0">
            <div>
              <div v-for="(song, index) in filteredSongList" :key="index">
                <div class="mini-library__song">
                  <div class="mini-library__dropdown-toggle-container">
                    <song-actions-popover
                      :song="song"
                      :actions="[
                        'share-song-as-post',
                        'share-song-as-message',
                        'add-to-queue',
                        'remove-from-library',
                        'add-to-playlist',
                        'create-radio-station',
                        'display-artist',
                        'display-song',
                        'display-album',
                        'copy-song-link',
                        'send-song-sms',
                      ]"
                    />
                  </div>
                  <p class="mini-library__song-name" @click="$store.dispatch('player/prependAndPlaySong', song)">
                    {{ song.name }}
                  </p>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
          <div v-else class="mini-library__empty-library-content">
            <div>No library data found.</div>
          </div>
        </div>
        <div class="mini-library__toggle-link">
          <a @click="changeView('playlists')">View Playlists</a>
        </div>
      </div>

      <!-- PLAYLIST VIEW -->
      <div v-if="currentView === 'playlists'">
        <div class="hud-sidebar__scrollable-content">
          <perfect-scrollbar>
            <div v-for="playlist in filteredPlaylistList" :key="playlist.id">
              <div class="mini-library__song">
                <div class="mini-library__dropdown-toggle-container">
                  <song-actions-popover :playlist="playlist" :actions="['add-playlist-to-queue']" />
                </div>
                <p class="mini-library__song-name" @click="setPlaylistAttributes(playlist.id)">
                  {{ playlist.name }}
                </p>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <div class="mini-library__toggle-link">
          <a @click="changeView('songs')">Back To Songs</a>
        </div>
      </div>

      <!-- PLAYLIST SONG VIEW -->
      <div v-if="currentView === 'playlist-songs'">
        <div class="hud-sidebar__scrollable-content">
          <perfect-scrollbar>
            <div v-for="song in filteredPlaylistSongList" :key="song.id" class="mini-library__song">
              <div class="mini-library__dropdown-toggle-container">
                <song-actions-popover
                  :song="song"
                  :actions="[
                    'share-song-as-post',
                    'share-song-as-message',
                    'add-to-queue',
                    'remove-from-library',
                    'add-to-playlist',
                    'create-radio-station',
                    'display-artist',
                    'display-song',
                    'display-album',
                    'copy-song-link',
                    'send-song-sms',
                  ]"
                />
              </div>
              <p class="mini-library__song-name" @click="$store.dispatch('player/prependAndPlaySong', song)">
                {{ song.name }}
              </p>
            </div>
          </perfect-scrollbar>
        </div>
        <div class="mini-library__toggle-link">
          <a @click="changeView('playlists')">Back To Playlists</a>
        </div>
      </div>
    </div>
    <div v-else class="video-lyrics-container hud-sidebar__module">
      <div class="video-lyrics-container__header">{{ computedVideoName }}</div>
      <perfect-scrollbar>
        <p>{{ computedVideoLyrics }}</p>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import AtmoLoading from "@/components/atmo-loading";
import SongActionsPopover from "@/components/song-actions-popover";
import Axios from "axios";
import { callSongReferencesIndex } from "@/helpers/axiosCalls";
import { mapMutations } from "vuex";

export default {
  components: { AtmoLoading, SongActionsPopover },

  data() {
    return {
      playlistId: null,
      playlistSongs: [],
      searchQuery: "",
    };
  },
  created() {
    this.$store.dispatch("libraries/getLibrary");
    this.$store.dispatch("atmoQueues/getAtmoQueue");
  },
  computed: {
    computedVideoName() {
      return this.$store.state.videoPlayer.videoBackgroundName;
    },
    computedVideoLyrics() {
      return this.$store.state.videoPlayer.videoBackgroundLyrics;
    },
    currentView() {
      return this.$store.getters["libraries/miniLibraryCurrentView"];
    },
    searchPlaceholder() {
      return this.currentView === "playlists" ? "Type Playlist Name" : "Type Song Name";
    },
    library() {
      return this.$store.getters["libraries/library"];
    },
    libraryLoading() {
      return this.$store.getters["libraries/libraryLoading"];
    },
    filteredSongList() {
      return this.library.filter((song) => song.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    filteredPlaylistList() {
      return this.$store.state.playlists.currentUserPlaylists.filter((playlist) => playlist.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    filteredPlaylistSongList() {
      return this.$store.state.playlists.currentPlaylistSongs.filter((playlistSong) => playlistSong.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
  },
  mounted() {
    this.getUserPlaylists();
  },
  methods: {
    ...mapMutations("libraries", ["toggleLibraryOpen"]),
    filterList() {
      // This method is bound to the input event and updates the filtered list
      // based on the search query in real-time.
    },
    getSongUrls(arr) {
      for (var i = 0; i < arr.length; i++) {
        this.audioSources.push(arr[i].url);
      }
    },

    removeDuplicates(originalArray) {
      return originalArray.filter(function (item, index) {
        return originalArray.indexOf(item) >= index;
      });
    },
    // GET ALL COLLAB PLAYLISTS [playlist, playlist, ...]
    getAllCollabPlaylists(playlists) {
      Axios.get(`/api/v1/users/${this.$store.state.currentUserId}/collaborator_playlists`, {})
        .then((response) => {
          var allPlaylists = response.data;
          if (allPlaylists.length > 0) {
            // GET COLLABORATOR ARRAY IN EACH PLAYLIST [[collaborator, collaborator], [collaborator, collaborator], ...]
            for (var i = 0; i < allPlaylists.length; i++) {
              var playlistCollaborators = allPlaylists[i].playlist_collaborators;
              if (playlistCollaborators.length > 0) {
                //ITERATE OVER EACH COLLABORATOR IN A PLAYLIST playlist: [collaborator, collaborator, collaborator, ...]
                for (var j = 0; j < playlistCollaborators.length; j++) {
                  var playlistCollaboratorId = playlistCollaborators[j].user.id;
                  var playlistCollaboratorPlaylistId = playlistCollaborators[j].playlist_id;
                  //IF COLLABORATOR MATCHES CURRENT USER, GET PLAYLIST FOR COLLABORATOR
                  if (playlistCollaboratorId == this.$store.state.currentUserId) {
                    Axios.get(`/api/v1/users/${playlistCollaboratorId}/playlists/${playlistCollaboratorPlaylistId}`, {})
                      .then((response) => {
                        var collaboratorPlaylist = response.data;
                        // PUSH PLAYLIST TO NEW ARRAY (PLAYLISTS USER IS A COLLABORATOR ON) & COMBINE WITH USER PLAYLISTS
                        this.allCollaboratorPlaylists.push(collaboratorPlaylist);
                        this.combinedPlaylists = playlists.concat(this.allCollaboratorPlaylists);
                        this.userAndCollaboratorPlaylists = this.removeDuplicates(this.combinedPlaylists);
                        this.$store.commit("playlists/setCurrentUserPlaylists", this.userAndCollaboratorPlaylists);
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                }
              } else {
                this.combinedPlaylists = playlists;
                this.userAndCollaboratorPlaylists = this.removeDuplicates(this.combinedPlaylists);
                this.$store.commit("playlists/setCurrentUserPlaylists", this.userAndCollaboratorPlaylists);
              }
            }
          } else {
            this.combinedPlaylists = [];
            this.userAndCollaboratorPlaylists = this.removeDuplicates(this.combinedPlaylists);
            this.$store.commit("playlists/setCurrentUserPlaylists", this.userAndCollaboratorPlaylists);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getUserPlaylists() {
      console.log("-----------------------------------getting user playlists...", this.$store.state.currentUserId);
      Axios.get(`/api/v1/users/${this.$store.state.currentUserId}/playlists`, {})
        .then((response) => {
          this.playlists = response.data;
          this.getAllCollabPlaylists(this.playlists);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    changeView(newView) {
      this.$store.dispatch("libraries/changeMiniLibraryView", newView);
      this.searchQuery = "";
    },
    setPlaylistAttributes(playlistId) {
      this.playlistId = playlistId;

      callSongReferencesIndex({
        songable_id: this.playlistId,
        songable_type: "Playlist",
      })
        .then((response) => {
          this.playlistSongs = response.map((songReference) => {
            return songReference.song;
          });

          this.$store.commit("playlists/setCurrentPlaylistSongs", this.playlistSongs);
        })
        .catch((error) => {
          console.error(error);
        });

      this.changeView("playlist-songs");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.video-lyrics-container {
  .video-lyrics-container__header {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }

  p {
    line-height: 20px;
  }
}

.mini-library {
  min-width: 10rem;

  .ps {
    max-height: $library-chat-height;
  }

  &__header {
    display: flex;
  }

  &__search-input {
    flex-grow: 1;
    width: 0;
  }

  &__icon {
    width: 28px;
    height: 28px;
    margin-left: 5px;
  }

  &__empty-library-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 1.5rem;
  }

  &__song {
    display: flex;
    align-items: center;
    padding: 3px;
  }

  &__song-name {
    cursor: pointer;
    font-size: 0.8rem;
    padding: 5px;
    padding-bottom: 1px;
    margin-left: -3px;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $atmo-purple--medium;
    }
  }

  &__dropdown-toggle-container {
    display: flex;
    align-items: center;
    height: 20px;
    min-width: 20px;
    margin-right: 4px;
  }

  &__toggle-link {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.9em;
    text-align: center;

    a {
      color: white;
    }
  }
}
</style>
