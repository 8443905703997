import Axios from 'axios';

export function callLiveStreamsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/live_streams`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLiveStreamsShow(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/live_stream`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLiveStreamsCreate(userId, requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users/${userId}/live_streams`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLiveStreamsEndStream(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/live_streams/end_stream`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callLiveStreamsStream(formData) {
    return Axios.post("/api/v1/live_streams/stream", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    })
    .then(response => response.data)
    .catch(error => {
        console.error("Streaming error:", error);
        throw error;
    });
}

export function callLiveStreamsEventLiveStream(queryParams = {}) {
    return Axios.get(`/api/v1/live_streams/event_live_stream`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}