<template>
  <AtmoModal id="max-chat" ref="maxChatRef" v-model="$store.state.chatRooms.maxChatOpen" title="max-chat" customClass="max-chat-modal" @opened="getMaxChatData">
    <EmojiPickerModal v-model="isEmojiPickerOpen" @select="onSelectEmoji" />
    <img class="max-chat__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Atmo Chat" @click="toggleMaxChatOpen" />
    <header class="max-chat__header atmo-modal__header">
      <div class="atmo-page-header-container">
        <div class="atmo-page-header-container__header-icon-wrap">
          <ModalHeader heading="Atmo Chat" marginBottom="0rem" />
        </div>
      </div>
    </header>
    <div class="max-chat-grid">
      <create-group-chat-modal />
      <!-- <delete-group-chat-modal /> -->
      <group-chat-settings-modal />
      <div class="max-chat-grid__friends-container">
        <div class="container__heading-wrap">Chats</div>
        <span class="create-room" style="display: block; margin-bottom: 1rem; text-transform: uppercase" @click="toggleCreateGroupChatModal()">Create Room</span>
        <div>
          <div class="friends-container__search-wrap">
            <input class="search-field-rounded" type="text" placeholder="Search for a chat" v-model="searchQuery" />
          </div>

          <div class="friends-container__combined-wrap">
            <perfect-scrollbar class="combined-scroll-wrap">
              <div v-if="combinedChatList && combinedChatList.length > 0">
                <div v-if="combinedChatList?.length === 0">No chats available.</div>
                <div class="users-wrap__user-wrap" v-for="chat in combinedChatList" :key="chat.id" @click="openChat(chat)">
                  <div class="user-wrap__image-wrap">
                    <img class="image-wrap__image" :src="chat.image" />
                    <notification-dot v-if="chat.type === 'friend'" :chat-user="chat.user" dot-size="small" notification-type="Chat Message"></notification-dot>
                  </div>
                  <div class="user-wrap__name-wrap">{{ chat.name }}</div>
                </div>
              </div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
      <div class="max-chat-grid__max-chat-container">
        <div class="container__heading-wrap">
          <div v-if="currentChatTitleData.roomType == 'friend'" @click="toggleMaxChatOpen">
            <profile-link :profile-type="currentChatTitleData.profileType" :profile-id="currentChatTitleData.profileId" class="friend-chat-title">
              <div class="heading-wrap__text-wrap">{{ currentChatTitleData.name }}</div>
            </profile-link>
          </div>
          <div v-else class="heading-wrap__text-wrap">{{ currentChatTitleData.name }}</div>
          <div class="testing-chat-container">
            <div class="testing-chat" @click="startVoiceCall"><img src="@/assets/images/profile_icons/headphones_2.png" /></div>
            <div class="testing-chat" @click="startVideoCall"><img src="@/assets/images/icons/video.png" /></div>
          </div>
          <!-- Video Call UI -->
          <div v-if="isInCall" :class="['video-call-container', { minimized: isMinimized }]" :style="callWindowStyle">
            <div class="video-call-header" @mousedown="startDrag">
              {{ isAudioOnly ? "Voice Call" : "Video Call" }}
              <button @click="toggleMinimize">🔽</button>
            </div>

            <div class="video-call-body">
              <video v-if="!isAudioOnly" ref="localVideo" autoplay playsinline></video>
              <video v-if="!isAudioOnly" ref="remoteVideo" autoplay playsinline></video>
              <p v-if="isAudioOnly" class="audio-call-text">🎙️ Voice Call in Progress...</p>
            </div>

            <!-- Mute/Unmute Audio Button -->
            <button @click="toggleMuteAudio" class="mute-button">🎙️ {{ isMuted ? "Unmute" : "Mute" }}</button>

            <!-- Mute/Unmute Video Button (Only for Video Calls) -->
            <button v-if="!isAudioOnly" @click="toggleMuteVideo" class="mute-button">📷 {{ isVideoMuted ? "Turn Video On" : "Turn Video Off" }}</button>

            <button class="end-call-button" @click="endCall">End Call</button>
          </div>

          <!-- Incoming Call Prompt -->
          <div v-if="isReceivingCall" class="incoming-call-modal">
            <div class="incoming-call-modal__heading-container">
              <p>{{ isAudioOnly ? "Incoming Voice Call..." : "Incoming Video Call..." }}</p>
            </div>
            <div class="incoming-call-modal__button-container">
              <button @click="acceptCall" class="call-button atmo-button--primary">Accept</button>
              <button @click="rejectCall" class="call-button atmo-button--secondary">Reject</button>
            </div>
          </div>

          <div class="heading-wrap__settings-wrap" v-if="groupChatRoomSelected">
            <img src="@/assets/images/icons/chat/settings_icon.png" class="settings-icon" @click="openChatSettings()" />
          </div>
        </div>
        <div v-if="currentChatRoomMessages.length === 0" class="messages-container empty-messages">Empty Chat History :(</div>
        <perfect-scrollbar v-else class="messages-container">
          <div v-for="(message, messageIndex) in currentChatRoomMessages" :key="message.id" :class="messageClassFor(message)">
            <div class="max-chat__message-content" @click="closeReactionMenu()">
              <div class="user-reactions-container">
                <div class="user-reactions-container__reaction" v-if="message.reactions[0]">
                  <img class="reaction__img" :src="require(`@/assets/images/reactions/${message.reactions[0].reaction_type.emoji}`)" />
                </div>
                <div class="user-reactions-container__reaction" v-if="message.reactions[1]">
                  <img class="reaction__img" :src="require(`@/assets/images/reactions/${message.reactions[1].reaction_type.emoji}`)" />
                </div>
                <div class="user-reactions-container__reactions-length" v-if="message.reactions.length > 2">
                  {{ message.reactions.length }}
                </div>
              </div>
              <div v-show="openElement === messageIndex" class="reactions-container">
                <div class="reactions-container__reaction-icon" v-for="(reactionType, reactionTypeIndex) in reactionTypes" :key="reactionTypeIndex" @click="addReaction(reactionType, message)">
                  <img class="reaction-icon__img" :src="require(`@/assets/images/reactions/${reactionType.emoji}`)" />
                </div>
              </div>
              <p class="max-chat__message-text" v-html="formatMessage(message.body)"></p>
              <div v-if="message.song_references.length > 0" class="max-chat__message-song">
                <img class="max-chat__message-song-icon" src="@/assets/images/icons/play.png" />
                <a class="max-chat__message-song-name">
                  {{ message.song_references[0].song.name }}
                </a>
              </div>

              <div v-if="message.chat_message_image" class="max-chat__message-image-wrap" @click="openChatImageModal(message.chat_message_image.image.url)">
                <img class="max-chat__message-image" :src="message.chat_message_image.image.url" />
              </div>
              <p class="max-chat__message-timestamp">
                {{ getDate(message.created_at) }}
              </p>
            </div>
            <div @click="toggleMaxChatOpen">
              <profile-link :profile-type="message.user.profile_type" :profile-id="message.user.profile_id">
                <div
                  class="atmo-avatar atmo-avatar--extra-small atmo-avatar--no-border"
                  :style="{ 'background-image': 'url(' + getFeaturedImage(message.user.images, 'thumb') + ')' }"
                  title="message.user.name"
                />
              </profile-link>
            </div>
            <div class="reaction-trigger" @click="toggleReactions(messageIndex)">☻</div>
          </div>
        </perfect-scrollbar>
        <form class="max-chat__form" @submit.prevent="saveMessage(newMessageBody)">
          <div class="input-container">
            <input v-model="newMessageBody" class="atmo-input atmo-input--square max-chat__text-input" placeholder="Type your message here" type="text" />

            <!-- File Upload Icon -->
            <label for="file-input" class="input-icon file-upload-icon">
              <img src="@/assets/images/icons/chat/upload_image.png" class="image-upload-icon" alt="Upload Image" />
            </label>
            <input id="file-input" type="file" @change="handleFileUpload" style="display: none" />

            <!-- Emoji Picker Icon -->
            <button type="button" class="input-icon emoji-picker-trigger" @click="isEmojiPickerOpen = true">☻</button>
          </div>
        </form>
      </div>
    </div>
  </AtmoModal>
</template>

<script>
import Axios from "axios";
import { sendMessage, setCallback as setChatCallback, connectToAtmoChatChannel } from "@/cable-channels/atmoChat";
import { connectToVideoCallsChannel, sendSignalingMessage, setCallback as setVideoCallback } from "@/cable-channels/videoCalls";
import EmojiPickerModal from "@/modals/emoji-picker-modal.vue";
import devMockData from "@/devUtils/mockData";
import createGroupChatModal from "@/modals/atmo-chat/create-group-chat-modal.vue";
import groupChatSettingsModal from "@/modals/atmo-chat/group-chat-settings-modal.vue";
import { formatDateToDayTimeYear, getFeaturedImage, findOtherUsersInRoom } from "@/helpers/utilityFunctions";
import { callReactionTypesIndex, callReactionsCreate, callChatRoomUsersCreate, callChatRoomsCreate, callChatRoomsIndex, callChatMessagesCreate } from "@/helpers/axiosCalls";
import NotificationDot from "@/components/notifications/notification-dot";
import { mapState } from "vuex";
import ProfileLink from "@/components/profile-link";
import ModalHeader from "@/components/modal-header";

export default {
  components: {
    EmojiPickerModal,
    createGroupChatModal,
    groupChatSettingsModal,
    NotificationDot,
    ProfileLink,
    ModalHeader,
  },
  data() {
    return {
      newMessageBody: "",
      search: "",
      devMockData,
      groupChatRoomSelected: false,
      searchQuery: "",
      reactionTypes: [],
      openElement: null,
      isEmojiPickerOpen: false,
      isInCall: false,
      isReceivingCall: false,
      callOffer: null, // Store the received offer
      localStream: null,
      peerConnection: null,
      signalingChannel: null,
      isMinimized: false,
      isDragging: false,
      offsetX: 0,
      offsetY: 0,
      posX: 20, // Default position (bottom-right)
      posY: 20,
      isAudioOnly: false,
      isMuted: false, // Track if the microphone is muted
      isVideoMuted: false, // Track if the camera is muted
    };
  },
  mounted() {
    console.log("📞 Mounting chat modal...");

    // ✅ Set callback first
    setVideoCallback(this.handleSignalingMessage);
    console.log("✅ Video Call callback set BEFORE connecting!");
  },
  computed: {
    ...mapState("chatRooms", ["currentChatRoom", "currentChatTitleData", "currentChatRoomMessages", "chatRooms", "chatUserFriendships", "chatCurrentView"]),
    acceptedFriendships() {
      return this.chatUserFriendships.filter((friendship) => friendship.state === "accepted");
    },
    filteredFriendList() {
      console.log("🔍 Filtering Friend List:", this.acceptedFriendships, " | Search Query:", this.searchQuery);
      return this.acceptedFriendships.filter((friendship) => friendship.friend.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    combinedChatList() {
      return [
        // ✅ Add Friend Chats
        ...(this.acceptedFriendships || []).map((friendship) => ({
          id: friendship.friend?.id || 0,
          name: friendship.friend?.name || "Unknown Friend",
          image: this.getFeaturedImage(friendship.friend?.images || [], "full"),
          type: "friend",
          user: friendship.friend,
        })),

        // ✅ Add All Chat Rooms (including DirectoryProfile, DirectoryAd, UserFriendship)
        ...(this.chatRooms || []).map((room) => ({
          id: room.id || 0,
          name: room.name || "Unnamed Chat",
          image: room.image ? room.image.url : "@/assets/images/default_chat.png",
          type: room.room_type,
          room,
        })),
      ];
    },
    callWindowStyle() {
      return {
        transform: `translate(${this.posX}px, ${this.posY}px)`,
      };
    },
  },
  methods: {
    openChat(chat) {
      console.log("Opening chat:", chat);

      if (chat.type === "friend") {
        this.setChatRoomAttributes(this.$store.state.currentUserId, chat.user);
      } else {
        this.getGroupRoomMessages(chat.room);
      }
    },
    // 🎙️ Toggle Microphone Mute/Unmute
    toggleMuteAudio() {
      if (this.localStream) {
        this.localStream.getAudioTracks().forEach((track) => {
          track.enabled = !track.enabled;
          this.isMuted = !track.enabled;
        });
      }
    },

    // 📷 Toggle Camera On/Off (For Video Calls)
    toggleMuteVideo() {
      if (this.localStream) {
        this.localStream.getVideoTracks().forEach((track) => {
          track.enabled = !track.enabled;
          this.isVideoMuted = !track.enabled;
        });
      }
    },
    async startVoiceCall() {
      this.isAudioOnly = true; // ✅ Ensure this is set before initializing media
      this.isInCall = true;

      console.log("🎙️ Starting voice call...");

      const mediaReady = await this.initializeMedia(false); // Pass `false` to disable video

      if (!mediaReady) {
        console.error("❌ Audio initialization failed, aborting voice call.");
        this.isInCall = false;
        return;
      }

      console.log("🔗 Creating peer connection...");
      this.createPeerConnection();

      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);

      sendSignalingMessage(this.currentChatRoom.id, {
        type: "offer",
        offer,
        callerId: this.$store.state.currentUserId,
        audioOnly: true, // ✅ Mark it as an audio-only call
      });

      console.log("✅ Voice call offer sent.");
    },
    toggleMinimize() {
      this.isMinimized = !this.isMinimized;
    },
    startDrag(event) {
      this.isDragging = true;
      this.offsetX = event.clientX - this.posX;
      this.offsetY = event.clientY - this.posY;
      document.addEventListener("mousemove", this.drag);
      document.addEventListener("mouseup", this.stopDrag);
    },
    drag(event) {
      if (this.isDragging) {
        this.posX = event.clientX - this.offsetX;
        this.posY = event.clientY - this.offsetY;
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.drag);
      document.removeEventListener("mouseup", this.stopDrag);
    },
    async startVideoCall() {
      this.isInCall = true;
      this.isAudioOnly = false; // 🔥 ENSURE this is reset for video calls

      console.log("🎥 Initializing media...");
      const mediaReady = await this.initializeMedia(true);

      if (!mediaReady) {
        console.error("❌ Media initialization failed, aborting video call.");
        this.isInCall = false;
        return;
      }

      console.log("🔗 Creating peer connection...");
      this.createPeerConnection();

      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);

      sendSignalingMessage(this.currentChatRoom.id, {
        type: "offer",
        offer,
        callerId: this.$store.state.currentUserId, // Store caller ID
      });

      console.log("✅ Offer sent.");
    },
    async initializeMedia(videoEnabled = true) {
      try {
        console.log("🎙️ Requesting access to media devices...");

        const constraints = {
          video: videoEnabled ? { width: { ideal: 1280 }, height: { ideal: 720 }, facingMode: "user" } : false,
          audio: true, // Ensure microphone is requested
        };

        this.localStream = await navigator.mediaDevices.getUserMedia(constraints);

        if (this.localStream) {
          console.log("✅ Media access granted.");
          if (!this.isAudioOnly) {
            this.$refs.localVideo.srcObject = this.localStream;
          }
        } else {
          throw new Error("Failed to obtain local media stream.");
        }
      } catch (error) {
        console.error("❌ Error accessing media devices:", error.name, error.message);

        if (error.name === "NotReadableError") {
          alert("🚨 Your microphone or camera is in use by another app. Close other apps and restart.");
        } else if (error.name === "NotAllowedError") {
          alert("🚨 Permission denied! Please allow access to the microphone in your browser settings.");
        } else if (error.name === "NotFoundError") {
          alert("🚨 No microphone found. Please check your audio device.");
        } else {
          alert("❌ Unable to access media. Check browser permissions.");
        }

        this.isInCall = false;
        return false;
      }
      return true;
    },
    createPeerConnection() {
      this.peerConnection = new RTCPeerConnection({
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      });

      // Add local stream to the peer connection
      this.localStream.getTracks().forEach((track) => {
        this.peerConnection.addTrack(track, this.localStream);
      });

      // Handle remote stream
      this.peerConnection.ontrack = (event) => {
        console.log("Receiving remote stream");

        this.$nextTick(() => {
          if (this.$refs.remoteVideo) {
            this.$refs.remoteVideo.srcObject = event.streams[0];
          } else {
            console.error("❌ remoteVideo ref is not yet available!");
          }
        });
      };

      // Handle ICE candidates
      this.peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          console.log("Sending ICE candidate:", event.candidate);
          sendSignalingMessage(this.currentChatRoom.id, { type: "candidate", candidate: event.candidate });
        }
      };
    },
    handleSignalingMessage(data) {
      console.log("🔥 [Video Call Signal Received]:", data);

      if (!data.signal || !data.signal.type) {
        console.error("❌ Invalid signaling data:", data);
        return;
      }

      if (data.signal.type === "call-ended") {
        console.log("📞 Remote user hung up, ending call...");

        // Check if remoteVideo exists before accessing properties
        if (this.$refs.remoteVideo && this.$refs.remoteVideo.srcObject) {
          this.$refs.remoteVideo.srcObject.getTracks().forEach((track) => track.stop());
          this.$refs.remoteVideo.srcObject = null;
        }

        // Ensure call state is reset
        this.isInCall = false;
      }

      if (data.signal.type === "offer") {
        console.log("📞 Incoming call detected!");

        if (data.signal.callerId !== this.$store.state.currentUserId) {
          this.$nextTick(() => {
            this.isReceivingCall = true;
            this.callOffer = data.signal.offer;
            this.isAudioOnly = data.signal.audioOnly || false; // ✅ Detect if it's an audio call
            console.log("✅ isReceivingCall set:", this.isReceivingCall, " | Audio Only:", this.isAudioOnly);
          });
        }
      } else if (data.signal.type === "answer") {
        console.log("✅ Received answer.");
        this.handleAnswer(data.signal.answer);
      } else if (data.signal.type === "candidate") {
        console.log("📡 Received ICE candidate.");
        this.handleCandidate(data.signal.candidate);
      } else {
        console.warn("⚠️ Unknown signaling type:", data.signal.type);
      }
    },
    async handleOffer(offer) {
      console.log("📞 Received offer:", offer);

      if (!this.peerConnection) {
        await this.initializeMedia(true);
        this.createPeerConnection();
      }

      if (this.peerConnection.signalingState !== "stable") {
        console.warn("⚠️ PeerConnection is not stable. Ignoring duplicate offer.");
        return;
      }

      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(offer));

      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answer);
      sendSignalingMessage(this.currentChatRoom.id, { type: "answer", answer });
    },
    async handleAnswer(answer) {
      console.log("✅ Received answer:", answer);

      if (this.peerConnection.signalingState !== "have-local-offer") {
        console.warn("⚠️ Ignoring setRemoteDescription: Already in stable state.");
        return;
      }

      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(answer));

      // ✅ Process any pending ICE candidates now
      if (this.pendingCandidates) {
        console.log("⏳ Processing stored ICE candidates...");
        this.pendingCandidates.forEach(async (c) => {
          await this.peerConnection.addIceCandidate(new RTCIceCandidate(c));
        });
        this.pendingCandidates = [];
      }
    },
    async handleCandidate(candidate) {
      console.log("📡 Received ICE candidate:", candidate);

      if (!this.peerConnection) {
        console.error("❌ PeerConnection does not exist yet.");
        return;
      }

      if (!this.peerConnection.remoteDescription || !this.peerConnection.remoteDescription.type) {
        console.warn("⏳ Remote description not set yet. Storing candidate...");
        this.pendingCandidates = this.pendingCandidates || [];
        this.pendingCandidates.push(candidate);
        return;
      }

      try {
        await this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate));
        console.log("✅ ICE candidate added successfully.");
      } catch (error) {
        console.error("❌ Error adding ICE candidate:", error);
      }
    },
    endCall() {
      console.log("📞 Call ended.");

      // Stop all local stream tracks safely
      if (this.localStream) {
        this.localStream.getTracks().forEach((track) => track.stop());
        this.localStream = null;
      }

      // Stop remote stream tracks safely
      if (this.$refs.remoteVideo && this.$refs.remoteVideo.srcObject) {
        this.$refs.remoteVideo.srcObject.getTracks().forEach((track) => track.stop());
        this.$refs.remoteVideo.srcObject = null;
      }

      // Clear local video source
      if (this.$refs.localVideo) {
        this.$refs.localVideo.srcObject = null;
      }

      // Close the peer connection safely
      if (this.peerConnection) {
        this.peerConnection.close();
        this.peerConnection = null;
      }

      // Reset call states
      this.isInCall = false;
      this.isAudioOnly = false; // 🔥 RESET to allow video calls again

      // Notify the other user that the call has ended
      sendSignalingMessage(this.currentChatRoom.id, { type: "call-ended" });
    },
    async acceptCall() {
      console.log("📞 Accepting call...");

      this.isReceivingCall = false;
      this.isInCall = true;

      if (!this.callOffer) return;

      console.log("🎥 Initializing media...");
      const mediaReady = await this.initializeMedia(!this.isAudioOnly); // ✅ Disable video for audio-only calls

      if (!mediaReady) {
        console.error("❌ Media initialization failed, aborting call.");
        return;
      }

      console.log("🔗 Creating peer connection...");
      this.createPeerConnection();

      await this.peerConnection.setRemoteDescription(new RTCSessionDescription(this.callOffer));

      console.log("✅ Remote description set.");

      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answer);
      sendSignalingMessage(this.currentChatRoom.id, { type: "answer", answer });

      console.log("✅ Answer sent.");

      this.callOffer = null;
    },
    rejectCall() {
      this.isReceivingCall = false;
      this.callOffer = null;
    },
    testingStuff() {
      window.alert("CALLING... RING RING RING");
    },
    getMaxChatData() {
      this.getUserFriendships();
      this.getChatRooms();
      this.getReactionTypes();
    },
    toggleMaxChatOpen() {
      this.$store.commit("chatRooms/toggleMaxChatOpen");
    },
    filterList() {
      // This method is bound to the input event and updates the filtered list
      // based on the search query in real-time.
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    getReactionTypes() {
      callReactionTypesIndex()
        .then((response) => {
          this.reactionTypes = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addReaction(type, message) {
      callReactionsCreate(this.currentChatRoom.id, message.id, {
        user_id: this.$store.state.currentUserId,
        reactable_type: "ChatMessage",
        reactable_id: message.id,
        reaction_type_id: type.id,
      })
        .then(() => {
          this.openElement = null;
          sendMessage("", this.currentChatRoom.id, this.$store.state.currentUserId);
          this.getRoomMessages(this.currentChatRoom.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    toggleReactions(identifier) {
      this.openElement = this.openElement === identifier ? null : identifier;
    },
    closeReactionMenu() {
      this.openElement = null;
    },
    formatMessage(message) {
      const urlPattern = /https?:\/\/[^\s]+/g;
      return message.replace(urlPattern, (url) => `<a class="chat-link" href="${url}" target="_blank">${url}</a>`);
    },
    getDate(date) {
      return formatDateToDayTimeYear(date);
    },
    openChatImageModal(url) {
      this.$store.commit("chatRooms/setChatImageModalUrl", url);
      this.$store.commit("chatRooms/showChatImageModal");
    },
    async handleFileUpload(event) {
      const selectedFile = event.target.files[0];
      if (!selectedFile) return;

      callChatMessagesCreate(this.currentChatRoom.id, {
        chat_room_id: this.currentChatRoom.id,
        user_id: this.$store.state.currentUserId,
        body: "",
      })
        .then(async (res) => {
          const messageId = res.id;
          const formData = new FormData();
          formData.append("image", selectedFile);
          formData.append("chat_message_id", messageId);

          await Axios.post("/api/v1/chat_message_images", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          sendMessage("", this.currentChatRoom.id, this.$store.state.currentUserId);
          this.newMessageBody = "";
          this.getRoomMessages(this.currentChatRoom.id);
          setChatCallback(() => {
            this.getRoomMessages(this.currentChatRoom.id);
          });

          const otherUsersInRoom = findOtherUsersInRoom(this.currentChatRoom, this.$store);
          otherUsersInRoom.forEach((user) => {
            this.$store.dispatch("notifications/createNotification", {
              userId: user.user_id,
              payload: {
                sender_id: this.$store.state.currentUserId,
                receiver_id: user.user_id,
                notification_type_name: "Chat Message",
                status: "unread",
                notifiable_type: "ChatRoom",
                notifiable_id: this.currentChatRoom.id,
              },
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openChatSettings() {
      this.$store.commit("chatRooms/toggleGroupChatSettingsModal");
    },
    toggleCreateGroupChatModal() {
      this.$store.commit("chatRooms/toggleCreateGroupChatModal");
    },
    getGroupRoomMessages(room) {
      this.groupChatRoomSelected = true;
      this.$store.commit("chatRooms/setCurrentChatTitleData", {
        name: room.name,
        roomType: "Group",
        profileType: null,
        profileId: null,
      });

      this.$store.commit("chatRooms/setCurrentChatRoom", room);

      setChatCallback(() => {
        this.getRoomMessages(room.id);
      });
      connectToAtmoChatChannel(room.id);
      this.$store.commit("chatRooms/changeChatView", "messages");

      this.getRoomMessages(room.id);
    },
    onSelectEmoji(emoji) {
      this.newMessageBody += emoji;
      this.isEmojiPickerOpen = false; // Close the modal after selecting an emoji

      console.log("EMOJI", emoji);
    },
    messageClassFor(message) {
      return ["max-chat__message", message.user_id === this.$store.state.currentUser.profile_id ? "max-chat__message--current-user" : ""].join(" ");
    },
    getUserFriendships() {
      this.$store.dispatch("chatRooms/getUserFriendships", this.$store.state.currentUserId);
    },
    getChatRooms() {
      this.$store.dispatch("chatRooms/getChatRooms", this.$store.state.currentUserId);
    },
    getRoomMessages(roomId) {
      this.$store.dispatch("chatRooms/getRoomMessages", roomId);
    },
    scrollToBottom() {
      // Scroll logic here
    },
    retrieveRoomMessages(chatRoomId) {
      setChatCallback(() => {
        this.getRoomMessages(chatRoomId);
      });
      connectToAtmoChatChannel(chatRoomId);
      this.$store.commit("chatRooms/changeChatView", "messages");

      this.getRoomMessages(chatRoomId);
    },
    saveChatUser(roomId, userId) {
      callChatRoomUsersCreate(roomId, {
        chat_room_id: roomId,
        user_id: userId,
        is_admin: true,
      })
        .then(() => {
          console.log(`SAVING USER ${userId} TO ROOM ${roomId} SUCCESSFUL`);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    createRoom() {
      callChatRoomsCreate({
        name: `private-${this.userChatRoomId}-${this.friendChatRoomId}`,
        room_type: "friend",
        roomable_type: "UserFriendship",
        creator_id: this.userChatRoomId,
        user_ids: [this.userChatRoomId, this.friendChatRoomId],
      })
        .then((res) => {
          this.$store.commit("chatRooms/setCurrentChatRoom", res);

          // 🔥 No need to manually add users anymore
          this.retrieveRoomMessages(this.currentChatRoom.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async setChatRoomAttributes(userChatRoomId, friendChatRoom) {
      console.log("Setting chat room attributes for friend:", friendChatRoom);

      this.userChatRoomId = userChatRoomId;
      this.friendChatRoomId = friendChatRoom.id;

      this.$store.commit("chatRooms/setCurrentChatTitleData", {
        name: friendChatRoom.name,
        roomType: "friend",
        profileType: friendChatRoom.profile_type,
        profileId: friendChatRoom.profile_id,
      });

      try {
        console.log("🔍 Checking existing chat rooms with params:", {
          type: "friend",
          user_id: this.userChatRoomId,
          friend_id: this.friendChatRoomId,
        });

        let response = await callChatRoomsIndex({
          type: "friend",
          user_id: this.userChatRoomId,
          friend_id: this.friendChatRoomId,
        });

        console.log("🔎 API response for existing rooms:", response);

        // 🔥 Ensure we get the right chat room
        let chatRoom = response.find(
          (room) => (room.creator_id === this.userChatRoomId && room.roomable_id === this.friendChatRoomId) || (room.creator_id === this.friendChatRoomId && room.roomable_id === this.userChatRoomId)
        );

        if (!chatRoom || !chatRoom.id) {
          console.log("❌ No existing chat room found, creating a new one...");

          chatRoom = await callChatRoomsCreate({
            name: `private-${this.userChatRoomId}-${this.friendChatRoomId}`,
            room_type: "friend",
            roomable_type: "UserFriendship",
            roomable_id: friendChatRoom.id,
            creator_id: this.userChatRoomId,
            user_ids: [this.userChatRoomId, this.friendChatRoomId],
          });
        }

        console.log("✅ Chat room set:", chatRoom);

        this.$store.commit("chatRooms/setCurrentChatRoom", chatRoom);

        // ✅ Reset messages before loading new ones
        this.$store.commit("chatRooms/setChatRoomMessages", []);

        if (chatRoom.id) {
          this.getRoomMessages(chatRoom.id);
          connectToAtmoChatChannel(chatRoom.id);
        } else {
          console.error("❌ Error: chatRoom.id is still undefined!");
        }
      } catch (error) {
        console.error("❌ Error fetching or creating chat room:", error);
      }
    },
    saveMessage(messageBody) {
      if (!this.currentChatRoom || !this.currentChatRoom.id) {
        console.error("No chat room selected or this.currentChatRoom.id is undefined");
        return;
      }

      callChatMessagesCreate(this.currentChatRoom.id, {
        chat_room_id: this.currentChatRoom.id,
        user_id: this.$store.state.currentUserId,
        body: messageBody,
      })
        .then(() => {
          sendMessage(messageBody, this.currentChatRoom.id, this.$store.state.currentUserId);
          this.newMessageBody = "";
          this.getRoomMessages(this.currentChatRoom.id);
          setChatCallback(() => {
            this.getRoomMessages(this.currentChatRoom.id);
          });

          const otherUsersInRoom = findOtherUsersInRoom(this.currentChatRoom, this.$store);
          otherUsersInRoom.forEach((user) => {
            this.$store.dispatch("notifications/createNotification", {
              userId: user.user_id,
              payload: {
                sender_id: this.$store.state.currentUserId,
                receiver_id: user.user_id,
                notification_type_name: "Chat Message",
                status: "unread",
                notifiable_type: "ChatRoom",
                notifiable_id: this.currentChatRoom.id,
              },
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },

    viewFriends() {
      this.$store.commit("chatRooms/changeChatView", "friends");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.max-chat-modal {
  .ps {
    max-height: 40rem;
  }

  .testing-chat-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    .testing-chat {
      height: 1rem;
      cursor: pointer;

      img {
        height: 1rem;
      }
    }

    .testing-chat + .testing-chat {
      margin-left: 0.5rem;
    }
  }

  .video-call-container {
    position: fixed;
    width: 400px; /* Set fixed width */
    height: 300px; /* Set fixed height */
    bottom: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 10px;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }

  .video-call-header {
    width: 100%;
    background: #222;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
    color: white;
    font-weight: bold;
  }

  .video-call-header button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
  }

  .video-call-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 10px;
  }

  .video-call-container video {
    width: 48%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
  }

  .video-call-container.minimized {
    width: 200px;
    height: 50px;
    overflow: hidden;
    bottom: 10px;
    right: 10px;
  }

  .video-call-container.minimized .video-call-body {
    display: none;
  }

  .mute-button {
    background-color: #444;
    color: white;
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background-color: #666;
    }
  }

  .end-call-button {
    background-color: $atmo-invalid; /* Bright red for emergency/call end */
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.2s ease;

    &:hover {
      background-color: #cc0000; /* Darker red on hover */
    }

    &:active {
      background-color: #a00000; /* Even darker red on click */
    }
  }

  .incoming-call-modal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(96, 72, 117, 0.4);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    z-index: 999999999999999999;

    .incoming-call-modal__heading-container {
      margin-bottom: 0.5rem;
    }

    .incoming-call-modal__button-container {
      display: flex;
      justify-content: center;
      gap: 0.5rem;
    }

    .call-button {
      border-radius: 5px;
      padding: 0.5rem 2rem 0.5rem 2rem;
      border: none;
    }
  }

  .max-chat__hide-modal {
    position: absolute;
    top: 1rem;
    left: 1rem;
    height: 2rem;
    width: 2rem;
    opacity: 0.5;
    cursor: pointer;
  }

  .max-chat__header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    margin-bottom: 2rem;

    .atmo-page-header-container__header-icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .atmo-popover__actions-trigger {
      height: 18px;
      cursor: pointer;
      padding: 5px 10px;
      box-sizing: content-box;
    }

    .icon-wrap__icon {
      height: 2rem;
    }
  }

  .messages-container {
    min-height: 45rem;
  }

  .messages-container.empty-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
  }

  .max-chat-grid {
    // border: 1px solid white;
    display: grid;
    grid-template-columns: minmax(max-content, 20rem) 1fr;
    grid-gap: 1rem;
    // height: 100%;
  }

  .container__heading-wrap {
    font-size: 1.5rem;
    color: $atmo-blue--medium;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2rem;
    // border: 1px solid white;
    width: 100%;
    display: flex;
    align-items: center;

    .heading-wrap__text-wrap {
      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .heading-wrap__icon-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .icon-wrap__icon {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  .heading-wrap__settings-wrap {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .settings-icon {
    height: 1.5rem;
    cursor: pointer;
    top: 0rem;
  }

  .max-chat-grid__friends-container {
    // border: 1px solid white;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0rem 1rem 1rem 1rem;
  }

  .friends-container__search-wrap {
    margin-bottom: 1rem;
  }

  .search-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    // width: 100%;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .friends-container__friends-wrap {
    margin-bottom: 1rem;
    min-height: 25rem;
  }

  .friend-chat-title {
    color: $atmo-blue--medium;
  }

  .friends-scroll-wrap {
    max-height: 20rem;
  }

  .rooms-scroll-wrap {
    max-height: 16rem;
  }

  .friends-wrap__header {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding: 0.3rem 0rem 0.3rem 0rem;
    display: flex;
    justify-content: space-between;
  }

  .friends-container__rooms-wrap {
    margin-bottom: 1rem;
    max-height: 16rem;
  }

  .rooms-wrap__header {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding: 0.3rem 0rem 0.3rem 0rem;
    display: flex;
    justify-content: space-between;
  }

  .create-room {
    cursor: pointer;
    color: white;
  }

  .members-wrap__header {
    font-size: 0.8rem;
    text-transform: uppercase;
    color: $atmo-gray--light--faded;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 1rem;
    padding: 0.3rem 0rem 0.3rem 0rem;
  }

  .users-wrap__user-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    padding: 0.3rem 0.3rem 0.3rem 0.3rem;

    &:hover {
      background-color: rgba(190, 167, 221, 0.3);

      .user-wrap__make-admin-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $atmo-blue--medium;
        font-weight: 500;
      }

      .user-wrap__delete-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 1rem;
      }
    }

    .image-wrap__image {
      width: 2.2rem;
      height: 2.2rem;
      border-radius: 100%;
      margin-right: 0.5rem;
      object-fit: cover;
    }

    .user-wrap__name-wrap {
      font-size: 0.8rem;
      font-weight: 500;
      margin-right: 0.5rem;
    }

    .user-wrap__make-admin-wrap {
      display: none;
    }

    .user-wrap__delete-wrap {
      display: none;
    }

    .delete-wrap__icon {
      height: 0.7rem;
      opacity: 0.3;
    }
  }

  .max-chat__message {
    display: flex;
    padding: 7px 0;

    &:hover {
      .reaction-trigger {
        margin-right: 1rem;
        cursor: pointer;
        display: block;
        height: 1rem;
        width: 1rem;
        border-radius: 100%;
        font-size: 1.5rem;
      }
    }

    .reaction-trigger {
      display: none;
    }

    &--current-user {
      flex-direction: row-reverse;

      .max-chat__message-content {
        padding: 7px 6px 3px;
        background: rgba($atmo-purple--light, 0.3);
        border-radius: 2px;
        margin-left: 5px;
        border: none;
        max-width: 20rem;
        position: relative;

        .user-reactions-container {
          // border: 1px solid red;
          position: absolute;
          bottom: -0.5rem;
          left: -0.3rem;
          display: flex;

          .reaction__img {
            height: 1rem;
            width: 1rem;
          }
        }
      }

      .chat-link {
        color: $atmo-blue--medium;
      }

      .max-chat__message-timestamp {
        align-self: flex-end;
        color: white;
      }

      .reactions-container {
        padding: 0.5rem;
        display: flex;
        gap: 0.5rem;
        background-color: $atmo-purple--medium-dark--overlay-faded;
        position: absolute;
        bottom: -1rem;
        left: 1rem;
        border-radius: 2px;
        max-width: 8rem;

        .reactions-container__reaction-icon {
          cursor: pointer;
          height: 1rem;
          width: 1rem;
          background-color: $atmo-purple--medium-dark--overlay-faded;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .reaction-icon__img {
            height: 1rem;
          }
        }
      }
    }

    .reactions-container {
      padding: 0.5rem;
      display: flex;
      gap: 0.5rem;
      background-color: $atmo-purple--medium-dark--overlay-faded;
      position: absolute;
      bottom: -1rem;
      right: 1rem;
      border-radius: 2px;
      max-width: 8rem;

      .reactions-container__reaction-icon {
        cursor: pointer;
        height: 1rem;
        width: 1rem;
        background-color: $atmo-purple--medium-dark--overlay-faded;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .reaction-icon__img {
          height: 1rem;
        }
      }
    }

    .user-reactions-container__reactions-length {
      height: 1rem;
      width: 1rem;
      font-weight: 600;
      border: 1px solid $atmo-blue--medium;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $atmo-purple--medium-dark--overlay-faded;
      border-radius: 100%;
      color: white;
    }
  }

  .max-chat__message-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 7px 6px 3px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    margin-right: 5px;
    max-width: 20rem;
    position: relative;

    .user-reactions-container {
      // border: 1px solid red;
      position: absolute;
      bottom: -0.5rem;
      right: -0.3rem;
      display: flex;

      .reaction__img {
        height: 1rem;
        width: 1rem;
      }
    }

    .chat-link {
      color: $atmo-blue--medium;
    }
  }

  .max-chat__message-text {
    color: white;
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .max-chat__message-song {
    display: flex;
    cursor: pointer;
  }

  .max-chat__message-song-icon {
    height: 15px;
    width: 15px;
  }

  .max-chat__message-song-name {
    color: white;
    cursor: pointer;
    font-style: italic;
    font-size: 0.8rem;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
    margin-bottom: 5px;

    &:hover {
      color: $atmo-purple--dark;
    }
  }

  .max-chat__message-image-wrap {
    display: flex;
    cursor: pointer;
    // padding: .5rem .5rem .5rem 0rem;
    margin-bottom: 0.5rem;
  }

  .max-chat__message-image {
    object-fit: cover;
    width: 100%;
    border-radius: 2px;
    // height: 15rem;
    // width: 15rem;
  }

  .max-chat__message-timestamp {
    color: white;
    font-size: 0.7rem;
    font-weight: 300;
  }

  .max-chat__messages-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .max-chat__form {
    width: 100%;
    margin: 5px 0;
    // display: flex;
  }

  /* .max-chat__text-input {
    margin-top: 1rem;
    // min-width: 30rem;
    width: 100%;
    // height: 7rem;
    padding: 1rem 0.5rem 1rem 3.5rem;
    color: white;
    font-size: 0.8rem;
  } */

  .input-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .max-chat__text-input {
    width: 100%;
    padding: 1rem 5rem 1rem 3rem; /* Adjust padding for space */
    color: white;
    font-size: 0.9rem;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;
  }

  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    background: transparent;
  }

  .file-upload-icon {
    left: 10px; /* Positioning inside the input */
  }

  .emoji-picker-trigger {
    //   position: absolute;
    left: 1.1rem;
    top: 15px;
    z-index: 1;
    cursor: pointer;
    background: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    font-size: 1.5rem;
    // border: 1px solid red;
  }

  .image-upload-icon {
    // position: absolute;
    // left: 2rem;
    // bottom: .55rem;
    height: 0.9rem;
    cursor: pointer;
  }

  .message-send-button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-right: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  textarea,
  textarea::placeholder {
    color: $atmo-white--medium-faded;
    font-family: "Roboto", sans-serif;
    font-size: 0.8rem;
    font-weight: 400;
  }
}
</style>
