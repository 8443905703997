<template>
  <div class="atmo-dashboard" data-cy="atmo-dashboard">
    <!-- <h2 class="atmo-page-header">Atmo Dashboard</h2> -->

    <!-- LOADING SCREEN -->
    <ProgressLoading :isLoading="isLoading" :loadingMessages="loadingMessages" :progress="progress" />

    <div v-if="!isLoading" class="atmo-dashboard-container">
      <div class="atmo-dashboard-container__dashboard-content">
        <div class="dashboard-content__recommendations">
          <div class="recommendations__moods-explore-container">
            <suggested-playlists :suggestedPlaylists="suggestedPlaylists" :suggestedPlaylistsLoading="suggestedPlaylistsLoading" />
            <div class="moods-explore-container__explore-container">
              <div class="explore-container__title-container">Explore</div>
              <div class="explore-container__grid-container">
                <router-link class="grid-container__item" :to="{ name: 'atmo_artists' }">
                  <div class="item__image-wrap" v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/atmo_artists.png`) + ')' }">
                    <img class="image-wrap__image" src="@/assets/images/icons/atmo_dashboard/artists.png" />
                    <p>Atmo Artists</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'music.charts' }">
                  <div class="item__image-wrap" v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/atmo_charts.png`) + ')' }">
                    <img class="image-wrap__image" src="@/assets/images/icons/atmo_dashboard/charts.png" />
                    <p>Atmo Charts</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'products.index' }">
                  <div class="item__image-wrap" v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/atmo_store.png`) + ')' }">
                    <img class="image-wrap__image" src="@/assets/images/icons/atmo_dashboard/store.png" />
                    <p>Atmo Store</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'atmo_radio.index' }">
                  <div class="item__image-wrap" v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/radio.png`) + ')' }">
                    <img class="image-wrap__image" src="@/assets/images/icons/atmo_dashboard/channel.png" />
                    <p>Atmo Radio</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="`/events/`">
                  <div class="item__image-wrap" v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/atmo_channel.png`) + ')' }">
                    <img class="image-wrap__image" src="@/assets/images/icons/atmo_dashboard/radio.png" />
                    <p>Events</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'contests.index' }">
                  <div class="item__image-wrap" v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/radio.png`) + ')' }">
                    <img class="image-wrap__image" src="@/assets/images/icons/atmo_dashboard/channel.png" />
                    <p>Contests</p>
                  </div>
                </router-link>
                <!-- <router-link class="grid-container__item" :to="{ name: 'coming_soon' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/atmo_dashboard/news_and_blogs.png`) + ')' }">
                    <div class="coming-soon">Soon</div>
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/news_and_blogs.png' />
                    <p>News & Blogs</p>
                  </div>
                </router-link> -->
                <!-- <router-link class="grid-container__item" :to="{ name: 'coming_soon' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/artists/${generateRandomString(artistImageArray)}`) + ')' }">
                    <div class="coming-soon">Soon</div>
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/radio.png' />
                    <p>Radio</p>
                  </div>
                </router-link>
                <router-link class="grid-container__item" :to="{ name: 'coming_soon' }">
                  <div class="item__image-wrap"
                    v-bind:style="{ 'background-image': 'url(' + require(`@/assets/images/artists/${generateRandomString(artistImageArray)}`) + ')' }">
                    <div class="coming-soon">Soon</div>
                    <img class="image-wrap__image" src='@/assets/images/icons/atmo_dashboard/channel.png' />
                    <p>Atmo Channel</p>
                  </div>
                </router-link> -->
              </div>
            </div>
          </div>
          <div class="recommendations__category-selection-container">
            <button
              v-for="(category, index) in dashRecCategoryList"
              id="category.key"
              :key="index"
              @click="selectCategoryOrType(category.key, 'dashRecCategoryList')"
              v-bind:class="{ categoryselected: category.isSelected, categorynotselected: !category.isSelected }"
            >
              {{ category.name }}
            </button>
          </div>
          <div class="recommendations__recommended-songs-container">
            <div class="recommended-songs-container__type-selection-container">
              <button
                v-for="(type, index) in dashRecTypeList"
                id="type.key"
                :key="index"
                @click="selectCategoryOrType(type.key, 'dashRecTypeList')"
                v-bind:class="{ typeselected: type.isSelected, typenotselected: !type.isSelected }"
              >
                {{ type.name }}
              </button>
            </div>
            <RecommendedSongs
              v-if="selectedRecType.typeName === 'songs'"
              :songs="currentRecObjs"
              :currentlyPlayingSong="currentlyPlayingSong"
              @play="playSong"
              @pause="pauseSong"
              @togglePlayPause="togglePlayPause"
            />
            <RecommendedAlbums v-else-if="selectedRecType.typeName === 'albums'" :albums="currentRecObjs" />
            <RecommendedArtists v-else-if="selectedRecType.typeName === 'artists'" :artists="currentRecObjs" />
            <RecommendedPlaylists v-else-if="selectedRecType.typeName === 'playlists'" :playlists="currentRecObjs" />
          </div>
        </div>
        <BroadcastPostList :posts="broadcastPosts" :loading="postsLoading" />
      </div>
      <div class="atmo-dashboard-container__spotlight-songs">
        <div class="spotlight-songs__title-container">Spotlight</div>
        <div class="spotlight-songs__songs-content-container">
          <div v-if="spotlightSongsLoading">LOADING</div>
          <div v-else class="spotlight-songs-grid">
            <div v-for="(song, index) in spotlightSongs" :key="index" class="songs-content-container__song-item">
              <div
                class="song-item__play"
                @click="togglePlayPause(song)"
                v-bind:style="{
                  'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%),' + `url(${getFeaturedImage(song?.primary_artist?.user?.images, 'full')})`,
                }"
              >
                <button class="spotlight-song-play-button" v-show="currentlyPlayingSong !== song?.id"></button>
                <button class="spotlight-song-pause-button" v-show="currentlyPlayingSong === song?.id"></button>
              </div>
              <div class="song-item__title-artist">
                <p class="title-artist__title">{{ song?.name }}</p>
                <p class="title-artist__artist">
                  <router-link v-if="song?.primary_artist?.id" class="artist-blue" :to="{ name: 'artist_profiles.show', params: { artistProfileId: song?.primary_artist?.id } }">
                    {{ song?.primary_artist?.user?.name }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import devMockData from "@/devUtils/mockData.js";
import { getFeaturedImage, timeAgo } from "@/helpers/utilityFunctions";
import { mapActions, mapState } from "vuex";
import BroadcastPostList from "@/components/atmo-dashboards/broadcast-post-list";
import SuggestedPlaylists from "@/components/atmo-dashboards/suggested-playlists";
import { callPlaylistsSuggested, callAtmoDashboards } from "@/helpers/axiosCalls";
import ProgressLoading from "@/components/progress-loading";
import RecommendedSongs from "@/components/atmo-dashboards/recommended-songs";
import RecommendedAlbums from "@/components/atmo-dashboards/recommended-albums";
import RecommendedArtists from "@/components/atmo-dashboards/recommended-artists";
import RecommendedPlaylists from "@/components/atmo-dashboards/recommended-playlists";

export default {
  components: {
    BroadcastPostList,
    SuggestedPlaylists,
    ProgressLoading,
    RecommendedSongs,
    RecommendedAlbums,
    RecommendedArtists,
    RecommendedPlaylists,
  },
  data() {
    return {
      liveStreams: [],
      devMockData,
      selectedCategory: null,
      suggestedPlaylists: [],
      spotlightSongs: [],
      featuredObj: {},
      currentRecObjs: [],
      selectedRecCategory: {
        key: "0",
        name: "Featured this Week",
        catName: "featured",
        typeName: "songs",
        isSelected: true,
      },
      selectedRecType: {
        key: "0",
        name: "Songs",
        catName: "featured",
        typeName: "songs",
        isSelected: true,
      },
      dashRecCategoryList: [
        {
          key: "0",
          name: "Featured this Week",
          catName: "featured",
          typeName: "songs",
          isSelected: true,
        },
        {
          key: "1",
          name: "New Releases",
          catName: "new_releases",
          typeName: "songs",
          isSelected: false,
        },
        {
          key: "2",
          name: "Recommended for you",
          catName: "recommended",
          typeName: "songs",
          isSelected: false,
        },
        {
          key: "3",
          name: "Recently played",
          catName: "recently_played",
          typeName: "songs",
          isSelected: false,
        },
        {
          key: "4",
          name: "Most played",
          catName: "most_played",
          typeName: "songs",
          isSelected: false,
        },
      ],
      dashRecTypeList: [
        {
          key: "0",
          name: "Songs",
          catName: "featured",
          typeName: "songs",
          isSelected: true,
        },
        {
          key: "1",
          name: "Albums",
          catName: "featured",
          typeName: "albums",
          isSelected: false,
        },
        {
          key: "2",
          name: "Artists",
          catName: "featured",
          typeName: "artists",
          isSelected: false,
        },
        {
          key: "3",
          name: "Playlists",
          catName: "featured",
          typeName: "playlists",
          isSelected: false,
        },
      ],
      postsLoading: true,
      suggestedPlaylistsLoading: true,
      recommendedSongsLoading: true,
      spotlightSongsLoading: true,
      currentlyPlayingSong: null,
      isLoading: true, // Track loading state
      progress: 0,
      progressInterval: null,
      loadingMessages: [
        "Initializing Music Dashboard...",
        "Fetching Latest Playlists...",
        "Synchronizing Personalized Recommendations...",
        "Retrieving Spotlight Songs...",
        "Analyzing Your Listening Trends...",
        "Connecting to Music Network...",
        "Optimizing UI for Faster Experience...",
        "Loading Live Broadcasts...",
        "Finalizing Dashboard Setup...",
      ],
    };
  },
  created() {
    // this.getSuggestedPlaylists();
    // this.getSpotlightSongs();
    this.getDashboardRecObjs("featured");
    this.loadDashboardData();
    this.$store.commit("setHudHeader", "Atmo Dashboard");
  },
  mounted() {
    this.$store.dispatch("userActivities/getBroadcastPosts", {
      userId: this.$store.state.currentUserId,
      params: {
        date_range: "all",
        limit: 20,
        show_current_user_posts: "true",
      },
    });
    this.createUserAccomplishments({
      userId: this.$store.state.currentUserId,
      accomplishmentData: {
        payloads: [
          {
            user_id: this.$store.state.currentUserId,
            category: "logins",
            sub_category: "loginNumber",
          },
          {
            user_id: this.$store.state.currentUserId,
            category: "logins",
            sub_category: "loginTime",
          },
          {
            user_id: this.$store.state.currentUserId,
            category: "logins",
            sub_category: "loginStreak",
          },
        ],
      },
    });
  },
  computed: {
    ...mapState("userActivities", ["broadcastPosts", "postsLoading"]),
    isPlaying() {
      return this.$store.state.player.isPlaying; // Assuming you have this state in your Vuex store
    },
  },
  methods: {
    ...mapActions("accomplishments", ["createUserAccomplishments"]),
    ...mapActions("userActivities", ["getBroadcastPosts"]),
    togglePlayPause(song) {
      // Check if the song is currently playing
      if (this.currentlyPlayingSong === song.id && this.isPlaying) {
        this.pauseSong(); // Pause if it's already playing
      } else {
        this.playSong(song); // Play if it's not playing
      }
    },
    playSong(song) {
      this.currentlyPlayingSong = song.id;
      this.$store.dispatch("player/prependAndPlaySong", song); // Dispatch play action
    },
    pauseSong() {
      this.$store.dispatch("player/pauseSong"); // Dispatch pause action
      this.currentlyPlayingSong = null;
    },
    getTimeAgo(date) {
      return timeAgo(date);
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    showPauseButton(index) {
      this.currentlyPlayingSong = index;
    },
    showPlayButton() {
      this.currentlyPlayingSong = null;
    },
    selectCategoryOrType(key, listName) {
      for (let i = 0; i < this[`${listName}`].length; i++) {
        if (this[`${listName}`][i].key !== key) {
          this[`${listName}`][i].isSelected = false;
        }
      }
      this.toggleSelection(key, listName);
    },
    toggleSelection(key, listName) {
      const obj = this[`${listName}`].find((item) => item.key === key);

      if (listName === "dashRecCategoryList") {
        this.selectedRecCategory = obj;
      } else if (listName === "dashRecTypeList") {
        this.selectedRecType = obj;
      }

      this.getDashboardRecObjs(this.selectedRecCategory.catName);
      if (obj) {
        obj.isSelected = !obj.isSelected;
      }
    },
    getDashboardRecObjs(routeName) {
      this.recommendedSongsLoading = true;
      callAtmoDashboards(routeName, {
        user_id: this.$store.state.currentUserId,
        type: this.selectedRecType.typeName,
        length: 9,
      })
        .then((response) => {
          this.currentRecObjs = response;
          this.recommendedSongsLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSpotlightSongs() {
      callAtmoDashboards("spotlight", {
        user_id: this.$store.state.currentUserId,
        type: "songs",
        length: 6,
      })
        .then((response) => {
          this.spotlightSongs = response;
          this.spotlightSongsLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSuggestedPlaylists() {
      callPlaylistsSuggested({
        user_id: this.$store.state.currentUserId,
        length: 3,
      })
        .then((response) => {
          this.suggestedPlaylists = response;
          console.log("SUGGESTED PLAYLISTS", this.suggestedPlaylists);
          this.suggestedPlaylistsLoading = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async loadDashboardData() {
      this.progress = 0;
      this.isLoading = true;

      try {
        const userId = this.$store.state.currentUserId;
        const totalSteps = this.loadingMessages.length; // Align with message count
        const progressIncrement = 100 / totalSteps; // Ensure smooth progress updates
        let currentStep = 0;

        const updateProgress = () => {
          this.progress = Math.min(100, Math.floor((++currentStep / totalSteps) * 100));
        };

        // **Step 1: Initial Progress Update**
        updateProgress(); // "Initializing Music Dashboard..."

        // **Step 2: Fetch Suggested Playlists**
        await this.getSuggestedPlaylists();
        updateProgress(); // "Fetching Latest Playlists..."

        // **Step 3: Small delay to allow UI updates**
        await new Promise((resolve) => setTimeout(resolve, 300));
        updateProgress(); // "Synchronizing Personalized Recommendations..."

        // **Step 4: Fetch Spotlight Songs**
        await this.getSpotlightSongs();
        updateProgress(); // "Retrieving Spotlight Songs..."

        // **Step 5: Small delay to let UI refresh**
        await new Promise((resolve) => setTimeout(resolve, 300));
        updateProgress(); // "Analyzing Your Listening Trends..."

        // **Step 6: Fetch Broadcast Posts**
        await this.getBroadcastPosts({
          userId,
          params: { date_range: "all", limit: 20, show_current_user_posts: "true" },
        });
        updateProgress(); // "Loading Live Broadcasts..."

        // **Final Step: Ensure 100% Progress**
        this.progress = 100;
      } catch (error) {
        console.error("Error loading dashboard:", error);
        this.progress = 100; // Prevent UI from getting stuck
      } finally {
        setTimeout(() => {
          this.isLoading = false;
        }, 800);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.artist-blue {
  color: $atmo-blue--medium;
}

.friend-white {
  color: white;
}

.ps {
  max-height: 27rem;
}

.atmo-dashboard {
  margin: 0 75px;
}

.atmo-dashboard-container {
  // border: 1px solid white;

  &__dashboard-content {
    // border: 1px solid red;
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 200px;
  }

  .recommendations__moods-explore-container {
    display: grid;
    gap: 30px;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 1rem;
  }

  .explore-container__title-container {
    font-size: 1rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .explore-container__grid-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
  }

  .grid-container__item {
    position: relative;
  }

  .grid-container__item .coming-soon {
    background: rgba(154, 45, 101, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 0px 5px 0px 5px;
    padding: 3px 7px 3px 7px;
    text-transform: uppercase;
  }

  .item__image-wrap {
    height: 100px;
    width: 100%;
    background-color: rgba(96, 72, 117, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-blend-mode: multiply;
    font-weight: 500;
    // background-image: url('@/assets/images/artists/deadmau5.png')
  }

  .image-wrap__image {
    height: 40px;
    object-fit: contain;
    margin-bottom: 0.5rem;
  }

  .recommendations__category-selection-container {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    margin-bottom: 1rem;
  }

  .category-selection-container__category-button {
    border: 1px solid white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
  }

  .categoryselected {
    border: 1px solid white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }

  .categorynotselected {
    border: 1px solid white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    background: transparent;
  }

  .recommendations__recommended-songs-container {
    width: 100%;
    min-width: 700px;
    max-width: 1000px;
  }

  .recommended-songs-container__type-selection-container {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    max-width: 25rem;
  }

  .typeselected {
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    border: none;
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 20px;
  }

  .typenotselected {
    background: rgba(255, 255, 255, 0);
    color: white;
    cursor: pointer;
    border: none;
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    color: white;
    background-image: unset;
  }

  .songs-grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    min-width: 700px;
    max-width: 1000px;
    height: 8.5rem;
  }

  .recommended-songs-container__type-selection-container {
    margin-bottom: 1rem;
  }

  .songs-container__song-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .song-item__image-wrap {
    height: 5rem;
    width: 5rem;
    // background-color: $atmo-purple--dark;
    border-radius: 100%;
    margin-bottom: 0.5rem;
    // background-size: auto;
    // background-repeat: no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .song-item__image-wrap.selected {
    border: 2px solid $atmo-blue--light;
  }

  .play-button {
    all: unset;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 20px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;
    // play state
    border-style: solid;
    border-width: 10px 0 10px 20px;

    &:hover {
      border-color: transparent transparent transparent $atmo-blue--light;
    }
  }

  .pause-button {
    all: unset;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 20px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;
    // play state
    border-style: double;
    border-width: 0px 0 0px 20px;

    &:hover {
      border-color: transparent transparent transparent $atmo-blue--light;
    }
  }

  .title-artist__artist {
    color: $atmo-blue--medium;
  }

  .content-wrap__title {
    text-transform: uppercase;
    margin-bottom: 0.2rem;
    font-weight: 500;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0rem 0.5rem 0rem 0rem;
    max-width: 14ch;
    text-align: center;
  }

  .content-wrap__artist {
    color: $atmo-blue--medium;
    display: block;
    text-align: center;
  }

  .shared-item-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 7rem;
    margin-bottom: 1rem;
    text-align: center;

    .image-wrap {
      height: 5rem;
      width: 5rem;
      border-radius: 100%;
      background-color: $atmo-purple--dark;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      margin-bottom: 0.5rem;
      border: 2px solid $atmo-blue--light;
    }

    .item-title {
      text-transform: uppercase;
      margin-bottom: 0.2rem;
      font-weight: 500;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0rem 0.5rem 0rem 0rem;
      max-width: 14ch;
    }
  }

  &__spotlight-songs {
    // border: 1px solid green;
    min-height: 50px;
    max-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .spotlight-songs__title-container {
    margin-right: 20px;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
  }

  .spotlight-songs__songs-content-container {
    max-width: 75rem;
    overflow-x: hidden;
  }

  .spotlight-songs-grid {
    display: flex;
    gap: 1rem;
  }

  .songs-content-container__song-item {
    display: flex;
    flex-direction: row;
    padding: 0rem 1rem 0rem 1rem;
    border-right: 1px solid white;
  }

  .song-item__play {
    margin-right: 10px;
    background-color: $atmo-purple--dark;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .spotlight-song-play-button {
    all: unset;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 16px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;

    // play state
    border-style: solid;
    border-width: 8px 0 8px 16px;

    // &.paused {
    //   border-style: double;
    //   border-width: 0px 0 0px 10px;
    // }

    &:hover {
      border-color: transparent transparent transparent $atmo-blue--light;
    }
  }

  .spotlight-song-pause-button {
    all: unset;
    border: 0;
    background: transparent;
    box-sizing: border-box;
    width: 0;
    height: 10px;
    border-color: transparent transparent transparent white;
    transition: 100ms all ease;
    cursor: pointer;

    // play state
    border-style: double;
    border-width: 0px 0 0px 10px;

    &:hover {
      border-color: transparent transparent transparent $atmo-blue--light;
    }
  }

  .song-item__title-artist {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title-artist__title {
    margin-bottom: 0.2rem;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0rem 0.5rem 0rem 0rem;
    max-width: 14ch;
  }
}
</style>
