<template>
  <div class="label-dashboard" data-cy="publisher-revenue-insights">
    <atmo-back-link></atmo-back-link>
    <div class="label-dashboard__grid-container">
      <div class="label-dashboard__summary-container">
        <div class="label-dashboard__section-header">Total Revenue</div>
        <div class="label-dashboard__card">
          <p class="label-dashboard__metric-highlight stat-count">${{ totalRevenue !== null ? totalRevenue.toLocaleString() : "—" }}</p>
          <p class="text-sm">
            {{ monthlyChange != null ? `${monthlyChange > 0 ? "+" : ""}${monthlyChange}% this month` : "—" }}
          </p>
        </div>

        <div class="label-dashboard__section-header">Revenue by Source</div>
        <div class="label-dashboard__card">
          <ul class="label-dashboard__list">
            <li v-for="(value, key) in revenueBySource" :key="key">{{ formatRevenueSourceLabel(key) }} — ${{ value.toLocaleString() }}</li>
          </ul>
        </div>

        <div class="label-dashboard__section-header">Revenue by Artist</div>
        <div class="label-dashboard__card">
          <ul class="label-dashboard__list">
            <li v-for="artist in revenueByArtist" :key="artist.name">
              <span>
                <router-link v-if="artist.id" :to="{ name: 'artist_profiles.show', params: { artistProfileId: artist.id } }">
                  {{ artist.name }}
                </router-link>
                — ${{ artist.revenue.toLocaleString() }}
              </span>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div class="label-dashboard__section-header revenue-trends">Revenue Trends (Last 30 Days)</div>
        <div class="label-dashboard__section-split">
          <div class="label-dashboard__section-left">
            <div class="label-dashboard__card" style="margin-bottom: 2rem">
              <ul class="label-dashboard__list">
                <li v-for="insight in humanizedInsights" :key="insight">{{ insight }}</li>
              </ul>
            </div>
          </div>
          <div class="label-dashboard__section-right">
            <div class="label-dashboard__card">
              <apexchart width="100%" height="200" type="line" :options="revenueChartOptions" :series="revenueChartSeries" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callPublisherDashboardsRevenueInsights } from "@/helpers/axiosCalls";

export default {
  components: { AtmoBackLink },
  created() {
    this.$store.commit("setHudHeader", "Revenue Insights");
    this.fetchData();
  },
  data() {
    return {
      publisherProfileId: this.$route.params.publisherProfileId,
      totalRevenue: null,
      revenueBySource: [],
      revenueByArtist: [],
      monthlyChange: null,
      humanizedInsights: [],
      revenueChartSeries: [],
      revenueChartOptions: {
        chart: { type: "line", height: 200, toolbar: { show: false } },
        stroke: { curve: "smooth", width: 4 },
        xaxis: {
          categories: [],
          labels: { show: false },
        },
        yaxis: { labels: { style: { colors: "#ddd" } } },
        tooltip: { theme: "dark" },
        colors: ["#44AEDC"],
      },
    };
  },
  methods: {
    async fetchData() {
      try {
        const data = await callPublisherDashboardsRevenueInsights(this.publisherProfileId);
        this.totalRevenue = data.total_revenue;
        this.revenueBySource = data.revenue_by_source;
        this.revenueByArtist = data.revenue_by_artist;
        this.monthlyChange = data.monthly_change;
        this.humanizedInsights = data.humanized_insights;

        this.revenueChartSeries = [
          {
            name: "Revenue",
            data: data.trends_chart.map((point) => point.revenue),
          },
        ];

        this.revenueChartOptions.xaxis.categories = data.trends_chart.map((point) => point.day);
      } catch (err) {
        console.error("Publisher Revenue Insights API error:", err);
      }
    },
    formatRevenueSourceLabel(key) {
      const labels = {
        streaming: "Streaming",
        merch_sales: "Merch Sales",
        platform_bonuses: "Platform Bonuses",
        other: "Other",
      };
      return labels[key] || key;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.revenue-trends {
  margin-bottom: 1.5rem;
}
</style>
