import Axios from 'axios';

export function callAtmoDashboards(route, queryParams = {}) {
    return Axios.get(`/api/v1/atmo_dashboards/${route}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}