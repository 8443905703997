<template>
  <div class="edit-user-profile" data-cy="edit-user-profile">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">Edit Profile</h2>
    </div> -->
    <div class="edit-user-profile-grid">
      <div class="edit-user-profile-grid__image-container">
        <atmo-media-uploader
          v-if="currentUser?.profile_image?.image?.url"
          :existingMediaUrl="currentUser?.profile_image?.image?.url"
          componentId="image-upload"
          mediaType="image"
          @selected-image-upload="handleUploadedImage"
          height="15rem"
        />
        <perfect-scrollbar>
          <div class="image-container__select-image-container">
            <div class="select-image-container__image-wrap" v-for="(image, index) in currentUser.images" :key="index" v-bind:style="{ 'background-image': `url(${image.image.url})` }">
              <div @click="selectImage(image.id)" class="image-wrap__select-wrap">
                <img class="select-wrap__image" src="@/assets/images/icons/profile/check.png" />
              </div>
              <div @click="deleteImage(image.id)" class="image-wrap__delete-wrap">
                <img class="delete-wrap__image" src="@/assets/images/icons/profile/delete.png" />
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
      <div class="edit-user-profile-grid__fields-container">
        <div class="three-column-grid">
          <div class="field-wrap">
            <label for="name">Business Name</label>
            <input class="text-field" type="text" id="name" name="name" placeholder="ex: Seth" v-model="currentBusinessName" />
          </div>
          <div class="field-wrap">
            <label for="email-address">Email Address</label>
            <input class="text-field" type="text" id="email-address" name="email-address" placeholder="ex: youremail@email.com" v-model="currentUserEmail" />
          </div>
          <div class="field-wrap">
            <label for="address">Address Line 1</label>
            <input class="text-field" type="text" id="address1" name="address1" placeholder="ex: 654 27th Ave N" v-model="currentUserAddressLine1" />
          </div>
          <div class="field-wrap">
            <label for="address">Address Line 2</label>
            <input class="text-field" type="text" id="address2" name="address2" placeholder="ex: 654 27th Ave N" v-model="currentUserAddressLine2" />
          </div>
          <div class="field-wrap">
            <label for="address">Phone</label>
            <input class="text-field" type="text" id="phone" name="phone" placeholder="(111) 222-3333" v-model="currentUserPhoneNumber" />
          </div>
        </div>
        <div class="three-column-grid">
          <div class="field-wrap">
            <label for="country">Country</label>
            <select id="country" v-model="addressData.country" @change="onCountryChange" class="dropdown-group">
              <option value="" disabled>Select Country*</option>
              <option v-for="country in countries" :key="country.id" :value="country.id">
                {{ country.name }}
              </option>
            </select>
          </div>

          <div class="field-wrap">
            <label for="state">State</label>
            <select id="state" v-model="addressData.state" :disabled="!states.length" @change="onStateChange" class="dropdown-group">
              <option value="" disabled>
                {{ states.length ? "Select State*" : "No States Available" }}
              </option>
              <option v-for="state in states" :key="state.id" :value="state.id">
                {{ state.name }}
              </option>
            </select>
          </div>

          <div class="field-wrap">
            <label for="city">City</label>
            <select id="city" v-model="addressData.city" class="dropdown-group">
              <option value="" disabled>Select City*</option>
              <option v-for="city in cities" :key="city.id" :value="city.id">
                {{ city.name }}
              </option>
            </select>
          </div>

          <div class="field-wrap">
            <label for="city">Zip Code</label>
            <input class="text-field" type="text" id="city" name="city" placeholder="ex: Texas City" v-model="currentUserAddressZipCode" />
          </div>
        </div>
        <div class="two-column-grid">
          <div class="field-wrap">
            <label for="business-type">Business Type</label>
            <select id="business-type" v-model="currentBusinessType" class="dropdown-group">
              <option disabled value="">Select Business Type</option>
              <option v-for="type in businessTypes" :key="type.id" :value="type.id">
                {{ type.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="two-column-grid">
          <div class="field-wrap">
            <label for="twitter">Twitter Profile Link</label>
            <input class="text-field" type="text" id="twitter" name="twitter" placeholder="ex: twitter.com/sethjones" v-model="currentBusinessFacebook" />
          </div>

          <div class="field-wrap">
            <label for="facebook">Facebook Profile Link</label>
            <input class="text-field" type="text" id="facebook" name="facebook" placeholder="ex: fb.com/sethjones" v-model="currentBusinessTwitter" />
          </div>

          <div class="field-wrap">
            <label for="facebook">Website Link</label>
            <input class="text-field" type="text" id="facebook" name="facebook" placeholder="ex: fb.com/sethjones" v-model="currentBusinessWebsite" />
          </div>
        </div>
        <div class="fields-container__button-wrap">
          <div class="button-wrap__save-cancel-container">
            <button class="save-cancel-container__save-button" @click="saveUserAttributes()">Save</button>
            <button class="save-cancel-container__cancel-button" @click="goBack()">Cancel</button>
          </div>
          <div class="button-wrap__account-settings-container">
            <router-link class="account-settings-container__account-settings-button" :to="{ name: 'AMI.settings' }">
              <img class="account-settings-button__image" src="@/assets/images/icons/profile/gear.png" />
              Account Settings
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { callImagesIndex, callImagesDelete, callImagesUpdate, callUsersUpdate, callBusinessProfilesShow, callUsersShow } from "@/helpers/axiosCalls";
import AtmoMediaUploader from "@/components/atmo-media-uploader";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    AtmoMediaUploader,
  },
  async created() {
    await this.getCurrentUser();
    await this.getAddressData();
    await this.getBusinessTypes();
    this.$store.commit("setHudHeader", "Edit Profile");
  },
  data() {
    return {
      file: "",
      addressData: {
        country: "",
        state: "",
        city: "",
      },
      currentUser: {
        profile_image: {
          image: {
            url: null,
          },
        },
      },
      currentUserProfile: {},
      firstNameChanged: false,
      lastNameChanged: false,
      newProfileImage: null,
      newUserAttributes: {
        userProfile: {},
        user: {},
        address: {},
      },
      businessTypes: [],
    };
  },
  computed: {
    ...mapState("address", ["countries", "states", "cities"]),
    ...mapGetters("address", ["getCountryById", "getStateById", "getCityById"]),
    currentBusinessProfile() {
      return this.currentUserProfile || {};
    },
    currentCountryName() {
      return this.getCountryById(this.addressData.country)?.name || "";
    },
    currentStateName() {
      return this.getStateById(this.addressData.state)?.name || "";
    },
    currentCityName() {
      return this.getCityById(this.addressData.city)?.name || "";
    },
    currentBusinessName: {
      get() {
        return this.currentBusinessProfile?.name || "";
      },
      set(value) {
        this.newUserAttributes.businessProfile = this.newUserAttributes.businessProfile || {};
        this.newUserAttributes.businessProfile.name = value;
      },
    },
    currentUserEmail: {
      get() {
        return this.currentUser.email;
      },
      set(value) {
        this.newUserAttributes.user.email = value;
      },
    },
    currentUserAddressLine1: {
      get() {
        return this.currentUser.user_address ? this.currentUser.user_address.line_1 : "";
      },
      set(value) {
        this.newUserAttributes.address.line_1 = value;
      },
    },
    currentUserAddressLine2: {
      get() {
        return this.currentUser.user_address ? this.currentUser.user_address.line_2 : "";
      },
      set(value) {
        this.newUserAttributes.address.line_2 = value;
      },
    },
    currentUserAddressCountry: {
      get() {
        return this.addressData.country || (this.currentUser.user_address ? this.currentUser.user_address.country : "");
      },
      set(value) {
        this.addressData.country = value;
        this.newUserAttributes.address.country = value;
      },
    },
    currentUserAddressState: {
      get() {
        return this.addressData.state || (this.currentUser.user_address ? this.currentUser.user_address.state : "");
      },
      set(value) {
        this.addressData.state = value;
        this.newUserAttributes.address.state = value;
      },
    },
    currentUserAddressCity: {
      get() {
        return this.addressData.city || (this.currentUser.user_address ? this.currentUser.user_address.city : "");
      },
      set(value) {
        this.addressData.city = value;
        this.newUserAttributes.address.city = value;
      },
    },
    currentUserAddressZipCode: {
      get() {
        return this.currentUser.user_address ? this.currentUser.user_address.zip_code : "";
      },
      set(value) {
        this.newUserAttributes.address.zip_code = value;
      },
    },
    currentUserPhoneNumber: {
      get() {
        if (this.currentUser.phone) {
          let cleaned = ("" + this.currentUser.phone).replace(/\D/g, "");
          let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
          if (match) {
            return "(" + match[1] + ") " + match[2] + "-" + match[3];
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      set(value) {
        this.newUserAttributes.user.phone = value.replace(/[^+\d]+/g, "");
      },
    },
    currentBusinessFacebook: {
      get() {
        return this.currentBusinessProfile?.facebook || "";
      },
      set(value) {
        this.newUserAttributes.businessProfile = this.newUserAttributes.businessProfile || {};
        this.newUserAttributes.businessProfile.facebook = value;
      },
    },

    currentBusinessTwitter: {
      get() {
        return this.currentBusinessProfile?.twitter || "";
      },
      set(value) {
        this.newUserAttributes.businessProfile = this.newUserAttributes.businessProfile || {};
        this.newUserAttributes.businessProfile.twitter = value;
      },
    },

    currentBusinessWebsite: {
      get() {
        return this.currentBusinessProfile?.website || "";
      },
      set(value) {
        this.newUserAttributes.businessProfile = this.newUserAttributes.businessProfile || {};
        this.newUserAttributes.businessProfile.website = value;
      },
    },
    currentBusinessType: {
      get() {
        return this.currentBusinessProfile?.business_profile_type_id || "";
      },
      set(value) {
        this.newUserAttributes.businessProfile = this.newUserAttributes.businessProfile || {};
        this.newUserAttributes.businessProfile.business_profile_type_id = value;
      },
    },
  },
  watch: {
    "addressData.country"(newValue) {
      this.addressData.state = "";
      this.addressData.city = "";
      if (newValue) {
        this.fetchStates(newValue);
      }
    },
    "addressData.state"(newValue) {
      this.addressData.city = "";
      if (newValue) {
        this.fetchCities();
      }
    },
  },
  methods: {
    ...mapActions("address", ["fetchCountries", "fetchStates", "fetchCities"]),
    async getBusinessTypes() {
      try {
        const response = await Axios.get("/api/v1/business_profile_types");
        this.businessTypes = response.data;
      } catch (error) {
        console.error("Failed to load business types", error);
      }
    },
    validateForm() {
      if (!this.currentUserFirstName || !this.currentUserLastName || !this.currentUserEmail) {
        alert("First Name, Last Name, and Email are required!");
        return false;
      }
      if (!this.validateEmail(this.currentUserEmail)) {
        alert("Invalid Email Format");
        return false;
      }
      return true;
    },
    validateEmail(email) {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    },
    onCountryChange() {
      this.addressData.state = "";
      this.addressData.city = "";
      if (this.addressData.country) {
        this.fetchStates(this.addressData.country); // Pass selected country_id
      }
    },
    onStateChange() {
      this.addressData.city = "";
      if (this.addressData.state) {
        this.fetchCities(this.addressData.state); // Pass selected state_id
      }
    },
    handleUploadedImage(value) {
      this.newProfileImage = value;

      // Prepare image data
      const imageData = {
        user_id: this.currentUser.id,
        imageable_id: this.currentUser.id,
        imageable_type: "User",
        is_featured_image: true,
        name: `Profile Image ${new Date().toISOString()}`,
        image: value, // Assuming `value` is the file or base64 string of the image
      };

      // Add image data to newUserAttributes
      this.newUserAttributes.image = imageData;
    },
    async selectImage(imageId) {
      if (!window.confirm(`Are you sure you want to make this image your profile image?`)) {
        return false;
      }

      await callImagesUpdate(imageId, {
        is_featured_image: true,
      });

      await this.getCurrentUser();
    },
    async deleteImage(imageId) {
      if (!window.confirm(`Are you sure you want to delete this image?`)) {
        return false;
      }

      await callImagesDelete(imageId);
      await this.getCurrentUser();
    },
    goBack() {
      this.$router.back();
    },
    convertMMDDYYToDate(birthday) {
      if (birthday) {
        let date = birthday.split("/");
        return new Date(date[2], date[0] - 1, date[1]);
      }
    },
    async saveUserAttributes() {
      if (!this.validateForm()) return;

      const updatedPayload = {};

      // Prepare address
      this.newUserAttributes.address = {
        ...this.newUserAttributes.address,
        country_id: this.addressData.country,
        state_id: this.addressData.state,
        city_id: this.addressData.city,
        line_1: this.newUserAttributes.address.line_1,
        line_2: this.newUserAttributes.address.line_2,
        zip_code: this.newUserAttributes.address.zip_code,
      };

      const hasImage = this.newUserAttributes.image;
      const formData = hasImage ? new FormData() : null;

      // Track user attribute changes
      if (this.currentUser.email !== this.newUserAttributes.user.email) {
        updatedPayload.email = this.newUserAttributes.user.email;
      }
      if (this.currentUser.phone !== this.newUserAttributes.user.phone) {
        updatedPayload.phone = this.newUserAttributes.user.phone;
      }

      // Address changes
      const addressAttributes = this.getChangedAttributes(this.currentUser.user_address, this.newUserAttributes.address, ["line_1", "line_2", "country_id", "state_id", "city_id", "zip_code"]);
      if (Object.keys(addressAttributes).length > 0) {
        updatedPayload.address_attributes = addressAttributes;
      }

      // Business profile changes
      const businessProfileAttributes = this.getChangedAttributes(this.currentBusinessProfile, this.newUserAttributes.businessProfile, [
        "name",
        "facebook",
        "twitter",
        "website",
        "business_profile_type_id",
      ]);
      if (Object.keys(businessProfileAttributes).length > 0) {
        updatedPayload.business_profile = businessProfileAttributes;
      }

      // Add image fields to FormData
      if (hasImage) {
        const image = this.newUserAttributes.image;
        formData.append("image[user_id]", image.user_id);
        formData.append("image[imageable_id]", image.imageable_id);
        formData.append("image[imageable_type]", image.imageable_type);
        formData.append("image[is_featured_image]", image.is_featured_image);
        formData.append("image[name]", image.name);
        formData.append("image[image]", image.image);

        // Add user-level fields
        if (updatedPayload.email !== undefined) {
          formData.append("email", updatedPayload.email);
        }
        if (updatedPayload.phone !== undefined) {
          formData.append("phone", updatedPayload.phone);
        }

        // Add address
        if (updatedPayload.address_attributes) {
          Object.entries(updatedPayload.address_attributes).forEach(([key, value]) => {
            formData.append(`address_attributes[${key}]`, value);
          });
        }

        // Add business profile
        if (updatedPayload.business_profile) {
          Object.entries(updatedPayload.business_profile).forEach(([key, value]) => {
            formData.append(`business_profile[${key}]`, value);
          });
        }
      }

      // Log what’s being sent
      console.log("Updated Payload", updatedPayload);

      // Submit the update
      try {
        if (hasImage) {
          await Axios.patch(`/api/v1/users/${this.currentUser.id}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
        } else if (Object.keys(updatedPayload).length > 0) {
          await Axios.patch(`/api/v1/users/${this.currentUser.id}`, updatedPayload);
        } else {
          console.log("No changes detected.");
          return;
        }

        window.alert("Successfully updated business profile");
        await this.getCurrentUser();
      } catch (error) {
        console.error("Error updating business profile:", error);
        window.alert("There was a problem saving your changes.");
      }
    },

    // Helper method to get changed attributes
    getChangedAttributes(currentAttributes, newAttributes, keys) {
      const changedAttributes = {};
      keys.forEach((key) => {
        if (currentAttributes?.[key] !== newAttributes?.[key]) {
          changedAttributes[key] = newAttributes[key];
        }
      });
      return changedAttributes;
    },
    getCurrentUserProfile(profileId) {
      callBusinessProfilesShow(profileId)
        .then((response) => {
          this.currentUserProfile = response;
          console.log("this.currentUserProfile", this.currentUserProfile);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async getCurrentUser() {
      try {
        const response = await callUsersShow(this.$store.state.currentUserId);
        this.currentUser = response;
        this.getCurrentUserProfile(this.currentUser.profile_id);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    },
    async getAddressData() {
      // Fetch countries first
      await this.fetchCountries();

      // Prepopulate the country
      this.addressData.country = this.currentUser.user_address?.country_id || null;

      // Fetch states for the selected country
      if (this.addressData.country) {
        await this.fetchStates(this.addressData.country);
      }

      // Prepopulate the state
      this.addressData.state = this.currentUser.user_address?.state_id || null;

      // Fetch cities for the selected state
      if (this.addressData.state) {
        await this.fetchCities(this.addressData.state);
      }

      // Prepopulate the city
      this.addressData.city = this.currentUser.user_address?.city_id || null;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.edit-user-profile {
  margin: 0 75px;

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-user-profile-grid {
    display: grid;
    gap: 3rem;
    // grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 18rem 1fr;
    // border: 1px solid white;
  }

  .image-container__image-wrap {
    height: 19rem;
    // width: 19rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .image-wrap__icon-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .icon-wrap__icon {
    height: 9rem;
    margin-bottom: 1rem;
  }

  .icon-wrap__text {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
  }

  .image-wrap__image {
    border-radius: 5px;
    margin-bottom: 0.8rem;
    box-sizing: border-box;
  }

  .editing-image {
    border: 3px dashed white;
  }

  .image-container__select-image-container {
    max-height: 12rem;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));
  }

  .select-image-container__image-wrap {
    height: 5rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      .image-wrap__select-wrap {
        display: flex;
      }

      .image-wrap__delete-wrap {
        display: flex;
      }
    }
  }

  .image-wrap__select-wrap {
    height: 1.5rem;
    width: 1.5rem;
    background: $atmo-purple--medium-dark--overlay-faded;
    border-radius: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: none;
  }

  .image-wrap__delete-wrap {
    height: 1.5rem;
    width: 1.5rem;
    background: rgba(220, 53, 69, 0.9);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    display: none;
  }

  .image-wrap__select-wrap + .image-wrap__delete-wrap {
    margin-left: 0.5rem;
  }

  .select-wrap__image {
    height: 0.8rem;
  }

  .delete-wrap__image {
    height: 0.8rem;
  }

  .two-column-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1.5rem;
  }

  .three-column-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.5rem;
  }

  .field-wrap {
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .text-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.4);
    }

    .dropdown-group {
      background: $atmo-purple--medium-dark;
      color: white;
      text-transform: uppercase;
      border: none;
      border-radius: 5px;
      padding: 0.5rem;
      font-weight: 500rem;
      cursor: pointer;
    }

    .dropdown-field {
    }

    .radio-group {
      display: flex;
      padding: 0.6rem 0rem 0.6rem 0rem;
    }

    .radio-field-wrap {
      margin-right: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .radio-field {
      margin-right: 0.5rem;
      cursor: pointer;
    }

    .radio-field-label {
      margin: 2px 0px 0px 0px;
    }
  }

  .fields-container__button-wrap {
    display: flex;
    margin-top: 1rem;
  }

  .button-wrap__save-cancel-container {
    display: flex;

    .save-cancel-container__save-button {
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: transparent;
      background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
      border-radius: 5px;
      padding: 0.5rem 2rem 0.5rem 2rem;
      margin-right: 1rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      cursor: pointer;
    }

    .save-cancel-container__cancel-button {
      border: 1px solid white;
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: transparent;
      border-radius: 5px;
      padding: 0.5rem 1.5rem 0.5rem 1.5rem;
      margin-right: 1rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }

  .button-wrap__account-settings-container {
    display: flex;
    margin-left: auto;

    .account-settings-container__account-settings-button {
      font-size: 1rem;
      text-transform: uppercase;
      color: white;
      background-color: $atmo-purple--medium;
      border-radius: 5px;
      padding: 0.5rem 1.5rem 0.5rem 1.5rem;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .account-settings-button__image {
      height: 1rem;
      width: auto;
      margin-right: 0.5rem;
    }
  }
}
</style>
