<template>
  <div class="songs-grid">
    <div v-for="(song, index) in songs" :key="index" class="songs-container__song-item">
      <div
        class="song-item__image-wrap selected"
        @click="toggle(song)"
        :style="{
          'background-image': `linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%), url(${getFeaturedImage(song?.primary_artist?.user?.images, 'full')})`,
        }"
      >
        <button class="play-button" v-show="currentlyPlayingSong !== song?.id" @click.stop="$emit('play', song)"></button>
        <button class="pause-button" v-show="currentlyPlayingSong === song?.id" @click.stop="$emit('pause')"></button>
      </div>
      <div class="song-item__content-wrap">
        <p class="content-wrap__title">{{ song?.name }}</p>

        <router-link v-if="song?.primary_artist?.id" class="content-wrap__artist artist-blue" :to="{ name: 'artist_profiles.show', params: { artistProfileId: song?.primary_artist?.id } }">
          {{ song?.primary_artist?.user?.name }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { getFeaturedImage } from "@/helpers/utilityFunctions";
export default {
  props: {
    songs: Array,
    currentlyPlayingSong: Number,
  },
  methods: {
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    toggle(song) {
      this.$emit("toggle", song);
    },
  },
};
</script>
