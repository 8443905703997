import Axios from 'axios';

export function callAtmoLibrariesSongsByAlbum(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/atmo_libraries/songs_by_album`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callAtmoLibrariesSongsByArtist(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/atmo_libraries/songs_by_artist`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}


export function callAtmoLibrariesSongs(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/atmo_libraries/songs`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}